import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { formatDateRange } from "../formatters";
import "dayjs/locale/es";

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.locale("es");

interface WeekFilterProps {
  onWeekSelect: (week: number | null, startDay: string) => void;
  onRequestData?: (week: number, startDay: string) => void;
  dateRange: string | null;
  setDateRange: (range: string) => void;
  year: number;
  setYear: (year: number) => void;
  projectId: number;
  initialStartDate?: Dayjs | null;
  initialEndDate?: Dayjs | null;
  disabled?: boolean; // Nueva prop
}

const WeekFilter: React.FC<WeekFilterProps> = ({
  onWeekSelect,
  dateRange,
  onRequestData,
  setDateRange,
  setYear,
  projectId,
  initialStartDate,
  initialEndDate,
  disabled,
}) => {
  const [selectedWeek, setSelectedWeek] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [range, setRange] = useState<{ start: Dayjs; end: Dayjs } | null>(null);
  const theme = useTheme();

  const weekOptions = Array.from({ length: 52 }, (_, i) => ({
    id: i + 1,
    name: `Semana ${i + 1}`,
  }));

  const parseWeekRange = (weekString: string) => {
    console.log(weekString);
    const [startDay, , endDay, , month, year] = weekString.split(" ");
    const startDate = dayjs(
      `${startDay} ${month} ${year}`,
      "DD MMMM YYYY",
      "es"
    );
    const endDate = dayjs(`${endDay} ${month} ${year}`, "DD MMMM YYYY", "es");
    return { start: startDate, end: endDate };
  };

  const calculateWeekNumber = (date: Dayjs): number => {
    let week = date.week();
    const dayOfWeek = date.day();

    // Si es jueves o después, incrementamos la semana
    if (dayOfWeek >= 4) {
      week++;
      // Si la semana supera 52, volvemos a la semana 1
      if (week > 52) {
        week = 1;
      }
    }

    return week;
  };

  const calculateDateRange = (date: Dayjs): { start: Dayjs; end: Dayjs } => {
    const dayOfWeek = date.day();
    let thursdayOfWeek;

    // Calculamos el jueves de la semana
    if (dayOfWeek >= 4) {
      // Si es jueves o después
      thursdayOfWeek = date.subtract(dayOfWeek - 4, "day");
    } else {
      thursdayOfWeek = date.subtract(dayOfWeek + 3, "day");
    }

    const startOfWeek = thursdayOfWeek.startOf("day");
    const endOfWeek = startOfWeek.add(6, "day").endOf("day");

    return { start: startOfWeek, end: endOfWeek };
  };

  useEffect(() => {
    if (initialStartDate && initialEndDate) {
      // Usar las fechas iniciales proporcionadas
      const weekNumber = calculateWeekNumber(initialEndDate);
      setSelectedWeek(weekNumber);
      setSelectedDate(initialStartDate);
      setRange({ start: initialStartDate, end: initialEndDate });
      setDateRange(
        `${initialStartDate.format("YYYY-MM-DD")} / ${initialEndDate.format(
          "YYYY-MM-DD"
        )}`
      );
      setYear(initialStartDate.year());
      onWeekSelect(weekNumber, "Wednesday");
    } else {
      // Usar la fecha de hoy como valor predeterminado
      const today = dayjs();
      const initialRange = calculateDateRange(today);
      const weekNumber = calculateWeekNumber(initialRange.end);

      setSelectedWeek(weekNumber);
      setSelectedDate(today);
      setRange(initialRange);
      setDateRange(
        `${initialRange.start.format("YYYY-MM-DD")} / ${initialRange.end.format(
          "YYYY-MM-DD"
        )}`
      );
      setYear(today.year());
      onWeekSelect(weekNumber, "Wednesday");
    }
  }, [initialStartDate, initialEndDate]);

  const handleWeekChange = (_event: any, newValue: any) => {
    const week = newValue ? newValue.id : null;
    if (week && week !== selectedWeek) {
      const currentYear = dayjs().year();
      // Comenzamos desde el miércoles de la semana seleccionada
      const wednesdayOfWeek = dayjs().year(currentYear).week(week).day(3);
      const newRange = calculateDateRange(wednesdayOfWeek);
      const weekNumber = calculateWeekNumber(newRange.end);

      setSelectedWeek(weekNumber);
      setSelectedDate(wednesdayOfWeek);
      setRange(newRange);
      setDateRange(
        `${newRange.start.format("YYYY-MM-DD")} / ${newRange.end.format(
          "YYYY-MM-DD"
        )}`
      );
      setYear(wednesdayOfWeek.year());
      onWeekSelect(weekNumber, "Wednesday");
    }
  };

  const handleDateChange = (newValue: Dayjs | null) => {
    if (newValue) {
      const newRange = calculateDateRange(newValue);
      const weekNumber = calculateWeekNumber(newRange.end);

      setSelectedDate(newValue);
      setSelectedWeek(weekNumber);
      setRange(newRange);
      setDateRange(
        `${newRange.start.format("YYYY-MM-DD")} / ${newRange.end.format(
          "YYYY-MM-DD"
        )}`
      );
      onWeekSelect(weekNumber, "Thursday");
    }
  };

  const isInSelectedWeek = (day: Dayjs) => {
    if (!range) return false;
    const startDate = range.start.startOf("day");
    const endDate = range.end.startOf("day");
    const currentDate = day.startOf("day");

    return (
      currentDate.unix() >= startDate.unix() &&
      currentDate.unix() <= endDate.unix()
    );
  };

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={2}
        sx={{
          background: theme.palette.secondary.light,
          borderRadius: "10px",
          padding: "15px 15px",
          marginLeft: "2px",
          maxWidth: "375px",
          minWidth: "375px",
        }}
      >
        <Grid item xs={6}>
          <Autocomplete
            options={weekOptions}
            getOptionLabel={(option) => option.name}
            value={
              weekOptions.find((option) => option.id === selectedWeek) || null
            }
            onChange={handleWeekChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Seleccionar Semana"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
            disabled={disabled}
          />
        </Grid>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <Grid item xs={6}>
            <DatePicker
              label="Seleccionar Día"
              value={selectedDate}
              onChange={handleDateChange}
              format="YYYY-MM-DD"
              slots={{
                textField: (props) => (
                  <TextField
                    {...props}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                ),
              }}
              slotProps={{
                day: (ownerState) => {
                  const isSelected = isInSelectedWeek(ownerState.day);
                  const startOfWeek = range?.start.startOf("day");
                  const endOfWeek = range?.end.startOf("day");

                  let borderRadius = {};

                  if (isSelected) {
                    if (
                      startOfWeek &&
                      ownerState.day.unix() === startOfWeek.unix()
                    ) {
                      borderRadius = {
                        borderTopLeftRadius: "50%",
                        borderBottomLeftRadius: "50%",
                      };
                    }
                    if (
                      endOfWeek &&
                      ownerState.day.unix() === endOfWeek.unix()
                    ) {
                      borderRadius = {
                        borderTopRightRadius: "50%",
                        borderBottomRightRadius: "50%",
                      };
                    }
                  }

                  return {
                    sx: isSelected
                      ? {
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                          borderRadius: 0,
                          ...borderRadius,
                          "&:hover": {
                            backgroundColor: theme.palette.primary.dark,
                          },
                          "&:focus": {
                            backgroundColor: theme.palette.primary.dark,
                          },
                        }
                      : {},
                  };
                },
              }}
              sx={{
                "& .MuiPickersDay-root": {
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  },
                },
              }}
            />
          </Grid>
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12}>
        {range && (
          <Typography
            variant="subtitle2"
            sx={{ marginTop: 2, fontWeight: "bold" }}
          >
            Semana seleccionada: {selectedWeek} ({formatDateRange(dateRange!)})
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default WeekFilter;
