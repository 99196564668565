import { useState, useEffect } from "react";
import {
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Box,
  Divider,
  InputLabel,
  Select,
  useTheme,
  Dialog,
  OutlinedInput,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FancyDialogActions from "./FancyDialogActions";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";
import FancyTooltip from "./FancyTooltip";
import {
  deleteDocuments,
  uploadDocuments,
  getRequestDocuments,
} from "../../services/documents/DocumentService";
import { useApp } from "../../hooks/useApp";

interface AddOrUpdateInventoryTypes {
  inventoryImage?: File[];
  id?: number | null;
  code: string;
  officialCode?: string;
  serialCode: string;
  description: string;
  acquisitionDate: string;
  availability: boolean;
  availableQuantity?: number;
  quantity?: number;
  brand: string;
  category: string;
  color: string;
  cost?: number;
  status: number;
  subSubFamily: {
    id: number;
    name?: string;
    code?: string;
    description?: string;
    subFamily: {
      id: number;
      name?: string;
      code?: string;
      description?: string;
      family: {
        id: number;
        name?: string;
        code?: string;
        description?: string;
      };
    };
  };
  warehouseLocation: string;
  warehouseId?: number;
  measure: string;
  notes?: string;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType: string;
  FamilyData?: any;
  SubFamilyData?: any;
  SubSubFamilyData?: any;
  WarehouseData?: any;
  handleActiveUser?: any;
  handleFetchSubFamilyData: (id: number, setSubfamily: any) => void;
  handleFetchSubSubFamilyData: (id: number, setSubSubfamily: any) => void;
}

const AddOrUpdateInventory = ({
  actionButton,
  data,
  FamilyData,
  WarehouseData,
  onSubmit,
  cancelModal,
  modalType,
  handleFetchSubFamilyData,
  handleFetchSubSubFamilyData,
}: DialogProps) => {
  const { authInfo, setLoading, setErrorMsg } = useApp();
  const theme = useTheme();
  const validationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    code: Yup.string().required("Codigo es requerido"),
    serialCode: Yup.string().required("Campo es requerido"),
    description: Yup.string().required("Campo es requerido"),
    acquisitionDate: Yup.string().required("Campo es requerido"),
    availability: Yup.boolean().required("Campo es requerido"),
    // availableQuantity: Yup.number()
    //   .required("Cantidad Disponible es un campo obligatorio")
    //   .positive("Debe ser un número mayor a 0"),
    // quantity: Yup.number()
    //   .required("Cantidad Total es un campo obligatorio")
    //   .positive("Debe ser un número mayor a 0"),
    brand: Yup.string().required("Campo es requerido"),
    category: Yup.string().required("Campo es requerido"),
    color: Yup.string().required("Campo es requerido"),
    // cost: Yup.number().required("El costo es un campo obligatorio").positive("Debe ser un número mayor a 0"),
    status: Yup.number().required("Campo es requerido"),
    warehouseLocation: Yup.string().required("Campo es requerido"),
    notes: Yup.string().max(500, "No puede exceder los 500 caracteres"),
    measure: Yup.string().required("Campo es requerido"),
    subSubFamily: Yup.object().shape({
      id: Yup.number()
        .required("Campo es requerido")
        .positive("Debe ser un número mayor a 0"),
      name: Yup.string(),
      code: Yup.string(),
      description: Yup.string(),
      subFamily: Yup.object().shape({
        id: Yup.number()
          .required("Campo es requerido")
          .positive("Debe ser un número mayor a 0"),
        name: Yup.string(),
        code: Yup.string(),
        description: Yup.string(),
        family: Yup.object().shape({
          id: Yup.number()
            .required("Campo es requerido")
            .positive("Debe ser un número mayor a 0"),
          name: Yup.string(),
          code: Yup.string(),
          description: Yup.string(),
        }),
      }),
    }),
  });

  const defaultValues: AddOrUpdateInventoryTypes = {
    inventoryImage: [] as File[],
    id: data?.id ?? 0,
    code: data?.code ?? "",
    officialCode: data?.officialCode ?? "",
    serialCode: data?.serialCode ?? "",
    description: data?.description ?? "",
    acquisitionDate: data?.acquisitionDate
      ? dayjs(data.acquisitionDate).format("YYYY-MM-DD")
      : dayjs().format("YYYY-MM-DD"),
    availability: data?.availability ?? true,
    availableQuantity: data?.availableQuantity ?? 0,
    quantity: data?.quantity ?? 0,
    brand: data?.brand ?? "",
    category: data?.category ?? "",
    color: data?.color ?? "",
    cost: data?.cost ?? 0,
    status: data?.status ?? 1,
    subSubFamily: {
      id: data?.subSubFamily?.id ?? 0,
      name: data?.subSubFamily?.name ?? "",
      code: data?.subSubFamily?.code ?? "",
      description: data?.subSubFamily?.description ?? "",
      subFamily: {
        id: data?.subSubFamily?.subFamily?.id ?? 0,
        name: data?.subSubFamily?.subFamily?.name ?? "",
        code: data?.subSubFamily?.subFamily?.code ?? "",
        description: data?.subSubFamily?.subFamily?.description ?? "",
        family: {
          id: data?.subSubFamily?.subFamily?.family?.id ?? 0,
          name: data?.subSubFamily?.subFamily?.family?.name ?? "",
          code: data?.subSubFamily?.subFamily?.family?.code ?? "",
          description: data?.subSubFamily?.subFamily?.family?.description ?? "",
        },
      },
    },
    warehouseLocation: data?.warehouseLocation ?? "",
    warehouseId: data?.warehouseId ?? 0,
    measure: data?.measure ?? "Cubeta",
    notes: data?.notes ?? "",
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
  } = useForm<AddOrUpdateInventoryTypes>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const file2 = [
    "https://blinkergroup.com/es-es/imagenes/01225.250x250.jpg",
    "https://gardencentershop.com/t/trim/fit-in/250x250/filters:fill(white)/media/imgs/almacen_articulo/31eebbfc-ca8d-42c2-9902-1ddd018d9a35/7a67de7e-fe8e-44fd-a152-ff3bfa960dc5.jpg",
    "https://telnetron.com/wp-content/uploads/2020/06/3145-250x250.jpg",
  ];

  const [SerialCode, setSerialCode] = useState<any[]>([]);
  const [Subfamily, setSubfamily] = useState<any[]>([]);
  const [SubSubfamily, setSubSubfamily] = useState<any[]>([]);
  const [allowSubfamily, setAllowSubfamily] = useState(false);
  const [allowSubSubfamily, setAllowSubSubfamily] = useState(false);
  const [FamilyId, setFamilyId] = useState(0);
  const [SubfamilyId, setSubfamilyId] = useState(0);
  const [SubSubfamilyId, setSubSubfamilyId] = useState(0);
  const [file, setFile] = useState<any>([]); // Lista de imágenes
  const [activeIndex, setActiveIndex] = useState(0); // Índice de imagen activa
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [refreshImages, setRefreshImages] = useState(false);

  useEffect(() => {
    if (modalType === "update" && FamilyData) {
      const selectedFamily = FamilyData.content?.find(
        (family: any) =>
          family?.id === data?.subSubFamily?.subFamily?.family?.id
      );
      const selectedSubFamily = Subfamily?.find(
        (family: any) => family?.id === data?.subSubFamily?.subFamily?.id
      );
      const selectedSubSubFamily = SubSubfamily?.find(
        (family: any) => family?.id === data?.subSubFamily?.id
      );

      if (selectedFamily && selectedSubFamily && selectedSubSubFamily) {
        setValue("subSubFamily", {
          id: selectedSubSubFamily.id,
          name: selectedSubSubFamily.name,
          code: selectedSubSubFamily.code,
          description: selectedSubSubFamily.description,
          subFamily: {
            id: selectedSubFamily.id,
            name: selectedSubFamily.name,
            code: selectedSubFamily.code,
            description: selectedSubFamily.description,
            family: {
              id: selectedFamily.id,
              name: selectedFamily.name,
              code: selectedFamily.code,
              description: selectedFamily.description,
            },
          },
        });
      } else {
        console.log(
          "No se encontró una coincidencia en familia, subfamilia o subsubfamilia."
        );
      }

      const selectedFamilyId = data?.subSubFamily?.subFamily?.family?.id;
      const selectedSubFamilyId = data?.subSubFamily?.subFamily?.id;

      setSerialCode(data.serialCode);
      setAllowSubfamily(true);
      setAllowSubSubfamily(true);

      if (selectedFamilyId)
        handleFetchSubFamilyData(selectedFamilyId, setSubfamily);
      if (selectedSubFamilyId)
        handleFetchSubSubFamilyData(selectedSubFamilyId, setSubSubfamily);
    } else if (modalType === "create") {
      setSubfamily([]);
      setSubSubfamily([]);
    }
  }, [modalType, FamilyData, data]);

  const handleImageUpload = async (event: any) => {
    const newFile = event.target.files[0];
    if (newFile) {
      const imageUrl = URL.createObjectURL(newFile);
      console.log(imageUrl);
      //NO ELIMINAR

      if (modalType === "update") {
        try {
          const formData = new FormData();
          formData.append("productId", data.id);
          formData.append("file", newFile);
          formData.append("user", authInfo.username);
          await uploadDocuments(formData);
          setLoading && setLoading(false);
        } catch (error: any) {
          setErrorMsg && setErrorMsg(error.message);
          setLoading && setLoading(false);
        }
      }
      setFile((prevFiles: any) => [...prevFiles, imageUrl]);

      // Obtiene el valor actual de `inventoryImage` y le agrega la nueva imagen
      const currentImages = getValues("inventoryImage") || [];
      setValue("inventoryImage", [...currentImages, newFile]);
    }
    setRefreshImages((prev) => !prev);
  };

  const handleImageDelete = async () => {
    //NO ELIMINAR

    if (modalType === "update") {
      try {
        const imageToDelete = file[activeIndex];

        console.log(imageToDelete);

        await deleteDocuments(imageToDelete.id, data.id);

        setFile((prevFiles: any) => {
          const updatedFiles = [...prevFiles];
          updatedFiles.splice(activeIndex, 1);
          return updatedFiles;
        });

        setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
      } catch (error) {
        console.error("Error al eliminar el documento:", error);
      }
    }

    setFile((prevFiles: any) => {
      const updatedFiles = [...prevFiles];
      console.log(updatedFiles);
      updatedFiles.splice(activeIndex, 1); // Elimina la imagen activa
      return updatedFiles;
    });
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    setRefreshImages((prev) => !prev);
  };

  const handleOpenImageModal = (img: any) => {
    setSelectedImage(img);
    setShowImageModal(true);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
  };

  const fetchImages = async (productId: number) => {
    try {
      if (data?.id) {
        const response = await getRequestDocuments(productId);
        setFile(response);
      }
    } catch (error) {
      console.error("Error al obtener las imágenes:", error);
    }
  };

  useEffect(() => {
    if (data?.id) {
      fetchImages(data.id);
    }
  }, [data?.id, refreshImages]);

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar producto" : "Actualizar producto"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item sm={3} xs={12}>
              <Grid container spacing={3} sx={{ pt: 4, pl: 2 }}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <FancyTooltip
                    title={file?.length > 0 ? "Ampliar imagen" : ""}
                    placement="top"
                  >
                    <Box
                      sx={{
                        width: "120px",
                        height: "120px",
                        border: "1px solid #ccc",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      <Carousel
                        index={activeIndex} // Muestra la imagen activa
                        onChange={(index: any) => setActiveIndex(index)}
                        interval={8000}
                        sx={{
                          width: "120px",
                          height: "120px",
                          "& .carousel-root": {
                            width: "120px",
                            height: "120px",
                          },
                        }}
                        indicators={false}
                      >
                        {file.map((img: any, index: any) => (
                          <Box
                            key={index}
                            onClick={() =>
                              handleOpenImageModal(img.url ? img.url : img)
                            }
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "120px",
                              height: "120px",
                            }}
                          >
                            <Box
                              component="img"
                              src={img.url ? img.url : img}
                              loading="lazy"
                              sx={{
                                maxHeight: "100%",
                                maxWidth: "100%",
                                width: "auto",
                                height: "auto",
                                objectFit: "contain",
                                objectPosition: "center",
                                transition: "max-height 0.3s ease",
                              }}
                              alt={`Imagen ${index + 1}`}
                            />
                          </Box>
                        ))}
                      </Carousel>
                    </Box>
                  </FancyTooltip>
                  <Box sx={{ display: "flex", flexDirection: "column", pl: 5 }}>
                    {/* Ícono para agregar imagen */}
                    <label
                      htmlFor="image-upload"
                      style={{ cursor: "pointer", marginBottom: 1 }}
                    >
                      <AddIcon sx={{ fontSize: 30, color: "green" }} />
                    </label>
                    <input
                      id="image-upload"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                      // disabled={file.length >= 1}
                    />

                    {/* Ícono para eliminar imagen */}
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={handleImageDelete} // Eliminar la imagen actual
                    >
                      <DeleteIcon sx={{ fontSize: 30, color: "red" }} />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={9} xs={12}>
              <Grid container spacing={3} sx={{ pt: 8 }}>
                <Grid item md={4} xs={12}>
                  <Controller
                    name={"subSubFamily.subFamily.family.id"}
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                        error={!!errors.subSubFamily?.subFamily?.family?.id}
                      >
                        <InputLabel shrink>Familia</InputLabel>
                        <Select
                          notched
                          onChange={(e) => {
                            const selectedId = Number(e.target.value);
                            field.onChange(selectedId);
                            setSubfamily([]);
                            setSubSubfamily([]);

                            setFamilyId(selectedId);

                            handleFetchSubFamilyData(selectedId, setSubfamily);
                            setAllowSubfamily(true);
                            setAllowSubSubfamily(false);
                          }}
                          label="Familia"
                          size="small"
                          value={field.value}
                          variant="outlined"
                          sx={{
                            "& input": { pl: "0!important" },
                            color: "#b5bac3",
                          }}
                        >
                          {FamilyData &&
                            FamilyData.content.map((family: any) => (
                              <MenuItem key={family.id} value={family.id}>
                                {family.name}
                              </MenuItem>
                            ))}
                        </Select>
                        {errors.subSubFamily?.subFamily?.family?.id && (
                          <span style={{ color: "red", fontSize: "0.8rem" }}>
                            {errors.subSubFamily.subFamily.family?.id.message}
                          </span>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <Controller
                    name={"subSubFamily.subFamily.id"}
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                        error={!!errors.subSubFamily?.subFamily?.id}
                        disabled={!allowSubfamily}
                      >
                        <InputLabel shrink>Sub Familia</InputLabel>
                        <Select
                          notched
                          onChange={(e) => {
                            const selectedId = Number(e.target.value);
                            field.onChange(selectedId);
                            setSubSubfamily([]);

                            setSubfamilyId(selectedId);

                            handleFetchSubSubFamilyData(
                              selectedId,
                              setSubSubfamily
                            );
                            setAllowSubSubfamily(true);
                          }}
                          label="Sub Familia"
                          size="small"
                          value={field.value}
                          variant="outlined"
                          sx={{
                            "& input": { pl: "0!important" },
                            color: "#b5bac3",
                          }}
                        >
                          {Array.isArray(Subfamily) &&
                            Subfamily.map((subFamily: any) => (
                              <MenuItem key={subFamily.id} value={subFamily.id}>
                                {subFamily.name}
                              </MenuItem>
                            ))}
                        </Select>
                        {errors.subSubFamily?.subFamily?.id && (
                          <span style={{ color: "red", fontSize: "0.8rem" }}>
                            {errors.subSubFamily?.subFamily?.id.message}
                          </span>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <Controller
                    name={"subSubFamily.id"}
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                        error={!!errors.subSubFamily?.id}
                        disabled={!allowSubSubfamily}
                      >
                        <InputLabel shrink>Sub Sub Familia</InputLabel>
                        <Select
                          notched
                          onChange={(e) => {
                            const selectedId = Number(e.target.value);
                            field.onChange(selectedId);

                            const selectedSubSubFamily = SubSubfamily.find(
                              (subSubFamily: any) =>
                                subSubFamily?.id === selectedId
                            );

                            if (selectedSubSubFamily) {
                              setValue("subSubFamily", {
                                id: selectedSubSubFamily.id,
                                name: selectedSubSubFamily.name,
                                code: selectedSubSubFamily.code,
                                description: selectedSubSubFamily.description,
                                subFamily: {
                                  id: selectedSubSubFamily.subFamily.id,
                                  name: selectedSubSubFamily.subFamily.name,
                                  code: selectedSubSubFamily.subFamily.code,
                                  description:
                                    selectedSubSubFamily.subFamily.description,
                                  family: {
                                    id: selectedSubSubFamily.subFamily.family
                                      .id,
                                    name: selectedSubSubFamily.subFamily.family
                                      .name,
                                    code: selectedSubSubFamily.subFamily.family
                                      .code,
                                    description:
                                      selectedSubSubFamily.subFamily.family
                                        .description,
                                  },
                                },
                              });
                            } else {
                              console.log(
                                "No se encontró una coincidencia en familia, subfamilia o subsubfamilia."
                              );
                            }
                          }}
                          label="Sub Sub Familia"
                          size="small"
                          value={field.value}
                          variant="outlined"
                          sx={{
                            "& input": { pl: "0!important" },
                            color: "#b5bac3",
                          }}
                        >
                          {SubSubfamily &&
                            SubSubfamily.map((subSubFamily: any) => (
                              <MenuItem
                                key={subSubFamily.id}
                                value={subSubFamily.id}
                              >
                                {subSubFamily.name}
                              </MenuItem>
                            ))}
                        </Select>
                        {errors.subSubFamily?.id && (
                          <span style={{ color: "red", fontSize: "0.8rem" }}>
                            {errors.subSubFamily?.id.message}
                          </span>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={modalType === "update" ? 4 : 6}>
              <Controller
                name={"code"}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Código"
                    type="text"
                    variant="outlined"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setSerialCode([e.target.value]);
                      setValue("serialCode", e.target.value);
                    }}
                    value={field.value}
                    error={Boolean(errors.code)}
                    helperText={errors.code?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                      maxLength: 100,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={modalType === "update" ? 4 : 6}>
              <Controller
                name={"serialCode"}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Código de Serie"
                    type="text"
                    variant="outlined"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setSerialCode([e.target.value]); // Actualiza solo el estado
                    }}
                    value={SerialCode} // Sincronizado con el estado local
                    error={Boolean(errors.serialCode)}
                    helperText={errors.serialCode?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                      maxLength: 100,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>

            {modalType === "update" && (
              <Grid item xs={12} md={4}>
                <Controller
                  name="officialCode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Código Oficial"
                      type="text"
                      InputProps={{ readOnly: true }}
                      {...field}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Divider sx={{ my: 3 }} />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name={"category"}
                control={control}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!errors.category}
                  >
                    <InputLabel shrink={true}>Categoría</InputLabel>
                    <Select
                      notched
                      onChange={(e) => field.onChange(e.target.value)}
                      label="Categoría"
                      size="small"
                      value={field.value}
                      variant="outlined"
                      sx={{
                        "& input": { pl: "0!important" },
                        color: "#b5bac3",
                      }}
                    >
                      <MenuItem value="Insumos">Insumos</MenuItem>
                      <MenuItem value="Herramientas">Herramientas</MenuItem>
                    </Select>
                    {errors.category && (
                      <span style={{ color: "red", fontSize: "0.8rem" }}>
                        {errors.category.message}
                      </span>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                name={"brand"}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Marca"
                    type="text"
                    variant="outlined"
                    {...field}
                    error={Boolean(errors.brand)}
                    helperText={errors.brand?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                      maxLength: 100,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                name={"color"}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Color"
                    type="text"
                    variant="outlined"
                    {...field}
                    value={field.value}
                    error={Boolean(errors.color)}
                    helperText={errors.color?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                      maxLength: 100,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                name={"measure"}
                control={control}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!errors.status}
                  >
                    <InputLabel shrink>Unidad</InputLabel>
                    <Select
                      notched
                      fullWidth
                      size="small"
                      label="Unidad"
                      variant="outlined"
                      {...field}
                      value={field.value}
                      sx={{ color: "#B0BEC5" }}
                      onChange={(e) => field.onChange(e.target.value)}
                    >
                      <MenuItem value="Cubeta">Cubeta</MenuItem>
                      <MenuItem value="Galón">Galón</MenuItem>
                      <MenuItem value="Unit">Unidad</MenuItem>
                      <MenuItem value="Cuarto Galón">Cuarto Galón</MenuItem>
                    </Select>
                    {errors.measure && (
                      <span style={{ color: "red", fontSize: "0.8rem" }}>
                        {errors.measure.message}
                      </span>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"description"}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Descripción"
                    type="text"
                    variant="outlined"
                    {...field}
                    error={Boolean(errors.description)}
                    helperText={errors.description?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                      maxLength: 100,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 3 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"acquisitionDate"}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    label="Fecha de adquisición"
                    value={dayjs(value, "YYYY-MM-DD")}
                    onChange={(date) => {
                      onChange(date ? dayjs(date).format("YYYY-MM-DD") : "");
                    }}
                    format="YYYY-MM-DD"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        backgroundColor: theme.palette.primary.main,
                      },
                      "& input": {
                        borderRadius: "10px 0 0 10px",
                      },
                      "& button": {
                        paddingLeft: "0",
                        "& svg": {
                          fill: "#FFF",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "10px",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Controller
                name={"warehouseLocation"}
                control={control}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!errors.warehouseLocation}
                  >
                    <InputLabel shrink={true}>Ubicación en bodega</InputLabel>
                    <Select
                      notched
                      onChange={(e) => {
                        const selectedName = e.target.value; 
                        const selectedWarehouse = WarehouseData.find(
                          (warehouse: any) => warehouse.name === selectedName
                        ); // Encontrar el objeto de la bodega seleccionada
                    
                        field.onChange(selectedName); 
                        setValue("warehouseId", selectedWarehouse?.id || null);
                      }}
                      size="small"
                      label="Ubicación en bodega"
                      value={field.value}
                      variant="outlined"
                      sx={{
                        "& input": { pl: "0!important" },
                        color: "#b5bac3",
                      }}
                    >
                      {WarehouseData &&
                        WarehouseData.map((WarehouseData: any) => (
                          <MenuItem
                            key={WarehouseData.id}
                            value={WarehouseData.name}
                          >
                            {WarehouseData.name}
                          </MenuItem>
                        ))}
                    </Select>
                    {errors.warehouseLocation && (
                      <span style={{ color: "red", fontSize: "0.8rem" }}>
                        {errors.warehouseLocation.message}
                      </span>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"cost"}
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    customInput={TextField}
                    fullWidth
                    size="small"
                    label="Costo"
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix="₡"
                    value={field.value || 0}
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      field.onChange(floatValue || 0);
                    }}
                    error={Boolean(errors.cost)}
                    helperText={errors.cost?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                      min: 0,
                      max: 9999999999,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"quantity"}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Cantidad total en existencia"
                    type="number"
                    variant="outlined"
                    {...field}
                    error={Boolean(errors.quantity)}
                    helperText={errors.quantity?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                      min: 0,
                      max: 9999999999,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"availableQuantity"}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Cantidad disponible en bodega"
                    type="number"
                    variant="outlined"
                    {...field}
                    error={Boolean(errors.availableQuantity)}
                    helperText={errors.availableQuantity?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                      min: 0,
                      max: 9999999999,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name={"status"}
                control={control}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!errors.status}
                  >
                    <InputLabel shrink>Estado</InputLabel>
                    <Select
                      notched
                      fullWidth
                      size="small"
                      label="Estado"
                      variant="outlined"
                      {...field}
                      value={field.value}
                      sx={{ color: "#B0BEC5" }}
                      onChange={(e) => field.onChange(e.target.value)}
                    >
                      <MenuItem value={1}>Buena</MenuItem>
                      <MenuItem value={2}>Mala</MenuItem>
                      <MenuItem value={3}>En reparación</MenuItem>
                      <MenuItem value={4}>Dañado</MenuItem>
                      <MenuItem value={5}>Desaparecido</MenuItem>
                      <MenuItem value={6}>Fuera de uso</MenuItem>
                      <MenuItem value={7}>Ignorado</MenuItem>
                      <MenuItem value={8}>Para Ingresar</MenuItem>
                      <MenuItem value={9}>Prestamo</MenuItem>
                      <MenuItem value={10}>Revisión</MenuItem>
                      <MenuItem value={11}>Robada</MenuItem>
                      <MenuItem value={12}>Uso Bodega</MenuItem>
                    </Select>
                    {errors.status && (
                      <span style={{ color: "red", fontSize: "0.8rem" }}>
                        {errors.status.message}
                      </span>
                    )}
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6} sx={{ paddingTop: "7px" }}>
              <Controller
                name={"availability"}
                control={control}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!errors.availability}
                  >
                    <InputLabel shrink>Disponibilidad</InputLabel>
                    <Select
                      notched
                      fullWidth
                      size="small"
                      label="Disponibilidad"
                      variant="outlined"
                      {...field}
                      value={field.value ? 1 : 0}
                      sx={{ color: "#B0BEC5" }}
                      onChange={(e) => field.onChange(e.target.value)}
                    >
                      <MenuItem value={0}>En proyecto</MenuItem>
                      <MenuItem value={1}>Disponible</MenuItem>
                    </Select>
                    {errors.availability && (
                      <span style={{ color: "red", fontSize: "0.8rem" }}>
                        {errors.availability.message}
                      </span>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"notes"}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Notas"
                    multiline
                    maxRows={4}
                    variant="outlined"
                    {...field}
                    error={Boolean(errors.notes)}
                    helperText={errors.notes?.message}
                    sx={{ background: "#FFF" }}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                      sx: { color: "#B0BEC5" },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Dialog open={showImageModal} onClose={handleCloseImageModal}>
        <DialogTitle>Imagen Ampliada</DialogTitle>
        <Dialog
          open={showImageModal}
          onClose={handleCloseImageModal}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle sx={{ fontSize: "1.5rem", textAlign: "center" }}>
            Imagen Ampliada
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            {file && file.length > 0 && (
              <Carousel
                index={activeIndex}
                onChange={(index: any) => setActiveIndex(index)}
                indicators={true}
                navButtonsAlwaysVisible={true}
                autoPlay={false}
                sx={{
                  width: "100%",
                  "& .carousel-root": {
                    width: "100%",
                  },
                }}
              >
                {file.map((img: any, index: any) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "40vh",
                    }}
                  >
                    <Box
                      component="img"
                      src={img.url ? img.url : img}
                      loading="lazy"
                      alt={`Imagen ${index + 1}`}
                      sx={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                    />
                  </Box>
                ))}
              </Carousel>
            )}
          </DialogContent>
        </Dialog>
      </Dialog>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdateInventory;
