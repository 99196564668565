import React, { useState } from 'react';
import { Autocomplete, DialogContent, DialogTitle, Grid, TextField, Typography, Dialog, DialogActions, Button, DialogContentText } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FancyDialogActions from "./FancyDialogActions";
import { getDatesInRange, formatDateRange } from "./formatters";
import { es } from 'date-fns/locale';
import { format, parse } from 'date-fns';
import { useApp } from '../../hooks/useApp';
import { createCoworkerAdvance, validateHours } from '../../services/advances/advancesService';
import dayjs from 'dayjs';

interface DialogProps {
    actionButton?: any;
    data: any;
    cancelModal: any;
    getData?: any;
    coworkersData?: any;
}

const AddOrUpdateCoworkerModal = ({ data, cancelModal, getData, coworkersData }: DialogProps) => {
    const { setLoading, setErrorMsg, setSuccessMsg } = useApp();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [validationMessage, setValidationMessage] = useState("");

    const [startDate, endDate] = data.modalObject.modalObject.split(' / ');
    const datesInRange = getDatesInRange(startDate, endDate);

    const validation = Yup.object().shape({
        dailyHours: Yup.object().shape(
            datesInRange.reduce((acc: any, date: any) => {
                acc[date] = Yup.number()
                    .typeError("Debe ser un número")
                    .min(0, "Debe ser mayor o igual a 0")
                    .required("Campo requerido");
                return acc;
            }, {})
        ),
        selectedUser: Yup.object().nullable().required("Debe seleccionar un usuario")
    });

    const defaultValues = {
        dailyHours: datesInRange.reduce((acc: any, date: any) => {
            acc[date] = 0;
            return acc;
        }, {}),
        selectedUser: null
    };

    const { control, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues,
        //@ts-ignore
        resolver: yupResolver(validation),
    });

    const watchAllFields = watch();
    const totalHours = datesInRange.reduce(
        (total, date) => total + Number(watchAllFields.dailyHours[date] || 0),
        0
    );

    console.log(data)
    console.log(datesInRange)

    const validateHoursBeforeSubmit = async (formData: any, selectedUser: any, year: number, week: number) => {
        console.log(formData)
        const payload = {
            personId: selectedUser.id,
            year,
            week,
            thursdayHours: formData.dailyHours[datesInRange[0]] || 0, // Jueves
            fridayHours: formData.dailyHours[datesInRange[1]] || 0,   // Viernes
            saturdayHours: formData.dailyHours[datesInRange[2]] || 0, // Sábado
            sundayHours: formData.dailyHours[datesInRange[3]] || 0,   // Domingo
            mondayHours: formData.dailyHours[datesInRange[4]] || 0,   // Lunes
            tuesdayHours: formData.dailyHours[datesInRange[5]] || 0,  // Martes
            wednesdayHours: formData.dailyHours[datesInRange[6]] || 0, // Miércoles
        };

        const response = await validateHours(payload);
        return response;
    };

    const submitFormData = async (formData: any) => {
        try {
            setLoading(true);

            const dailyHoursEntries = Object.entries(formData.dailyHours).reverse();

            for (const [date, hours] of dailyHoursEntries) {
                const payload = {
                    date: dayjs(date).format("DD-MM-YYYY"),
                    positionId: formData.selectedUser.positionId,
                    personId: formData.selectedUser.id,
                    progressId: data?.advanceData?.id ? data.advanceData.id : data?.advanceId,
                    workedHours: Number(hours),
                };

                const response: any = await createCoworkerAdvance(payload);

                if (!response?.header) {
                    throw new Error(`Error al agregar horas del día ${date}`);
                }
            }

            setSuccessMsg("Contabilización de horas exitosa");
        } catch (error: any) {
            setErrorMsg(error);
        } finally {
            setLoading(false);
        }

        cancelModal();
        await getData();
    };

    const onSubmit = async (formData: any) => {
        try {
            const validationResponse = await validateHoursBeforeSubmit(formData, formData.selectedUser, data?.year, data?.week);

            const hasExceededHours = Object.values(validationResponse).some((hours: any) => hours !== undefined && hours !== null);

            if (hasExceededHours) {
                setValidationMessage("Alerta: El colaborador está en 2 proyectos y se excede en horas en un mismo día.");
                setShowConfirmationModal(true);
            } else {
                await submitFormData(formData);
            }
        } catch (error) {
            console.error("Error en onSubmit:", error);
            setErrorMsg("Error al validar las horas.");
        }
    };

    const handleConfirm = async () => {
        setShowConfirmationModal(false);
        await submitFormData(watchAllFields);
    };

    const handleCancel = () => {
        setShowConfirmationModal(false);
    };

    return (
        <>
            <DialogTitle sx={{ pt: 4, px: 4 }}>Horas por día</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1" sx={{ mb: 2, pt: 2, px: 4 }}>
                    Rango de fechas: {formatDateRange(data.modalObject.modalObject)}
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} direction="column" sx={{ px: 4 }}>
                        <Grid item xs={12}>
                            <Controller
                                name="selectedUser"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        options={coworkersData || []}
                                        getOptionLabel={(option: any) => `${option.name} - ${option.positionName}`}
                                        onChange={(_, value) => field.onChange(value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Seleccionar usuario"
                                                error={!!errors.selectedUser}
                                                helperText={errors.selectedUser?.message}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        {datesInRange.map((date) => (
                            <Grid item xs={12} key={date} sx={{ mt: 2 }}>
                                <Controller
                                    name={`dailyHours.${date}`}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label={`${format(parse(date, 'yyyy-MM-dd', new Date()), "EEEE, d 'de' MMMM", { locale: es })} (${date})`}
                                            type="number"
                                            inputProps={{ min: 0 }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ mt: 2 }}>
                                Total: {totalHours} horas
                            </Typography>
                        </Grid>
                    </Grid>
                    <FancyDialogActions
                        handleAccept={handleSubmit(onSubmit)}
                        cancelModal={cancelModal}
                        textAcceptButton="Guardar"
                    />
                </form>
            </DialogContent>

            <Dialog open={showConfirmationModal} onClose={handleCancel}>
                <DialogContent>
                    <Typography>{validationMessage}</Typography>
                </DialogContent>
                <FancyDialogActions
                    handleAccept={handleConfirm}
                    cancelModal={handleCancel}
                    textAcceptButton="Aceptar"
                    textCancelButton="Cancelar"
                />
            </Dialog>
        </>
    );
};

export default AddOrUpdateCoworkerModal;