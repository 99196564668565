import { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Button,
  Stack,
  Dialog,
} from "@mui/material";
import {
  TrashIcon,
  PlusIcon,
  PenIcon,
} from "../../components/svgicons/SvgIcons";
import FancyTooltip from "../../components/utils/FancyTooltip";
import FancyPaper from "../../components/FancyPaper";
import { useForm } from "react-hook-form";
import {
  createRequest,
  deleteRequest,
  getRequest,
  updateRequest,
} from "../../services/company/CompanyService";
import { properties } from "../../utils/Properties_es";
import { useApp } from "../../hooks/useApp";
import DeleteModal from "../../components/utils/DeleteModal";
import ResourceAccess from "../../components/security/ResourceAccess";
import FancyTablePagination from "../../components/utils/FancyTablePagination";
import AddOrUpdateCompany from "../../components/utils/AddOrUpdateCompany";
import FancyDialogActions from "../../components/utils/FancyDialogActions";

const ManagementCompany = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const [companyData, setCompanyData] = useState<any>([]);
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);

  const initForm = {
    id: 0,
    description: "",
    phone_one: "",
    phone_two: "",
    email: "",
    contactName: "",
  };
  const [formData, setFormData] = useState<any>(initForm);

  const { handleSubmit } = useForm();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  /**
   * Evento de aplicar filtro de busqueda
   */
  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(0, preFilter);
    }
  };

  /**
   *
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
    currentPage: number,
    filter: string,
    applyRole?: any
  ) => {
    setLoading && setLoading(true);
    try {
      setPreFilter(filter);
      setPage(currentPage);

      let data = await getRequest(currentPage, filter);
      if (data) {
        setCompanyData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  // console.log(companyDataEdit)

  // const handleFetchByID = async (id: number) => {
  //   setLoading && setLoading(true);
  //   try {
  //     let userData = await findByIdRequest(id);
  //     if (userData) {
  //       //@ts-ignore
  //       setFormData(userData.content);
  //     }
  //     setLoading && setLoading(false);
  //   } catch (error: any) {
  //     setErrorMsg && setErrorMsg(error.message);
  //     setLoading && setLoading(false);
  //   }
  // };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    console.log(data);
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      let createData = await createRequest({
        ...data,
        user: authInfo.username,
      });

      if (!createData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_company_create_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_company_create);
      //call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg &&
        setErrorMsg(properties.com_parval_label_company_create_error);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      let updateData = await updateRequest({
        ...data,
        user: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_company_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_company_update);
      //call fetch data
      await handleFetchData(page, "");
    } catch (error: any) {
      setErrorMsg &&
        setErrorMsg(properties.com_parval_label_company_update_error);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      let deleteData: any = await deleteRequest(id);

      if (!deleteData.deleted) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_company_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_company_delete);

      //reset page and call fetch data
      setPage(0);
      await handleFetchData(0, preFilter);
    } catch (error: any) {
      setErrorMsg &&
        setErrorMsg(properties.com_parval_label_company_delete_error);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update") {
      //await handleFetchByID(id);
      object = companyData.content.filter(
        (item: any) => item.id === parseInt(id)
      )?.[0];
    }

    if (modalAction === "delete") {
      object = companyData.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * Evento de cierre de modal
   * @param event
   */
  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }

    /* if (customPage !== page) {
      await handleFetchData(customPage, filter);
    } */
    //await handleFetchData(0, filter);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    //setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit = async (data: any) => {
    console.log(data);
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== "delete") {
      setFormData(formData);
    }
  };

  return (
    <>
      <FancyPaper pagetitle="Empresas">
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={9} sm={6} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={"COMPANY:WRITE"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                data-name="create"
              >
                Agregar empresa
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <TextField
                placeholder="Filtro de búsqueda"
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderRadius: "0.2rem 0 0 0.2rem",
                  },
                  "& fieldset": {
                    borderRightWidth: "0",
                  },
                }}
                value={preFilter}
                onChange={(e: any) => {
                  setPreFilter(e.target.value);
                  if (e.target.value === "") {
                    handleFetchData(0, "");
                  }
                }}
                onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleApplyFilter}
                sx={{
                  borderRadius: "0 0.2rem 0.2rem 0!important",
                }}
              >
                Buscar
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell>Teléfonos</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData &&
                companyData.content &&
                companyData.content.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell scope="row">{row.id}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>
                      {row.phoneNumber1 + "/" + row.phoneNumber1}
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell align="center">
                      <ResourceAccess
                        isCode={true}
                        pathOrCode={"COMPANY:WRITE"}
                      >
                        <FancyTooltip title="Editar" placement="top">
                          <IconButton
                            aria-label="edit"
                            component="label"
                            color="primary"
                            onClick={handleOpenModal}
                            data-name="update"
                            data-id={row.id}
                          >
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title="Eliminar" placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="error"
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name="delete"
                            disabled={row.id === 10 || row.id === 13}
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}

              {!companyData ||
                !companyData.content ||
                (companyData.content?.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      {"No hay registros"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            companyData?.content?.length > 0 ? companyData?.content?.length : 0
          }
          rowsPerPage={companyData.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={companyData.totalElements}
          totalPages={companyData.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateCompany
            data={modalData.modalObject}
            //data={formData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <FancyDialogActions
                handleAccept={handleSubmit(onSubmit)}
                cancelModal={handleCancelModal}
                textAcceptButton={"Eliminar"}
              />
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default ManagementCompany;
