import { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Stack,
  useTheme,
  MenuItem,
  Menu,
} from "@mui/material";
import FancyPaper from "../../components/FancyPaper";
import FancyTablePagination from "../../components/utils/FancyTablePagination";
import { useApp } from "../../hooks/useApp";
import FancySearch from "../../components/utils/FancySearch";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { getRequestHistory } from "../../services/history/HistoryService";
import {
  getRequestHistoryReport,
  getRequestHistoryReportExcel,
} from "../../services/report/ReportService";

const ManagementHistory = () => {
  const theme = useTheme();
  const { setLoading, setErrorMsg, modalData, setModalData, errorMsg } =
    useApp();
  const [inventoryData, setInventoryData] = useState<any>([]);
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [exportMenuAnchor, setExportMenuAnchor] = useState<null | HTMLElement>(
    null
  );

  const initForm = {
    id: 0,
    product: "",
    serial: "",
  };

  // useEffect(() => {
  //   // Asignar la fake data al estado
  //   setInventoryData(FakeDataInventario);
  // }, []);

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  const handleFetchData = async (currentPage: number, filter: string) => {
    setLoading && setLoading(true);
    try {
      let data = await getRequestHistory(
        currentPage,
        filter,
        startDate?.format("YYYY-MM-DD"),
        endDate?.format("YYYY-MM-DD")
      );
      if (data) {
        setInventoryData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de aplicar filtro de busqueda
   */
  const handleApplyFilter = async () => {
    // if (preFilter !== "") {
    await handleFetchData(0, preFilter);
    // }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportMenuAnchor(event.currentTarget);
  };

  const handleExportClose = async () => {
    setExportMenuAnchor(null);
  };

  const handleExportExcel = async () => {
    console.log("excel");
    setLoading && setLoading(true);
    try {
      await getRequestHistoryReportExcel(
        "excel",
        0,
        2147483647,
        startDate?.format("YYYY-MM-DD"),
        endDate?.format("YYYY-MM-DD")
      );
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    } finally {
      setLoading && setLoading(false); // Se asegura que siempre se detenga el loading
      handleExportClose();
    }
  };

  const handleExportPdf = async () => {
    console.log("pdf");
    setLoading && setLoading(true);
    try {
      await getRequestHistoryReport(
        "pdf",
        0,
        2147483647,
        startDate?.format("YYYY-MM-DD"),
        endDate?.format("YYYY-MM-DD")
      );
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    } finally {
      setLoading && setLoading(false); // Se asegura que siempre se detenga el loading
      handleExportClose();
    }
  };

  console.log(inventoryData);

  return (
    <>
      <FancyPaper pagetitle="Historial de movimientos">
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={12} sm={12} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={2}
              >
                <DatePicker
                  label="Fecha de inicio"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  format="YYYY-MM-DD"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      backgroundColor: theme.palette.primary.main,
                    },
                    "& input": {
                      borderRadius: "10px 0 0 10px",
                    },
                    "& button": {
                      paddingLeft: "0",
                      "& svg": {
                        fill: "#FFF",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "10px",
                    },
                  }}
                />
                <DatePicker
                  label="Fecha de fin"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  format="YYYY-MM-DD"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      backgroundColor: theme.palette.primary.main,
                    },
                    "& input": {
                      borderRadius: "10px 0 0 10px",
                    },
                    "& button": {
                      paddingLeft: "0",
                      "& svg": {
                        fill: "#FFF",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
                sx={{
                  flexDirection: { xs: "column", sm: "row" },
                  width: "100%",
                  "& > *": {
                    width: { xs: "100%", sm: "auto" },
                  },
                }}
              >
                <Button variant="contained" onClick={handleExportClick}>
                  Exportar
                </Button>
                <Menu
                  anchorEl={exportMenuAnchor}
                  open={Boolean(exportMenuAnchor)}
                  onClose={handleExportClose}
                >
                  <MenuItem onClick={handleExportExcel}>Excel</MenuItem>
                  <MenuItem onClick={handleExportPdf}>PDF</MenuItem>
                </Menu>
                <FancySearch
                  preFilter={preFilter}
                  setPreFilter={setPreFilter}
                  handleFetchData={() => handleFetchData(0, "")}
                  handleApplyFilter={handleApplyFilter}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="inventory table">
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell align="center">Código Oficial</TableCell>
                <TableCell align="center">Proyecto</TableCell>
                <TableCell align="center">Tipo de movimiento</TableCell>
                <TableCell align="center">Nº de boleta</TableCell>
                <TableCell align="center">Cantidad</TableCell>
                <TableCell align="center">Disponibles</TableCell>
                <TableCell align="center">Persona que retiró</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inventoryData?.content?.length > 0 ? (
                inventoryData?.content?.map((row: any) => (
                  <TableRow key={row.id}>
                    <TableCell>{`${row.date}`}</TableCell>
                    <TableCell>{`${row.product}`}</TableCell>
                    <TableCell align="center">{row.officialCode}</TableCell>
                    <TableCell align="center">{row.project}</TableCell>
                    <TableCell align="center">{row.activityType}</TableCell>
                    <TableCell align="center">{row.ticketNumber}</TableCell>
                    <TableCell align="center">{row.quantity}</TableCell>
                    <TableCell align="center">
                      {row.availableQuantity}
                    </TableCell>
                    <TableCell align="center">{row.personFullName}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9}>No existen registros</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            inventoryData?.content?.length > 0
              ? inventoryData?.content?.length
              : 0
          }
          rowsPerPage={inventoryData.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={inventoryData.totalElements}
          totalPages={inventoryData.totalPages}
        />
      </FancyPaper>
    </>
  );
};

export default ManagementHistory;
