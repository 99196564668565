import { Button, DialogActions } from "@mui/material";

interface DialogActionsType {
  handleAccept: () => void;
  cancelModal: () => void;
  textAcceptButton?: string;
  textCancelButton?: string;
  disableAccept?: boolean; 
}

const FancyDialogActions = ({
  handleAccept,
  cancelModal,
  textAcceptButton,
  textCancelButton,
  disableAccept,
}: DialogActionsType) => {
  return (
    <>
      <DialogActions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {textCancelButton ?? "Cancelar"}
        </Button>{" "}
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            ml: 2,
          }}
          onClick={handleAccept}
          disabled={disableAccept}
        >
          {textAcceptButton ?? "Aceptar"}
        </Button>
      </DialogActions>
    </>
  );
};

export default FancyDialogActions;
