import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import theme from "../theme/theme";

const CardDashboard = (props: any) => {
  const { /*sx,*/ value, iconCard, title, subtitle, colorIcon } = props;
  return (
    <Card
      sx={{
        height: "100%",
        background: theme.palette.secondary.light,
        boxShadow: "none",
        transition: "box-shadow 0.5s ease",
        "&:hover": {
          boxShadow:
            "0px 5px 10px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        },
      }}
    >
      <CardContent sx={{ pb: "8px!important" }}>
        <Stack direction="row" alignItems="flex-start" spacing={3}>
          <Stack
            alignItems="flex-start"
            direction="column"
            justifyContent="space-between"
            spacing={2}
          >
            <Avatar
              sx={{
                backgroundColor: `${colorIcon}`,
                height: 40,
                width: 40,
              }}
            >
              <SvgIcon sx={{ fontSize: "1.5rem" }}>{iconCard}</SvgIcon>
            </Avatar>
          </Stack>
          <Stack direction="column" alignItems="flex-start" spacing={2}>
            <Stack spacing={1}>
              <Typography color="text.secondary" variant="h6">
                {title}
              </Typography>
            </Stack>
            <Typography variant="h4">{value} </Typography>
            <Typography variant="subtitle1">{subtitle}</Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CardDashboard;

CardDashboard.prototypes = {
  difference: PropTypes.number,
  positive: PropTypes.bool,
  sx: PropTypes.object,
  value: PropTypes.string.isRequired,
};
