/*****************************************************************************
 * REVISION 1.0
 * Fecha: 14/01/2023
 *
 * @description JS para manejo de labels
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
export const properties = {
  /** General */

  /** footer */
  com_parval_label_footer_terms_conditions: "Términos y Condiciones",
  /** Page not found */
  com_parval_label_page_404_title: "Oops!",
  com_parval_label_page_404_subtitle: "Página no encontrada",

  /** Loading */
  com_parval_label_loading_title: "Cargando",

  /** Home */
  com_parval_label_home_title: "Parval",

  /** General label */
  com_parval_label_yes: "Si",
  com_parval_label_not: "No",
  com_parval_label_add_subtitle: "Ingrese los datos indicados",
  com_parval_label_update_subtitle: "Modifique los datos indicados",
  com_parval_label_code: "Código",
  com_parval_label_codetfa: "Código TFA",
  com_parval_label_phone: "Teléfono",
  com_parval_label_url: "Url",
  com_parval_label_category: "Categoría",
  com_parval_label_name: "Nombre",
  com_parval_label_lastname: "Apellido",
  com_parval_label_state: "Estado",
  com_parval_label_role: "Perfil",
  com_parval_label_createat: "Fecha de creación",
  com_parval_label_email: "Correo electrónico",
  com_parval_label_password: "Contraseña",
  com_parval_label_passwordrepeat: "Repita contraseña",
  com_parval_label_id: "ID",
  com_parval_label_description: "Descripción",
  com_parval_label_price: "Precio",
  com_parval_label_tax: "ITBIS (18%)",
  com_parval_label_taxes: "Impuesto",
  com_parval_label_department: "Departamento",
  com_parval_label_generationtime: "Tiempo generación",
  com_parval_label_comment: "Comentario",
  com_parval_label_free: "Gratis",
  com_parval_label_zero: "0",
  com_parval_label_select: "Seleccione",
  com_parval_label_select_other: "Otro",
  com_parval_label_not_apply: "No aplica",
  /** General error labels in forms */
  com_parval_label_field_required: "Campo requerido",
  com_parval_label_field_format: "Formato invalido",
  com_parval_label_field_maxlenght: (lenght: string) =>
    `Longitud máxima permitida (${lenght})`,
  com_parval_label_field_minlenght: (lenght: string) =>
    `Longitud minima permitida (${lenght})`,
  /** General sucess labels in process */
  com_parval_label_request_save: "Registro insertado con éxito",
  com_parval_label_request_update: "Registro actualizado con éxito",
  com_parval_label_request_delete: "Registro eliminado con éxito",
  /** General error labels in process */
  com_parval_label_request_connection: "Falla en solicitud al servidor",
  com_parval_label_request_unauthorized:
    "No tiene permiso para realizar esta acción",
  com_parval_label_request_badrequest:
    "Falla en parámetros dentro de la solicitud al servidor",

  /** General button labels */
  com_parval_label_button_accept_title: "Aceptar",
  com_parval_label_button_ok_title: "Ok",
  com_parval_label_button_cancel_title: "Cancelar",
  com_parval_label_button_save_title: "Registrar",
  com_parval_label_button_update_title: "Editar",
  com_parval_label_button_detail_title: "Ver detalle",
  com_parval_label_button_delete_title: "Eliminar",
  com_parval_label_button_back_title: "Atrás",
  com_parval_label_button_back2_title: "Volver",
  com_parval_label_button_add_title: "Agregar",
  com_parval_label_button_search_title: "Buscar",
  com_parval_label_button_clear_title: "Limpiar",
  com_parval_label_button_login_title: "Iniciar Sesión",
  com_parval_label_button_close_title: "Cerrar",
  com_parval_label_button_view_title: "Ver",
  com_parval_label_button_exit_title: "Salir",
  com_parval_label_button_forgotpass_title: "Recuperar contraseña",
  com_parval_label_button_updatepass_title: "Actualizar contraseña",
  com_parval_label_button_updatebooking_title: "Mantenimiento de Instalaciones",

  /** Table label */
  com_parval_label_table_filter_title: "Filtrar",
  com_parval_label_table_filter_placeholder: "Filtro de búsqueda",
  com_parval_label_table_action: "Acciones",
  com_parval_label_table_empty: "No existen registros",

  /** General modal label  */
  com_parval_label_modal_delete_title: "Eliminar registro",

  /** Header dashboard label */
  com_parval_label_dashboard_header_title: "Operaciones",
  /** Menu dashboard label */
  com_parval_label_dashboard_menu_principal: "Portal Administrativo",
  com_parval_label_dashboard_menu_inicio: "Inicio",
  com_parval_label_dashboard_menu_logout: "Cerrar sesión",

  /** Parametros admin menu */
  com_parval_label_dashboard_menu_expedientes: "Expedientes",
  com_parval_label_dashboard_menu_reportes: "Reportes",
  /** configuration admin menu */
  com_parval_label_dashboard_menu_config: "Configuraciones",
  com_parval_label_dashboard_menu_config_user: "Usuarios",
  com_parval_label_dashboard_menu_email_profile: "Perfiles",
  com_parval_label_dashboard_menu_email_permissions: "Permisos",
  com_parval_label_dashboard_menu_email_emailprofile: "Correos por perfil",
  /** login */
  com_parval_label_login_title: "Portal Administrativo",
  com_parval_label_login_forget_password_title: "¿Olvidaste tu contraseña?",
  com_parval_label_login_portal_select_title: "Seleccione portal",
  com_parval_label_login_portal_dd_title: "Due Diligence",
  com_parval_label_login_portal_gl_title: "Gestiones en Línea",
  com_parval_label_login_portal_op_title: "Operaciones",
  /** login success*/
  com_parval_label_login_twofactor_success: "Código TFA enviado al correo",
  /** login error*/
  com_parval_label_login_error: "Error en proceso de inicio de sesión",
  com_parval_label_login_twofactor_error:
    "Error en proceso de enviar código 2fa",
  com_parval_label_login_general_error:
    "Error en proceso de ingresar al portal administrativo",
  com_parval_label_login_logout_error: "Error en proceso de cerrar sesion",
  com_parval_label_login_sessioncode_error:
    "Error en proceso de validar el código de sesión",

  /** RecoveryPassword */
  com_parval_label_recoverypass_title: "Recuperar Contraseña",
  com_parval_label_recoverypass_back_title: "Regresar a login",
  /** ForgotPassword success*/
  com_parval_label_recoverypass_general_sucess:
    "El correo de recuperación a sido enviado",
  /** ForgotPassword error*/
  com_parval_label_recoverypass_general_error:
    "Error en proceso de recuperar contraseña",

  /** ForgotPassword */
  com_parval_label_forgotpass_title: "Cambiar Contraseña",
  /** ForgotPassword success*/
  com_parval_label_forgotpass_general_sucess:
    "El cambio de contraseña se completó",
  /** ForgotPassword error*/
  om_parval_label_forgotpass_different_password_error:
    "Las contraseñas no coinciden",
  com_parval_label_forgotpass_general_error:
    "Error en proceso de cambiar contraseña",

  /** ActiveRegister */
  com_parval_label_activeregister_title: "Activación Usuario",
  /** ActiveRegister success*/
  com_parval_label_activeregister_general_sucess:
    "La activación del usuario se completó",
  com_parval_label_activeregister_general_sucess2:
    "La activación del usuario se completó correctamente.",
  /** ActiveRegister error*/
  com_parval_label_activeregister_general_error:
    "Error en proceso de activar usuario",

  /** Company */
  com_parval_label_company_button_add_title: "Agregar compañía",
  com_parval_label_company_button_update_title: "Editar compañía",

  /** Users */
  com_parval_label_user_button_add_title: "Agregar usuario",
  com_parval_label_user_add_title: "Agregar usuario",
  com_parval_label_user_update_title: "Editar usuario",
  com_parval_label_user_update_pass_title: "Actualizar contraseña",

  /** booking */
  com_parval_label_booking_button_add_title: "Agregar instalación",
  com_parval_label_booking_add_title: "Agregar instalación",
  com_parval_label_booking_update_title: "Editar instalación",
  com_parval_label_booking_update_pass_title: "Configuración Avanzada",
  com_parval_label_booking_update_conf_sucess: "Se actualizó la información correctamente",

  /** Role label */
  com_parval_label_user_button_add_role: "Agregar perfil",
  com_parval_label_user_button_update_role: "Editar perfil",

  /** Guests label */
  com_parval_label_user_button_add_guest: "Agregar invitado",
  com_parval_label_user_button_update_guest: "Editar invitado",

  /** Messages label */
  com_parval_label_user_button_add_message: "Agregar mensaje",
  com_parval_label_user_button_update_message: "Editar mensaje",

  /** Role label */
  com_parval_label_user_button_add_country: "Agregar país",
  com_parval_label_user_button_update_country: "Editar país",
  /** Email add */
  com_parval_label_user_button_add_emailprofile: "Agregar correo",
  /** Users service error label */
  com_parval_label_user_find_error: "Error en consulta de usuarios",
  com_parval_label_user_findid_error: "Error en consulta por id de usuario",
  com_parval_label_user_save_error: "Error en proceso de guardar usuario",
  com_parval_label_user_update_error: "Error en proceso de actualizar usuario",
  com_parval_label_user_delete_error: "Error en proceso de eliminar usuario",
  /** Booking service error label */
  com_parval_label_booking_find_error: "Error en consulta de instalación",
  com_parval_label_booking_findid_error: "Error en consulta por id de instalación",
  com_parval_label_booking_save_error: "Error en proceso de guardar instalación",
  com_parval_label_booking_update_error: "Error en proceso de actualizar instalación",
  com_parval_label_booking_delete_error: "Error en proceso de eliminar instalación",
  /** Area service error label */
  com_parval_label_area_find_error: "Error en consulta de área",
  com_parval_label_area_findid_error: "Error en consulta por id de área",
  com_parval_label_area_save_error: "Error en proceso de guardar área",
  com_parval_label_area_update_error: "Error en proceso de actualizar área",
  com_parval_label_area_delete_error: "Error en proceso de eliminar área",
  /** Role service error label */
  com_parval_label_role_find_error: "Error en consulta de roles",
  com_parval_label_role_findid_error: "Error en consulta por id de rol",
  com_parval_label_role_save_error: "Error en proceso de guardar rol",
  com_parval_label_role_update_error: "Error en proceso de actualizar rol",
  com_parval_label_role_delete_error: "Error en proceso de eliminar rol",
  /** Email Role service error label */
  com_parval_label_emailrole_find_error:
    "Error en consulta de correos por perfil",
  com_parval_label_emailrole_findid_error:
    "Error en consulta por id de correo por perfil",
  com_parval_label_emailrole_save_error:
    "Error en proceso de guardar correo por perfil",
  com_parval_label_emailrole_update_error:
    "Error en proceso de actualizar correo por perfil",
  com_parval_label_emailrole_delete_error:
    "Error en proceso de eliminar correo por perfil",
  /** Email Role service error label */
  com_parval_label_documentRole_find_error:
    "Error en consulta de documentos por perfil",
  com_parval_label_documentRole_findid_error:
    "Error en consulta por id de documento por perfil",
  com_parval_label_documentRole_save_error:
    "Error en proceso de guardar documento por perfil",
  com_parval_label_documentRole_update_error:
    "Error en proceso de actualizar documento por perfil",
  com_parval_label_documentRole_delete_error:
    "Error en proceso de eliminar documento por perfil",
  /** Document service error label */
  com_parval_label_document_find_error: "Error en consulta de documentos",
  com_parval_label_document_findid_error:
    "Error en consulta por id de documento",
  com_parval_label_document_save_error: "Error en proceso de guardar documento",
  com_parval_label_document_update_error:
    "Error en proceso de actualizar documento",
  com_parval_label_document_delete_error:
    "Error en proceso de eliminar documento",

  /** Guests maintenance */
  com_parval_label_visit_create: "Visita creada con éxito",
  com_parval_label_visit_create_error: "Error al crear la visita",
  com_parval_label_visit_update: "Visita actualizada con éxito",
  com_parval_label_visit_update_error: "Error al actualizar la visita",
  com_parval_label_visit_delete: "Visita eliminada con éxito",
  com_parval_label_visit_delete_error: "Error al eliminar la visita",

  /** Authority maintenance */
  com_parval_label_authority_create: "Permiso creado con éxito",
  com_parval_label_authority_create_error: "Error al crear permiso",
  com_parval_label_authority_update: "Permiso actualizado con éxito",
  com_parval_label_authority_update_error: "Error al actualizar el permiso",
  com_parval_label_authority_delete: "Permiso eliminado con éxito",
  com_parval_label_authority_delete_error: "Error al eliminar el permiso",

  /** Country maintenance */
  com_parval_label_country_create: "País creado con éxito",
  com_parval_label_country_create_error: "Error al crear país",
  com_parval_label_country_update: "País actualizado con éxito",
  com_parval_label_country_update_error: "Error al actualizar el país",
  com_parval_label_country_delete: "País eliminado con éxito",
  com_parval_label_country_delete_error: "Error al eliminar el país",

  /** Warehouse maintenance */
  com_parval_label_warehouse_create: "Ubicación en Bodega creada con éxito",
  com_parval_label_warehouse_create_error: "Error al crear ubicación en bodega",
  com_parval_label_warehouse_update: "Ubicación en Bodega actualizada con éxito",
  com_parval_label_warehouse_update_error: "Error al actualizar la ubicación en bodega",
  com_parval_label_warehouse_delete: "Ubicación en Bodega eliminada con éxito",
  com_parval_label_warehouse_delete_error: "Error al eliminar la ubicación en bodega",

  /** Exit Ticket maintenance */
  com_parval_label_exitTicket_create: "Boleta de salida creada con éxito",
  com_parval_label_exitTicket_create_error: "Error al crear boleta de salida",
  com_parval_label_exitTicket_update: "Boleta de salida actualizada con éxito",
  com_parval_label_exitTicket_update_error: "Error al actualizar la boleta de salida",
  com_parval_label_exitTicket_delete: "Boleta de salida eliminada con éxito",
  com_parval_label_exitTicket_delete_error: "Error al eliminar la boleta de salida",

  /** Holiday maintenance */
  com_parval_label_holiday_create: "Día feriado creado con éxito",
  com_parval_label_holiday_create_error: "Error al crear día feriado",
  com_parval_label_holiday_update: "Día feriado actualizado con éxito",
  com_parval_label_holiday_update_error: "Error al actualizar el día feriado",
  com_parval_label_holiday_delete: "Día feriado eliminado con éxito",
  com_parval_label_holiday_delete_error: "Error al eliminar el día feriado",


  /** Country maintenance */
  com_parval_label_bank_create: "Banco creado con éxito",
  com_parval_label_bank_create_error: "Error al crear banco",
  com_parval_label_bank_update: "Banco actualizado con éxito",
  com_parval_label_bank_update_error: "Error al actualizar el banco",
  com_parval_label_bank_delete: "Banco eliminado con éxito",
  com_parval_label_bank_delete_error: "Error al eliminar el banco",

  /** Inventory Control maintenance */
  com_parval_label_inventory_control_create: "Control de inventario creado con éxito",
  com_parval_label_inventory_control_create_error: "Error al crear control de inventario",
  com_parval_label_inventory_control_update: "Control de inventario actualizado con éxito",
  com_parval_label_inventory_control_update_error: "Error al actualizar el control de inventario",
  com_parval_label_inventory_control_delete: "Control de inventario eliminado con éxito",
  com_parval_label_inventory_control_delete_error: "Error al eliminar el control de inventario",

  /** Position maintenance */
  com_parval_label_position_create: "Cargo creado con éxito",
  com_parval_label_position_create_error: "Error al crear cargo",
  com_parval_label_position_update: "Cargo actualizado con éxito",
  com_parval_label_position_update_error: "Error al actualizar el cargo",
  com_parval_label_position_delete: "Cargo eliminado con éxito",
  com_parval_label_position_delete_error: "Error al eliminar el cargo",

  /** Provider maintenance */
  com_parval_label_provider_create: "Proveedor creado con éxito",
  com_parval_label_provider_create_error: "Error al crear proveedor",
  com_parval_label_provider_update: "Proveedor actualizado con éxito",
  com_parval_label_provider_update_error: "Error al actualizar el proveedor",
  com_parval_label_provider_delete: "Proveedor eliminado con éxito",
  com_parval_label_provider_delete_error: "Error al eliminar el proveedor",

  /** Route maintenance */
  com_parval_label_route_create: "Ruta creada con éxito",
  com_parval_label_route_create_error: "Error al crear ruta",
  com_parval_label_route_update: "Ruta actualizada con éxito",
  com_parval_label_route_update_error: "Error al actualizar la ruta",
  com_parval_label_route_delete: "Ruta eliminada con éxito",
  com_parval_label_route_delete_error: "Error al eliminar la ruta",

  /** Inventory maintenance */
  com_parval_label_inventory_create: "Producto creado con éxito",
  com_parval_label_inventory_create_error: "Error al crear producto",
  com_parval_label_inventory_update: "Producto actualizado con éxito",
  com_parval_label_inventory_update_error: "Error al actualizar el producto",
  com_parval_label_inventory_delete: "Producto eliminado con éxito",
  com_parval_label_inventory_delete_error: "Error al eliminar el producto",

  /** Company maintenance */
  com_parval_label_company_create: "Compañía creada con éxito",
  com_parval_label_company_create_error: "Error al crear compañía",
  com_parval_label_company_update: "Compañía actualizada con éxito",
  com_parval_label_company_update_error: "Error al actualizar la compañía",
  com_parval_label_company_delete: "Compañía eliminada con éxito",
  com_parval_label_company_delete_error: "Error al eliminar la compañía",

  /** Messages maintenance */
  com_parval_label_message_create: "Mensaje enviado con éxito",
  com_parval_label_message_create_error: "Error al enviar el mensaje",
  com_parval_label_message_update: "Mensaje actualizado con éxito",
  com_parval_label_message_update_error: "Error al actualizar el mensaje",

  /** Apartment */
  com_spage_label_apartment_button_add_error: "Error al guardar apartamento",
  com_spage_label_apartment_button_update_error: "Error al actualizar apartamento",

  //* Families */
  com_spage_label_families_button_add_error: "Error al guardar la familia",
  com_spage_label_families_button_update_error: "Error al actualizar la familia",

  // * Collaborators */
  com_spage_label_collaborators_create: "Colaborador creado con éxito",
  com_spage_label_collaborators_create_error: "Error al crear colaborador",
  com_spage_label_collaborators_update: "Colaborador actualizado con éxito",
  com_spage_label_collaborators_update_error: "Error al actualizar colaborador",
  com_spage_label_collaborators_delete: "Colaborador eliminado con éxito",
  com_spage_label_collaborators_delete_error: "Error al eliminar colaborador",
};
