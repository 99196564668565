import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import UrlBase from "../../url/Urls";

export const getRequestCountries = async (
  filter: string,
  page: number,
  size?: number
) => {
  let responseData = {};
  await privateFetch
    .get(
      URL.REQUEST_COUNTRY_LIST(
        filter,
        page,
        //@ts-ignore
        size ? size : UrlBase.registration_x_page
      )
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en consulta de países");
    });
  return responseData;
};

export const findByIdRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_AUTHORITY(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en consulta de permisos por ID");
    });
  return responseData;
};

export const createRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    name: data.name,
  };

  await privateFetch
    .post(URL.REQUEST_COUNTRY, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en proceso de guardar país");
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    name: data.name,
  };

  await privateFetch
    .put(URL.REQUEST_COUNTRY, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en proceso de actualizar país");
    });
  return responseData;
};

export const deleteRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_COUNTRY_DELETE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en proceso de eliminar país");
    });
  return responseData;
};

export const getRequestProvince = async () => {
  let responseData = {};
  await privateFetch
    .post(URL.REQUEST_PROVINCE_LIST, {
      page: 0,
      size: 100,
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const getRequestCanton = async (id: number) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_CANTON_LIST(id))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const getRequestDistrict = async (id: number) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_DISTRICT_LIST(id))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
  return responseData;
};
