import { URL } from "../../utils/UrlConstants";
import { UrlBase } from "../../url/Urls";
import { privateFetch } from "../../utils/CustomFetch.js";

export const getRequestPDFPurchaseOrdersReport = async (findById: string) => {
  try {
    window.open(
      UrlBase.base_url +
        UrlBase.api_context +
        URL.REQUEST_PURCHASE_ORDER_REPORT(findById),
      "blank"
    );
  } catch (error: any) {
    throw new Error("Error al obtener reporte de boleta");
  }
};

export const getRequestPDFPurchaseOrdersReportBase64 = async (
  findById: string
) => {
  try {
    const response = await privateFetch.post(
      URL.REQUEST_PURCHASE_ORDER_REPORTBase64(findById)
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const requestSendNotificationReportPurchaseOrder = async (
  findById: string
) => {
  try {
    const response = await privateFetch.post(
      URL.REQUEST_PURCHASE_ORDER_REPORT_NOTIFICATION(findById)
    );
    return response;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const requestSendCancellationReportPurchaseOrder = async (
  findById: string
) => {
  try {
    const response = await privateFetch.post(
      URL.REQUEST_PURCHASE_ORDER_REPORT_CANCELLATION(findById)
    );
    return response;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};
