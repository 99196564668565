import { useState, useEffect, createContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { SVCancelSubscriptionRequest, SVSessionValidateRequest } from "../services/Security";
import { requestTypes } from "../types/Context";
import { setAuthData, setItem, getItem } from "../utils/LocalStorageManager";
import { formatDateReverse } from "../utils/UtilsFunctions";

const initialState = {
  isLoading: false,
  setLoading: () => {},
  errorMsg: "",
  setErrorMsg: () => {},
  successMsg: "",
  setSuccessMsg: () => {},
  loginProvider: () => {},
  handleFetchHistoryGroupByPackStatus: () => {},
  onSubmitCancelSubscription: () => {},
  handleHistoryByPackStatus: () => {},
  handleGetDrivers: () => {},
  handleFetchRouteAll: () => {},
  countTabs: {
    pending: 0,
    assigned: 0,
    onTheWay: 0,
    delivered: 0,
    rejected: 0,
  },
  setCountTabs: () => {},
};

const AppContext = createContext<requestTypes>(initialState);

const AppProvider = ({ children, authData }: any) => {
  const navigate = useNavigate();
  const [authInfo, setAuthInfo] = useState(authData);
  const [authenticate, setAuthenticate] = useState(authData !== null);
  const [isLoading, setLoading] = useState(initialState.isLoading);
  const [errorMsg, setErrorMsg] = useState(initialState.errorMsg);
  const [successMsg, setSuccessMsg] = useState(initialState.successMsg);

  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: "",
    modalObject: null,
  });

  const loginProvider = async (loginData: any) => {
    console.log(loginData);
    try {
      const sessionCode = loginData.authCode;
      const username = loginData.username;
      const id = loginData.id;
      //const company = loginData.user.company;

      if (sessionCode && username) {
        const loginDataValidate = await SVSessionValidateRequest(
          username,
          sessionCode,
          id
        );
        let formatData = {
          ...loginDataValidate,
          //company: company,
        };
        //save login data in storage
        const authData = await setAuthData("authMRPintores", formatData);
        if (!authData) {
          setAuthenticate(false);
          return;
        }
        console.log(authData)
        const decodeToken: any = await jwtDecode(
          authData?.tokenInfo?.access_token
        );
        const expiresAt = await decodeToken?.exp;
        let valid = new Date().getTime() / 1000 < expiresAt;
        if (valid) {
          setAuthenticate(valid);
          setAuthInfo(authData);
          if (authData?.userRole?.id === 31) {
            navigate("/dashboard/advances");
          } else {
            navigate("/dashboard/home");
          }
          return;
        }
      }
    } catch (error) {
      console.log(error);
    }
    setAuthenticate(false);
  };

  const logoutProvider = async (e: any) => {
    await setAuthData("authMRPintores", null);
    setAuthenticate(false);
    navigate("/", { replace: true });
  };

  const resetErrorMsg = () => {
    setErrorMsg("");
  };

  const resetSuccessMsg = () => {
    setSuccessMsg("");
  };

  const currentDateMinusOneMonth = new Date(new Date()).setMonth(
    new Date(new Date()).getMonth() - 1
  );

  const onSubmitCancelSubscription = async (data: any) => {
    try {
        setLoading && setLoading(true);
        //Call service user
        const resultData: any = await SVCancelSubscriptionRequest(data);
        setSuccessMsg && setSuccessMsg(resultData.info);
        setLoading && setLoading(false);
        
    } catch (error: any) {
        setLoading && setLoading(false);
        setErrorMsg &&
            setErrorMsg(
                error.message
                    ? error.message
                    : "Error al cancelar la suscripción"
            );
    }
};


  const formContextValues: requestTypes = useMemo(
    () => ({
      authInfo,
      authenticate,
      isLoading,
      setLoading,
      errorMsg,
      setErrorMsg,
      successMsg,
      setSuccessMsg,
      modalData,
      setModalData,
      loginProvider,
      logoutProvider,
      resetErrorMsg,
      resetSuccessMsg,
      onSubmitCancelSubscription,
    }),
    // eslint-disable-next-line
    [
      authInfo,
      authenticate,
      isLoading,
      errorMsg,
      successMsg,
      modalData,
    ]
  );

  return (
    <AppContext.Provider value={formContextValues}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider };

export default AppContext;
