import { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Dialog,
} from "@mui/material";
import { TrashIcon, PlusIcon, PenIcon } from "../components/svgicons/SvgIcons";
import { useForm } from "react-hook-form";
import FancyTooltip from "../components/utils/FancyTooltip";
import FancyPaper from "../components/FancyPaper";
import { useApp } from "../hooks/useApp";
import DeleteModal from "../components/utils/DeleteModal";
import FancyLightButton from "../components/FancyLightButton";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import FancyDialogActions from "../components/utils/FancyDialogActions";
import FancySearch from "../components/utils/FancySearch";
import { baseRequestType, AddOrUpdateProjectsType } from "../types/Utils";
import {
  formatDate,
  formatDateToNewDate,
  validarFecha,
} from "../utils/UtilsFunctions";
import AddorUpdateProjects from "../components/utils/AddorUpdateProjects";
import {
  getRequestCountries,
  getRequestProvince,
} from "../services/country/CountryService";
import {
  getRequestProjects,
  createProject,
  deleteProject,
  getRequestProjectStatus,
  getRequestProjectWeekendStatus,
  getRequestMileagesType,
} from "../services/projects/ProjectService";
import {
  getRequestPersonPositionAllProjectManagers,
  getRequestPersonPositionAttendantProjectManagers,
} from "../services/person/PersonService";
import { uploadProjectDocuments } from "../services/documents/DocumentService";
import { properties } from "../utils/Properties_es";
import theme from "../theme/theme";

interface contentItem {
  id: string;
  code: string;
  name: string;
  attendand: {
    id: number;
    name: string;
  };
  manager: {
    id: number;
    name: string;
    fullName: string;
  };
  startDate: string;
  finalDate: string;
  status: {
    id: number;
    name: string;
  };
  weekendPermissions: {
    date?: string;
    status?: {
      id: number;
      name: string;
    };
    notes?: string;
  }[];
  endStatus: string | null;
}

interface getRequestType extends baseRequestType {
  content: contentItem[];
}

interface getProjectStatusType {
  id: number;
  name: string;
}

const ManagementProjects = () => {
  const {
    authInfo,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const { handleSubmit } = useForm();
  const [projects, setProjects] = useState<getRequestType | null>(null);
  const [preFilter, setPreFilter] = useState<string>("");
  const [projectStatus, setProjectStatus] =
    useState<getProjectStatusType | null>(null);
  const [page, setPage] = useState<number>(0);
  const [countries, setCountries] = useState<getRequestType | null>(null);
  const [provinces, setProvinces] = useState<getRequestType | null>(null);

  const [projectStatusData, setProjectStatusData] = useState<
    getProjectStatusType[] | null
  >(null);
  const [projectWeekendStatus, setProjectWeekendStatus] = useState<
    getProjectStatusType[] | null
  >(null);
  const [mileagesType, setMileagesType] = useState<
    getProjectStatusType[] | null
  >(null);
  const [attendantData, setAttendantData] = useState<any | null>(null);
  const [managerData, setManagerData] = useState<any | null>(null);
  const [file, setFile] = useState<any>([]); // Lista de imágenes

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "", "");
      await getCountries();
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    let RoleObject = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update") {
      object = projects?.content.find((p: any) => p.id === parseInt(id));
      //RoleObject = usersDataRole.content;
    }

    if (modalAction === "delete") {
      object = projects?.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const getCountries = async () => {
    try {
      isLoading !== true && setLoading(false);
      const country: any = await getRequestCountries("", 0, 100);

      if (country?.content) {
        setCountries(country.content);
      }

      const province: any = await getRequestProvince();
      if (province?.content) {
        setProvinces(province.content);
      }

      const projectStatus: any = await getRequestProjectStatus();
      if (projectStatus?.content) {
        setProjectStatusData(projectStatus.content);
      }

      const projectWeekendStatus: any = await getRequestProjectWeekendStatus();
      if (projectWeekendStatus?.content) {
        setProjectWeekendStatus(projectWeekendStatus.content);
      }

      const getMileagesType: any = await getRequestMileagesType(0);
      if (getMileagesType?.content) {
        setMileagesType(getMileagesType.content);
      }

      const allProjectManagers: any =
        await getRequestPersonPositionAllProjectManagers();
      const allAttendantProjectManagers: any =
        await getRequestPersonPositionAttendantProjectManagers();

      if (allAttendantProjectManagers) {
        setAttendantData(allAttendantProjectManagers);
      }

      if (allProjectManagers) {
        setManagerData(allProjectManagers);
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const handleFetchData = async (
    currentPage: number,
    filter: string,
    statusId?: any
  ) => {
    try {
      setLoading(true);
      setPreFilter(filter);
      setPage(currentPage);
      setProjectStatus(statusId);

      let data = await getRequestProjects(
        currentPage,
        filter,
        statusId?.id ?? ""
      );

      if (data) {
        setProjects(data);
      }
      setLoading(false);
    } catch (error: any) {
      setErrorMsg(error);
    }
  };

  const handleStatusProjectChange = async (status: any) => {
    setProjectStatus(status);

    await handleFetchData(0, preFilter, status);
  };

  /**
   * Evento de cierre de modal
   * @param event
   */
  const handleCancelModal = () => {
    //@ts-ignore

    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
    setFile([]);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter, projectStatus);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };

  /* function validarFecha(fechaString: string) {
    const fecha: any = new Date(fechaString);
    return !isNaN(fecha);
  } */

  const formatMileages = async (dataArray: any, typeOption: string) => {
    return dataArray.map((item: any) => {
      const newItem = { ...item }; // Crear una copia para no modificar el original
      newItem.dayType = `${newItem["dayType"]}`;
      newItem.mileageTypeId = newItem["mileageType"].id;

      if (typeOption === "create") {
        newItem["startDate"] = formatDate(new Date(newItem["startDate"]));
      } else {
        if (validarFecha(newItem["startDate"])) {
          newItem["startDate"] = formatDate(new Date(newItem["startDate"]));
        } else {
          newItem["startDate"] = formatDate(
            new Date(formatDateToNewDate(newItem["startDate"]))
          );
        }
      }

      delete newItem["mileageType"];
      delete newItem["endDate"];
      return newItem;
    });
  };

  const formatWeekendPermissions = async (dataArray: any) => {
    const filterItem = dataArray.filter(
      (week: any, i: number) => week.status.id !== 0
    );

    return filterItem.map((item: any) => {
      const newItem = { ...item }; // Crear una copia para no modificar el original

      const formatDates = JSON.parse(newItem["rangeDate"]);
      newItem["date"] = formatDates.start;
      newItem["endDate"] = formatDates.end;

      newItem.statusId = newItem["status"].id;
      delete newItem["status"];
      delete newItem["rangeDate"];

      return newItem;
    });
  };

  const handleAdd = async (data: AddOrUpdateProjectsType) => {
    //close modal
    handleCancelModal();
    setLoading(true);

    const arrayMileages = await formatMileages(data.mileages, "create");
    let arrayWeekendPermissions = null;
    if (data.weekendPermissions && data.weekendPermissions[0].status !== null) {
      arrayWeekendPermissions = await formatWeekendPermissions(
        data.weekendPermissions
      );
    }

    const formaData = {
      user: authInfo.username,
      type: data.type,
      name: data.name,
      contractAmount: data.contractAmount,
      currency: data.currency,
      guaranteeCompliance: data.guaranteeCompliance,
      address: data.address,
      projectStatusId: data.status.id,
      notes: data.notes,
      mileages: arrayMileages,
      weekendPermissions:
        arrayWeekendPermissions !== null ? arrayWeekendPermissions : [],
      ditrictId: data.district.id,
      attendantId: data.attendant.id,
      managerId: data.managerId.id,
    };

    try {
      let createData: any = await createProject({
        ...formaData,
      });
      if (createData && data?.projectImage !== undefined) {
        for (let index = 0; index < data?.projectImage.length; index++) {
          const element = data?.projectImage[index];
          if (element !== null) {
            const formData = new FormData();
            formData.append("projectId", createData.id);
            formData.append("file", element);
            formData.append("user", authInfo.username);
            formData.append("documentType", "1");
            await uploadProjectDocuments(formData);
          }
        }
      }
      if (!createData) {
        setErrorMsg("Error en proceso de guardar proyecto");
        setLoading && setLoading(false);
        return;
      }
      setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_save);
      //call fetch data
      await handleFetchData(0, "", "");
    } catch (error: any) {
      setErrorMsg(error);
      setLoading(false);
    }
  };

  const handleUpdate = async (data: AddOrUpdateProjectsType) => {
    //close modal
    handleCancelModal();
    setLoading(true);

    // console.log("handleUpdate: ", data);

    const arrayMileages = await formatMileages(data.mileages, "update");
    let arrayWeekendPermissions = null;
    if (
      data.weekendPermissions &&
      data.weekendPermissions.length > 0 &&
      data.weekendPermissions[0].status !== null
    ) {
      arrayWeekendPermissions = await formatWeekendPermissions(
        data.weekendPermissions
      );
    }

    const formaData = {
      id: data.id,
      user: authInfo.username,
      type: data.type,
      name: data.name,
      contractAmount: data.contractAmount,
      currency: data.currency,
      guaranteeCompliance: data.guaranteeCompliance,
      address: data.address,
      projectStatusId: data.status.id,
      notes: data.notes,
      mileages: arrayMileages,
      weekendPermissions:
        arrayWeekendPermissions !== null ? arrayWeekendPermissions : [],
      ditrictId: data.district.id,
      attendantId: data.attendant.id,
      managerId: data.managerId.id,
    };

    try {
      let createData = await createProject({
        ...formaData,
      });
      if (!createData) {
        setErrorMsg("Error en proceso de actualizar proyecto");
        setLoading && setLoading(false);
        return;
      }
      setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData(0, "", "");
    } catch (error: any) {
      setErrorMsg(error);
      setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    //close modal
    handleCancelModal();
    setLoading(true);
    try {
      //call service
      let deleteData = await deleteProject(id);

      if (!deleteData) {
        setErrorMsg("Error eliminando proyecto");
        setLoading(false);
        return;
      }
      setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_delete);

      //reset page and call fetch data
      setPage(0);
      await handleFetchData(0, preFilter, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <FancyPaper pagetitle="Proyectos">
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={4} sm={5} xs={12}>
            {/* {<ResourceAccess isCode={true} pathOrCode={"PERSON:WRITE"}>} */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              data-name="create"
            >
              Agregar proyecto
              <PlusIcon sx={{ ml: 1 }} />
            </Button>
            {/* {</ResourceAccess>} */}
          </Grid>
          <Grid item md={8} sm={7} xs={12}>
            <FancySearch
              preFilter={preFilter}
              setPreFilter={setPreFilter}
              handleFetchData={() => handleFetchData(0, "", "")}
              handleApplyFilter={() =>
                handleFetchData(0, preFilter, projectStatus)
              }
              handleStatusProjectChange={handleStatusProjectChange}
              projectStatusData={projectStatusData}
              projectStatus={projectStatus}
            />
          </Grid>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Código</TableCell>
                <TableCell align="center" sx={{ width: "200px" }}>
                  Nombre
                </TableCell>
                <TableCell align="center">Encargado</TableCell>
                <TableCell align="center">F. Inicio</TableCell>
                <TableCell align="center">F. Fin</TableCell>
                <TableCell align="center">Estado</TableCell>
                <TableCell align="center">Permisos</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects !== null && projects?.content?.length > 0 ? (
                projects?.content.map((item: contentItem) => (
                  <TableRow key={item.id}>
                    <TableCell align="center">{item.code}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="center">
                      {item.manager?.fullName}
                    </TableCell>
                    <TableCell align="center">{item.startDate}</TableCell>
                    <TableCell align="center">{item.finalDate}</TableCell>
                    <TableCell align="center">
                      {item.status.id === 1 ? (
                        <FancyLightButton
                          backg="#3ec8d459"
                          color="#2b7076 !important"
                          text={item.status.name}
                        />
                      ) : item.status.id === 2 ? (
                        <FancyLightButton
                          backg="#c02be64a"
                          color="#c02be6!important"
                          text={item.status.name}
                        />
                      ) : item.status.id === 3 ? (
                        <FancyLightButton
                          backg="#e65f2b5e"
                          color="#E65F2B!important"
                          text={item.status.name}
                        />
                      ) : item.status.id === 4 ? (
                        <FancyLightButton
                          backg="#e1c9315c"
                          color="#7d6c00!important"
                          text={item.status.name}
                        />
                      ) : item.status.id === 5 ? (
                        <FancyLightButton
                          backg="#749e1b63"
                          color="#749e1b!important"
                          text={item.status.name}
                        />
                      ) : (
                        <FancyLightButton
                          backg="#1a932e61"
                          color="#1a932e!important"
                          text={item.status.name}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <>
                        {item.weekendPermissions !== null &&
                          item.weekendPermissions?.length > 0 &&
                          (item.weekendPermissions[0].status?.id === 1 ? (
                            <FancyLightButton
                              backg="#1a932e61"
                              color="#1a932e!important"
                              tooltipText={
                                item.weekendPermissions[0]?.date
                                  ? formatDate(
                                      new Date(
                                        new Date(
                                          item.weekendPermissions[0]?.date
                                        ).toLocaleDateString("en-US", {
                                          timeZone: "UTC",
                                        })
                                      )
                                    )
                                  : ""
                              }
                              text={item.weekendPermissions[0].status?.name}
                            />
                          ) : item.weekendPermissions[0].status?.id === 3 ? (
                            <FancyLightButton
                              backg="#c02be64a"
                              color="#c02be6!important"
                              text={item.weekendPermissions[0].status?.name}
                              tooltipText={
                                item.weekendPermissions[0]?.date
                                  ? formatDate(
                                      new Date(
                                        new Date(
                                          item.weekendPermissions[0]?.date
                                        ).toLocaleDateString("en-US", {
                                          timeZone: "UTC",
                                        })
                                      )
                                    )
                                  : ""
                              }
                            />
                          ) : (
                            <FancyLightButton
                              backg={theme.palette.primary.main}
                              color={theme.palette.primary.light}
                              text={
                                item.weekendPermissions[0].status?.name ??
                                "Denegado"
                              }
                              tooltipText={
                                item.weekendPermissions[0]?.date
                                  ? formatDate(
                                      new Date(
                                        new Date(
                                          item.weekendPermissions[0]?.date
                                        ).toLocaleDateString("en-US", {
                                          timeZone: "UTC",
                                        })
                                      )
                                    )
                                  : ""
                              }
                            />
                          ))}
                        <br />
                      </>
                    </TableCell>
                    <TableCell align="center">
                      <FancyTooltip title="Editar" placement="top">
                        <IconButton
                          aria-label="edit"
                          component="label"
                          color="primary"
                          onClick={handleOpenModal}
                          data-name="update"
                          data-id={item.id}
                        >
                          <PenIcon />
                        </IconButton>
                      </FancyTooltip>
                      <FancyTooltip title="Eliminar" placement="top">
                        <IconButton
                          aria-label="trash"
                          component="label"
                          color="error"
                          data-id={item.id}
                          onClick={handleOpenModal}
                          data-name="delete"
                        >
                          <TrashIcon />
                        </IconButton>
                      </FancyTooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>No existen registros</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            projects !== null
              ? projects?.content?.length > 0
                ? projects?.content?.length
                : 0
              : 0
          }
          rowsPerPage={projects !== null ? projects?.size : 0}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={projects?.totalElements}
          totalPages={projects?.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          maxWidth="md"
          fullWidth
        >
          <AddorUpdateProjects
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            countries={countries}
            provinces={provinces}
            projectStatusData={projectStatusData}
            projectWeekendStatus={projectWeekendStatus}
            mileagesType={mileagesType}
            attendantData={attendantData}
            managerData={managerData}
            file={file}
            setFile={setFile}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                <FancyDialogActions
                  handleAccept={handleSubmit(onSubmit)}
                  cancelModal={handleCancelModal}
                  textAcceptButton={"Eliminar"}
                />
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default ManagementProjects;
