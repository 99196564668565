import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
} from "@mui/material";
import { PlusIcon } from "../components/svgicons/SvgIcons";
import FancyTooltip from "../components/utils/FancyTooltip";
import FancyPaper from "../components/FancyPaper";
import { useForm } from "react-hook-form";
import {
  getRequestListConsumption,
  getRequestProjectStatus,
} from "../services/projects/ProjectService";
import { useApp } from "../hooks/useApp";
import ResourceAccess from "../components/security/ResourceAccess";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import FancySearch from "../components/utils/FancySearch";
import FancyLightButton from "../components/FancyLightButton";

const ManagementConsumptionPerProject = () => {
  const { setLoading, setErrorMsg, modalData, setModalData, errorMsg } =
    useApp();
  const theme = useTheme();
  const [consumptionData, setConsumptionData] = useState<any>([]);
  const initForm = {
    id: 0,
    adminLogin: "",
    clientLogin: "",
    description: "",
    name: "",
    path: 0,
  };

  interface getProjectStatusType {
    id: number;
    name: string;
  }

  const [formData, setFormData] = useState<any>(initForm);
  const [page, setPage] = useState(0);
  const [preFilter, setPreFilter] = useState<any>("");
  const [projectStatus, setProjectStatus] =
    useState<getProjectStatusType | null>(null);

  const [projectStatusData, setProjectStatusData] = useState<
    getProjectStatusType[] | null
  >(null);
  const { handleSubmit } = useForm();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
      const projectStatus: any = await getRequestProjectStatus();
      if (projectStatus?.content) {
        setProjectStatusData(projectStatus.content);
      }
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
    currentPage: number,
    filter: string,
    status?: any
  ) => {
    setLoading && setLoading(true);
    setConsumptionData([]);
    try {
      setPage(currentPage);
      let data = await getRequestListConsumption(
        filter,
        currentPage,
        10,
        status
      );
      if (data) {
        setConsumptionData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleApplyFilter = async () => {
    await handleFetchData(0, preFilter, projectStatus?.id);
  };

  const handleStatusProjectChange = async (status: any) => {
    console.log("handleStatusProjectChange: ", status);
    setProjectStatus(status);

    await handleFetchData(0, preFilter, status?.id);
  };

  // const handleApplyFilter = async () => {
  //   await handleFetchData(projectId, 0, preFilter, category);
  // };

  // const handleApplyFilterStatus = async (selectedCategory: any) => {
  //   setCategory(selectedCategory);
  //   await handleFetchData(projectId, 0, preFilter, selectedCategory);
  // };

  /**
   * @param event
   */
  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    const customPage = newPage - 1;
    setPage(customPage);
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter, projectStatus?.id);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };

  return (
    <>
      <FancyPaper
        pagetitle="Consumo por proyectos"
        prevpage="Consumo por proyectos"
      >
        <Grid container spacing={8} sx={{ pb: 8 }} justifyContent={"flex-end"}>
          <Grid item md={5} sm={7} xs={12}>
            <FancySearch
              preFilter={preFilter}
              setPreFilter={setPreFilter}
              handleFetchData={() => handleFetchData(0, "", projectStatus?.id)}
              handleApplyFilter={handleApplyFilter}
              handleStatusProjectChange={handleStatusProjectChange}
              projectStatusData={projectStatusData}
              projectStatus={projectStatus}
            />
          </Grid>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ display: "none" }}>ID Proyecto</TableCell>
                <TableCell align="center">{"Código"}</TableCell>
                <TableCell align="center">{"Nombre"}</TableCell>
                <TableCell align="center">{"Encargado"}</TableCell>
                <TableCell align="center">{"Estado"}</TableCell>
                <ResourceAccess isCode={true} pathOrCode={"CONSUMPTION:WRITE"}>
                  <TableCell align="center">{"Acciones"}</TableCell>
                </ResourceAccess>
              </TableRow>
            </TableHead>
            <TableBody>
              {consumptionData &&
              consumptionData?.content &&
              consumptionData.content?.length > 0 ? (
                consumptionData?.content?.map((item: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ display: "none" }}
                    >
                      {item.id}
                    </TableCell>
                    <TableCell align="center">{item.code}</TableCell>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">
                      {item.manager?.fullName}
                    </TableCell>
                    <TableCell align="center">
                      {/* {item.status.name} */}
                      {item.status.id === 1 ? (
                        <FancyLightButton
                          backg="#3ec8d459"
                          color="#2b7076 !important"
                          text={item.status.name}
                        />
                      ) : item.status.id === 2 ? (
                        <FancyLightButton
                          backg="#c02be64a"
                          color="#c02be6!important"
                          text={item.status.name}
                        />
                      ) : item.status.id === 3 ? (
                        <FancyLightButton
                          backg="#e65f2b5e"
                          color="#E65F2B!important"
                          text={item.status.name}
                        />
                      ) : item.status.id === 4 ? (
                        <FancyLightButton
                          backg="#e1c9315c"
                          color="#7d6c00!important"
                          text={item.status.name}
                        />
                      ) : item.status.id === 5 ? (
                        <FancyLightButton
                          backg="#749e1b63"
                          color="#749e1b!important"
                          text={item.status.name}
                        />
                      ) : (
                        <FancyLightButton
                          backg="#1a932e61"
                          color="#1a932e!important"
                          text={item.status.name}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <ResourceAccess
                        isCode={true}
                        pathOrCode={"CONSUMPTION:WRITE"}
                      >
                        <FancyTooltip
                          title={"Ver Consumo por Proyecto"}
                          placement="top"
                        >
                          <Link
                            to={
                              "/dashboard/consumptiondetail/" +
                              item.id +
                              "/" +
                              item.name
                            }
                          >
                            <IconButton
                              aria-label="edit"
                              component="label"
                              color="secondary"
                              sx={{
                                "&:hover": {
                                  color: theme.palette.secondary.dark,
                                },
                              }}
                              data-name="update"
                              data-id={item.id}
                            >
                              <PlusIcon />
                            </IconButton>
                          </Link>
                        </FancyTooltip>
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>No existen registros</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            consumptionData.content?.length > 0
              ? consumptionData.content?.length
              : 0
          }
          rowsPerPage={consumptionData.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={consumptionData.totalElements}
          totalPages={consumptionData.totalPages}
        />
      </FancyPaper>
    </>
  );
};

export default ManagementConsumptionPerProject;
