import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import UrlBase from "../../url/Urls";

export const getRequestProvider = async (
  page: number,
  filter: string,
  size?: number
) => {
  const requestBody = {
    ...(filter && { filter: filter }),
    page: page,
    size: size ? size : UrlBase.registration_x_page,
  };
  try {
    const response = await privateFetch.post(
      URL.REQUEST_PROVIDER_LIST,
      JSON.stringify(requestBody),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const findByIdRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_AUTHORITY(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en consulta de permisos por ID");
    });
  return responseData;
};

export const createRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    user: data.user,
    name: data.name,
    legalIdNumber: data.legalIdNumber,
    email: data.email,
    emails: data.emails
  };

  await privateFetch
    .post(URL.REQUEST_PROVIDER, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en proceso de guardar proveedor");
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    user: data.user,
    name: data.name,
    legalIdNumber: data.legalIdNumber,
    email: data.email,
    emails: data.emails
  };

  await privateFetch
    .put(URL.REQUEST_PROVIDER, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en proceso de actualizar proveedor");
    });
  return responseData;
};

export const deleteRequest = async (id: any) => {
  let responseData = {};
  const requestBody = { id };

  await privateFetch
    .delete(URL.REQUEST_PROVIDER_DELETE, {
      data: requestBody,
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en proceso de eliminar proveedor");
    });

  return responseData;
};
