import { ChangeEvent } from "react";
import { TextField, Button, Stack, useTheme } from "@mui/material";

interface FancySearchProps {
    preFilter: string;
    setPreFilter: (value: string) => void;
    handleApplyFilter: () => void;
    isLoading?: boolean; // Para manejar un estado de carga opcional
}

const FancySearch = ({
    preFilter,
    setPreFilter,
    handleApplyFilter,
    isLoading = false,
}: FancySearchProps) => {
    const theme = useTheme();

    return (
        <Stack
            direction={{
                xs: "column",
                md: "row",
            }}
            alignItems={{
                xs: "flex-start",
                md: "center",
            }}
            spacing={{
                xs: 2,
                md: 4,
            }}
            justifyContent="flex-end"
            sx={{
                background: theme.palette.secondary.light,
                borderRadius: "0 0 10px 10px",
                padding: "15px 25px",
                width: {
                    xs: "100%",
                    md: "373px",
                },
                marginLeft: "auto",
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
                <TextField
                    placeholder="Filtro de búsqueda"
                    aria-label="Campo de búsqueda"
                    sx={{
                        flexGrow: 1,
                        marginRight: "10px",
                        "& .MuiInputBase-root": {
                            borderRadius: "18px",
                            background: "#FFF",
                        },
                        "& input": {
                            padding: "0.75rem 16px!important",
                        },
                        "& fieldset": {
                            borderColor: "#FFF!important",
                        },
                    }}
                    value={preFilter}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPreFilter(e.target.value)
                    }
                    onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
                />
                <Button
                    aria-label="Aplicar filtro de búsqueda"
                    variant="outlined"
                    color="primary"
                    onClick={handleApplyFilter}
                    disabled={isLoading}
                    sx={{
                        borderRadius: "8px!important",
                        padding: "0.75rem 2rem",
                        lineHeight: "1.4375em",
                        background: "#FFF",
                        whiteSpace: "nowrap",
                    }}
                >
                    {isLoading ? "Cargando..." : "Buscar"}
                </Button>
            </Stack>
        </Stack>
    );
};

export default FancySearch;