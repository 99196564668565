import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { CreditSend, TicketsSend } from "../../components/utils/credit_view/interface";

export const getRequest = async (
    page: number,
    week: string,
    projectId: number,
    filter: string,
    year: number,
    size?: number,
) => {

    console.log(URL.REQUEST_LIST(page, size ? size : UrlBase.registration_x_page, projectId, week, year));

    try {
        const response = await privateFetch.post(
            URL.REQUEST_LIST(page, size ? size : UrlBase.registration_x_page, projectId, week, year, filter),
            {
                headers: {
                    ...CONSTANT.HEADER_TYPE,
                },
            }
        );
        return response.data;
    } catch (error: any) {
        throw new Error(error.response.data.message || "Error al obtener la información");
    }
};

export const getRequestCredits = async (salaryAdjustmentId: number) => {

    try {
        const response = await privateFetch.get(URL.REQUEST_CREDIT(salaryAdjustmentId));
        return response.data;
    } catch (error: any) {
        console.log('error', error);

        throw new Error(error.response.data.message || "Error al obtener la información");
    }
};

export const updateCreditRequest = async (data: any) => {

    console.log(data);
    const requestBody: CreditSend = {
        amount: data.amount,
        authorized: data.authorized,
        credit: data.type === "credit" ? true : false,
        notes: data.notes,
        personId: data.personId,
        positionId: data.positionId,
        projectId: data.projectId,
        outstandingBalance: data.outstandingBalance,
        week: data.week,
        year: data.year,
        user: data.user,
        ...(data.id ? { id: data.id } : {}),
    }

    try {
        let response;

        if (data.id) {
            response = await privateFetch.put(URL.REQUEST_UPDATE, requestBody);

        } else {
            response = await privateFetch.post(URL.REQUEST_UPDATE, requestBody);
        }

        return response.data;
    } catch (error: any) {
        throw new Error(error.response.data.message || "Error al actualizar la información");
    }
}

export const getRequestTickets = async (ticketId: number) => {

    try {
        const response = await privateFetch.get(URL.REQUEST_TICKET(ticketId));

        return response.data;
    } catch (error: any) {
        console.log('error', error);
        throw new Error(error.response.data.message || "Error al obtener la información");
    }
}

export const updateTicketRequest = async (data: TicketsSend) => {

    try {
        let response;
        if (data.id) {
            response = await privateFetch.put(URL.REQUEST_TICKET_UPDATE, data);
        } else {
            response = await privateFetch.post(URL.REQUEST_TICKET_UPDATE, data);
        }
        return response.data;
    } catch (error: any) {
        throw new Error(error.response.data.message || "Error al actualizar la información");
    }
}