import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Divider,
  useTheme,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import LayersIcon from "@mui/icons-material/Layers";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CardDashboard from "../components/CardDashboard";
import { useApp } from "../hooks/useApp";
import FancyPaper from "../components/FancyPaper";
import FancyCircularProgress from "../components/FancyCircularProgress";
import FancyLightButton from "../components/FancyLightButton";
import { getRequestDashboard } from "../services/dashboard/DashboardService";
import GroupIcon from "@mui/icons-material/Group";
import { getRequestProjects } from "../services/projects/ProjectService";
import React from "react";
import FancyTablePagination from "../components/utils/FancyTablePagination";

const DashboardHome = () => {
  const theme = useTheme();
  const { setLoading, setErrorMsg } = useApp();
  const [allInformation, setAllInformation] = useState<any>(null);
  const [projects, setProjects] = useState<any>(null);
  const [page, setPage] = useState(0);

  interface searchData {
    fromDate?: Date | null;
    toDate?: Date | null;
  }

  const defaultValues: searchData = {
    fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    toDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
  };

  const handleFetchData = async (currentPage: number) => {
    setLoading && setLoading(true);
    try {
      let data = await getRequestDashboard();
      if (data) {
        setAllInformation(data);
      }
      let dataProjects = await getRequestProjects(currentPage, "", "");

      if (dataProjects) {
        setProjects(dataProjects);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0);
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage);
    }
  };

  return (
    <FancyPaper pagetitle={"Inicio"}>
      <Grid container spacing={6} sx={{ mt: "-60px" }}>
        <Grid item md={3} sm={6} xs={12}>
          <CardDashboard
            positive
            sx={{ height: "100%" }}
            value={allInformation?.userCount ? allInformation?.userCount : 0}
            title="Usuarios"
            subtitle=""
            colorIcon="#F0C274"
            iconCard={<PersonAddAlt1Icon />}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CardDashboard
            positive
            sx={{ height: "100%" }}
            value={
              allInformation?.productCount
                ? allInformation?.productCount + " " + "Productos"
                : 0
            }
            title="Inventario"
            subtitle=""
            colorIcon="#D398E7"
            iconCard={<LayersIcon />}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CardDashboard
            positive
            sx={{ height: "100%" }}
            value={
              allInformation?.projectCount ? allInformation?.projectCount : 0
            }
            title="Proyectos"
            subtitle=""
            colorIcon="#E89271"
            iconCard={<BusinessCenterOutlinedIcon />}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CardDashboard
            positive
            sx={{ height: "100%" }}
            value={
              allInformation?.personCount ? allInformation?.personCount : 0
            }
            title="Colaboradores"
            subtitle=""
            colorIcon="#70A1E5"
            iconCard={<GroupIcon />}
          />
        </Grid>
      </Grid>

      {/* Proyectos Section */}
      <Box sx={{ pl: 6, pt: 6, pb: 3, mt: 4 }}>
        <Grid
          container
          spacing={6}
          sx={{
            background: theme.palette.secondary.light,
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{ pt: "10px!important", pb: "10px!important" }}
          >
            <Typography variant="h6" color="initial">
              Proyectos
            </Typography>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              flex: 1,
              marginBottom: 3,
              paddingRight: 6,
              paddingTop: "0px!important",
            }}
          >
            <Box
              sx={{
                //height: "350px",
                height: {
                  xs: "350px",
                  xl: "auto",
                },
                overflowY: "auto",
                overflowX: "hidden",
                padding: "0px 10px 0",
                border: "1px solid #ddd",
                borderRadius: "10px",
                backgroundColor: "#FFFFFF60",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Proyecto</TableCell>
                      <TableCell>Encargado</TableCell>
                      <TableCell>Estado</TableCell>
                      <TableCell align="center">Progreso</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projects &&
                      projects?.content &&
                      projects?.content.map((item: any) => (
                        <TableRow key={item.id}>
                          <TableCell scope="row" sx={{ padding: "5px 12px" }}>
                            {item.name}
                          </TableCell>
                          <TableCell
                            scope="row"
                            sx={{ padding: "5px 12px" }}
                          >{`${item.manager?.name} ${item.manager?.lastName} ${item.manager?.surname}`}</TableCell>
                          <TableCell scope="row" sx={{ padding: "5px 12px" }}>
                            <Typography
                              variant="body1"
                              color="initial"
                              sx={{ textAlign: "center" }}
                            >
                              {item.status.id === 1 ? (
                                <FancyLightButton
                                  backg="#3ec8d459"
                                  color="#2b7076 !important"
                                  text={item.status.name}
                                />
                              ) : item.status.id === 2 ? (
                                <FancyLightButton
                                  backg="#c02be64a"
                                  color="#c02be6!important"
                                  text={item.status.name}
                                />
                              ) : item.status.id === 3 ? (
                                <FancyLightButton
                                  backg="#e65f2b5e"
                                  color="#E65F2B!important"
                                  text={item.status.name}
                                />
                              ) : item.status.id === 4 ? (
                                <FancyLightButton
                                  backg="#e1c9315c"
                                  color="#7d6c00!important"
                                  text={item.status.name}
                                />
                              ) : item.status.id === 5 ? (
                                <FancyLightButton
                                  backg="#749e1b63"
                                  color="#749e1b!important"
                                  text={item.status.name}
                                />
                              ) : (
                                <FancyLightButton
                                  backg="#1a932e61"
                                  color="#1a932e!important"
                                  text={item.status.name}
                                />
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ padding: "5px 12px" }}>
                            <FancyCircularProgress
                              value={(item.status.id - 1) * 20}
                              color={
                                item.status.id === 1
                                  ? "info"
                                  : item.status.id === 2
                                  ? "error"
                                  : item.status.id === 3
                                  ? "secondary"
                                  : item.status.id === 4
                                  ? "warning"
                                  : item.status.id === 5
                                  ? "success"
                                  : "primary"
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <FancyTablePagination
              count={
                projects?.content?.length > 0 ? projects?.content?.length : 0
              }
              rowsPerPage={projects?.size}
              page={page}
              onPageChange={handleChangePage}
              totalElements={projects?.totalElements}
              totalPages={projects?.totalPages}
            />
          </Grid>
        </Grid>
      </Box>
    </FancyPaper>
  );
};

export default DashboardHome;
