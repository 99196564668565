import { CONSTANT } from "./Constants";
export const URL = {
  /** HOME PATH */
  LOGIN_TWOFACTORCODE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/login/twofactor",
  LOGIN_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/autenticate",
  LOGIN_RECOVERY_PASS_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/recoverPasswordAdmin",
  LOGIN_CHANGE_PASS_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/changePassword",
  LOGIN_ACTIVE_USER_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/activate",
  SESSIONVALIDATE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/validatesession",

  /** users urls */
  REQUEST_USER_LIST: (page: string, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/?page=${page}&size=${size}`,
  REQUEST_USER_LIST2: (page: number, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/?page=${page}&size=${size}`,

  REQUEST_USER_LIST_FILTER: (page: string, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/${page}/${size}`,
  REQUEST_USER_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/user/createAdmin`,
  REQUEST_USER: (id: any) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/${id ? id : ""}`,
  REQUEST_USER_PASS: CONSTANT.DISPATCHER_USER_PREFIX + `/user/changePassword`,
  REQUEST_ROLE_LIST: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,
  REQUEST_ACTIVATE_USER: (code: string, username: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user/activate?code=${code}&username=${username}`,
  REQUEST_VALIDATE_AVAILABLE_USER: (username: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user/validateUserName?username=${username}`,

  REQUEST_VALIDATE_AVAILABLE_PASSWORD: (userId: number) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user/validateNewPassword?userId=${userId}`,

  /** Role urls */
  REQUEST_ROLE: (id: string | null, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/role/${id ? id : ""}`,
  REQUEST_LIST_ROLE: (filter: string, page: number, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/role/?page=${page}&size=${size}${filter ? "&filter=" + filter : ""}`,
  REQUEST_ROLE2: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/role/${id ? id : ""}`,
  REQUEST_ROLE_BY_ID: (id: string | null) =>
    `${CONSTANT.DISPATCHER_USER_PREFIX}/role${id ? `/${id}` : ""}`,
  REQUEST_ROLE_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,
  REQUEST_ROLE_UPDATE: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/role/${id}`,
  REQUEST_ROLE_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/role/${id}`,

  /** Company urls */
  REQUEST_COMPANY: (page: any, size: any, filter: any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/company/all/${page}/${size}${filter ? `?filter=${filter}` : ""}`,

  REQUEST_COMPANY_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/save`,
  REQUEST_COMPANY_DELETE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/delete`,

  /** Inventory urls */
  REQUEST_INVENTORY: CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/products/findAll`,
  REQUEST_INVENTORY_CREATE: CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/products/`,
  REQUEST_INVENTORY_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/products/?id=${id}`,
  REQUEST_INVENTORY_TOTAL:
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/products/total`,
  REQUEST_INVENTORY_XML: CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/products/xmlInvoice`,

  /** authority */
  REQUEST_AUTHORITY_LIST: (filter: string, page: number, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/authority/?${filter
      ? "filter=" + filter + "&" + "page=" + page + "&" + "size=" + size
      : "page=" + page + "&" + "size=" + size
    }`,

  REQUEST_AUTHORITY: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/${id ? id : ""}`,
  REQUEST_AUTHORITY_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/authority`,

  /** authorityByRole */
  REQUEST_AUTHORITY_BY_ROLE_LIST: (
    roleId: string,
    page: number,
    size: number,
    filter: string
  ) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/authority-role/${roleId}/${page}/${size}` +
    (filter ? `?filter=${filter}` : ""),
  REQUEST_AUTHORITY_BY_ROLE: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${id ? id : ""}`,

  REQUEST_AUTHORITY_NO_ADDED_BY_ROLE_LIST: (
    roleId: number,
    page: number,
    size: number,
    filter: string
  ) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/authority/${roleId}/${page}/${size}?${filter ? "filter=" + filter + "&" : ""
    }${filter ? "&platform=mobile" : "platform=mobile"}`,

  /** family */
  REQUEST_FAMILY: (id: string | null, size: string) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/family/${id ? id : ""}`,
  REQUEST_FAMILY_LIST: (filter: string, page: number, size: string) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX +
    `/family/?page=${page}&size=${size}${filter ? "&filter=" + filter : ""}`,
  REQUEST_FAMILY2: (id: string | null) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/family/${id ? id : ""}`,
  REQUEST_FAMILY_BY_ID: (id: string | null) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/family/${id ? id : ""}`,
  REQUEST_FAMILY_CREATE: CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/family/`,
  REQUEST_FAMILY_UPDATE: CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/family/`,
  REQUEST_FAMILY_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/family/?id=${id}`,

  /** Subfamily */
  REQUEST_SUBFAMILY: (id: string | null, size: string) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/family/${id ? id : ""}`,
  REQUEST_SUBFAMILY_LIST: (
    familyId: number,
    filter: string,
    page: number,
    size: string
  ) =>
    `${CONSTANT.DISPATCHER_INVENTORY_PREFIX}/subfamily/?familyId=${familyId}&filter=${filter}&page=${page}&size=${size}`,
  REQUEST_SUBFAMILY2: (id: string | null) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/subfamily/${id ? id : ""}`,
  REQUEST_SUBFAMILY_BY_ID: (id: string | null) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/subfamily/${id ? id : ""}`,
  REQUEST_SUBFAMILY_CREATE:
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/subfamily/`,
  REQUEST_SUBFAMILY_UPDATE:
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/subfamily/`,
  REQUEST_SUBFAMILY_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/subfamily/?id=${id}`,

  /** SubSubfamily */
  REQUEST_SUBSUBFAMILY: (id: string | null, size: string) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/subSubFamily/${id ? id : ""}`,
  REQUEST_SUBSUBFAMILY_LIST: (
    subFamilyId: number,
    filter: string,
    page: number,
    size: string
  ) =>
    `${CONSTANT.DISPATCHER_INVENTORY_PREFIX}/subSubFamily/?subFamilyId=${subFamilyId}&filter=${filter}&page=${page}&size=${size}`,
  REQUEST_SUBSUBFAMILY2: (id: string | null) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/subSubFamily/${id ? id : ""}`,
  REQUEST_SUBSUBFAMILY_BY_ID: (id: string | null) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/subSubFamily/${id ? id : ""}`,
  REQUEST_SUBSUBFAMILY_CREATE:
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/subSubFamily/`,
  REQUEST_SUBSUBFAMILY_UPDATE:
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/subSubFamily/`,
  REQUEST_SUBSUBFAMILY_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/subSubFamily/?id=${id}`,

  /** Bank */
  REQUEST_BANK_LIST: (filter: string, page: number, size: string) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX +
    `/bank/?${filter
      ? "filter=" + filter + "&" + "page=" + page + "&" + "size=" + size
      : "page=" + page + "&" + "size=" + size
    }`,
  REQUEST_BANK: CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/bank/`,
  REQUEST_BANK_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/bank/?id=${id}`,

  /** Country */
  REQUEST_COUNTRY_LIST: (filter: string, page: number, size: string) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX +
    `/countries/?${filter
      ? "filter=" + filter + "&" + "page=" + page + "&" + "size=" + size
      : "page=" + page + "&" + "size=" + size
    }`,
  REQUEST_COUNTRY: CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/countries/`,
  REQUEST_COUNTRY_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/countries/?id=${id}`,

  REQUEST_PROVINCE_LIST:
    CONSTANT.DISPATCHER_PROJECTS_PREFIX + `/province/findAll`,
  REQUEST_CANTON_LIST: (id: number) =>
    CONSTANT.DISPATCHER_PROJECTS_PREFIX + `/canton/allByProvince/${id}`,
  REQUEST_DISTRICT_LIST: (id: number) =>
    CONSTANT.DISPATCHER_PROJECTS_PREFIX + `/district/allByCanton/${id}`,

  //https://dispatcher-mrpintores.bitcode-enterprise.dev/v1/projects/canton/allByProvince/1

  /** Person */
  REQUEST_PERSON_LIST:
    CONSTANT.DISPATCHER_PERSON_PREFIX + `/person/findAllByState`,

  REQUEST_PERSON: CONSTANT.DISPATCHER_PERSON_PREFIX + `/person/`,
  REQUEST_PERSON_DELETE: CONSTANT.DISPATCHER_PERSON_PREFIX + `/person/`,
  REQUEST_PERSON_TSE: (id: number) =>
    CONSTANT.DISPATCHER_PERSON_PREFIX + `/person/findTsePersonById/${id}`,

  /** Credits & Debits Screen*/
  REQUEST_LIST: (
    page: number,
    size: number,
    projectId: number,
    week: string,
    year: number,
    filter?: string
  ) =>
    CONSTANT.DISPATCHER_PROJECTS_PREFIX +
    `/salaryAdjustment/findWeekSummary?page=${page}&size=${size}&projectId=${projectId}&week=${week}&year=${year}&filter=${filter}`,

  // ** Credits & Debits */
  REQUEST_CREDIT: (salaryAdjustmentId: number) =>
    CONSTANT.DISPATCHER_PROJECTS_PREFIX +
    `/salaryAdjustment/findById/${salaryAdjustmentId}`,
  REQUEST_UPDATE: CONSTANT.DISPATCHER_PROJECTS_PREFIX + `/salaryAdjustment/`,

  // ** Tickets */

  REQUEST_TICKET: (ticketId: number) =>
    CONSTANT.DISPATCHER_PROJECTS_PREFIX +
    `/transportationPayment/findById/${ticketId}`,
  REQUEST_TICKET_UPDATE:
    CONSTANT.DISPATCHER_PROJECTS_PREFIX + `/transportationPayment/`,

  /** Location */
  REQUEST_WAREHOUSE_LIST:
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + "/warehouse/findAll",
  REQUEST_WAREHOUSE: CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/warehouse/`,
  REQUEST_WAREHOUSE_DELETE:
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/warehouse/`,

  /** Documents */
  DOCUMENTS_UPLOAD: CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/documents/upload`,
  DOCUMENTS_DELETE: CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/documents/`,
  DOCUMENTS_LIST: (id: number) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/products/documents/${id}`,

  DOCUMENTS_UPLOAD_PERSON:
    CONSTANT.DISPATCHER_PERSON_PREFIX + `/documents/upload`,
  DOCUMENTS_DELETE_PERSON: CONSTANT.DISPATCHER_PERSON_PREFIX + `/documents/`,
  DOCUMENTS_LIST_PERSON: (id: number, documentType: number) =>
    CONSTANT.DISPATCHER_PERSON_PREFIX +
    `/documents/documents/{personId}?personId=${id}&documentType=${documentType}`,
  DOCUMENTS_BASE64_LIST_PERSON: (id: number, documentType: number) =>
    CONSTANT.DISPATCHER_PERSON_PREFIX +
    `/documents/documentsBase64/{personId}?personId=${id}&documentType=${documentType}`,
  //https://dispatcher-mrpintores.bitcode-enterprise.dev/v1/person/documents/documentsBase64/{personId}?personId=15&documentType=2
  DOCUMENTS_PROJECTS_UPLOAD:
    CONSTANT.DISPATCHER_PROJECTS_PREFIX + `/documents/upload`,
  DOCUMENTS_PROJECTS_DELETE:
    CONSTANT.DISPATCHER_PROJECTS_PREFIX + `/documents/`,
  DOCUMENTS_PROJECTS_GET: (projectId: number, documentType: number) =>
    CONSTANT.DISPATCHER_PROJECTS_PREFIX +
    `/documents/documents/{projectId}?projectId=${projectId}&documentType=${documentType}`,

  /** Dashboard */
  REQUEST_DASHBOARD:
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/dashboard/countAll`,

  /** Projects */
  REQUEST_PROJECTS_LIST: (
    page: number,
    size: string,
    filter?: string,
    statusId?: string
  ) =>
    CONSTANT.DISPATCHER_PROJECTS_PREFIX +
    `/api/?page=${page}&size=${size}${filter !== "" ? "&filter=" + filter : ""
    }${statusId !== "" ? "&statusId=" + statusId : ""}`,

  REQUEST_PROJECTS: (id?: string) =>
    CONSTANT.DISPATCHER_PROJECTS_PREFIX +
    `/api/${id ? "consumptionProject" + id : ""}`,
  DELETE_PROJECTS: CONSTANT.DISPATCHER_PROJECTS_PREFIX + `/api/`,
  MAX_DATE_PROJECTS:
    CONSTANT.DISPATCHER_PROJECTS_PREFIX + `/api/maxDeliveryDate`,

  /**Advances */
  REQUEST_PROJECTS_BY_MANAGER: (id?: any) =>
    CONSTANT.DISPATCHER_PROJECTS_PREFIX + `/api/findAllProjectsByManager/${id}`,
  REQUEST_PROJECTS_VALIDATE_HOURS: (data?: any) =>
    CONSTANT.DISPATCHER_PROJECTS_PREFIX + `/projectProgress/getOvertimeWorkedHoursWarningByPerson?${data}`,
  REQUEST_CREATE_ADVANCE:
    CONSTANT.DISPATCHER_PROJECTS_PREFIX + `/projectProgress/`,
  REQUEST_COWORKERS: (id: any, page: any, size: any) =>
    CONSTANT.DISPATCHER_PERSON_PREFIX +
    `/person/findAllProjectProgressNonInactivePersons/${id}/${page}/${size}`,
  REQUEST_CREATE_COWORKERS_ADVANCE:
    CONSTANT.DISPATCHER_PROJECTS_PREFIX +
    `/projectProgress/createWeeklyPayrollPayment`,
  REQUEST_ADVANCES_FIND_ALL_DETAILS: (
    page: any,
    size: any,
    projectProgressId: any
  ) =>
    CONSTANT.DISPATCHER_PROJECTS_PREFIX +
    `/projectProgress/getDetail/${projectProgressId}/${size}/${page}`,
  REQUEST_DELETE_COWORKER_ADVANCE:
    CONSTANT.DISPATCHER_PROJECTS_PREFIX +
    `/projectProgress/deleteWeeklyPayrollPayment`,
  REQUEST_DELETE_ALL_ADVANCE:
    CONSTANT.DISPATCHER_PROJECTS_PREFIX + `/projectProgress/`,
  REQUEST_ADVANCE_CSS_REPORT: (id: any) =>
    CONSTANT.DISPATCHER_REPORT_PREFIX +
    `/inventory/excel/paymentsByProject/${id}`,
  REQUEST_ADVANCE_MONTH_REPORT: (month: any, year: any) =>
    CONSTANT.DISPATCHER_REPORT_PREFIX +
    `/inventory/excel/ccssPaymentReport/${month}/${year}`,

  //https://dispatcher-mrpintores.bitcode-enterprise.dev/v1/report/inventory/excel/ccssPaymentReport/2/2025

  /** Consumption */
  REQUEST_CONSUMPTION_PER_PROJECT: (
    projectId: number,
    filter: string,
    page: number,
    size: string,
    category?: string
  ) =>
    `${CONSTANT.DISPATCHER_PROJECTS_PREFIX
    }/api/consumptionProject/${projectId}?filter=${filter}${category ? "&category=" + category : ""
    }&page=${page}&size=${size}`,

  REQUEST_CONSUMPTION_PER_PROJECT_LIST: (
    filter: string,
    page: number,
    size?: string,
    status?: number
  ) =>
    CONSTANT.DISPATCHER_PROJECTS_PREFIX +
    `/api/?page=${page}&size=${size}${filter ? "&filter=" + filter : ""}${status ? "&statusId=" + status : ""
    }`,

  /** Status Projects */
  REQUEST_PROJECTS_STATUS_LIST:
    CONSTANT.DISPATCHER_PROJECTS_PREFIX + "/projectStatus/findAll",
  REQUEST_PROJECTS_WEEKEND_STATUS_LIST:
    CONSTANT.DISPATCHER_PROJECTS_PREFIX + "/projectWeekendStatus/findAll",

  /** Mileages Type */
  REQUEST_PROJECTS_MILEAGETYPE_LIST:
    CONSTANT.DISPATCHER_PROJECTS_PREFIX + "/mileageType/findAll",
  REQUEST_PROJECTS_MILEAGETYPE:
    CONSTANT.DISPATCHER_PROJECTS_PREFIX + "/mileageType/",

  //https://dispatcher-mrpintores.bitcode-enterprise.dev/v1/projects/mileageType/findAll
  //https://dispatcher-mrpintores.bitcode-enterprise.dev/v1/projects/mileageType/

  /** Exit Ticket */
  REQUEST_TYPE_MOVEMENT:
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/ticketActivityType/findAll`,
  REQUEST_EXIT_TICKET: (
    page: number,
    size: number,
    startDate?: string,
    endDate?: string,
    status?: boolean,
    filter?: string
  ) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX +
    `/ticket/findAll?page=${page}&size=${size}&startDate=${startDate}&endDate=${endDate}` +
    (filter ? `&filter=${filter}` : "") +
    (status !== undefined ? `&status=${status}` : ""),
  REQUEST_CRUD_EXIT_TICKET: CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/ticket/`,

  REQUEST_PRODUCT_PER_PROJECT: (idProject?: number) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/ticket/${idProject}/supplies`,

  /** Advances */
  REQUEST_ADVANCES_FIND_ALL:
    CONSTANT.DISPATCHER_PROJECTS_PREFIX + `/projectProgress/findAll`,

  /** Exit Ticket */
  REQUEST_HOLIDAY: (request: any) =>
    CONSTANT.DISPATCHER_PROJECTS_PREFIX +
    `/holiday/findAll?${request.filter ? request.filter : ""}${request.filter ? "&page=" + request.page : "page=" + request.page
    }&size=${request.size}&year=${request.year}`,
  REQUEST_CRUD_HOLIDAY: CONSTANT.DISPATCHER_PROJECTS_PREFIX + `/holiday/`,
  REQUEST_RESCHEDULE_HOLIDAY: (request: any) =>
    CONSTANT.DISPATCHER_PROJECTS_PREFIX +
    `/holiday/reschedule?holidayId=${request.holidayId}
  &dayOfMonth=${request.dayOfMonth}
  &month=${request.month}
  &year=${request.year}
  &mandatoryPayment=${request.mandatoryPayment}
  &user=${request.user}`,

  /** History Ticket */
  REQUEST_HISTORY:
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/products/departureActivity`,

  /** Position */
  REQUEST_POSITION_LIST:
    CONSTANT.DISPATCHER_PERSON_PREFIX + `/position/findAll`,
  REQUEST_POSITION_LIST2:
    CONSTANT.DISPATCHER_PERSON_PREFIX + `/person/findAllProjectManagers`,
  REQUEST_POSITION_LIST_ATTENDANT_PROJECT_MANAGERS:
    CONSTANT.DISPATCHER_PERSON_PREFIX + `/person/findAttendantProjectManagers`,
  REQUEST_POSITION: CONSTANT.DISPATCHER_PERSON_PREFIX + `/position/`,
  REQUEST_POSITION_DELETE: CONSTANT.DISPATCHER_PERSON_PREFIX + `/position/`,

  /** Report */

  REQUEST_REPORT_TICKET: (id: number) =>
    CONSTANT.DISPATCHER_REPORT_PREFIX + `/inventory/pdf/ticket/findById/${id}`,

  REQUEST_REPORT_INVENTORY_CONTROL: (id: number) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/inventory-control/pdf?id=${id}`,

  //https://dispatcher-mrpintores.bitcode-enterprise.dev/v1/inventory/inventory-control/pdf?id=9

  REQUEST_REPORT_HISTORY: (type: string) =>
    CONSTANT.DISPATCHER_REPORT_PREFIX +
    `/inventory/${type}/products/departureActivity`,

  /** Inventory Control */
  REQUEST_LIST_INVENTORY_CONTROL: (
    filter: string,
    page: number,
    size: string
  ) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX +
    `/inventory-control?page=${page}&size=${size}${filter ? "&filter=" + filter : ""
    }`,
  REQUEST_FIND_BY_ID: (id: number) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/inventory-control/${id}`,
  REQUEST_CREATE_CONTROL:
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/inventory-control`,
  REQUEST_APPLY_CONTROL:
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/inventory-control/apply`,
  REQUEST_UPDATE_CONTROL: (user: string) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX +
    `/inventory-control/product?user=${user}`,

  REQUEST_CONTROL_PREVIEW: (location: string, product: string) =>
    CONSTANT.DISPATCHER_INVENTORY_PREFIX +
    `/inventory-control/preview?location=${location}${product ? "&product=" + product : ""
    }`,

  // https://dispatcher-mrpintores.bitcode-enterprise.dev/v1/inventory/inventory-control/preview?location=Bodega%201&product=Goltex

  /** Position */
  REQUEST_PROVIDER_LIST:
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/supplier/findAll`,
  REQUEST_PROVIDER: CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/supplier/`,
  REQUEST_PROVIDER_DELETE: CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/supplier/`,

  /**Purchase Orders */
  REQUEST_PURCHASE_ORDERS:
    CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/purchaseOrder/findAll`,
  CUD_PURCHASE_ORDERS: CONSTANT.DISPATCHER_INVENTORY_PREFIX + `/purchaseOrder/`,

  /**Purchase Orders Report*/
  REQUEST_PURCHASE_ORDER_REPORT: (findById: string) =>
    CONSTANT.DISPATCHER_REPORT_PREFIX +
    `/inventory/pdf/purchaseOrder/findById/${findById}`,
  REQUEST_PURCHASE_ORDER_REPORTBase64: (findById: string) =>
    CONSTANT.DISPATCHER_REPORT_PREFIX +
    `/inventory/purchaseOrderBase64/${findById}`,
  REQUEST_PURCHASE_ORDER_REPORT_NOTIFICATION: (findById: string) =>
    CONSTANT.DISPATCHER_REPORT_PREFIX +
    `/inventory/sendMailPurchaseOrder/${findById}`,
  REQUEST_PURCHASE_ORDER_REPORT_CANCELLATION: (findById: string) =>
    CONSTANT.DISPATCHER_REPORT_PREFIX +
    `/inventory/sendMailPurchaseOrderCancellation/${findById}`,
};
