import * as Yup from "yup";

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const numberRegExp = /^[0-9]*$/;

export const urlRegExp =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const stringRegExp = /^[a-zA-Z]{20,255}$/;

export const requiredString = Yup.string().required("Campo es requerido");

export const requiredNumber = Yup.number().required("Campo es requerido");

export const requiredEmail = Yup.string()
  .email()
  .required("Email es requerido");

export const formSchemaOptions = {
  id: Yup.string().required("Required"),
  role_name: Yup.string().required("Required"),
};
export const formSchemaOptionsName = {
  id: Yup.string().required("Campo es requerido"),
  roleName: Yup.string().required("Campo es requerido"),
};

export const formSchemaOptionsIdAndName = {
  id: Yup.string().required("Campo es requerido"),
  name: Yup.string().required("Campo es requerido"),
};

export const formSchemaOptionsIdNumberAndName = {
  id: Yup.number().required("Campo es requerido"),
  name: Yup.string().required("Campo es requerido"),
};

export interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  roleData?: any;
  countries?: any | null;
  banks?: any | null;
  provinces?: any | null;
  position?: any | null;
  wereHouseData?: any | null;
  refreshData?: any | null;
  usersSystemData?: any | null;
}

export interface optionIdAndName {
  id: number;
  name: string;
}

export interface baseRequestType {
  pageable: {
    sort: {
      unsorted: boolean;
      sorted: boolean;
      empty: boolean;
    };
    pageNumber: number;
    pageSize: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  sort: {
    unsorted: boolean;
    sorted: boolean;
    empty: boolean;
  };
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export interface getRequestType {
  content: {
    id: number;
    name: string;
  }[];
  pageable: {
    sort: {
      unsorted: boolean;
      sorted: boolean;
      empty: boolean;
    };
    pageNumber: number;
    pageSize: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  sort: {
    unsorted: boolean;
    sorted: boolean;
    empty: boolean;
  };
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export interface week {
  date: Date | null;
  endDate?: Date | null;
  rangeDate?: string;
  statusId?: string;
  status?: optionIdAndName;
  notes?: string;
}

export interface mileagesType {
  area: number;
  areaType: string;
  startDate: Date;
  endDate?: any;
  daysQuantity: number;
  dayType: string;
  mileageTypeId?: number;
  mileageType: {
    id: string;
    name: string;
  };
}

export interface personType {
  id: number;
  name: string;
  lastName: string;
  surname: string;
  fullName: string;
}

export interface AddOrUpdateProjectsType {
  id: number;
  code?: string;
  type: string;
  name: string;
  attendant: personType;
  managerId: personType;
  contractAmount: number;
  currency: string;
  guaranteeCompliance?: string;
  startDate?: string;
  finalDate?: string;
  address: string;
  status: optionIdAndName;
  notes?: string;
  mileages: mileagesType[];
  weekendPermissions?: week[] | null;

  province: optionIdAndName;
  canton: optionIdAndName;
  district: optionIdAndName;
  projectImage?: File[];
}
