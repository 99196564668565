import { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Dialog,
  Tooltip,
} from "@mui/material";
import { TrashIcon, PlusIcon, PenIcon } from "../components/svgicons/SvgIcons";
import FancyTooltip from "../components/utils/FancyTooltip";
import FancyPaper from "../components/FancyPaper";
import { useForm } from "react-hook-form";
import {
  getRequestPerson,
  createRequest,
  updateRequest,
  deleteRequest,
  getRequestPosition,
} from "../services/person/PersonService";
import { getRequestCountries } from "../services/country/CountryService";
import { getRequestBank } from "../services/bank/BankService";
import { properties } from "../utils/Properties_es";
import { useApp } from "../hooks/useApp";
import DeleteModal from "../components/utils/DeleteModal";
import AddOrUpdatePersonModal from "../components/utils/AddOrUpdatePersonModal";
import UpdateUserPassModal from "../components/utils/UpdateUserPassModal";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import FancyDialogActions from "../components/utils/FancyDialogActions";
import FancyLightButton from "../components/FancyLightButton";
import FancySearch from "../components/utils/FancySearch";
import { getRequestType } from "../types/Utils";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { uploadDocumentsPerson } from "../services/documents/DocumentService";
import { getRequestUser } from "../services/user/User";

type Position = {
  id: number | null;
  name: string;
  salaryPerHour: number;
};

const ManagementPerson = () => {
  const {
    authInfo,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const [usersData, setUsersData] = useState<any>([]);
  const [usersSystemData, setUsersSystemData] = useState<any>([]);
  const [usersDataRole, setUsersRole] = useState<any>([]);
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);
  const [countries, setCountries] = useState<getRequestType | null>(null);
  const [banks, setBanks] = useState<getRequestType | null>(null);
  const [position, setPosition] = useState<any>(null);
  const [status, setStatus] = useState<{ id: string; title: string }>({
    id: "",
    title: "",
  });

  /* const [status, setStatus] = useState<statusType>({
    active: false,
    pending: false,
    probation: false,
  }); */

  const initForm = {
    id: 0,
    username: "",
    firstName: "",
    lastName: "",
    password: "",
    phone: null,
    status: "ACTIVE",
    roleId: 0,
    activationCode: "",
  };
  const [formData, setFormData] = useState<any>(initForm);

  const { handleSubmit } = useForm();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "", status?.id);
      await getCountriesAndBank();
      // handleDataCompany(0, "");
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  /**
   * Evento de aplicar filtro de busqueda
   */
  const handleApplyFilter = async (statusID?: string) => {
    await handleFetchData(
      0,
      preFilter,
      typeof statusID === "string" ? statusID : status?.id
    );
  };

  useEffect(() => {
    if (status === null) {
      handleFetchData(0, preFilter, "");
    }
    // eslint-disable-next-line
  }, [status]);

  const getCountriesAndBank = async () => {
    try {
      isLoading !== true && setLoading(false);
      const country: any = await getRequestCountries("", 0, 100);
      const banks: any = await getRequestBank("", 0, 100);
      const position: any = await getRequestPosition(0, "", 100);
      const user: any = await getRequestUser(0, "", "ACTIVE", 1000);
      if (user?.content) {
        setUsersSystemData(user.content);
      }

      if (country?.content) {
        setCountries(country.content);
      }
      if (banks?.content) {
        setBanks(banks.content);
      }
      if (position?.content) {
        setPosition(position.content);
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  /**
   *
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
    currentPage: number,
    filter: string,
    state?: string
  ) => {
    setLoading && setLoading(true);
    try {
      setPreFilter(filter);
      setPage(currentPage);

      let data = await getRequestPerson(currentPage, filter, state);

      if (data) {
        setUsersData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  /*  const handleFetchByID = async (id: number) => {
    setLoading && setLoading(true);
    try {
      let userData = await findByIdRequest(id);

      // let userData = usersData.content.find(
      //         (item: any) => item.id === Number(id)
      //       );
      //       console.log("userData: ", userData);
      if (userData) {
        //@ts-ignore
        setFormData(userData.content);
        //setFormData(userData);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  }; */

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData: any = await createRequest({
        ...data,
        user: authInfo?.username,
      });

      if (!createData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_inventory_create_error);
        setLoading && setLoading(false);
        return;
      }

      const idPerson = createData.id;

      if (!!data.inventoryImage.length) {
        const formData = new FormData();
        formData.append("personId", idPerson);
        for (let i = 0; i < data.inventoryImage.length; i++) {
          formData.append("file", data.inventoryImage[i]);
        }
        formData.append("user", authInfo.username);
        formData.append("documentType", "1");
        await uploadDocumentsPerson(formData);
      }

      if (!!data.inventoryDocuments.length) {
        const formData = new FormData();
        formData.append("personId", idPerson);
        for (let i = 0; i < data.inventoryDocuments.length; i++) {
          formData.append("file", data.inventoryDocuments[i]);
        }
        formData.append("user", authInfo.username);
        formData.append("documentType", "2");
        await uploadDocumentsPerson(formData);
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_spage_label_collaborators_create);
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      let updateData = await updateRequest({
        ...data,
        user: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_spage_label_collaborators_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_spage_label_collaborators_update);
      //call fetch data
      await handleFetchData(page, "", status?.id);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    console.log(id);
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      let deleteData = await deleteRequest(id);
      console.log(deleteData);

      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg(properties.com_spage_label_collaborators_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_spage_label_collaborators_delete);

      //reset page and call fetch data
      setPage(0);
      await handleFetchData(0, preFilter, status?.id);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    let RoleObject = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "create") {
      RoleObject = usersDataRole.content;
    }

    if (modalAction === "update" || modalAction === "passchange") {
      object = usersData?.content.find((p: any) => p.id === parseInt(id));
      //RoleObject = usersDataRole.content;
    }

    if (modalAction === "delete") {
      object = usersData?.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * Evento de cierre de modal
   * @param event
   */
  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter, status?.id);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== "delete") {
      setFormData(formData);
    }
  };

  const handleChangeStatus = async (e: any) => {
    setStatus(e);
    await handleApplyFilter(e?.id);
  };

  function isWithin30DaysOfExpiration(expirationDate: Date): boolean {
    // Obtener la fecha actual
    const today = new Date();

    // Calcular la diferencia en milisegundos entre ambas fechas
    const timeDifference = expirationDate.getTime() - today.getTime();

    // Convertir la diferencia a días
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    // Verificar si la diferencia es menor o igual a 15 días
    return daysDifference <= 30;
  }

  return (
    <>
      <FancyPaper pagetitle="Colaboradores">
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={5} sm={5} xs={12}>
            {/* {<ResourceAccess isCode={true} pathOrCode={"PERSON:WRITE"}>} */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              data-name="create"
            >
              Agregar colaborador
              <PlusIcon sx={{ ml: 1 }} />
            </Button>
            {/* {</ResourceAccess>} */}
          </Grid>
          <Grid item md={7} sm={7} xs={12}>
            <FancySearch
              preFilter={preFilter}
              setPreFilter={setPreFilter}
              handleFetchData={() => handleFetchData(0, "", status?.id)}
              handleApplyFilter={handleApplyFilter}
              handleChangeStatus={(e: any) => handleChangeStatus(e)}
              status={status}
              showStatus={true}
            />
          </Grid>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID Colaborador</TableCell>
                <TableCell align="center">Nombre</TableCell>
                <TableCell align="center">Cargo</TableCell>
                <TableCell align="center">Estado</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersData &&
              usersData?.content &&
              usersData?.content?.length > 0 ? (
                usersData?.content.map((row: any) => {
                  const positions = row?.positions;
                  const [firstPositionName, additionalPositionsCount] = row
                    ?.positions?.length
                    ? [row?.positions[0], row?.positions.length - 1]
                    : [{ id: null, name: "", salaryPerHour: 0 }, 0];
                  const fullName =
                    row?.name + " " + row?.lastName + " " + row?.surname;

                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell scope="row">
                        {row.idExpiration &&
                          isWithin30DaysOfExpiration(
                            new Date(row.idExpiration)
                          ) && (
                            <Tooltip
                              title="Documento pronto a vencer"
                              placement="top"
                              sx={{
                                marginLeft: "-20px",
                                marginRight: "4px",
                                transform: "translateY(4px)",
                              }}
                            >
                              <AnnouncementIcon color="primary" />
                            </Tooltip>
                          )}
                        {row.dni}
                      </TableCell>

                      <TableCell align="center">{fullName}</TableCell>

                      <TableCell align="center">
                        {firstPositionName.name === ""
                          ? "Sin cargo"
                          : firstPositionName.name}
                        {additionalPositionsCount > 0 && (
                          <Tooltip
                            title={
                              <div style={{ fontSize: "1.2em" }}>
                                <strong>Posiciones adicionales:</strong>
                                <ul style={{ margin: 0, paddingLeft: "0.9px" }}>
                                  {positions
                                    .slice(1)
                                    .map((position: Position) => (
                                      <li key={position.id}>{position.name}</li>
                                    ))}
                                </ul>
                              </div>
                            }
                            placement="top"
                          >
                            <span
                              style={{
                                fontSize: "0.9em",
                                color: "#888",
                                marginLeft: "8px",
                              }}
                            >
                              (+{additionalPositionsCount} más)
                            </span>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.state === 0 ? (
                          <FancyLightButton
                            backg="#1a932e61"
                            color="#1a932e!important"
                            text="Activo"
                          />
                        ) : row.state === 1 ? (
                          <FancyLightButton
                            backg="#cc00004d"
                            color="#CC0000!important"
                            text="Inactivo"
                          />
                        ) : (
                          <FancyLightButton
                            backg="#e65f2b5e"
                            color="#E65F2B!important"
                            text="Periodo prueba"
                          />
                        )}
                      </TableCell>

                      <TableCell align="center">
                        <FancyTooltip title="Editar" placement="top">
                          <IconButton
                            aria-label="edit"
                            component="label"
                            color="primary"
                            onClick={handleOpenModal}
                            data-name="update"
                            data-id={row.id}
                          >
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title="Eliminar" placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="error"
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name="delete"
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    {properties.com_parval_label_table_empty}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            usersData?.content?.length > 0 ? usersData?.content?.length : 0
          }
          rowsPerPage={usersData?.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={usersData?.totalElements}
          totalPages={usersData?.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          maxWidth="md"
          fullWidth
        >
          <AddOrUpdatePersonModal
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            countries={countries}
            banks={banks}
            usersSystemData={usersSystemData}
            position={position}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            roleData={usersDataRole}
          />
        </Dialog>
      )}
      {modalData?.modalType === "passchange" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <UpdateUserPassModal
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                <FancyDialogActions
                  handleAccept={handleSubmit(onSubmit)}
                  cancelModal={handleCancelModal}
                  textAcceptButton={"Eliminar"}
                />
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default ManagementPerson;
