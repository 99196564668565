import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import UrlBase from "../../url/Urls";

export const getRequestAdvances = async (
  page: number,
  size: number,
  startDate?: string,
  endDate?: string,
  filter?: string,
  userId?: number
) => {
  let responseData = {};
  const requestBody = {
    page: page,
    size: size ? size : UrlBase.registration_x_page,
    startDate: startDate,
    endDate: endDate,
    filter: filter,
    userId: userId,
  };
  await privateFetch
    .post(
      URL.REQUEST_ADVANCES_FIND_ALL, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en consulta de avances por proyecto");
    });
  return responseData;
};

export const getRequestDetailsAdvances = async (
  page: number,
  size: number,
  projectProgressId: string,
) => {
  let responseData = {};
  await privateFetch
    .get(
      URL.REQUEST_ADVANCES_FIND_ALL_DETAILS(page, size, projectProgressId), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en consulta de avances por proyecto");
    });
  return responseData;
};



export const createAdvance = async (
  data: any,
) => {
  const requestBody = {
    year: data.year,
    week: data.week,
    projectId: data.projectId,
    user: data.user,
    notes: "",
  };
  let responseData = {};
  await privateFetch
    .post(
      URL.REQUEST_CREATE_ADVANCE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en creación de avance");
    });
  return responseData;
};

export const createCoworkerAdvance = async (data: any) => {
  let responseData = {};
  await privateFetch
    .post(URL.REQUEST_CREATE_COWORKERS_ADVANCE, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const getRequestProjects = async (page: any, size: any) => {
  let responseData = {};
  await privateFetch
    .get(`/projects/api/?page=${page}&size=${size}`, {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en consulta de proyectos");
    });
  return responseData;
};

export const getRequestProductsPerProject = async (idProject?: number) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_PRODUCT_PER_PROJECT(idProject), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en consulta de proyectos");
    });
  return responseData;
};

export const deleteAdvanceRequest = async (data: any) => {
  console.log(data)
  let responseData = {};
  const requestBody = {
    positionId: data.positionId,
    personId: data.id,
    progressId: data.advanceId
  };
  await privateFetch
    .delete(URL.REQUEST_DELETE_COWORKER_ADVANCE, { data: requestBody })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en proceso de eliminar boleta");
    });
  return responseData;
};

export const deleteCompleteAdvanceRequest = async (id: any) => {
  let responseData = {};
  const requestBody = {
    id: id
  };
  await privateFetch
    .delete(URL.REQUEST_DELETE_ALL_ADVANCE, { data: requestBody })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en proceso de eliminar boleta");
    });
  return responseData;
};

export const getRequestAdvanceCSSReport = async (id: number): Promise<void> => {
  try {
    const url = `${UrlBase.base_url}${UrlBase.api_context}${URL.REQUEST_ADVANCE_CSS_REPORT(id)}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error al descargar el archivo. Código: ${response.status}`);
    }

    // Obtener el Blob de la respuesta
    const blob = await response.blob();

    // Forzar el tipo MIME correcto para Excel
    const excelBlob = new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Crear un enlace temporal para descargar el archivo
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(excelBlob);
    link.download = ""; // Nombre del archivo con extensión .xlsx
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  } catch (error: any) {
    console.error("Error al descargar el archivo:", error.message);
    throw new Error("Error al obtener el reporte de CSS del avance");
  }
};

export const getRequestAdvanceMonthReport = async (month: any, year: any): Promise<void> => {
  try {
    const url = `${UrlBase.base_url}${UrlBase.api_context}${URL.REQUEST_ADVANCE_MONTH_REPORT(month, year)}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error al descargar el archivo. Código: ${response.status}`);
    }

    // Obtener el Blob de la respuesta
    const blob = await response.blob();

    // Forzar el tipo MIME correcto para Excel
    const excelBlob = new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Crear un enlace temporal para descargar el archivo
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(excelBlob);
    link.download = ""; // Nombre del archivo con extensión .xlsx
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  } catch (error: any) {
    console.error("Error al descargar el archivo:", error.message);
    throw new Error("Error al obtener el reporte de CSS del avance");
  }
};


export const validateHours = async (data: any) => {
  // Construir los parámetros de la URL
  const queryParams = new URLSearchParams({
    personId: data.personId,
    year: data.year,
    week: data.week,
    mondayHours: data.mondayHours,
    tuesdayHours: data.tuesdayHours,
    wednesdayHours: data.wednesdayHours,
    thursdayHours: data.thursdayHours,
    fridayHours: data.fridayHours,
    saturdayHours: data.saturdayHours,
    sundayHours: data.sundayHours,
  }).toString();

  let responseData = {};

  await privateFetch
    .get(`${URL.REQUEST_PROJECTS_VALIDATE_HOURS(queryParams)}`, {}) // Usar la URL con los parámetros
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en validación de horas");
    });

  return responseData;
};

