import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import UrlBase from "../../url/Urls";

export const getRequestHistory = async (
  page: number,
  filter?: string,
  from?: string,
  to?: string
) => {
  const requestBody = {
    ...(filter && { filter: filter }),
    page: page,
    size: UrlBase.registration_x_page,
    from: from,
    to: to
  };
  let responseData = {};
  await privateFetch
    .post(
      URL.REQUEST_HISTORY, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en consulta de día de feriado");
    });
  return responseData;
};
