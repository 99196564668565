import { DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DialogProps } from "../../types/Utils";
import FancyDialogActions from "./FancyDialogActions";

interface AddOrUpdateBank {
  id?: number;
  name: string;
}

const AddOrUpdateBankModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  roleData,
}: DialogProps) => {
  // const { t } = useTranslation();
  const validation = Yup.object().shape({
    name: Yup.string().required("Campo es requerido"),
  });

  const defaultValues = {
    id: data?.id ?? 0,
    name: data?.name ?? "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateBank>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar banco" : "Actualizar banco"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={"Nombre"}
                    type="text"
                    variant="outlined"
                    value={value}
                    {...register("name")}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdateBankModal;
