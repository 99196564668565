import { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateReverse } from "../utils/UtilsFunctions";
import es from "date-fns/locale/es";
registerLocale("es", es);

const FancyRangeDatePicker = ({ rangeDate, date, endValue, onChange }: any) => {
  const [startDate, setStartDate] = useState(
    date !== null
      ? new Date(
          new Date(date).toLocaleDateString("en-US", { timeZone: "UTC" })
        )
      : new Date()
  );
  const [endDate, setEndDate] = useState<Date | null>(
    endValue !== null
      ? new Date(
          new Date(endValue).toLocaleDateString("en-US", { timeZone: "UTC" })
        )
      : new Date()
  );

  const onChangeWeekendPermissions = (dates: any) => {
    const [start, end] = dates;
    let stringDate = "";
    setStartDate(start);
    setEndDate(end);
    if (end !== null && end !== undefined) {
      stringDate = `{
                   "start": "${formatDateReverse(start)}",
                   "end": "${formatDateReverse(end)}"
                  }`;
    }

    onChange(stringDate);
  };

  return (
    <>
      <DatePicker
        selected={startDate}
        locale="es"
        onChange={(date) => {
          onChangeWeekendPermissions(date);
        }}
        startDate={startDate}
        endDate={endDate ?? undefined}
        selectsRange
        inline
        minDate={new Date()}
      />
    </>
  );
};

export default FancyRangeDatePicker;
