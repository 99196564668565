import { DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import FancyDialogActions from "./FancyDialogActions";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

interface AddOrUpdateRole {
  id?: number;
  adminLogin: boolean;
  clientLogin: boolean;
  description: string;
  roleName: string;
  notify?: {
    id?: boolean;
    name?: string;
  };
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

const AddOrUpdateRoleModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  //Validación de datos
  const validation = Yup.object().shape({
    adminLogin: Yup.boolean(),
    clientLogin: Yup.boolean(),
    description: Yup.string().required("Campo requerido"),
    roleName: Yup.string().required("Campo requerido"),
    notify: Yup.object().shape({
      id: Yup.boolean().required("Campo requerido"),
    }),
  });

  const defaultValues = {
    id: data ? data.id : 0,
    adminLogin: data ? data.adminLogin : false,
    clientLogin: data ? data.clientLogin : false,
    description: data ? data.description : "",
    roleName: data ? data.roleName : "",
    companyId: data ? data.companyId : 0,
    notify:
      data && data.notify
        ? { id: true, name: "Si" }
        : { id: false, name: "No" },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateRole>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update" ? "Actualizar perfil" : "Agregar perfil"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"roleName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={"Nombre"}
                    type="text"
                    variant="outlined"
                    value={value}
                    autoComplete="name3"
                    {...register("roleName")}
                    error={errors.roleName && Boolean(errors.roleName)}
                    helperText={errors.roleName && errors.roleName.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"description"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={"Descripción"}
                    type="text"
                    variant="outlined"
                    value={value}
                    autoComplete="description"
                    {...register("description")}
                    error={errors.description && Boolean(errors.description)}
                    helperText={
                      errors.description && errors.description.message
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdateRoleModal;
