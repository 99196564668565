import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";

export const getRequestProjects = async (
  page: number,
  filter?: string,
  statusId?: string,
  size?: number
) => {
  try {
    const response = await privateFetch.get(
      URL.REQUEST_PROJECTS_LIST(
        page,
        size ?? UrlBase.registration_x_page,
        filter,
        statusId
      )
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestCoworkers = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_COWORKERS(id, 0, 1000))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.mesagge);
    });
  return responseData;
};

export const getRequestProjectsByManager = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_PROJECTS_BY_MANAGER(id))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.mesagge);
    });
  return responseData;
};

export const findByIdRequestProject = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_PROJECTS(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.mesagge);
    });
  return responseData;
};

export const createProject = async (data: any) => {
  let responseData = {};

  await privateFetch
    .post(URL.REQUEST_PROJECTS(), JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const updateProject = async (data: any) => {
  let responseData = {};
  console.log("updateProject", data);

  await privateFetch
    .put(URL.REQUEST_PROJECTS(data.id), JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const deleteProject = async (id: any) => {
  let responseData = {};
  const requestBody = {
    id: id,
  };
  await privateFetch
    .delete(URL.DELETE_PROJECTS, { data: requestBody })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const getRequestListDetails = async (
  projectId: number,
  filter: string,
  page: number,
  size?: number,
  category?: string
) => {
  let responseData = {};
  await privateFetch
    .get(
      URL.REQUEST_CONSUMPTION_PER_PROJECT(
        projectId,
        filter,
        page,
        //@ts-ignore
        size ? size : UrlBase.registration_x_page,
        category
      )
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en la consulta de Consumo por proyecto " + error);
    });
  return responseData;
};

export const getRequestListConsumption = async (
  filter: string,
  page: number,
  size?: number,
  status?: number
) => {
  let responseData = {};
  await privateFetch
    .get(
      URL.REQUEST_CONSUMPTION_PER_PROJECT_LIST(
        filter,
        page,
        //@ts-ignore
        size ? size : UrlBase.registration_x_page,
        status
      )
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en consulta de permisos " + error);
    });
  return responseData;
};

export const getRequestProjectStatus = async () => {
  try {
    const response = await privateFetch.post(URL.REQUEST_PROJECTS_STATUS_LIST, {
      page: 0,
      size: UrlBase.registration_x_page,
      sorts: [
        {
          property: "id",
        },
      ],
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestProjectWeekendStatus = async () => {
  try {
    const response = await privateFetch.post(
      URL.REQUEST_PROJECTS_WEEKEND_STATUS_LIST,
      {
        page: 0,
        size: UrlBase.registration_x_page,
      }
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestMileagesType = async (page: number, filter?: string) => {
  try {
    const response = await privateFetch.post(
      URL.REQUEST_PROJECTS_MILEAGETYPE_LIST,
      {
        ...(filter && { filter: filter }),
        page,
        size: UrlBase.registration_x_page,
      }
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createMileageType = async (data: any) => {
  let responseData = {};

  await privateFetch
    .post(URL.REQUEST_PROJECTS_MILEAGETYPE, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const updateMileageType = async (data: any) => {
  let responseData = {};

  await privateFetch
    .put(URL.REQUEST_PROJECTS_MILEAGETYPE, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const deleteMileageType = async (id: any) => {
  let responseData = {};
  const requestBody = {
    id: id,
  };
  await privateFetch
    .delete(URL.REQUEST_PROJECTS_MILEAGETYPE, { data: requestBody })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const maxDateProjects = async (data: any) => {
  let responseData = {};

  await privateFetch
    .post(URL.MAX_DATE_PROJECTS, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};
