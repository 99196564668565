import { ChangeEvent } from "react";
import {
  TextField,
  Button,
  Stack,
  useTheme,
  Autocomplete,
} from "@mui/material";

interface searchType {
  preFilter: string;
  setPreFilter: any;
  handleFetchData: any;
  handleApplyFilter: () => {};
  handleApplyFilterStatus?: (value: string) => void;
  setCategory?: (value: string) => void;
  handleChangeStatus?: (e: ChangeEvent<HTMLInputElement>) => {};
  /* status?: {
    active: boolean;
    pending: boolean;
    probation: boolean;
  }; */
  category?: { id: string; title: string } | null;
  showStatus?: boolean;
}

const FancySearchConsumption = ({
  preFilter,
  setPreFilter,
  setCategory: setCategory,
  handleFetchData,
  handleApplyFilter,
  handleApplyFilterStatus,
  handleChangeStatus,
  category: category,
  showStatus,
}: searchType) => {
  const theme = useTheme();

  return (
    <Stack
      direction={{
        xs: "column",
        md: "row",
      }}
      alignItems={{
        xs: "flex-start",
        md: "center",
      }}
      spacing={{
        xs: 2,
        md: 4,
      }}
      justifyContent="flex-end"
      sx={{
        background: theme.palette.secondary.light,
        borderRadius: "10px",
        padding: "15px 25px",
        width: "fit-content",
        marginLeft: "auto",
      }}
    >
      {showStatus && (
        <Autocomplete
          options={[
            { id: "1", title: "Insumos" },
            { id: "2", title: "Herramientas" },
          ]}
          getOptionLabel={(option: any) => option.title || ""}
          value={category}
          onChange={(event: any, newValue: any) => {
            handleChangeStatus && handleChangeStatus(newValue);
          }}
          sx={{ width: "300px" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Categoría"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
        /*  <FormControl component="fieldset" variant="standard">
          <FormLabel
            component="legend"
            sx={{ color: theme.palette.primary.main }}
          >
            Estado
          </FormLabel>
          <FormGroup
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={status?.active}
                  onChange={handleChangeStatus}
                  name="active"
                />
              }
              label="Activo"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={status?.probation}
                  onChange={handleChangeStatus}
                  name="probation"
                />
              }
              label="Periodo prueba"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={status?.pending}
                  onChange={handleChangeStatus}
                  name="pending"
                />
              }
              label="Inactivo"
            />
          </FormGroup>
        </FormControl> */
      )}
      {handleApplyFilterStatus && (
        <Autocomplete
          options={[
            { id: "INSUMOS", title: "Insumos" },
            { id: "HERRAMIENTAS", title: "Herramientas" },
          ]}
          getOptionLabel={(option: any) => option.title || ""}
          value={category}
          onChange={(event: any, newValue: any) => {
            newValue === null
              ? handleApplyFilterStatus("")
              : handleApplyFilterStatus(newValue.id);
          }}
          sx={{ width: "250px" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Categoría"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      )}

      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <TextField
          placeholder="Filtro de búsqueda"
          sx={{
            width: "100%",
            marginRight: "10px",
            "& .MuiInputBase-root": {
              borderRadius: "18px",
              background: "#FFF",
            },
            "& input": {
              padding: "0.75rem 16px!important",
            },
            "& fieldset": {
              borderColor: "#FFF!important",
            },
          }}
          value={preFilter}
          onChange={(e: any) => {
            setPreFilter(e.target.value);
            if (e.target.value === "") {
              handleFetchData();
            }
          }}
          onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={handleApplyFilter}
          sx={{
            borderRadius: "8px!important",
            padding: "0.75rem 2rem",
            lineHeight: "1.4375em",
            background: "#FFF",
          }}
        >
          Buscar
        </Button>
      </Stack>
    </Stack>
  );
};

export default FancySearchConsumption;
