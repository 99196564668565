import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import UrlBase from "../../url/Urls";
import { properties } from "../../utils/Properties_es";

export const getRequestExitTicket = async (
  page: number,
  size: number,
  startDate?: string,
  endDate?: string,
  filter?: string,
  status?: string,
) => {
  console.log(status)
  let responseData = {};
  await privateFetch
    .post(
      URL.REQUEST_EXIT_TICKET(page, size, startDate, endDate, status !== undefined && status !== '0' ? true : status === '0' ? false : undefined, filter), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en consulta de tipos de movimiento");
    });
  return responseData;
};


export const getRequestTypeMovement = async (
  filter: string,
  page: number,
  size?: number
) => {
  const requestBody = {
    page: page,
    size: size ? size : UrlBase.registration_x_page
  };
  let responseData = {};
  await privateFetch
    .post(
      URL.REQUEST_TYPE_MOVEMENT, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en consulta de tipos de movimiento");
    });
  return responseData;
};

export const getRequestProjects = async (page: any, size: any) => {
  let responseData = {};
  await privateFetch
    .get(`/projects/api/?page=${page}&size=${size}`, {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en consulta de proyectos");
    });
  return responseData;
};

export const getRequestProductsPerProject = async (idProject?: number) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_PRODUCT_PER_PROJECT(idProject), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en consulta de proyectos");
    });
  return responseData;
};


export const createRequest = async (data: any) => {
  console.log(data)
  let responseData = {};
  const ticketActivities = data.items.map((item: any) => ({
    productCost: item.product.cost !== undefined ? item.product.cost : item.cost,
    quantity: item.quantity,
    productId: item.product.id
  }));

  const projectTransfer = data.items.map((item: any) => ({
    quantity: item.quantity,
    productId: item.product.id
  }));

  const requestBody = {
    user: data.user,
    date: data.date,
    notes: data.notes,
    status: data.status.id === 0 ? false : true,
    projectId: data.project.id,
    personId: data.person.id,
    // activityTypeId: data.movement_type.id,
    activityType: {
      id: data.movement_type.id,
      name: data.movement_type.name,
      code: data.movement_type.code
    },
    ticketProjectTransfer: {
      projectId: data.projectTransfer.id,
      productTransferQuantities: projectTransfer
    },
    ticketActivities: ticketActivities,
  };

  await privateFetch
    .post(URL.REQUEST_CRUD_EXIT_TICKET, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(
        error.response?.data?.message ? error.response.data.message : properties.com_parval_label_exitTicket_create_error
      );
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  console.log(data)
  let responseData = {};
  const ticketActivities = data.items.map((item: any) => ({
    // user: data.user,
    productCost:
      item.product.cost !== undefined
        ? item.product.cost
        : item.productCost !== undefined
          ? item.productCost
          : item.cost, // Corregido aquí      
    quantity: item.quantity,
    // ticketNumber: 1,      
    productId: item.product.id
  }));

  const projectTransfer = data.items.map((item: any) => ({
    quantity: item.quantity,
    productId: item.product.id
  }));

  const requestBody = {
    id: data.id,
    user: data.user,
    date: data.date,
    notes: data.notes,
    status: data.status.id === 0 ? false : true,
    projectId: data.project.id,
    personId: data.person.id,
    // activityTypeId: data.movement_type.id,
    activityType: {
      id: data.movement_type.id,
      name: data.movement_type.name,
      code: data.movement_type.code
    },
    ticketProjectTransfer: {
      projectId: data.projectTransfer.id,
      productTransferQuantities: projectTransfer
    },
    ticketActivities: ticketActivities,
  };

  await privateFetch
    .put(URL.REQUEST_CRUD_EXIT_TICKET, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(
        error.response?.data?.message ? error.response.data.message : properties.com_parval_label_exitTicket_update_error
      );
    });
  return responseData;
};

export const deleteRequest = async (id: any) => {
  let responseData = {};
  const requestBody = {
    id: id,
  };
  await privateFetch
    .delete(URL.REQUEST_CRUD_EXIT_TICKET, { data: requestBody })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response?.data?.message ? error.response.data.message : properties.com_parval_label_exitTicket_delete_error);
    });
  return responseData;
};
