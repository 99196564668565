import React, { useEffect } from 'react';
import { DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography, Box } from "@mui/material";
import { useForm, Controller, FieldErrors } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { NumericFormat } from 'react-number-format';
import FancyDialogActions from "../FancyDialogActions";
import { formatDateRange, formatNumber, getDatesInRange, formatDateForBackend } from '../formatters';
import { Tickets, TicketsSend } from './interface';
import { useApp } from '../../../hooks/useApp';
import { getRequestTickets, updateTicketRequest } from '../../../services/creditsDebitsTickets/CreditsService';
import { format, parse } from 'date-fns';
import { es } from 'date-fns/locale';

interface UserDetail {
    id?: number;
    name: string;
    authorized: boolean;
    dailyAmounts: DailyAmounts;
    notes: string;
    week: number;
    year: number;
    positionId: number;
    projectId: number;
    personId: number;
}

interface DailyAmounts {
    [key: string]: number;
}

interface AmountsModalProps {
    data: any;
    cancelModal: () => void;
    getData: () => void;
}

const AmountsByDayModal = ({
    data,
    cancelModal,
    getData,
}: AmountsModalProps) => {

    const {
        setLoading,
        setErrorMsg,
        setSuccessMsg,
        authInfo,
    } = useApp();

    const [startDate, endDate] = data.modalObject.dateRange.split(' / ');
    const datesInRange = getDatesInRange(startDate, endDate);

    const validation = Yup.object().shape({
        notes: Yup.string(),
        authorized: Yup.boolean().required("Campo requerido"),
        dailyAmounts: Yup.object().shape(
            datesInRange.reduce((acc: { [key: string]: Yup.NumberSchema }, date) => {
                acc[date] = Yup.number()
                    .typeError("Debe ser un número")
                    .min(0, "Debe ser mayor o igual a 0")
                    .required("Campo requerido");
                return acc;
            }, {})
        )
    });

    console.log(data.modalObject);

    const defaultValues: UserDetail = {

        name: data.modalObject.name,
        week: data.modalObject.filteredWeek,
        year: data.modalObject.year,
        positionId: data.modalObject.positionId,
        personId: data.modalObject.id,
        projectId: data.modalObject.project.id,

        authorized: false,
        notes: '',
        dailyAmounts: datesInRange.reduce((acc: DailyAmounts, date) => {
            acc[date] = 0;
            return acc;
        }, {})
    };


    const { control, handleSubmit, watch, register, reset, formState: { errors } } = useForm({
        defaultValues,
        resolver: yupResolver(validation),
    });

    const watchAllFields = watch();

    const totalAmount = datesInRange.reduce((total, date) => {
        return total + (watchAllFields.dailyAmounts[date] || 0);
    }, 0);

    const mapApiResponseToForm = (apiResponse: Tickets): UserDetail => {
        const defaultDailyAmounts = datesInRange.reduce((acc: DailyAmounts, date) => {
            acc[date] = 0;
            return acc;
        }, {});

        apiResponse.details.forEach((detail) => {
            const formattedDate = format(parse(detail.date, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
            if (defaultDailyAmounts[formattedDate] !== undefined) {
                defaultDailyAmounts[formattedDate] = detail.amount;
            }
        });

        return {
            id: apiResponse.id,
            name: apiResponse.person.name || "Desconocido",
            authorized: apiResponse.authorized,
            notes: apiResponse.notes,
            week: apiResponse.week,
            year: apiResponse.year,
            positionId: data.modalObject.positionId,
            personId: apiResponse.person.id,
            projectId: apiResponse.project.id,
            dailyAmounts: defaultDailyAmounts,
        };
    };

    const getTickets = async (ticketId: number) => {
        try {
            setLoading && setLoading(true);
            const response = await getRequestTickets(ticketId);

            if (!response) {
                return;
            }
            const formData = mapApiResponseToForm(response);

            reset(formData);
        } catch (error: any) {
            console.log(error);
            setErrorMsg && setErrorMsg(error.message);
        } finally {
            setLoading && setLoading(false);
        }
    };

    const mapFormToApiRequest = (formData: UserDetail): TicketsSend => {
        const details = Object.entries(formData.dailyAmounts).map(([date, amount]) => ({
            date: formatDateForBackend(new Date(date)),
            amount,
        }));

        return {
            user: authInfo?.username,
            id: formData.id,
            authorized: formData.authorized,
            notes: formData.notes,
            week: data.modalObject.filteredWeek,
            year: data.modalObject.year,
            positionId: data.modalObject.positionId,
            personId: data.modalObject.id,
            projectId: data.modalObject.project.id,
            details,
        };
    };

    const onSubmit = async (formData: any) => {
        const apiRequest = mapFormToApiRequest(formData);
        setLoading && setLoading(true);
        try {
            const response = await updateTicketRequest(apiRequest);
            if (response) {
                if (apiRequest.id) {
                    setSuccessMsg && setSuccessMsg("Datos actualizados correctamente");
                } else {
                    setSuccessMsg && setSuccessMsg("Datos guardados correctamente");
                }
                cancelModal && cancelModal();
                await getData();
            }
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
        } finally {
            setLoading && setLoading(false);
        }
    };

    useEffect(() => {
        if (data.modalObject.transportationPaymentId) {
            getTickets(data.modalObject.transportationPaymentId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.modalObject.transportationPaymentId]);


    return (
        <>
            <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>Montos por día</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1" sx={{ mb: 2, pt: 2, px: 4 }}>
                    Rango de fechas: {formatDateRange(data.modalObject.dateRange)}
                </Typography>

                {/* Información del colaborador */}
                <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        sx={{ px: 4 }}
                    >

                        <Grid item xs={12} sm={6}>
                            <Typography variant="h6">Colaborador:</Typography>
                            <Typography variant="body1">
                                {data.modalObject.name} {data.modalObject.lastName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h6">Puesto:</Typography>
                            <Typography variant="body1">
                                {data.modalObject.position || 'No especificado'}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="h6">DNI:</Typography>
                            <Typography variant="body1">
                                {data.modalObject.dni}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1">Autorizada</Typography>
                            <Controller
                                name="authorized"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel value="true" control={<Radio />} label="Sí" />
                                        <FormControlLabel value="false" control={<Radio />} label="No" />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} direction="column" sx={{ px: 4 }}>
                        {datesInRange.map((date) => (
                            <Grid item xs={12} key={date} sx={{ mt: 2 }}>
                                <Controller
                                    name={`dailyAmounts.${date}`}
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <NumericFormat
                                            customInput={TextField}
                                            fullWidth
                                            size="small"
                                            label={`${format(parse(date, 'yyyy-MM-dd', new Date()), "EEEE, d 'de' MMMM", { locale: es })} (${date})`}
                                            prefix="₡"
                                            decimalScale={2}
                                            fixedDecimalScale
                                            thousandSeparator
                                            value={value || 0}
                                            onValueChange={(values) => {
                                                const { floatValue } = values;
                                                onChange(floatValue || 0);
                                            }}
                                            variant="outlined"
                                            error={!!(errors.dailyAmounts && (errors.dailyAmounts as FieldErrors<DailyAmounts>)[date])}
                                            helperText={(errors.dailyAmounts && (errors.dailyAmounts as FieldErrors<DailyAmounts>)[date]?.message)} />
                                    )}
                                />
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ mt: 2 }}>
                                Total: {formatNumber(totalAmount)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="notes"
                                control={control}
                                render={({ field: { value } }) => (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Notas"
                                        multiline
                                        maxRows={4}
                                        disabled={data?.status === true}
                                        value={value}
                                        variant="outlined"
                                        {...register("notes")}
                                        sx={{ background: "#FFF" }}
                                        error={errors.notes && Boolean(errors.notes)}
                                        helperText={errors.notes && errors.notes.message}
                                        inputProps={{
                                            autoComplete: "new-password",
                                            form: {
                                                autoComplete: "off",
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <FancyDialogActions
                        handleAccept={handleSubmit(onSubmit)}
                        cancelModal={cancelModal}
                        textAcceptButton="Guardar"
                    />
                </form>
            </DialogContent>
        </>
    );
};

export default AmountsByDayModal;

