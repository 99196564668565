import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";

export const getRequestDashboard = async () => {
  try {
    const response = await privateFetch.get(URL.REQUEST_DASHBOARD);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      console.error("Error en la respuesta de la API:", error.response.data);
    } else {
      console.error("Error al hacer la solicitud:", error.message);
    }
    throw new Error(error.message);
  }
};
