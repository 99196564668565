import { useState, FC, useLayoutEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Grid, Typography, Button, styled, Stack, Paper } from "@mui/material";
import { useApp } from "../../hooks/useApp";
import { SVActiveUserRequest } from "../../services/Security";
import LogoMR from "../../resources/images/imagen_logo_mrpintores.png";
import loginScreen from "../../resources/images/recover_password.png";

// Estilos personalizados
const RememberContainer = styled(Grid)(({ theme }) => ({
  height: "100vh",
  backgroundColor: "#f5f5f5",
}));

const LeftSide = styled(Grid)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "2rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const RightSide = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${loginScreen})`,
  backgroundSize: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem",
  height: "100vh",
}));

const Logo = styled("img")({
  width: "500px", // Ajusta el tamaño del logo
  height: "auto",
});

const ActiveRegister: FC = () => {
  const { code, email } = useParams();
  const [validateUser, setValidateUser] = useState(false);
  const [message, setMessage] = useState("");

  const { setLoading } = useApp();

  const activeUser = async () => {
    try {
      setLoading(true);
      if (email && code) {
        console.log("Entra");
        const response = await SVActiveUserRequest(email, code);
        if (response) {
          setLoading(false);
          setValidateUser(true);
        }
      }
    } catch (e: any) {
      setLoading(false);
      setMessage(e.response.data.message);
    }
  };

  useLayoutEffect(() => {
    activeUser();
    // eslint-disable-next-line
  }, []);

  return (
    <RememberContainer container>
      <RightSide item xs={12} md={6}>
        <Logo src={LogoMR} alt="Company Logo" />
      </RightSide>
      <LeftSide item xs={12} md={6}>
        <Typography
          textAlign="center"
          variant="h4"
          color="primary"
          fontWeight="bold"
          gutterBottom
          sx={{ mb: 5 }}
        >
          Activación de Usuario
        </Typography>
        <Stack justifyContent="center" alignItems="center" p={2}>
          <Paper
            sx={{
              background: "#FFFFFF",
              py: 10,
              px: 10,
              //boxShadow: "none",
            }}
          >
            {validateUser ? (
              <>
                <Typography variant="body1" align="center" sx={{ mb: 3 }}>
                  ¡Ha autenticado y activado su cuenta correctamente!
                </Typography>
                <Button
                  fullWidth
                  component={Link}
                  to="/"
                  sx={{
                    mt: 3,
                    width: "350px",
                    backgroundColor: "#D32F2F",
                    fontSize: "16px",
                    borderRadius: "30px",
                    color: "#FFF",
                    "&:hover": {
                      backgroundColor: "#C62828",
                    },
                  }}
                >
                  Iniciar sesión
                </Button>
              </>
            ) : (
              <>
                <Typography variant="body1" color={"error"}>
                  ¡Ha ocurrido un error!
                </Typography>
                <Typography variant="subtitle1" color="success.main">
                  {message}
                </Typography>
              </>
            )}
          </Paper>
        </Stack>
      </LeftSide>
    </RememberContainer>
  );
};

export default ActiveRegister;
