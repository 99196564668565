import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";

export const getRequestProductsInventory = async (
  page: number,
  filter: string,
  size?: number,
  warehouse?: string,
  description?: string,
  availability?: string,
  availableQuantity?: string
) => {
  const requestBody = {
    filter: filter,
    page: page,
    size: size ? size : UrlBase.registration_x_page,
    warehouse: warehouse,
    description: description,
    availability: availability,
    availableQuantity: availableQuantity
  };
  try {
    const response = await privateFetch.post(URL.REQUEST_INVENTORY, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      console.error("Error:", error.response.data.message);
      throw new Error(error.response.data.message);
    } else {
      throw new Error(error.message);
    }
  }
};

export const getRequestWarehouse = async () => {
  let responseData = {};
  const requestBody = {
    filter: "",
    page: 0,
    size: 50,
  };
  await privateFetch
    .post(URL.REQUEST_WAREHOUSE_LIST, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data.content;
    })
    .catch(async (error) => {
      if (error.response && error.response.data) {
        console.error("Error:", error.response.data.message);
        throw new Error(error.response.data.message);
      } else {
        console.error("Error:", error.message);
        throw new Error(error.message);
      }
    });

  return responseData;
};

export const getRequestListFamily = async (
  filter: string,
  page: number,
  size: string
) => {
  let responseData = {};
  await privateFetch
    .get(
      URL.REQUEST_FAMILY_LIST(
        filter,
        page,
        size ? size : UrlBase.registration_x_page
      )
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        console.error("Error:", error.response.data.message);
        throw new Error(error.response.data.message);
      } else {
        console.error("Error:", error.message);
        throw new Error(error.message);
      }
    });
  return responseData;
};

export const getRequestListSubFamily = async (
  familyId: number,
  filter: string,
  page: number,
  size: string
) => {
  let responseData = {};
  await privateFetch
    .get(
      URL.REQUEST_SUBFAMILY_LIST(
        familyId,
        filter,
        page,
        size ? size : UrlBase.registration_x_page
      )
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        console.error("Error:", error.response.data.message);
        throw new Error(error.response.data.message);
      } else {
        console.error("Error:", error.message);
        throw new Error(error.message);
      }
    });
  return responseData;
};

export const getRequestListSubSubFamily = async (
  subFamilyId: number,
  filter: string,
  page: number,
  size: string
) => {
  let responseData = {};
  await privateFetch
    .get(
      URL.REQUEST_SUBSUBFAMILY_LIST(
        subFamilyId,
        filter,
        page,
        //@ts-ignore
        size ? size : UrlBase.registration_x_page
      )
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        console.error("Error:", error.response.data.message);
        throw new Error(error.response.data.message);
      } else {
        console.error("Error:", error.message);
        throw new Error(error.message);
      }
    });
  return responseData;
};

export const getRequestTotal = async () => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_INVENTORY_TOTAL)
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        console.error("Error:", error.response.data.message);
        throw new Error(error.response.data.message);
      } else {
        console.error("Error:", error.message);
        throw new Error(error.message);
      }
    });
  return responseData;
};

export const createRequest = async (data: any) => {
  let responseData = {};

  const requestBody = {
    user: data?.user,
    code: data?.code,
    officialCode: data?.officialCode,
    serialCode: data?.serialCode,
    description: data?.description,
    acquisitionDate: data?.acquisitionDate,
    availability: data?.availability,
    availableQuantity: data?.availableQuantity,
    quantity: data?.quantity,
    brand: data?.brand,
    category: data?.category,
    color: data?.color,
    cost: data?.cost,
    status: data?.status,
    warehouseLocation: data?.warehouseLocation,
    warehouseId: data?.warehouseId,
    notes: data?.notes,
    subSubFamilyId: data?.subSubFamily?.id,
    measure: data?.measure,
  };
  console.log("Crear: ", requestBody);
  await privateFetch
    .post(URL.REQUEST_INVENTORY_CREATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      if (error.response && error.response.data) {
        console.error("Error:", error.response.data.message);
        throw new Error(error.response.data.message);
      } else {
        console.error("Error:", error.message);
        throw new Error(error.message);
      }
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data?.id,
    code: data?.code,
    officialCode: data?.officialCode,
    serialCode: data?.serialCode,
    description: data?.description,
    acquisitionDate: data?.acquisitionDate,
    availability: data?.availability,
    availableQuantity: data?.availableQuantity,
    quantity: data?.quantity,
    brand: data?.brand,
    category: data?.category,
    color: data?.color,
    cost: data?.cost,
    status: data?.status,
    subSubFamilyId: data?.subSubFamily?.id,
    warehouseLocation: data?.warehouseLocation,
    warehouseId: data?.warehouseId,
    notes: data?.notes,
    measure: data?.measure,
  };
  console.log("Actualizar: ", requestBody);
  await privateFetch
    .put(URL.REQUEST_INVENTORY_CREATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      if (error.response && error.response.data) {
        console.error("Error:", error.response.data.message);
        throw new Error(error.response.data.message);
      } else {
        console.error("Error:", error.message);
        throw new Error(error.message);
      }
    });
  return responseData;
};

export const deleteRequest = async (id: any) => {
  let responseData = {};
  try {
    const response = await privateFetch.delete(
      URL.REQUEST_INVENTORY_DELETE(id)
    );
    responseData = response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      console.error("Error:", error.response.data.message);
      throw new Error(error.response.data.message);
    } else {
      console.error("Error:", error.message);
      throw new Error(error.message);
    }
  }
  return responseData;
};

export const uploadInventoryXml = async (xmlFile: File) => {
  // Crear un FormData para enviar el archivo
  const formData = new FormData();
  formData.append("file", xmlFile); // "file" es el nombre del campo que espera el backend

  try {
    const response = await privateFetch.post(
      URL.REQUEST_INVENTORY_XML, 
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Especificamos el tipo de contenido
        },
      }
    );

    return response.data; // Retornamos la respuesta del servidor
  } catch (error: any) {
    if (error.response && error.response.data) {
      console.error("Error:", error.response.data.message);
      throw new Error(error.response.data.message);
    } else {
      throw new Error(error.message);
    }
  }
};
