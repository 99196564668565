import {
  Grid,
  Typography,
  TextField,
  Button,
  styled,
  InputAdornment,
  Paper,
} from "@mui/material";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { SVRecoveryPassRequest } from "../../services/Security";
import LogoMR from "../../resources/images/imagen_logo_mrpintores.png";
import loginScreen from "../../resources/images/recover_password.png";

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: "none",
  fontWeight: 700,
}));

// Estilos personalizados
const RememberContainer = styled(Grid)(({ theme }) => ({
  height: "100vh",
  backgroundColor: "#f5f5f5",
}));

const LeftSide = styled(Grid)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "2rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const RightSide = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${loginScreen})`,
  backgroundSize: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem",
  height: "100vh",
}));

const Logo = styled("img")({
  width: "500px", // Ajusta el tamaño del logo
  height: "auto",
});

const RememberPassword = () => {
  const { setLoading, setErrorMsg, setSuccessMsg } = useApp();

  const validation = Yup.object().shape({
    username: Yup.string().required("Email es requerido"),
    //.email("Email es invalido"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<any>({
    resolver: yupResolver(validation),
  });

  const handleRememberPass = async (email: string) => {
    try {
      setLoading(true);
      const response: any = await SVRecoveryPassRequest(email);
      if (response) {
        setLoading(false);
        setSuccessMsg(response.info);
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const onSubmit = async (data: any) => {
    console.log(data);
    await handleRememberPass(data);
    //navigate("/dashboard/home");
  };

  return (
    <>
      <RememberContainer container>
        <RightSide item xs={12} md={6}>
          <Logo src={LogoMR} alt="Company Logo" />
        </RightSide>
        <LeftSide item xs={12} md={6}>
          <Typography
            textAlign="center"
            variant="h4"
            color="primary"
            fontWeight="bold"
            gutterBottom
            sx={{ mb: 5 }}
          >
            Recuperar contraseña!
          </Typography>
          <form>
            <Grid item xs={12} textAlign="center">
              <Typography sx={{ mb: 4 }}>
                Enviaremos un correo para poder recuperar la contraseña
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={4}
              sx={{ mb: 10 }}
            >
              <Paper
                sx={{
                  background: "#FFFFFF",
                  py: 10,
                  px: 10,
                  //boxShadow: "none",
                }}
              >
                <Grid item xs={12} textAlign="center">
                  <Typography fontWeight="bold" sx={{ ml: -70 }}>
                    Email:
                  </Typography>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Controller
                    name={"username"}
                    control={control}
                    render={() => (
                      <TextField
                        fullWidth
                        size="small"
                        sx={{
                          width: "350px",
                          marginBottom: "20px",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px", // Bordes redondeados
                          },
                        }}
                        type="email"
                        placeholder="Usuario"
                        autoComplete="username"
                        {...register("username")}
                        error={errors.username && Boolean(errors.username)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PermContactCalendarIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                    sx={{
                      mt: 3, // Espaciado superior (margen superior)
                      width: "350px", // Ajusta el ancho del botón
                      backgroundColor: "#D32F2F", // Color de fondo
                      fontSize: "16px",
                      borderRadius: "30px",
                      color: "#FFF", // Color del texto
                      "&:hover": {
                        backgroundColor: "#C62828", // Color de fondo al hacer hover
                      },
                    }}
                  >
                    Enviar
                  </Button>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Typography align="center" sx={{ mt: 6 }} variant="body1">
                    Regresar a <LinkTo to="/">inicio de sesión</LinkTo>
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          </form>
        </LeftSide>
      </RememberContainer>
    </>
  );
};

export default RememberPassword;
