import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";

export const uploadDocuments = async (data: any) => {
  let responseData = {};

  await privateFetch
    .post(URL.DOCUMENTS_UPLOAD, data, {
      headers: {
        ...CONSTANT.HEADER_TYPE_DOCUMENTS,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.message);
    });
  return responseData;
};

export const getRequestDocuments = async (id: number) => {
  try {
    const response = await privateFetch.get(URL.DOCUMENTS_LIST(id));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const deleteDocuments = async (data: any, product: any) => {
  let responseData = {};
  const requestBody = {
    documentIds: [data],
    productId: product,
  };

  await privateFetch
    .delete(URL.DOCUMENTS_DELETE, { data: requestBody })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      //throw new Error(properties.com_parval_label_role_delete_error);
      console.log(error);
    });
  return responseData;
};

export const uploadDocumentsPerson = async (data: any) => {
  let responseData = {};

  await privateFetch
    .post(URL.DOCUMENTS_UPLOAD_PERSON, data, {
      headers: {
        ...CONSTANT.HEADER_TYPE_DOCUMENTS,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.message);
    });
  return responseData;
};

export const getRequestDocumentsPerson = async (
  id: number,
  documentType: number
) => {
  try {
    const response = await privateFetch.get(
      URL.DOCUMENTS_LIST_PERSON(id, documentType)
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestDocumentsBase64Person = async (
  id: number,
  documentType: number
) => {
  try {
    const response = await privateFetch.get(
      URL.DOCUMENTS_BASE64_LIST_PERSON(id, documentType)
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const deleteDocumentsPerson = async (data: any, product: any) => {
  let responseData = {};
  const requestBody = {
    documentIds: [data],
    personId: product,
  };

  await privateFetch
    .delete(URL.DOCUMENTS_DELETE_PERSON, { data: requestBody })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      //throw new Error(properties.com_parval_label_role_delete_error);
      console.log(error);
    });
  return responseData;
};

export const uploadProjectDocuments = async (data: any) => {
  let responseData = {};

  await privateFetch
    .post(URL.DOCUMENTS_PROJECTS_UPLOAD, data, {
      headers: {
        ...CONSTANT.HEADER_TYPE_DOCUMENTS,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.message);
    });
  return responseData;
};

export const deleteProjectDocuments = async (data: any, product: any) => {
  let responseData = {};
  const requestBody = {
    documentIds: [data],
    projectId: product,
  };

  await privateFetch
    .delete(URL.DOCUMENTS_PROJECTS_DELETE, { data: requestBody })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      //throw new Error(properties.com_parval_label_role_delete_error);
      console.log(error);
    });
  return responseData;
};

export const getRequestProjectDocuments = async (
  id: number,
  documentType: number
) => {
  try {
    const response = await privateFetch.get(
      URL.DOCUMENTS_PROJECTS_GET(id, documentType)
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};
