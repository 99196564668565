import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  styled,
  InputAdornment,
  Paper,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { ForgotPasswordType } from "../../types/Login";
import { SVChangePassRequest } from "../../services/Security";
import LogoMR from "../../resources/images/imagen_logo_mrpintores.png";
import loginScreen from "../../resources/images/recover_password.png";

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: "none",
  fontWeight: 700,
}));

// Estilos personalizados
const RememberContainer = styled(Grid)(({ theme }) => ({
  height: "100vh",
  backgroundColor: "#f5f5f5",
}));

const LeftSide = styled(Grid)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "2rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const RightSide = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${loginScreen})`,
  backgroundSize: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem",
  height: "100vh",
}));

const Logo = styled("img")({
  width: "500px", // Ajusta el tamaño del logo
  height: "auto",
});

function ForgotPassword() {
  const { code } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const { setLoading, setErrorMsg, setSuccessMsg } = useApp();

  const validationForgotPassword = Yup.object().shape({
    password: Yup.string()
      .required("Contaseña es requerida")
      .min(6, "Contraseña debe tener al menos 8 caracteres")
      .test(
        "password",
        `Formato no es correcto, debe contener al menos 1 mayúsculas, 1 minúsculas, 1 número y 1 carácter especial`,
        (value: any) =>
          value.match(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/
          )?.length > 0
      ),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Contraseñas no coinciden")
      .required("Repetir contraseña es requerido"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<ForgotPasswordType>({
    resolver: yupResolver(validationForgotPassword),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangePassword = async (data: ForgotPasswordType) => {
    try {
      setLoading(true);
      const response: any = await SVChangePassRequest({
        id: code,
        password: data.password,
      });
      if (response) {
        setLoading(false);
        setSuccessMsg(response.info);

        reset({
          password: "", // Reiniciar campo de contraseña
          repeatPassword: "", // Reiniciar campo de repetir contraseña
        });
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const onSubmit = async (data: any) => {
    await handleChangePassword(data);
  };

  return (
    <>
      <RememberContainer container>
        <RightSide item xs={12} md={6}>
          <Logo src={LogoMR} alt="Company Logo" />
        </RightSide>
        <LeftSide item xs={12} md={6}>
          <Typography
            textAlign="center"
            variant="h4"
            color="primary"
            fontWeight="bold"
            gutterBottom
            sx={{ mb: 6 }}
          >
            Recuperar contraseña!
          </Typography>
          <form>
            <Grid item xs={12} textAlign="center">
              <Typography sx={{ ml: -15, mb: 6 }}>
                Escriba su nueva contraseña
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={4}
              sx={{ mb: 10 }}
            >
              <Paper
                sx={{
                  background: "#FFFFFF",
                  py: 10,
                  px: 10,
                  //boxShadow: "none",
                }}
              >
                <Grid item xs={12} sx={{ mb: 6 }}>
                  <Controller
                    name={"password"}
                    control={control}
                    render={() => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Contraseña"
                        type={showPassword ? "text" : "password"}
                        autoComplete="password"
                        {...register("password")}
                        error={errors.password && Boolean(errors.password)}
                        helperText={errors.password && errors.password.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton onClick={handleClickShowPassword}>
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name={"repeatPassword"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Confirmar Contraseña"
                        type={showPassword ? "text" : "password"}
                        autoComplete="repeatPassword"
                        {...register("repeatPassword")}
                        error={
                          errors.repeatPassword &&
                          Boolean(errors.repeatPassword)
                        }
                        helperText={
                          errors.repeatPassword && errors.repeatPassword.message
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton onClick={handleClickShowPassword}>
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                    sx={{
                      mt: 3,
                    }}
                  >
                    Enviar
                  </Button>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Typography align="center" sx={{ mt: 6 }} variant="body1">
                    Regresar a <LinkTo to="/">inicio de sesión</LinkTo>
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          </form>
        </LeftSide>
      </RememberContainer>
    </>
  );
}

export default ForgotPassword;
