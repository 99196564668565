import { Button } from "@mui/material";
import FancyTooltip from "./utils/FancyTooltip";

interface buttonsProps {
  backg: string;
  color: string;
  text: string;
  tooltipText?: string;
}
const FancyLightButton = ({
  backg,
  color,
  text,
  tooltipText,
}: buttonsProps) => {
  return (
    <FancyTooltip title={tooltipText} placement="top">
      <Button
        variant="contained"
        sx={{
          background: backg,
          textTransform: "capitalize",
          color: color,
          borderRadius: "25rem",
          padding: "0.5rem 1rem",
          "&:hover": {
            background: backg,
          },
        }}
      >
        {text}
      </Button>
    </FancyTooltip>
  );
};

export default FancyLightButton;
