import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import UrlBase from "../../url/Urls";

export const getRequestHoliday = async (
  page: number,
  size: number,
  filter?: string,
  selectedYear?:any
) => {
  const requestBody = {
    ...(filter && { filter: filter }),
    page: page,
    size: size ? size : UrlBase.registration_x_page,
    year: selectedYear
  };
  let responseData = {};
  await privateFetch
    .post(
      URL.REQUEST_HOLIDAY(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en consulta de día de feriado");
    });
  return responseData;
};

export const createRequest = async (data: any) => {
  console.log(data)
  let responseData = {};
  const requestBody = {
    dayOfMonth: data.dayOfMonth,
    month: data.month,
    //year: data.year,
    description: data.description,
    mandatoryPayment: data.mandatoryPayment,
    enabled: data.enabled,
    user: data.user
  };

  await privateFetch
    .post(URL.REQUEST_CRUD_HOLIDAY, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en proceso de guardar día feriado");
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  console.log(data)
  let responseData = {};
  const requestBody = {
    id: data.id,
    dayOfMonth: data.dayOfMonth,
    month: data.month,
    //year: data.year,
    description: data.description,
    mandatoryPayment: data.mandatoryPayment,
    enabled: data.enabled,
    user: data.user
  };

  await privateFetch
    .put(URL.REQUEST_CRUD_HOLIDAY, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en proceso de actualizar día feriado");
    });
  return responseData;
};

export const rescheduleRequest = async (data: any) => {
  console.log(data)
  let responseData = {};
  const requestBody = {
    holidayId: data.id,
    dayOfMonth: data.newDayOfMonth,
    month: data.newMonth,
    year: data.year.id,
    mandatoryPayment: data.mandatoryPayment,
    user: data.user
  };

  await privateFetch
    .post(URL.REQUEST_RESCHEDULE_HOLIDAY(requestBody),{
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en proceso de recalendarizar día feriado");
    });
  return responseData;
};

export const deleteRequest = async (id: any) => {
  let responseData = {};
  const requestBody = {
    id: id,
  };
  await privateFetch
    .delete(URL.REQUEST_CRUD_HOLIDAY, {data: requestBody})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en proceso de eliminar día feriado");
    });
  return responseData;
};
