import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import FancyPaper from "../components/FancyPaper";
import { getRequestListDetails } from "../services/projects/ProjectService";
import { useApp } from "../hooks/useApp";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import FancySearchConsumption from "../components/utils/FancySearchConsumption";

const ManagementSubFamilies = () => {
  const { setLoading, setErrorMsg, modalData, setModalData, errorMsg } =
    useApp();
  const theme = useTheme();
  const [detailsData, setDetailsData] = useState<any>([]);
  const { id, nameC } = useParams<{ id: string; nameC: string }>();
  const projectId = Number(id);
  const [page, setPage] = useState(0);
  const [preFilter, setPreFilter] = useState<any>("");
  const [category, setCategory] = useState("");
  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(projectId, 0, "");
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
    projectId: number,
    currentPage: number,
    filter: string,
    category?: any
  ) => {
    setLoading && setLoading(true);
    setDetailsData([]);
    try {
      setPage(currentPage);
      let data = await getRequestListDetails(
        projectId,
        filter,
        currentPage,
        50,
        category
      );
      if (data) {
        setDetailsData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleApplyFilter = async () => {
    await handleFetchData(projectId, 0, preFilter, category);
  };

  const handleApplyFilterStatus = async (selectedCategory: any) => {
    setCategory(selectedCategory);
    await handleFetchData(projectId, 0, preFilter, selectedCategory);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    const customPage = newPage - 1;
    setPage(customPage);
    if (customPage !== page) {
      await handleFetchData(projectId, customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };
  const totalCost =
    detailsData?.reduce((acc: number, row: any) => acc + row.cost, 0) || 0;

  console.log(detailsData)
  return (
    <>
      <FancyPaper
        pagetitle={nameC}
        prevpage="Consumo por Proyecto"
        linkprevpage="/dashboard/consumption"
      >
        <Grid container spacing={8} sx={{ pb: 8 }} justifyContent={"flex-end"}>
          <Grid item md={5} sm={7} xs={12}>
            <FancySearchConsumption
              preFilter={preFilter}
              setPreFilter={setPreFilter}
              handleFetchData={() =>
                handleFetchData(projectId, 0, "", category)
              }
              handleApplyFilter={handleApplyFilter}
              handleApplyFilterStatus={handleApplyFilterStatus}
              setCategory={setCategory}
            />
          </Grid>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{"Fecha"}</TableCell>
                <TableCell align="center">{"Tipo de movimiento"}</TableCell>
                <TableCell align="center">{"Categoría"}</TableCell>
                <TableCell align="center">{"Cantidad"}</TableCell>
                <TableCell align="center">{"Código"}</TableCell>
                <TableCell align="center">{"Descripción"}</TableCell>
                <TableCell align="center">{"Notas"}</TableCell>
                <TableCell align="center">{"Costo"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detailsData.map((row: any, i: number) => (
                <TableRow
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell scope="row">{row.date}</TableCell>
                  <TableCell align="center">
                    {row.movementType === "Cambio de Proyecto" ? (
                      <>
                        {row.movementType}
                        <br />
                        Proyecto origen: {row.originProjectName ? row.originProjectName : "No registrado"}
                      </>
                    ) : (
                      row.movementType
                    )}
                  </TableCell>

                  <TableCell align="center">{row.category}</TableCell>
                  <TableCell align="center">{row.quantity}</TableCell>
                  <TableCell align="center">{row.code}</TableCell>
                  <TableCell align="center">{row.description}</TableCell>
                  <TableCell align="center">{row.notes}</TableCell>
                  <TableCell align="center">
                    {row.cost.toLocaleString("es-CR", {
                      style: "currency",
                      currency: "CRC",
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell
                  colSpan={7}
                  align="right"
                  style={{
                    fontWeight: "bold",
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: "10px",
                  }}
                >
                  Costo Total:
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: "bold",
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: "10px",
                  }}
                >
                  {totalCost.toLocaleString("es-CR", {
                    style: "currency",
                    currency: "CRC",
                    minimumFractionDigits: 2,
                  })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            detailsData.content?.length > 0 ? detailsData.content?.length : 0
          }
          rowsPerPage={detailsData.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={detailsData.totalElements}
          totalPages={detailsData.totalPages}
        />
      </FancyPaper>
    </>
  );
};

export default ManagementSubFamilies;
