import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { getRequestType } from "./PurchaseOrdersType";
import { formatDateReverse } from "../../utils/UtilsFunctions";

export const getRequestPurchaseOrders = async (
  page: number,
  data: any,
  filter: string,
  status: string
) => {
  let responseData: getRequestType | null = null;
  let startDate = formatDateReverse(new Date(data.startDate));
  let endDate = formatDateReverse(new Date(data.endDate));

  const params = new URLSearchParams([
    ["filter", filter],
    ["page", page],
    ["size", UrlBase.registration_x_page],
    ["startDate", startDate],
    ["endDate", endDate],
    ["status", status],
  ]);

  let keysParams: any = [];
  params.forEach((value, key) => {
    if (value === "" || value === null) {
      keysParams.push(key);
    }
  });

  keysParams.forEach((key: any) => {
    params.delete(key);
  });

  await privateFetch
    .post(URL.REQUEST_PURCHASE_ORDERS, null, {
      params,
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const createPurchaseOrders = async (data: any) => {
  let responseData = {};

  await privateFetch
    .post(URL.CUD_PURCHASE_ORDERS, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const updatePurchaseOrders = async (data: any) => {
  let responseData = {};
  console.log("updateProject", data);

  await privateFetch
    .put(URL.CUD_PURCHASE_ORDERS, JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const deletePurchaseOrders = async (id: any) => {
  let responseData = {};
  const requestBody = {
    id: id,
  };
  await privateFetch
    .delete(URL.CUD_PURCHASE_ORDERS, { data: requestBody })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};
