import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { SubSubFamilyResponse } from "./SubSubFamilyInterface";

export const getRequest = async (
  company: string
): Promise<SubSubFamilyResponse> => {
  try {
    const response = await privateFetch.get(
      URL.REQUEST_SUBSUBFAMILY(company, UrlBase.registration_x_page)
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestList = async (
  subFamilyId: number,
  filter: string,
  page: number,
  size?: number
) => {
  let responseData = {};
  await privateFetch
    .get(
      URL.REQUEST_SUBSUBFAMILY_LIST(
        subFamilyId,
        filter,
        page,
        //@ts-ignore
        size ? size : UrlBase.registration_x_page
      )
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en consulta de permisos " + error);
    });
  return responseData;
};

export const getRequestSubSubFamily = async () => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_SUBSUBFAMILY2(null))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      //throw new Error(properties.com_parval_label_role_find_error);
      throw new Error(error);
    });
  return responseData;
};

export const findByIdRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_SUBSUBFAMILY(id, UrlBase.registration_x_page), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const findByIdRequestById = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_SUBSUBFAMILY_BY_ID(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const createRequest = async (data: any) => {
  console.log(data);
  let responseData = {};
  const requestBody = {
    user: data.user,
    name: data.name,
    code: data.code,
    description: data.description,
    idSubFamily: data.idSubFamily,
  };
  await privateFetch
    .post(URL.REQUEST_SUBSUBFAMILY_CREATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_spage_label_families_button_add_error);
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    user: data.user,
    id: data.id,
    name: data.name,
    code: data.code,
    description: data.description,
    idSubFamily: data.idSubFamily,
  };
  await privateFetch

    .put(URL.REQUEST_SUBSUBFAMILY_UPDATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_spage_label_families_button_update_error);
    });
  return responseData;
};

export const deleteRequest = async (id: number) => {
  try {
    const response = await privateFetch.delete(
      URL.REQUEST_SUBSUBFAMILY_DELETE(id)
    );
    return response.data;
  } catch (error) {
    console.error("Error eliminando la SubSubFamilia:", error);
    throw new Error("Error eliminando la SubSubFamilia");
  }
};
