import { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  Button,
  Stack,
  Divider,
  Dialog,
} from "@mui/material";
import { TrashIcon, PlusIcon, PenIcon } from "../components/svgicons/SvgIcons";
import FancyPaper from "../components/FancyPaper";
import FancyTooltip from "../components/utils/FancyTooltip";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import { useForm } from "react-hook-form";
import { useApp } from "../hooks/useApp";
import { properties } from "../utils/Properties_es";
import AddOrUpdateExitTicketModal from "../components/utils/AddOrUpdateExitTicketModal";
import DeleteModal from "../components/utils/DeleteModal";
import ResourceAccess from "../components/security/ResourceAccess";
import FancyDialogActions from "../components/utils/FancyDialogActions";
import FancySearch from "../components/utils/FancySearch";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import {
  getRequestExitTicket,
  getRequestProjects,
  getRequestTypeMovement,
  createRequest,
  updateRequest,
  deleteRequest,
} from "../services/exitTicket/ExitTicketService";
import { getRequestProductsInventory } from "../services/inventory/InventoryService";
import { getRequestPersonPositionAllProjectManagers } from "../services/person/PersonService";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import FancyLightButton from "../components/FancyLightButton";
import { getRequestTicketReport } from "../services/report/ReportService";
import Swal from "sweetalert2";

const ManagementExitTicket = () => {
  const {
    authInfo,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);
  const [typeMovement, setTypeMovement] = useState<any>(null);
  const [projects, setProjects] = useState<any>(null);
  const [product, setProduct] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [exitTicketData, setExitTicketData] = useState<any>([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().startOf("month"));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [status, setStatus] = useState<any>(null);

  const initForm = {
    id: 0,
    code: "",
    createdBy: "",
    description: "",
    path: 0,
  };
  const [formData, setFormData] = useState<any>(initForm);
  const { handleSubmit } = useForm();

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData("", 0);
      await getTypeMovement();
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  const handleFetchData = async (
    filter: string,
    currentPage: number,
    statusId?: string
  ) => {
    console.log(statusId);
    if (setLoading) setLoading(true);

    try {
      const data = await getRequestExitTicket(
        currentPage,
        10,
        startDate?.format("YYYY-MM-DD"),
        endDate?.format("YYYY-MM-DD"),
        filter,
        statusId
      );

      if (data) {
        setExitTicketData(data);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    } finally {
      if (setLoading) setLoading(false);
    }
  };

  const getTypeMovement = async () => {
    try {
      setLoading && setLoading(true);
      const type: any = await getRequestTypeMovement("", 0, 200);
      const products: any = await getRequestProductsInventory(0, "", 2000);
      const users: any = await getRequestPersonPositionAllProjectManagers();
      const projects: any = await getRequestProjects(0, 1500);

      if (type?.content) {
        setTypeMovement(type.content);
      }

      if (products?.content) {
        setProduct(products.content);
      }

      if (users) {
        setUser(users);
      }

      if (projects?.content) {
        setProjects(projects.content);
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error.message);
    } finally {
      if (setLoading) setLoading(false);
    }
  };

  // const handleFetchByID = async (id: string) => {
  //   setLoading && setLoading(true);
  //   try {
  //     let authorityDataID: any = await findByIdRequest(id);
  //     if (authorityDataID) {
  //       setFormData(authorityDataID);
  //     }
  //     setLoading && setLoading(false);
  //   } catch (error: any) {
  //     setErrorMsg && setErrorMsg(error.message);
  //     setLoading && setLoading(false);
  //   }
  // };

  const handleAdd = async (data: any) => {
    console.log(data);
    setLoading && setLoading(true); // Iniciar el estado de carga
    try {
      let createData = await createRequest({
        ...data,
        user: authInfo?.username,
      });

      if (!createData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_exitTicket_create_error);
        setLoading && setLoading(false);
        return;
      }

      // Si la solicitud es exitosa, mostrar mensaje de éxito y actualizar datos
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_exitTicket_create);
      setPage(0);
      await handleFetchData("", 0);

      // Cerrar el modal solo después de que todo haya salido bien
      handleCancelModal();
    } catch (error: any) {
      Swal.fire({
        title: `<span style="font-weight: 400">${error}</span>`,
        text: "",
        icon: "error",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#CC0000",
      });
    } finally {
      setLoading && setLoading(false);
    }
  };

  const handleUpdate = async (data: any) => {
    setLoading && setLoading(true);
    try {
      let updateData = await updateRequest({
        ...data,
        user: authInfo?.username,
      });
      console.log(data);

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_exitTicket_update_error);
        setLoading && setLoading(false);
        return;
      }

      // Si la solicitud es exitosa, mostrar mensaje de éxito y actualizar datos
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_exitTicket_update);
      setPage(0);
      await handleFetchData("", 0);

      // Cerrar el modal solo después de que todo haya salido bien
      handleCancelModal();
    } catch (error: any) {
      Swal.fire({
        title: `<span style="font-weight: 400">${error}</span>`,
        text: "",
        icon: "error",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#CC0000",
      });
    } finally {
      setLoading && setLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRequest(id);
      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_exitTicket_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_exitTicket_delete);
      //reset page and call fetch data
      setPage(0);
      await handleFetchData("", 0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error);
      setLoading && setLoading(false);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(preFilter, customPage, status?.id);
    }
  };

  const handleApplyFilter = async (statusID?: string) => {
    await handleFetchData(
      preFilter,
      0,
      typeof statusID === "string"
        ? statusID
        : statusID !== undefined
          ? status?.id
          : undefined
    );
  };

  /** Validate errors in case of opening modal **/
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update") {
      // await handleFetchByID(id);
      object = exitTicketData.content.find((p: any) => p.id === parseInt(id));
    }

    if (modalAction === "delete") {
      object = exitTicketData.content.find((p: any) => p.id === parseInt(id));
      //object = FakeData.content.find((p: any) => p.id === parseInt(id));
    }
    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
  };

  const handleStatusChange = async (e: any) => {
    setStatus(e);
    await handleApplyFilter(e?.id);
  };

  const exportToExcel = async (id: any) => {
    //getRequestOrdersExcel
    setLoading && setLoading(true);
    try {
      let data = await getRequestTicketReport(id);
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  return (
    <>
      <FancyPaper
        pagetitle="Boletas de salida"
        prevpage="Inventario"
        linkprevpage=""
      >
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={7} sm={5} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={"EXITTICKET:WRITE"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                data-name="create"
              >
                {"Agregar boleta"}
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing={2}
              sx={{ mt: 5 }}
            >
              <DatePicker
                label="Fecha de inicio"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                format="YYYY-MM-DD"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    backgroundColor: theme.palette.primary.main,
                  },
                  "& input": {
                    borderRadius: "10px 0 0 10px",
                  },
                  "& button": {
                    paddingLeft: "0",
                    "& svg": {
                      fill: "#FFF",
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "10px",
                  },
                }}
              />
              <DatePicker
                label="Fecha de fin"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                format="YYYY-MM-DD"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    backgroundColor: theme.palette.primary.main,
                  },
                  "& input": {
                    borderRadius: "10px 0 0 10px",
                  },
                  "& button": {
                    paddingLeft: "0",
                    "& svg": {
                      fill: "#FFF",
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "10px",
                  },
                }}
              />
            </Stack>
          </Grid>
          <Grid item md={5} sm={7} xs={12}>
            <FancySearch
              preFilter={preFilter}
              setPreFilter={setPreFilter}
              handleFetchData={() => handleFetchData("", 0)}
              handleApplyFilter={handleApplyFilter}
              handleStatusChange={(e: any) => handleStatusChange(e)}
            />
          </Grid>
        </Grid>
        <Divider />
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Boleta</TableCell>
                <TableCell>Tipo de movimiento</TableCell>
                <TableCell>Código</TableCell>
                <TableCell>Proyecto</TableCell>
                <TableCell>Se entrega a</TableCell>
                <TableCell align="center">Estado</TableCell>
                <ResourceAccess isCode={true} pathOrCode={"EXITTICKET:WRITE"}>
                  <TableCell align="center">{"Acciones"}</TableCell>
                </ResourceAccess>
              </TableRow>
            </TableHead>
            <TableBody>
              {exitTicketData &&
                exitTicketData?.content &&
                exitTicketData?.content.map((r: any) => (
                  <TableRow key={r.id}>
                    <TableCell scope="row">{r.date}</TableCell>
                    <TableCell align="center">{r.ticketNumber}</TableCell>
                    <TableCell align="center">{r.activityType?.name}</TableCell>
                    <TableCell align="center">{r.project?.code}</TableCell>
                    <TableCell align="center">{r.project?.name}</TableCell>
                    <TableCell align="center">{r.person?.name}</TableCell>
                    <TableCell align="center">
                      {r.status === false ? (
                        <FancyLightButton
                          backg="#cc00004d"
                          color="#CC0000!important"
                          text="Pendiente"
                        />
                      ) : (
                        <FancyLightButton
                          backg="#e65f2b5e"
                          color="#E65F2B!important"
                          text="Finalizado"
                        />
                      )}
                    </TableCell>

                    <TableCell align="center">
                      <ResourceAccess
                        isCode={true}
                        pathOrCode={"EXITTICKET:WRITE"}
                      >
                        <FancyTooltip title={"Editar"} placement="top">
                          <IconButton
                            aria-label="edit"
                            component="label"
                            color="primary"
                            onClick={handleOpenModal}
                            data-name="update"
                            data-id={r.id}
                          >
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title={"Imprimir"} placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="secondary"
                            // sx={{
                            //   "&:hover": {
                            //     color: theme.palette.error.dark,
                            //   },
                            // }}
                            data-id={r.id}
                            onClick={() => exportToExcel(r.id)}
                          //data-name="delete"
                          >
                            <PrintRoundedIcon sx={{ fontSize: "1.3rem" }} />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title={"Eliminar"} placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="error"
                            sx={{
                              "&:hover": {
                                color: theme.palette.error.dark,
                              },
                            }}
                            data-id={r.id}
                            onClick={handleOpenModal}
                            data-name="delete"
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}
              {!exitTicketData ||
                !exitTicketData.content ||
                (exitTicketData.content?.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      {"No hay registros"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            exitTicketData?.content?.length > 0
              ? exitTicketData?.content?.length
              : 0
          }
          rowsPerPage={exitTicketData?.size}
          page={page}
          onPageChange={handleChangePage}
          totalElements={exitTicketData?.totalElements}
          totalPages={exitTicketData?.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
          <Dialog
            open={modalData.modalOpen}
            onClose={handleCancelModal}
            maxWidth="lg"
            fullWidth
          >
            <AddOrUpdateExitTicketModal
              data={modalData?.modalObject}
              onSubmit={onSubmit}
              cancelModal={handleCancelModal}
              modalType={modalData?.modalType}
              typeMovement={typeMovement}
              projects={projects}
              products={product}
              user={user}
            />
          </Dialog>
        )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.code}
            actionButton={
              <>
                <FancyDialogActions
                  handleAccept={handleSubmit(onSubmit)}
                  cancelModal={handleCancelModal}
                  textAcceptButton={"Eliminar"}
                />
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default ManagementExitTicket;
