import {
  Box,
  Paper,
  Typography,
  Divider,
  useTheme,
  IconButton,
  Avatar,
  styled,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router";
import { useApp } from "../hooks/useApp";

const PageTitle = styled(Typography)(({ theme }) => ({
  //position: "absolute",
  //left: "310px",
  //top: "10px",
  fontSize: "1.5rem",
  //zIndex: "10000",
  fontWeight: "700",
  lineHeight: "1",
}));

const FancyPaper = (props: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { authInfo } = useApp();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 35px",
        }}
        className="anita"
      >
        <PageTitle
          variant="caption"
          color="text.primary"
          sx={{ pl: "25px", fontSize: "1.25rem" }}
        >
          {props.pagetitle}
        </PageTitle>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          sx={{
            background: "#fff",
            borderRadius: "25rem",
            padding: "7px 15px 7px 7px",
            "&:hover, &:focus": {
              background: "transparent",
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
          }}
        >
          <Avatar sx={{ mr: 2, width: 24, height: 24 }}>
            <PersonIcon sx={{ fontSize: "18px" }} color="primary" />
          </Avatar>

          <Typography variant="body1" sx={{ fontSize: "0.9rem" }}>
            {authInfo?.name}
          </Typography>
        </IconButton>
      </Box>
      <Divider sx={{ borderColor: "#00000026" }} />
      <Box
        sx={{
          p: {
            xs: 8,
            md: `${theme.spacing(6)} ${theme.spacing(8)} ${theme.spacing(0)}`,
          },
          overflow: "hidden",
        }}
      >
        {props.pagetitle !== "Inicio" && (
          <>
            {props.showHome !== false && ( // Mostrar "Inicio" solo si showHome no es false
              <Typography
                variant="caption"
                color="info.dark"
                onClick={() => navigate("/dashboard/home")}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                Inicio{" "}
              </Typography>
            )}
            {props.prevpage && props.linkprevpage && (
              <Typography
                variant="caption"
                color="info.dark"
                onClick={() => navigate(props.linkprevpage)}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                {"/ "} {props.prevpage}
              </Typography>
            )}
            {props.prevpages &&
              props.prevpages.map((item: any, index: number) => (
                <Typography
                  key={index}
                  variant="caption"
                  color="info.dark"
                  onClick={() => navigate(item.linkprevpage)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {"/ "} {item.name}
                </Typography>
              ))}

            <Typography variant="caption" color="text.primary">
              {"/ "} {props.pagetitle}
            </Typography>
          </>
        )}

        <Divider sx={{ py: 4, background: "transparent" }} />
        <Paper
          sx={{
            background: "#ffffff00",
            boxShadow: "none",
            padding: {
              xs: 0,
              md: 0,
            },
            mt: {
              xs: 0,
              md: 0,
            },
            mb: 5,
          }}
          {...props}
        >
          {props.children}
        </Paper>
      </Box>
    </>
  );
};

export default FancyPaper;
