import { DialogContent, Grid, Button, Dialog, DialogTitle, Typography } from "@mui/material";
import FancyDialogActions from "./utils/FancyDialogActions";
import { useForm } from "react-hook-form";
import PdfPreview from "./FancyPDFPreview";
import Swal from "sweetalert2";
import { useState } from "react";

interface DialogProductsPurchaseOrder {
  base64Pdf: string;
  onSubmit: any;
  cancelModal: any;
  status: string;
  handleSendNotification: () => void;
  handleSendCancellation: () => void;
}

const FancyDialogProductsPurchaseOrder: React.FC<
  DialogProductsPurchaseOrder
> = ({ base64Pdf, onSubmit, cancelModal, status, handleSendNotification, handleSendCancellation }) => {
  const { handleSubmit } = useForm<any>({
    mode: "onChange",
  });
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleConfirmCancellation = () => {
    handleSendCancellation();
    handleCloseDialog();
  };

  return (
    <>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <Grid container spacing={8} maxWidth="lg" sx={{ pt: 4, px: 6, pb: 8 }}>
          {status === "ABIERTA" && (
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mr: "-25px",
                }}
                onClick={handleSendNotification}
              >
                Enviar notificación
              </Button>
            </Grid>
          )}
          {status === "ENVIADA" && (
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ mr: "-25px" }}
                onClick={handleOpenDialog}
              >
                Anular orden de compra
              </Button>

              {/* Diálogo de confirmación */}
              <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>¿Estás seguro?</DialogTitle>
                <DialogContent>
                  <Typography>
                    Esta acción anulará la orden de compra y no se podrá revertir.
                  </Typography>
                </DialogContent>
                <FancyDialogActions
                  handleAccept={handleConfirmCancellation}
                  cancelModal={handleCloseDialog}
                  textAcceptButton="Aceptar"
                  textCancelButton="Cancelar"
                />
              </Dialog>
            </Grid>
          )}
          <Grid item xs={12}>
            <PdfPreview base64Pdf={base64Pdf} />
          </Grid>
        </Grid>
      </DialogContent>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
        textCancelButton="Cerrar"
        textAcceptButton="Descargar Pdf"
      />
    </>
  );
};

export default FancyDialogProductsPurchaseOrder;
