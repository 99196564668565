import { properties } from "../../utils/Properties_es";
import { DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DialogProps } from "../../types/Utils";
import FancyDialogActions from "./FancyDialogActions";

interface AddOrUpdateFamilyTypes {
  id?: number;
  name: string;
  code: string;
  description: string;
  idFamily?: number;
}

const AddOrUpdateSubFamilyModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  //Validación de datos
  const validation = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
    code: Yup.string().required("Campo requerido"),
    description: Yup.string().required("Campo requerido"),
  });

  const defaultValues = {
    id: data ? data.id : 0,
    name: data ? data.name : "",
    code: data ? data.code : "",
    description: data ? data.description : "",
    idFamily: data ? data.idFamily : 0,
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateFamilyTypes>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update" ? "Actualizar SubFamilia" : "Añadir SubFamilia"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"name"}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="outlined"
                    {...field}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"description"}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Descripción"
                    type="text"
                    variant="outlined"
                    {...field}
                    error={Boolean(errors.description)}
                    helperText={errors.description?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"code"}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Código"
                    type="text"
                    variant="outlined"
                    {...field}
                    error={Boolean(errors.code)}
                    helperText={errors.code?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdateSubFamilyModal;
