import { ChangeEvent } from "react";
import {
  TextField,
  Button,
  Stack,
  useTheme,
  Autocomplete,
  Grid,
  Box,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";

interface getProjectStatusType {
  id: number;
  name: string;
}

interface OperatorOption {
  value: string;
  label: string;
}

interface searchType {
  preFilter: string;
  setPreFilter: any;
  handleFetchData: any;
  handleApplyFilter: () => {};
  handleApplyFilterStatus?: (value: string) => void;
  setStatus?: (value: string) => void;
  handleChangeStatus?: (e: ChangeEvent<HTMLInputElement>) => {};
  handleStatusChange?: (e: ChangeEvent<HTMLInputElement>) => {};
  status?: { id: string; title: string } | null;
  showStatus?: boolean;
  projectStatusData?: getProjectStatusType[] | null;
  projectStatus?: getProjectStatusType | null;
  handleStatusProjectChange?: (value: any) => {};
  purchaseOrderStatus?: { id: string; title: string };
  setPurchaseOrderStatus?: Dispatch<
    SetStateAction<{
      id: string;
      title: string;
    }>
  >;
  showPurchaseOrderStatus?: boolean;

  // New props for inventory filters
  showInventoryFilters?: boolean;
  warehouseData?: any[];
  warehouseFilter?: string;
  setWarehouseFilter?: (value: string) => void;
  statusFilter?: string;
  setStatusFilter?: (value: string) => void;
  quantityFilter?: number | null;
  setQuantityFilter?: (value: number | null) => void;
  quantityOperator?: string;
  setQuantityOperator?: (value: string) => void;
  operatorOptions?: OperatorOption[];
}

const FancySearch = ({
  preFilter,
  setPreFilter,
  setStatus,
  handleFetchData,
  handleApplyFilter,
  handleApplyFilterStatus,
  handleChangeStatus,
  handleStatusChange,
  status,
  showStatus,
  projectStatus,
  projectStatusData,
  handleStatusProjectChange,
  purchaseOrderStatus,
  setPurchaseOrderStatus,
  showPurchaseOrderStatus,

  // New inventory filter props
  showInventoryFilters = false,
  warehouseData = [],
  warehouseFilter = "",
  setWarehouseFilter = () => { },
  statusFilter = "",
  setStatusFilter = () => { },
  quantityFilter = null,
  setQuantityFilter = () => { },
  quantityOperator = "",
  setQuantityOperator = () => { },
  operatorOptions = [],
}: searchType) => {
  const theme = useTheme();

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{
        background: theme.palette.secondary.light,
        borderRadius: "10px",
        padding: "15px 25px",
        width: showInventoryFilters ? "100%" : "fit-content", // Ancho condicional
        marginLeft: showInventoryFilters ? "0" : "auto", // Margen condicional
      }}
    >
      {showInventoryFilters && (
        <Grid container spacing={1} sx={{ mb: 2 }}>
          {/* Primera fila */}
          <Grid item container spacing={1} sx={{ mb: 1 }}>
            {/* Warehouse filter */}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={warehouseData.map((warehouse) => warehouse.name)}
                value={warehouseFilter}
                onChange={(event, newValue) => setWarehouseFilter(newValue || "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bodega"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    sx={{ minWidth: "100%" }}
                  />
                )}
              />
            </Grid>

            {/* Status filter */}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={["Disponible", "En proyecto"]}
                value={statusFilter}
                onChange={(event, newValue) => setStatusFilter(newValue || "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Estado"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    sx={{ minWidth: "100%" }}
                  />
                )}
              />
            </Grid>
          </Grid>

          {/* Segunda fila */}
          <Grid item container spacing={1}>
            {/* Quantity filter with operator */}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={operatorOptions}
                value={
                  quantityOperator
                    ? operatorOptions.find((option) => option.value === quantityOperator) || null
                    : null
                }
                onChange={(event, newValue) => {
                  setQuantityOperator(newValue?.value || "");
                }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Operador"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    size="medium"
                    sx={{ minWidth: "100%" }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label="Cantidad"
                value={quantityFilter ?? ""}
                onChange={(e) => {
                  const value = e.target.value;
                  setQuantityFilter(value === "" ? null : Number(value));
                }}
                InputLabelProps={{ shrink: true }}
                size="medium"
                sx={{ minWidth: "100%" }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Stack
        direction={{
          xs: "column",
          md: "row",
        }}
        alignItems={{
          xs: "flex-start",
          md: "center",
        }}
        spacing={{
          xs: 2,
          md: 4,
        }}
        justifyContent="flex-end"
        sx={{ width: "100%" }}
      >
        {showStatus && (
          <Autocomplete
            options={[
              { id: "0", title: "Activo" },
              { id: "1", title: "Inactivo" },
              { id: "2", title: "Periodo prueba" },
            ]}
            getOptionLabel={(option: any) => option.title || ""}
            value={status}
            onChange={(event: any, newValue: any) => {
              handleChangeStatus && handleChangeStatus(newValue);
            }}
            sx={{ width: "250px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Estado"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        )}
        {handleApplyFilterStatus && (
          <Autocomplete
            options={[
              { id: "ACTIVE", title: "Activo" },
              { id: "DELETED", title: "Inactivo" },
              { id: "PENDING_AP", title: "Pendiente" },
            ]}
            getOptionLabel={(option: any) => option.title || ""}
            value={status}
            onChange={(event: any, newValue: any) => {
              newValue === null
                ? handleApplyFilterStatus("")
                : handleApplyFilterStatus(newValue.id);
            }}
            sx={{ width: "250px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Estado"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        )}

        {handleStatusChange && (
          <Autocomplete
            options={[
              { id: "0", title: "Pendiente" },
              { id: "1", title: "Finalizado" },
            ]}
            getOptionLabel={(option: any) => option.title || ""}
            value={status}
            onChange={(event: any, newValue: any) => {
              handleStatusChange && handleStatusChange(newValue);
            }}
            sx={{ width: "250px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Estado"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        )}
        {handleStatusProjectChange && (
          <Autocomplete
            options={projectStatusData ?? [{ id: 0, name: "" }]}
            getOptionLabel={(option: any) => option.name || ""}
            value={projectStatus}
            onChange={(event: any, newValue: any) => {
              handleStatusProjectChange && handleStatusProjectChange(newValue);
            }}
            sx={{ width: "300px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Estado"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        )}
        {showPurchaseOrderStatus && (
          <Autocomplete
            options={[
              { id: "ABIERTA", title: "Abierta" },
              { id: "ENVIADA", title: "Enviado" },
              { id: "CERRADA", title: "Cerrada" },
              { id: "ANULADA", title: "Anulada" },
            ]}
            getOptionLabel={(option: any) => option.title || ""}
            value={purchaseOrderStatus}
            onChange={(event: any, newValue: any) => {
              setPurchaseOrderStatus && setPurchaseOrderStatus(newValue);
            }}
            sx={{ width: "300px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Estado"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            flex: 1,
            width: {
              xs: "100%",
              md: "auto"
            }
          }}
        >
          <TextField
            placeholder="Filtro de búsqueda"
            sx={{
              width: "100%",
              marginRight: "10px",
              "& .MuiInputBase-root": {
                borderRadius: "18px",
                background: "#FFF",
              },
              "& input": {
                padding: "0.75rem 16px!important",
              },
              "& fieldset": {
                borderColor: "#FFF!important",
              },
            }}
            value={preFilter}
            onChange={(e: any) => {
              setPreFilter(e.target.value);
              if (e.target.value === "") {
                handleFetchData();
              }
            }}
            onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
            InputLabelProps={{ shrink: true }}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={handleApplyFilter}
            sx={{
              borderRadius: "8px!important",
              padding: "0.75rem 2rem",
              lineHeight: "1.4375em",
              background: "#FFF",
            }}
          >
            Buscar
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FancySearch;