import { URL } from "../../utils/UrlConstants";
import UrlBase from "../../url/Urls";

export const getRequestTicketReport = async (id: number) => {
  try {
    window.open(
      UrlBase.base_url + UrlBase.api_context + URL.REQUEST_REPORT_TICKET(id),
      "blank"
    );
  } catch (error: any) {
    throw new Error("Error al obtener reporte de boleta");
  }
};

export const getRequestInventoryControlReport = async (id: number) => {
  try {
    const response = await fetch(
      `${UrlBase.base_url}${
        UrlBase.api_context
      }${URL.REQUEST_REPORT_INVENTORY_CONTROL(id)}`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      throw new Error("Error al obtener el reporte");
    }

    // Obtener el blob del archivo
    const blob = await response.blob();

    // Crear un enlace temporal
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Reporte_Inventory_Control_REV_${id}.pdf`;
    document.body.appendChild(a);
    a.click();

    a.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error al obtener reporte de boleta:", error);
    throw new Error("Error al obtener reporte de boleta");
  }
};

export const getRequestHistoryReport = async (
  type: string,
  page: number,
  size: number,
  from?: string,
  to?: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      const requestBody = {
        page,
        size,
        from,
        to,
      };

      const url = `${UrlBase.base_url}${
        UrlBase.api_context
      }${URL.REQUEST_REPORT_HISTORY(type)}`;
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.responseType = "blob";

      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = new Blob([xhr.response], {
            type: xhr.getResponseHeader("Content-Type") ?? undefined,
          });

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = ""; // Usa el nombre por defecto del servidor
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);

          resolve(); // Indica que la descarga se inició
        } else {
          console.error(`Error al descargar el archivo. Código: ${xhr.status}`);
          reject(new Error("Error al obtener el reporte"));
        }
      };

      xhr.onerror = () => {
        reject(new Error("Error en la solicitud al servidor"));
      };

      xhr.send(JSON.stringify(requestBody));
    } catch (error: any) {
      reject(error);
    }
  });
};

export const getRequestHistoryReportExcel = async (
  type: string,
  page: number,
  size: number,
  from?: string,
  to?: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      const requestBody = {
        page,
        size,
        from,
        to,
      };

      const url = `${UrlBase.base_url}${
        UrlBase.api_context
      }${URL.REQUEST_REPORT_HISTORY(type)}`;
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.responseType = "blob";

      xhr.onload = () => {
        if (xhr.status === 200) {
          // Crear el Blob con la respuesta y el tipo de contenido adecuado
          const blob = new Blob([xhr.response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);

          resolve();
        } else {
          console.error(`Error al descargar el archivo. Código: ${xhr.status}`);
          reject(new Error("Error al obtener el reporte"));
        }
      };

      xhr.onerror = () => {
        reject(new Error("Error en la solicitud al servidor"));
      };

      // Enviar la solicitud con los parámetros del cuerpo
      xhr.send(JSON.stringify(requestBody));
    } catch (error: any) {
      reject(error);
    }
  });
};
