import React, { useEffect, useState } from 'react';
import {
    Grid, Typography, TextField, Autocomplete, Divider,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery, IconButton,
    Button,
    Dialog
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FancyPaper from '../../components/FancyPaper';
import { useApp } from '../../hooks/useApp';
import { getRequestProjects } from '../../services/projects/ProjectService';
import { useLocation } from 'react-router-dom';
import WeekFilter from '../../components/utils/credit_view/WeekFilter';
import AddOrUpdateCoworkerModal from '../../components/utils/AddOrUpdateCoworkerModal';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

const DetailsAdvances = () => {
    const theme = useTheme();
    const { setLoading, setErrorMsg, modalData, setModalData } = useApp();
    const location = useLocation();
    const { name: locationName, id: projectId, week: locationWeek } = location.state || {};

    const [details, setDetails] = useState<any>(null);
    const [projects, setProjects] = useState<any[]>([]);
    const [selectedProject, setSelectedProject] = useState<any | null>(null);
    const [filteredWeek, setFilteredWeek] = useState<number>(1);
    const [dateRange, setDateRange] = useState<string>('');
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const [advanceCreated, setAdvanceCreated] = useState(false);

    // Estado del modal de horas
    const [openHoursModal, setOpenHoursModal] = useState(false);

    console.log(locationWeek)

    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    useEffect(() => {
        fetchProjects();
    }, []);

    useEffect(() => {
        if (locationName && projects.length > 0) {
            // Buscar el proyecto que coincide con el projectId
            const project = projects.find((p) => p.name === locationName);
            console.log(project)
            if (project) {
                setSelectedProject(project);
            }
        }
    }, [locationName, projects]);

    const fetchProjects = async () => {
        try {
            setLoading(true);
            const data = await getRequestProjects(0, '', '', 500);
            if (data) {
                setProjects(data.content);
            }
        } catch (error: any) {
            setErrorMsg(error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (selectedProject: any) => {
        setSelectedProject(selectedProject);
    };

    // Abrir el modal con los datos del usuario seleccionado
    const handleOpenHoursModal = (data: any) => {
        console.log(data);
        setModalData && setModalData({ modalOpen: true, modalType: 'addHours', modalObject: data });
    };

    // Cerrar el modal y limpiar el estado
    const handleCloseHoursModal = () => {
        setModalData && setModalData({ modalOpen: false, modalType: '', modalObject: null });
    };

    return (
        <>
            <FancyPaper pagetitle="Detalles de Avances" prevpage="Avances" linkprevpage="/dashboard/advances">
                <Grid container spacing={6} sx={{ background: theme.palette.secondary.light, borderRadius: 2, padding: 4, marginBottom: 6, marginTop: 4, marginLeft: 4 }}>
                    {/* Selector de Semana */}
                    <Grid item xs={12} md={3} sx={{ marginBottom: 4 }}>
                        <WeekFilter
                            onWeekSelect={(week) => week !== null && setFilteredWeek(week)}
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                            year={year}
                            setYear={setYear}
                            projectId={selectedProject?.id || 0}
                        />
                    </Grid>

                    {/* Selector de Proyecto y Avance */}
                    <Grid item xs={12} md={3} sx={{ marginTop: 2, marginLeft: 25, marginRight: 25 }}>
                        <Autocomplete
                            disablePortal
                            options={projects.map((project) => ({
                                id: project.id,
                                name: project.name,
                                code: project.code,
                                manager: project.manager,
                                attendand: project.attendand
                            }))}
                            sx={{ width: '100%' }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => `${option.code} - ${option.name}`}
                            value={selectedProject}
                            onChange={(event, newValue) => newValue && handleChange(newValue)}
                            renderInput={(params) => <TextField {...params} placeholder="Seleccione un Proyecto" />}
                        />
                        <Typography variant="body2" fontWeight="bold">Avance:</Typography>
                        <Typography>{selectedProject?.name}</Typography>
                    </Grid>

                    {/* Encargado y Subencargado */}
                    <Grid item xs={12} md={3} sx={{ marginBottom: 4 }}>
                        <Typography variant="body2" fontWeight="bold">Encargado:</Typography>
                        <Typography>{selectedProject?.manager.fullName}</Typography>
                        <Typography variant="body2" fontWeight="bold">Subencargado:</Typography>
                        <Typography>{selectedProject?.attendand?.fullName}</Typography>
                    </Grid>
                </Grid>


                <Grid container justifyContent="right" sx={{ mt: 4 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpenHoursModal(dateRange)}
                    >
                        Agregar Horas
                    </Button>
                </Grid>


                <Divider sx={{ my: 3 }} />

                {isLargeScreen && (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">ID</TableCell>
                                    <TableCell align="center">Nombre</TableCell>
                                    <TableCell align="center">Cargo</TableCell>
                                    <TableCell align="center">Monto por Hora</TableCell>
                                    <TableCell align="center">J</TableCell>
                                    <TableCell align="center">V</TableCell>
                                    <TableCell align="center">S</TableCell>
                                    <TableCell align="center">D</TableCell>
                                    <TableCell align="center">L</TableCell>
                                    <TableCell align="center">M</TableCell>
                                    <TableCell align="center">K</TableCell>
                                    <TableCell align="center">Total Horas</TableCell>
                                    <TableCell align="center">Total Horas Extra</TableCell>
                                    <TableCell align="center">Subtotal</TableCell>
                                    <TableCell align="center">Deducción CCSS</TableCell>
                                    <TableCell align="center">Créditos / Débitos</TableCell>
                                    <TableCell align="center">Total a Pagar</TableCell>
                                    <TableCell align="center">Pasajes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {details?.users?.map((user: any) => (
                                    <TableRow key={user.id}>
                                        <TableCell align="center">{user.id}</TableCell>
                                        <TableCell align="center">{user.name}</TableCell>
                                        <TableCell align="center">{user.amountPerHour}</TableCell>
                                        {user.days.map((day: any) => (
                                            <TableCell key={day.day} align="center">{day.amount}</TableCell>
                                        ))}
                                        <TableCell align="center">{user.totalHours}</TableCell>
                                        <TableCell align="center">{user.subTotal}</TableCell>
                                        <TableCell align="center">{user.deductionCCSS}</TableCell>
                                        <TableCell align="center">{user.total}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </FancyPaper>
            {(modalData?.modalType === "addHours") && (
                <Dialog
                    open={modalData.modalOpen}
                    onClose={handleCloseHoursModal}
                    maxWidth="lg"
                    fullWidth
                >
                    <AddOrUpdateCoworkerModal
                        data={{ modalObject: modalData }}
                        cancelModal={handleCloseHoursModal}
                        getData={fetchProjects}
                    />
                </Dialog>
            )}
        </>
    );
};

export default DetailsAdvances;
