import { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Dialog,
} from "@mui/material";
import {
  KeyIcon,
  TrashIcon,
  PlusIcon,
  PenIcon,
} from "../components/svgicons/SvgIcons";
import FancyTooltip from "../components/utils/FancyTooltip";
import FancyPaper from "../components/FancyPaper";
import { useForm } from "react-hook-form";
import { getRequestRoleList } from "../services/role/RoleService";
import {
  getRequestUser,
  createUser,
  updateUser,
  updatePassRequest,
  deleteRequest,
  activateUser,
  findByIdRequest,
} from "../services/user/User";
import { properties } from "../utils/Properties_es";
import { useApp } from "../hooks/useApp";
import DeleteModal from "../components/utils/DeleteModal";
import AddOrUpdateUserModal from "../components/utils/AddOrUpdateUserModal";
import UpdateUserPassModal from "../components/utils/UpdateUserPassModal";
import ResourceAccess from "../components/security/ResourceAccess";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import FancyDialogActions from "../components/utils/FancyDialogActions";
import FancyLightButton from "../components/FancyLightButton";
import FancySearch from "../components/utils/FancySearch";

const ManagementUsers = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const [usersData, setUsersData] = useState<any>([]);
  const [usersDataRole, setUsersRole] = useState<any>([]);
  const [preFilter, setPreFilter] = useState<any>("");
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(0);

  const initForm = {
    id: 0,
    username: "",
    firstName: "",
    lastName: "",
    password: "",
    phone: null,
    status: "ACTIVE",
    roleId: 0,
    activationCode: "",
  };
  const [formData, setFormData] = useState<any>(initForm);

  const { handleSubmit } = useForm();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "", "");
      let userRoleData: any = await getRequestRoleList("", 0);
      if (userRoleData) {
        setUsersRole(userRoleData);
      }
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  /**
   * Evento de aplicar filtro de busqueda
   */
  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(0, preFilter, status);
    }
  };

  const handleApplyFilterStatus = async (status: any) => {
    console.log(status);
    setStatus(status);
    await handleFetchData(0, preFilter, status);
  };

  /**
   *
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
    currentPage: number,
    filter: string,
    status: any
  ) => {
    setLoading && setLoading(true);
    try {
      setPreFilter(filter);
      setPage(currentPage);

      let data = await getRequestUser(currentPage, filter, status);
      if (data) {
        setUsersData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleFetchByID = async (id: number) => {
    setLoading && setLoading(true);
    try {
      let userData = await findByIdRequest(id);

      /* let userData = usersData.content.find(
        (item: any) => item.id === Number(id)
      );
      console.log("userData: ", userData); */
      if (userData) {
        //@ts-ignore
        setFormData(userData.content);
        //setFormData(userData);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    //close modal
    handleCancelModal();
    console.log("Datos enviados:", data);
    //loading
    setLoading && setLoading(true);
    try {
      let createData = await createUser({
        ...data,
        userCreated: authInfo.username,
        createdAt: new Date().toISOString(),
      });

      if (!createData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_user_save_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_save);
      //call fetch data
      await handleFetchData(0, "", "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      let updateData = await updateUser({
        ...data,
        modifierUser: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_user_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData(page, "", "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar password del usuario
   * @param data
   */
  const handlePassUpdate = async (data: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      let updateData = await updatePassRequest(data);

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_forgotpass_general_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_forgotpass_general_sucess);

      //call fetch data
      await handleFetchData(0, "", "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      let deleteData = await deleteRequest(id);

      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_user_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_delete);

      //reset page and call fetch data
      setPage(0);
      await handleFetchData(0, preFilter, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    let RoleObject = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "create") {
      RoleObject = usersDataRole.content;
    }

    if (modalAction === "update" || modalAction === "passchange") {
      object = usersData.content.find((p: any) => p.id === parseInt(id));
      RoleObject = usersDataRole.content;
    }

    if (modalAction === "delete") {
      object = usersData.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * Evento de cierre de modal
   * @param event
   */
  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter, status);
    }

    /* if (customPage !== page) {
      await handleFetchData(customPage, filter);
    } */
    //await handleFetchData(0, filter);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };

  const handleActiveUser = async () => {
    setLoading && setLoading(true);
    try {
      let dataActiveUser = await activateUser(
        formData.activationCode,
        formData.username
      );
      if (!dataActiveUser) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_user_save_error);
        setLoading && setLoading(false);
        return;
      }
      await handleFetchByID(formData.id);
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg("Usuario ha sido activado");
      console.log(dataActiveUser);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "passchange":
        await handlePassUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== "delete") {
      setFormData(formData);
    }
  };

  return (
    <>
      <FancyPaper pagetitle="Usuarios">
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={7} sm={5} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={"USER:WRITE"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                data-name="create"
              >
                Agregar usuario
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>
          </Grid>

          <FancySearch
            preFilter={preFilter}
            setPreFilter={setPreFilter}
            handleFetchData={() => handleFetchData(0, "", "")}
            handleApplyFilter={handleApplyFilter}
            handleApplyFilterStatus={handleApplyFilterStatus}
            setStatus={setStatus}
          />
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="center">Nombre</TableCell>
                <TableCell align="center">Apellido</TableCell>
                <TableCell align="center">Teléfono</TableCell>
                <TableCell align="center">Estado</TableCell>
                <TableCell align="center">Perfil</TableCell>
                <ResourceAccess isCode={true} pathOrCode={"USER:WRITE"}>
                  <TableCell align="center">Acciones</TableCell>
                </ResourceAccess>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersData &&
              usersData.content &&
              usersData.content.length > 0 ? (
                usersData.content.map((row: any, i: number) => {
                  const userRole = usersDataRole?.content?.find(
                    (role: any) => role.id === row.roleId
                  );
                  return (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell scope="row">{row.id}</TableCell>
                      <TableCell>{row.username}</TableCell>
                      <TableCell align="center">{row.firstName}</TableCell>
                      <TableCell align="center">{row.lastName}</TableCell>
                      <TableCell align="center">{row.phone}</TableCell>
                      <TableCell align="center">
                        {row.status === "ACTIVE" ? (
                          <FancyLightButton
                            backg="#1a932e61"
                            color="#1a932e!important"
                            text="Activo"
                          />
                        ) : row.status === "PENDING_AP" ? (
                          <FancyLightButton
                            backg="#cc00004d"
                            color="#CC0000!important"
                            text="Pendiente"
                          />
                        ) : (
                          <FancyLightButton
                            backg="#cc00004d"
                            color="#CC0000!important"
                            text="Inactivo"
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {userRole ? userRole.roleName : "Sin rol asignado"}
                      </TableCell>
                      <ResourceAccess isCode={true} pathOrCode={"USER:WRITE"}>
                        <TableCell align="center">
                          <FancyTooltip title="Editar" placement="top">
                            <IconButton
                              aria-label="edit"
                              component="label"
                              color="primary"
                              onClick={handleOpenModal}
                              data-name="update"
                              data-id={row.id}
                            >
                              <PenIcon />
                            </IconButton>
                          </FancyTooltip>
                          <FancyTooltip
                            title="Actualizar Contraseña"
                            placement="top"
                          >
                            <IconButton
                              aria-label="sorter"
                              component="label"
                              color="secondary"
                              data-id={row.id}
                              onClick={handleOpenModal}
                              data-name="passchange"
                            >
                              <KeyIcon />
                            </IconButton>
                          </FancyTooltip>
                          <FancyTooltip title="Eliminar" placement="top">
                            <IconButton
                              aria-label="trash"
                              component="label"
                              color="error"
                              data-id={row.id}
                              onClick={handleOpenModal}
                              data-name="delete"
                            >
                              <TrashIcon />
                            </IconButton>
                          </FancyTooltip>
                        </TableCell>
                      </ResourceAccess>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    {properties.com_parval_label_table_empty}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            usersData?.content?.length > 0 ? usersData?.content?.length : 0
          }
          rowsPerPage={usersData.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={usersData.totalElements}
          totalPages={usersData.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          maxWidth="md"
          fullWidth
        >
          <AddOrUpdateUserModal
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            roleData={usersDataRole}
            refreshData={handleFetchData}
          />
        </Dialog>
      )}
      {modalData?.modalType === "passchange" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <UpdateUserPassModal
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                <FancyDialogActions
                  handleAccept={handleSubmit(onSubmit)}
                  cancelModal={handleCancelModal}
                  textAcceptButton={"Eliminar"}
                />
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default ManagementUsers;
