import { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Divider,
  Dialog,
  Autocomplete,
  TextField,
  useTheme,
  Typography,
} from "@mui/material";

import ResourceAccess from "../../security/ResourceAccess";
import { useApp } from "../../../hooks/useApp";
import FancyPaper from "../../FancyPaper";
import { PaymentIcon, TicketIcon } from "../../svgicons/SvgIcons";
import FancyTooltip from "../FancyTooltip";
import FancyTablePagination from "../FancyTablePagination";
import { getRequest } from "../../../services/creditsDebitsTickets/CreditsService";
import { formatNumber } from "../formatters";
import WeekFilter from "./WeekFilter";
import UpdateTicketsModal from "./UpdateTicketsModal";
import CreditDebitModal from "./CreditDebitModal";
import { getRequestProjects } from "../../../services/projects/ProjectService";
import FancySearch from "./CustomFancy";
import { Data, Person, Project } from "./interface";

const ManagementCreditsDebitsTickets = () => {
  const { setLoading, setErrorMsg, modalData, setModalData, errorMsg } =
    useApp();
  const theme = useTheme();

  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);
  const [usersData, setUsersData] = useState<Data>();
  const [filteredWeek, setFilteredWeek] = useState<number>(1);
  const [dateRange, setDateRange] = useState<string>("");
  const [year, setYear] = useState<number>(new Date().getFullYear());

  const [project, setProject] = useState<any>(null);
  const [projects, setProjects] = useState<Array<Project>>([]);

  const projectOptions = projects.map((project) => ({
    id: project.id,
    name: project.name,
  }));

  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProjects = async () => {
    try {
      setLoading(true);

      let data = await getRequestProjects(0, "", "", 500);

      if (data) {
        setProjects(data.content);
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error);
    }
  };

  useEffect(() => {
    if (project) {
      handleFetchData(page, filteredWeek.toString(), project.id, preFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, filteredWeek]);

  const handleFetchData = async (
    currentPage: number,
    week: string,
    projectId: number,
    filter: string
  ) => {
    setLoading && setLoading(true);
    try {
      setPreFilter(filter);
      setPage(currentPage);

      let data = await getRequest(currentPage, week, projectId, filter, year);

      if (data) {
        setUsersData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(
        customPage,
        filteredWeek.toString(),
        project.id,
        preFilter
      );
    }
  };

  /** Validate errors in case of opening modal **/
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("datatype");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");
    const position = event.currentTarget.getAttribute("data-name");
    object =
      usersData?.content &&
      usersData.content.find(
        (p: any) => p.id === parseInt(id) && p.position === position
      );
    object = { ...object, dateRange, year, project, filteredWeek };

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleApplyFilter = () => {
    handleFetchData(page, filteredWeek.toString(), project?.id || 0, preFilter);
  };

  return (
    <>
      <FancyPaper
        pagetitle="Créditos / Débitos / Pasajes"
        subtitle="Créditos / Débitos / Pasajes"
        prevpage="Proyectos"
        linkprevpage=""
      >
        <Grid
          container
          pb={8}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item md={5} sm={7} xs={12}>
            <WeekFilter
              onWeekSelect={(week) => week !== null && setFilteredWeek(week)}
              onRequestData={(week) =>
                handleFetchData(
                  page,
                  week.toString(),
                  project?.id || 0,
                  preFilter
                )
              }
              dateRange={dateRange}
              setDateRange={setDateRange}
              year={year}
              setYear={setYear}
              projectId={project?.id || 0}
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="flex-end"
            md={5}
            sm={7}
            xs={12}
            sx={{
              maxWidth: "375px",
              minWidth: "375px",
            }}
          >
            <Grid
              item
              sx={{
                background: theme.palette.secondary.light,
                borderRadius: "10px 10px 0 0",
                padding: "15px 25px 0",
                maxWidth: "373px",
                minWidth: "373px",
              }}
            >
              <Autocomplete
                options={projectOptions}
                getOptionLabel={(option) => option.name}
                value={project}
                onChange={(event, newValue) => setProject(newValue)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Seleccionar proyecto"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item mb={{ md: 8 }} sx={{ width: "100%", maxWidth: "373px" }}>
              <FancySearch
                preFilter={preFilter}
                setPreFilter={setPreFilter}
                handleApplyFilter={project ? handleApplyFilter : () => {}}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        {!project && (
          <Grid item xs={12}>
            <Typography
              textAlign={"center"}
              variant="h6"
              fontWeight={"normal"}
              sx={{ mt: 2 }}
            >
              Por favor seleccione un proyecto
            </Typography>
          </Grid>
        )}
        {project && (
          <>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Cédula</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Puesto</TableCell>
                    <TableCell>Créditos</TableCell>
                    <TableCell>Débitos</TableCell>
                    <TableCell>Pasajes</TableCell>
                    <ResourceAccess isCode={true} pathOrCode={"BANK:WRITE"}>
                      <TableCell align="center">{"Acciones"}</TableCell>
                    </ResourceAccess>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersData?.content && usersData?.content?.length > 0 ? (
                    usersData.content.map((r: Person) => (
                      <TableRow key={r.id + r.dni + r.position}>
                        <TableCell align="center">{r.dni}</TableCell>
                        <TableCell align="center">{r.name}</TableCell>
                        <TableCell align="center">
                          {r.position || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {formatNumber(r.creditAmount)}
                        </TableCell>
                        <TableCell align="center">
                          {formatNumber(r.debitAmount)}
                        </TableCell>
                        <TableCell align="center">
                          {formatNumber(r.transportationPaymentAmount)}
                        </TableCell>
                        <TableCell align="center">
                          <FancyTooltip
                            title="Crédito / Débito"
                            placement="top"
                          >
                            <IconButton
                              aria-label="credit"
                              color="primary"
                              onClick={handleOpenModal}
                              data-name={r.position}
                              data-id={r.id}
                              datatype="credit"
                            >
                              <PaymentIcon />
                            </IconButton>
                          </FancyTooltip>
                          <FancyTooltip title="Pasajes" placement="top">
                            <IconButton
                              aria-label="ticket"
                              color="primary"
                              onClick={handleOpenModal}
                              data-name={r.position}
                              data-id={r.id}
                              datatype="ticket"
                            >
                              <TicketIcon />
                            </IconButton>
                          </FancyTooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        {"No hay registros"}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <FancyTablePagination
              count={
                usersData?.content && usersData?.content?.length > 0
                  ? usersData?.content?.length
                  : 0
              }
              rowsPerPage={usersData?.size ? usersData.size : 0}
              page={page}
              onPageChange={handleChangePage}
              totalElements={usersData?.totalElements}
              totalPages={usersData?.totalPages}
            />
          </>
        )}
      </FancyPaper>
      {modalData?.modalType === "ticket" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <UpdateTicketsModal
            data={modalData}
            getData={handleApplyFilter}
            cancelModal={handleCancelModal}
          />
        </Dialog>
      )}
      {modalData?.modalType === "credit" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
          maxWidth="md"
        >
          <CreditDebitModal
            data={modalData}
            getData={handleApplyFilter}
            cancelModal={handleCancelModal}
          />
        </Dialog>
      )}
    </>
  );
};

export default ManagementCreditsDebitsTickets;
