import {
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DialogProps } from "../../types/Utils";
import FancyDialogActions from "./FancyDialogActions";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

interface AddOrUpdateProvider {
  id?: number;
  name: string;
  legalIdNumber?: string;
  email: string;
  emails?: any[];
}

const AddOrUpdateProviderModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  roleData,
}: DialogProps) => {
  const validation = Yup.object().shape({
    name: Yup.string().required("Campo es requerido"),
    // legalIdNumber: Yup.string().required("Campo es requerido"),
    email: Yup.string()
      .email("Debe ser un correo válido")
      .required("Correo es requerido"),
    emails: Yup.array().of(
      Yup.string()
        .email("Debe ser un correo válido")
        .required("Campo es requerido")
    ),
  });

  const defaultValues = {
    id: data?.id ?? 0,
    name: data?.name ?? "",
    legalIdNumber: data?.legalIdNumber ?? "",
    email: data?.email ?? "",
    emails: data?.emails ?? [],
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateProvider>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "emails",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar proveedor" : "Actualizar proveedor"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="outlined"
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="legalIdNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="Identificación Legal"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="Correo Principal"
                    type="email"
                    variant="outlined"
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {fields.map((item, index) => (
                  <Grid
                    key={item.id}
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                  >
                    <Controller
                      name={`emails.${index}`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size="small"
                          label={`Correo Alternativo ${index + 1}`}
                          type="email"
                          variant="outlined"
                          error={Boolean(errors.emails?.[index])}
                          helperText={
                            errors.emails?.[index]
                              ? String(errors.emails[index]?.message)
                              : undefined
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                    <IconButton
                      onClick={() => remove(index)}
                      color="error"
                      size="small"
                      sx={{ ml: 2 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => append("")}
                  >
                    Agregar Correo Alternativo
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdateProviderModal;
