import { useEffect, useState } from "react";

interface PdfPreviewProps {
  base64Pdf: string; // Base64 string of the PDF
}

const PdfPreview: React.FC<PdfPreviewProps> = ({ base64Pdf }) => {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  useEffect(() => {
    if (base64Pdf) {
      const pdfBlob = base64ToBlob(base64Pdf, "application/pdf");
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfBlobUrl);

      return () => {
        URL.revokeObjectURL(pdfBlobUrl); // Clean up the URL object
      };
    }
  }, [base64Pdf]);

  const base64ToBlob = (base64: string, contentType: string) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length)
      .fill(0)
      .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };

  return (
    <>
      {pdfUrl ? (
        <iframe src={pdfUrl} width="100%" height="600px" title="PDF Preview" />
      ) : (
        <p>No se pudo cargar el PDF.</p>
      )}
    </>
  );
};

export default PdfPreview;
