import { Suspense } from "react";
import { Await, useLoaderData, useOutlet } from "react-router-dom";
import { AppProvider } from "../../context/AppContext";
import Loading from "../Loading";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import 'dayjs/locale/es';



export const AuthLayout = () => {
  const outlet = useOutlet();

  const { authPromise }: any = useLoaderData();
  dayjs.locale('es');

  return (
    <Suspense fallback={<Loading />}>
      <Await
        resolve={authPromise}
        children={(authObject) => (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <AppProvider authData={authObject}>{outlet}</AppProvider>
          </LocalizationProvider>
        )}
      />
    </Suspense>
  );
};
