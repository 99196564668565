import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { activateUser } from "../../services/user/User";
import { properties } from "../../utils/Properties_es";
import { PatternFormat } from "react-number-format";
import FancyDialogActions from "./FancyDialogActions";

interface ValidationResult {
  exists: boolean;
  // otras propiedades si las hay
}

interface AddOrUpdateUserTypes {
  id?: number;
  username: string;
  firstName: string;
  lastName: string;
  password?: string;
  phone: string;
  status?: string;
  roleId: number;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType: string;
  roleData?: any;
  handleActiveUser?: any;
  refreshData?: any;
}
const AddOrUpdateUserModal = ({
  actionButton,
  data,
  roleData,
  onSubmit,
  cancelModal,
  modalType,
  refreshData,
}: DialogProps) => {
  const { setLoading, setErrorMsg, setSuccessMsg } = useApp();
  const [showPassword, setShowPassword] = useState(false);

  const validation = Yup.object().shape({
    username: Yup.string()
      .required("Campo es requerido")
      .email("Email es inválido"),
    firstName: Yup.string().required("Campo es requerido"),
    lastName: Yup.string().required("Campo es requerido"),
    password: Yup.string().when(modalType, () => {
      return modalType === "create"
        ? Yup.string()
            .required("Contraseña es requerida")
            .min(6, "Contraseña debe tener al menos 8 caracteres")
            .test(
              "password",
              `Formato incorrecto, debe contener al menos 1 mayúscula, 1 minúscula, 1 número y 1 carácter especial`,
              (value: any) =>
                value.match(
                  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/
                )?.length > 0
            )
        : Yup.string();
    }),
    phone: Yup.string()
      .required("El teléfono es requerido")
      .matches(
        /^[0-9]{4}-[0-9]{4}$/,
        "El teléfono debe tener el formato ####-####"
      )
      .min(
        9,
        "El número es muy corto, debe tener 9 caracteres incluyendo el guion"
      )
      .max(
        9,
        "El número es muy largo, debe tener 9 caracteres incluyendo el guion"
      ),
    roleId: Yup.number()
      .required("El rol es requerido")
      .min(1, "Debes seleccionar un rol válido")
      .typeError("Campo es requerido"),
  });

  const defaultValues = {
    id: data?.id ?? 0,
    username: data?.username ?? "",
    firstName: data?.firstName ?? "",
    lastName: data?.lastName ?? "",
    password: data?.password ?? "",
    phone: data?.phone ?? "",
    roleId: data?.roleId ?? 0,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<AddOrUpdateUserTypes>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const handleActiveUser = async (data: any) => {
    setLoading && setLoading(true);
    try {
      let dataActiveUser = await activateUser(
        data.activationCode,
        data.username
      );
      if (!dataActiveUser) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_user_update_error);
        setLoading && setLoading(false);
        return;
      }

      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
    refreshData(0, "", "");
    cancelModal();
  };

  console.log(data);

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {modalType === "create" ? "Agregar usuario" : "Actualizar usuario"}
          {data && data?.status !== "ACTIVE" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleActiveUser(data)}
            >
              Activar usuario
            </Button>
          )}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12} md={6}>
              <Controller
                name={"username"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Correo Electrónico"
                    type="email"
                    variant="outlined"
                    value={value}
                    autoComplete="email"
                    {...register("username")}
                    error={errors.username && Boolean(errors.username)}
                    helperText={errors.username && errors.username.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"firstName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="outlined"
                    value={value}
                    autoComplete="firstName"
                    {...register("firstName")}
                    error={errors.firstName && Boolean(errors.firstName)}
                    helperText={errors.firstName && errors.firstName.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"lastName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Apellido"
                    type="text"
                    variant="outlined"
                    value={value}
                    autoComplete="lastName"
                    {...register("lastName")}
                    error={errors.lastName && Boolean(errors.lastName)}
                    helperText={errors.lastName && errors.lastName.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            {modalType === "create" && (
              <Grid item xs={12} md={6}>
                <Controller
                  name={"password"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Contraseña"
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      value={value}
                      autoComplete="off"
                      inputProps={{
                        autoComplete: "no-pass",
                      }}
                      {...register("password")}
                      error={!!errors.password}
                      helperText={errors.password && errors.password.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Controller
                name={"phone"}
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <FormControl sx={{ width: "100%" }} error={!!errors.phone}>
                    <FormLabel
                      sx={{
                        color: "#5d5e60",
                        position: "absolute",
                        left: "-14px",
                        top: "-2px",
                        background: "#FFF",
                        padding: "0 1px",
                        WebkitTransform: "translate(14px, -9px) scale(0.75)",
                        MozTransform: "translate(14px, -9px) scale(0.75)",
                        MsTransform: "translate(14px, -9px) scale(0.75)",
                        transform: "translate(14px, -9px) scale(0.75)",
                        WebkitTransition:
                          "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                        transition:
                          "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                      }}
                    >
                      Teléfono
                    </FormLabel>
                    <PatternFormat
                      className="input-phone"
                      name={name}
                      onPaste={(e: any) => e.preventDefault()}
                      mask="_"
                      format={"####-####"}
                      value={value}
                      onChange={onChange}
                      placeholder={"####-####"}
                    />
                    {errors.phone && (
                      <span style={{ color: "red", fontSize: "0.8rem" }}>
                        {errors.phone.message}
                      </span>
                    )}
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="roleId"
                rules={{ required: "El campo de rol es obligatorio" }}
                render={({ field: { onChange, value } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={!!errors.roleId}
                  >
                    <InputLabel shrink={true}>Perfil</InputLabel>
                    <Select
                      notched
                      value={value}
                      onChange={onChange}
                      label="Perfil"
                      sx={{ "& input": { pl: "0!important" } }}
                    >
                      {roleData &&
                        roleData.content.map((role: any) => (
                          <MenuItem key={role.id} value={role.id}>
                            {role.roleName}
                          </MenuItem>
                        ))}
                    </Select>
                    {errors.roleId && (
                      <span style={{ color: "red", fontSize: "0.8rem" }}>
                        {errors.roleId.message}
                      </span>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdateUserModal;
