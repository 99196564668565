import {
  Autocomplete,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DialogProps } from "../../types/Utils";
import FancyDialogActions from "./FancyDialogActions";
import { useEffect, useState } from "react";
import { useApp } from "../../hooks/useApp";
import {
  controlPreview,
  createControl,
  updateQuantity,
} from "../../services/inventoryControl/InventoryControlService";
import { formatNumber } from "./formatters";

interface Product {
  id?: number;
  productId: number;
  productCode: any;
  productDescription: string;
  availableQuantity: number;
  diferencia: number;
  productCost: number;
  productFinalCost: number;
  controlQuantity: number;
  additionalInfo: string;
}

interface AddOrUpdateInventoryControl {
  id?: number;
  name: string;
  user: string;
  controlNumber: string;
  status: {
    id: number;
    name: string;
  };
  date: string;
  notes: string;
  products: Product[];
  warehouseLocation: string;
  additionalInfo: string;
}

const AddOrUpdateInventoryControlModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  roleData,
  wereHouseData,
  refreshData,
}: DialogProps) => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const validationSchema = Yup.object().shape({
    user: Yup.string().required("Usuario es requerido"),
    controlNumber: Yup.string().required("Número de control es requerido"),
    date: Yup.string().required("Fecha es requerida"),
    products: Yup.array().of(
      Yup.object().shape({
        // productId: Yup.number().required("ID de producto es requerido"),
        productDescription: Yup.string().required(
          "Nombre de producto es requerido"
        ),
        controlQuantity: Yup.number()
          .required("Cantidad es requerida")
          .integer("La cantidad debe ser un número entero")
          .typeError("Cantidad es requerida"),
      })
    ),
  });

  const parseDate = (dateString: any) => {
    // Asegúrate de que el valor no sea nulo o vacío
    if (!dateString) return new Date().toISOString().split("T")[0];

    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`; // Formato YYYY-MM-DD
  };

  const defaultValues: AddOrUpdateInventoryControl = {
    id: data?.inventoryControl?.id ?? 0,
    name: data?.name ?? "",
    user: data?.inventoryControl?.createdByUser ?? "",
    controlNumber: data?.inventoryControl?.code ?? "",
    status:
      data?.inventoryControl?.status === "DRAFT"
        ? { id: 2, name: "Pendiente" }
        : data?.inventoryControl?.status === "APPLIED"
        ? { id: 1, name: "Finalizado" }
        : { id: 2, name: "Pendiente" },
    date: parseDate(data?.inventoryControl?.createdAt),
    notes: data?.inventoryControl?.notes ?? "",
    products: data?.items ?? [],
    warehouseLocation: data?.wereHouseLocation ?? "",
    additionalInfo: data?.additionalInfo ?? "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    watch,
  } = useForm<AddOrUpdateInventoryControl>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  // useFieldArray for dynamic product fields
  const { fields, append } = useFieldArray({
    control,
    name: "products",
  });

  const [inventoryData, setInventoryData] = useState<any>({});
  const [isAdditionalFieldEnabled, setIsAdditionalFieldEnabled] =
    useState(false);
  const [canFetch, setCanFetch] = useState(false);
  const warehouseLocation = watch("warehouseLocation");
  const additionalInfo = watch("additionalInfo");
  const [filterInfo, setFilterInfo] = useState("");
  const [isControlConfirmed, setIsControlConfirmed] = useState(
    modalType === "update"
  );

  const fetchInventoryData = async (location: string, product: string) => {
    try {
      setLoading && setLoading(true);
      let data: any = await createControl({
        location: location,
        user: authInfo?.name,
        product: product,
      });

      // Asignar los valores a los campos mapeados
      setValue("id", data.inventoryControl.id);
      setValue("user", data.inventoryControl.createdByUser);
      setValue("date", parseDate(data.inventoryControl.createdAt));
      setValue("controlNumber", data.inventoryControl.code);
      setValue(
        "status",
        data.inventoryControl.status === "DRAFT"
          ? { id: 2, name: "Pendiente" }
          : { id: 1, name: "Completado" }
      );

      const formattedProducts = data.items.map((item: any) => ({
        id: item.id,
        productId: item.productCode,
        productCode: item.productCode,
        productDescription: item.productDescription,
        productCost: item.productCost,
        availableQuantity: item.availableQuantity,
        controlQuantity: item.controlQuantity,
      }));

      setValue("products", formattedProducts);

      setInventoryData(data);
      setIsControlConfirmed(true);
      refreshData("", 0);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    } finally {
      setLoading && setLoading(false);
    }
  };

  const updateItemCount = async (id: number, count: number) => {
    console.log(id, count);
    try {
      let data = await updateQuantity(
        { id: id, count: count },
        authInfo?.username
      );
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleLocationSelect = (value: string) => {
    console.log(value)
    setValue("warehouseLocation", value);
    setIsAdditionalFieldEnabled(true);
  };

  const products = watch("products");

  const calculateProductFields = (index: number) => {
    const currentProducts = getValues("products");
    const product = currentProducts[index];

    const diferencia = product.availableQuantity - product.controlQuantity;
    setValue(`products.${index}.diferencia`, diferencia);

    const productFinalCost =
      product.availableQuantity * product.productCost -
      product.controlQuantity * product.productCost;
    setValue(`products.${index}.productFinalCost`, productFinalCost);
  };

  const changeCount = (index: number) => {
    const currentProducts = getValues("products");
    const product: any = currentProducts[index];

    if (product.id && product.controlQuantity != "") {
      updateItemCount(product.id, product.controlQuantity);
    }
  };

  useEffect(() => {
    if (products && products.length > 0) {
      products.forEach((_, index) => {
        const controlQuantity = getValues(`products.${index}.controlQuantity`);

        if (controlQuantity !== null && controlQuantity !== undefined) {
          calculateProductFields(index);
        }
      });
    }
  }, [products]);
  console.log(warehouseLocation);

  useEffect(() => {
    const fetchData = async () => {
      if (!warehouseLocation) return;
      setLoading && setLoading(true);
      try {
        const response: any = await controlPreview(
          warehouseLocation,
          additionalInfo
        );

        const formattedProducts =
          response.length > 0
            ? response.map((item: any) => ({
                productId: item.productCode,
                productCode: item.productCode,
                productDescription: item.productDescription,
                productCost: item.productCost,
                availableQuantity: item.availableQuantity,
                controlQuantity: item.controlQuantity,
              }))
            : [];

        setValue("products", formattedProducts);
      } catch (error: any) {
        setErrorMsg && setErrorMsg(error);
      } finally {
        setLoading && setLoading(false);
      }
    };

    const debounceFetch = setTimeout(fetchData, 1000);

    return () => clearTimeout(debounceFetch);
  }, [filterInfo, warehouseLocation, additionalInfo]);

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar control" : "Actualizar control"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            {modalType === "create" && !isControlConfirmed && (
              <Grid item xs={6}>
                <Controller
                  name={"warehouseLocation"}
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      size="small"
                      variant="outlined"
                      error={!!errors.warehouseLocation}
                    >
                      <InputLabel shrink={true}>Ubicación en bodega</InputLabel>
                      <Select
                        notched
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          handleLocationSelect(e.target.value);
                        }}
                        label="Ubicación en bodega"
                        size="small"
                        value={field.value}
                        disabled={data?.inventoryControl?.status === "APPLIED"}
                        variant="outlined"
                        sx={{
                          "& input": { pl: "0!important" },
                          color: "#b5bac3",
                        }}
                      >
                        {wereHouseData &&
                          wereHouseData.map((WarehouseData: any) => (
                            <MenuItem
                              key={WarehouseData.id}
                              value={WarehouseData.id}
                            >
                              {WarehouseData.name}
                            </MenuItem>
                          ))}
                      </Select>
                      {errors.warehouseLocation && (
                        <span style={{ color: "red", fontSize: "0.8rem" }}>
                          {errors.warehouseLocation.message}
                        </span>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            )}
            {isAdditionalFieldEnabled && (
              <Grid item xs={6}>
                <Controller
                  name="additionalInfo"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Búsqueda específica"
                      variant="outlined"
                      value={value}
                      {...register("additionalInfo")}
                      disabled={data?.inventoryControl?.status === "APPLIED"}
                      placeholder="Búsqueda específica"
                      onChange={(e) => {
                        onChange(e);
                        setFilterInfo(e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
            )}
            {/* Usuario */}
            <Grid item xs={6}>
              <Controller
                name="user"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Usuario que genera"
                    variant="outlined"
                    {...field}
                    disabled={data?.inventoryControl?.status === "APPLIED"}
                    error={Boolean(errors.user)}
                    helperText={errors.user?.message}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>

            {/* Número de Control */}
            <Grid
              item
              xs={
                (modalType === "update" && warehouseLocation === "") ||
                (modalType === "create" && warehouseLocation)
                  ? 6
                  : 4
              }
            >
              <Controller
                name="controlNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Número de Control"
                    variant="outlined"
                    {...field}
                    disabled={data?.inventoryControl?.status === "APPLIED"}
                    error={Boolean(errors.controlNumber)}
                    helperText={errors.controlNumber?.message}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={
                (modalType === "update" && warehouseLocation === "") ||
                (modalType === "create" && warehouseLocation)
                  ? 6
                  : 4
              }
            >
              <Controller
                name={"status"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={[
                      { id: 1, name: "Completado" },
                      { id: 2, name: "Pendiente" },
                    ]}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    value={value}
                    disabled={true}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Estado"
                          variant="outlined"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.status}
                          helperText={errors.status && errors.status.message}
                          InputLabelProps={{ shrink: true }}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>

            {/* Fecha */}
            <Grid
              item
              xs={
                (modalType === "update" && warehouseLocation === "") ||
                (modalType === "create" && warehouseLocation)
                  ? 6
                  : 4
              }
            >
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Fecha"
                    variant="outlined"
                    type="date"
                    {...field}
                    disabled={data?.inventoryControl?.status === "APPLIED"}
                    error={Boolean(errors.date)}
                    helperText={errors.date?.message}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            {modalType === "create" && isAdditionalFieldEnabled && (
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (warehouseLocation) {
                      setIsAdditionalFieldEnabled(false);
                      fetchInventoryData(warehouseLocation, additionalInfo);
                    } else {
                      alert("Seleccione una ubicación en la bodega primero.");
                    }
                  }}
                >
                  Confirmar control
                </Button>
              </Grid>
            )}
          </Grid>
          {/* Productos */}
          {fields.map((product, index) => (
            <Grid container spacing={2} key={product.id} sx={{ mt: 2 }}>
              {/* ID de Producto */}
              <Grid item xs={2}>
                <Controller
                  name={`products.${index}.productCode`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Código oficial"
                      variant="outlined"
                      {...field}
                      disabled={data?.inventoryControl?.status === "APPLIED"}
                      error={Boolean(errors.products?.[index]?.productId)}
                      helperText={errors.products?.[index]?.productId?.message}
                      InputProps={{ readOnly: true }}
                    />
                  )}
                />
              </Grid>

              {/* Nombre de Producto */}
              <Grid item xs={2}>
                <Controller
                  name={`products.${index}.productDescription`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Descripción"
                      variant="outlined"
                      {...field}
                      disabled={data?.inventoryControl?.status === "APPLIED"}
                      error={Boolean(
                        errors.products?.[index]?.productDescription
                      )}
                      helperText={
                        errors.products?.[index]?.productDescription?.message
                      }
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>

              {/* Cantidad */}
              <Grid item xs={2}>
                <Controller
                  name={`products.${index}.availableQuantity`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Cantidad"
                      variant="outlined"
                      type="number"
                      {...field}
                      disabled={data?.inventoryControl?.status === "APPLIED"}
                      error={Boolean(
                        errors.products?.[index]?.availableQuantity
                      )}
                      helperText={
                        errors.products?.[index]?.availableQuantity?.message
                      }
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>

              {/* Conteo */}
              <Grid item xs={2}>
                <Controller
                  name={`products.${index}.controlQuantity`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Conteo"
                      variant="outlined"
                      type="number"
                      {...field}
                      disabled={data?.inventoryControl?.status === "APPLIED"}
                      inputProps={{ min: 0 }}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        if (value < 0) return;

                        field.onChange(e);
                        calculateProductFields(index);
                        changeCount(index);
                      }}
                      error={Boolean(errors.products?.[index]?.controlQuantity)}
                      helperText={
                        errors.products?.[index]?.controlQuantity?.message
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>

              {/* Diferencia */}
              <Grid item xs={2}>
                <Controller
                  name={`products.${index}.diferencia`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Diferencia"
                      variant="outlined"
                      type="number"
                      disabled={data?.inventoryControl?.status === "APPLIED"}
                      value={
                        products?.[index]?.controlQuantity -
                        products?.[index]?.availableQuantity
                      }
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>

              {/* Costo */}
              <Grid item xs={2}>
                <Controller
                  name={`products.${index}.productFinalCost`}
                  control={control}
                  render={({ field }) => {
                    const cost =
                      products?.[index]?.controlQuantity *
                        products?.[index]?.productCost -
                      products?.[index]?.availableQuantity *
                        products?.[index]?.productCost;
                    return (
                      <TextField
                        fullWidth
                        size="small"
                        label="Costo"
                        variant="outlined"
                        disabled={data?.inventoryControl?.status === "APPLIED"}
                        value={formatNumber(cost)}
                        InputProps={{ readOnly: true }}
                        InputLabelProps={{ shrink: true }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          ))}
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            {/* Notas */}
            <Grid item xs={12}>
              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Notas"
                    variant="outlined"
                    multiline
                    rows={2}
                    {...field}
                    disabled={data?.inventoryControl?.status === "APPLIED"}
                    error={Boolean(errors.notes)}
                    helperText={errors.notes?.message}
                    InputLabelProps={{ shrink: true }}
                    sx={{ background: "#FFF" }}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                      sx: { color: "#B0BEC5" },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
        textCancelButton={
          data?.inventoryControl?.status === "APPLIED"
            ? "Cancelar"
            : isControlConfirmed
            ? "Guardar en borrador"
            : "Cancelar"
        }
        textAcceptButton={
          data?.inventoryControl?.status === "APPLIED" ? "Aplicado" : "Aplicar"
        }
        disableAccept={
          data?.inventoryControl?.status === "APPLIED" ||
          (modalType === "create" && !isControlConfirmed)
        }
      />
    </>
  );
};

export default AddOrUpdateInventoryControlModal;
