import React, { useEffect, useState } from "react";
import {
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DialogProps } from "../../types/Utils";
import FancyDialogActions from "./FancyDialogActions";

interface HolidayData {
  id?: number;
  description: string;
  dayOfMonth: number;
  month: number;
  mandatoryPayment: boolean;
  enabled: boolean;
  reschedule?: boolean;
  newDayOfMonth?: number;
  newMonth?: number;
}

const AddOrUpdateHolidayModal = ({
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const [reschedule, setReschedule] = useState(
    data?.reScheduledHoliday ? true : false
  );

  // Validación del formulario
  const validation = Yup.object().shape({
    description: Yup.string().required("Campo es requerido"),
    dayOfMonth: Yup.number()
      .typeError("Campo es requerido")
      .min(1, "El día no puede ser menor a 1")
      .max(31, "El día no puede ser mayor a 31")
      .required("Campo es requerido"),
    month: Yup.number()
      .typeError("Campo es requerido")
      .min(1, "El mes no puede ser menor a 1")
      .max(12, "El mes no puede ser mayor a 12")
      .required("Campo es requerido"),
    newDayOfMonth: Yup.number()
      .typeError("Campo es requerido")
      .min(1, "El día no puede ser menor a 1")
      .max(31, "El día no puede ser mayor a 31")
      .when("reschedule", {
        is: true, // Condición
        then: (schema) => schema.required("Campo es requerido al reprogramar"), // Validación si la condición se cumple
        otherwise: (schema) => schema.notRequired(), // Validación si la condición no se cumple
      }),
    newMonth: Yup.number()
      .typeError("Campo es requerido")
      .min(1, "El mes no puede ser menor a 1")
      .max(12, "El mes no puede ser mayor a 12")
      .when("reschedule", {
        is: true,
        then: (schema) => schema.required("Campo es requerido al reprogramar"),
        otherwise: (schema) => schema.notRequired(),
      }),
  });

  console.log(data);

  const defaultValues = {
    id: data?.id ?? 0,
    description: data?.description ?? "",
    dayOfMonth: data?.dayOfMonth ?? 1,
    month: data?.month ?? 1,
    mandatoryPayment: data?.mandatoryPayment ?? false,
    enabled: data?.enabled ?? true,
    reschedule: data?.reScheduledHoliday
      ? data.reScheduledHoliday.dayOfMonth !== data.dayOfMonth ||
        data.reScheduledHoliday.month !== data.month
      : false,
    newDayOfMonth: data?.reScheduledHoliday
      ? data?.reScheduledHoliday?.dayOfMonth
      : undefined,
    newMonth: data?.reScheduledHoliday
      ? data?.reScheduledHoliday?.month
      : undefined,
  };

  useEffect(() => {
    if (data?.reScheduledHoliday) {
      const isDifferentDate =
        data.reScheduledHoliday.dayOfMonth !== data.dayOfMonth ||
        data.reScheduledHoliday.month !== data.month;

      setReschedule(isDifferentDate); // Actualiza el estado
    } else {
      setReschedule(false); // No hay reprogramación
    }
  }, [data]);

  useEffect(() => {
    if (!reschedule && data?.id) {
      // Si se desmarca 'Reprogramar', actualiza los valores de los nuevos campos con los valores principales
      setValue("newDayOfMonth", defaultValues.dayOfMonth);
      setValue("newMonth", defaultValues.month);
    }
  }, [reschedule]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<HolidayData>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar feriado" : "Actualizar feriado"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            {/* Campo Descripción */}
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Descripción"
                    type="text"
                    variant="outlined"
                    {...field}
                    error={Boolean(errors.description)}
                    helperText={errors.description?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: { autoComplete: "off" },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            {/* Campo Día */}
            <Grid item xs={6}>
              <Controller
                name="dayOfMonth"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Día"
                    type="number"
                    variant="outlined"
                    {...field}
                    error={Boolean(errors.dayOfMonth)}
                    helperText={errors.dayOfMonth?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            {/* Campo Mes */}
            <Grid item xs={6}>
              <Controller
                name="month"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Mes"
                    type="number"
                    variant="outlined"
                    {...field}
                    error={Boolean(errors.month)}
                    helperText={errors.month?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            {/* Pago Obligatorio */}
            <Grid item xs={6}>
              <Controller
                name="mandatoryPayment"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Pago obligatorio"
                  />
                )}
              />
            </Grid>
            {/* Habilitado */}
            <Grid item xs={6}>
              <Controller
                name="enabled"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Habilitado"
                  />
                )}
              />
            </Grid>
            {modalType === "update" && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={reschedule}
                        onChange={(e) => {
                          setReschedule(e.target.checked);
                          setValue("reschedule", e.target.checked);
                        }}
                      />
                    }
                    label="Reprogramar feriado"
                  />
                </Grid>
                {/* Campos Nuevos Día y Mes */}
                {reschedule && (
                  <>
                    <Grid item xs={6}>
                      <Controller
                        name="newDayOfMonth"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="Nuevo Día"
                            type="number"
                            variant="outlined"
                            {...field}
                            error={Boolean(errors.newDayOfMonth)}
                            helperText={errors.newDayOfMonth?.message}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="newMonth"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="Nuevo Mes"
                            type="number"
                            variant="outlined"
                            {...field}
                            error={Boolean(errors.newMonth)}
                            helperText={errors.newMonth?.message}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </form>
      </DialogContent>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdateHolidayModal;
