import { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Dialog,
} from "@mui/material";
import { TrashIcon, PlusIcon, PenIcon } from "../components/svgicons/SvgIcons";
import { useForm } from "react-hook-form";
import FancyTooltip from "../components/utils/FancyTooltip";
import FancyPaper from "../components/FancyPaper";
import { useApp } from "../hooks/useApp";
import DeleteModal from "../components/utils/DeleteModal";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import FancyDialogActions from "../components/utils/FancyDialogActions";
import FancySearch from "../components/utils/FancySearch";
import { baseRequestType } from "../types/Utils";
import { properties } from "../utils/Properties_es";
import {
  getRequestMileagesType,
  createMileageType,
  updateMileageType,
  deleteMileageType,
} from "../services/projects/ProjectService";
import AddOrUpdateAreaType from "../components/utils/AddOrUpdateAreaType";

interface AddOrUpdateArea {
  id: number;
  name: string;
}

interface getRequestType extends baseRequestType {
  content: AddOrUpdateArea[];
}

const ManagementAreaType = () => {
  const { setLoading, setErrorMsg, modalData, setModalData, setSuccessMsg } =
    useApp();
  const { handleSubmit } = useForm();
  const [preFilter, setPreFilter] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [areasData, setAreasData] = useState<getRequestType | null>(null);

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  const handleFetchData = async (currentPage: number, filter: string) => {
    try {
      setLoading(true);
      setPreFilter(filter);
      setPage(currentPage);

      /* let data: any = areasData?.content.filter((item: any) =>
        item.name.includes(filter)
      ) */ let data = await getRequestMileagesType(currentPage, filter);

      if (data) {
        setAreasData(data);
      }
      setLoading(false);
    } catch (error: any) {
      setErrorMsg(error);
    }
  };

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update") {
      object = areasData?.content.find((p: any) => p.id === parseInt(id));
    }

    if (modalAction === "delete") {
      object = areasData?.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };

  /**
   * Evento de cierre de modal
   * @param event
   */
  const handleCancelModal = () => {
    //@ts-ignore

    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleAdd = async (data: AddOrUpdateAreaType) => {
    //close modal
    handleCancelModal();
    setLoading(true);
    try {
      let createData = await createMileageType({
        ...data,
      });
      if (!createData) {
        setErrorMsg("Error en proceso de guardar tipo de área");
        setLoading && setLoading(false);
        return;
      }
      setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_save);
      //call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg(error);
      setLoading(false);
    }
  };

  const handleUpdate = async (data: AddOrUpdateAreaType) => {
    //close modal
    handleCancelModal();
    setLoading(true);

    try {
      let createData = await updateMileageType({
        ...data,
      });
      if (!createData) {
        setErrorMsg("Error en proceso de actualizar tipo de área");
        setLoading && setLoading(false);
        return;
      }
      setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg(error);
      setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    console.log(id);
    //close modal
    handleCancelModal();
    //loading
    setLoading(true);
    try {
      //call service
      let deleteData = await deleteMileageType(id);
      console.log(deleteData);

      if (!deleteData) {
        setErrorMsg("Error eliminando tipo de área");
        setLoading(false);
        return;
      }
      setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_delete);

      //reset page and call fetch data
      setPage(0);
      await handleFetchData(0, preFilter);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <FancyPaper pagetitle="Tipos de área">
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={5} sm={5} xs={12}>
            {/* {<ResourceAccess isCode={true} pathOrCode={"PERSON:WRITE"}>} */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              data-name="create"
            >
              Agregar área
              <PlusIcon sx={{ ml: 1 }} />
            </Button>
            {/* {</ResourceAccess>} */}
          </Grid>
          <Grid item md={7} sm={7} xs={12}>
            <FancySearch
              preFilter={preFilter}
              setPreFilter={setPreFilter}
              handleFetchData={() => handleFetchData(0, "")}
              handleApplyFilter={() => handleFetchData(0, preFilter)}
            />
          </Grid>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Id</TableCell>
                <TableCell align="center">Descripción</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {areasData !== null && areasData?.content?.length > 0 ? (
                areasData?.content.map((item: AddOrUpdateAreaType) => (
                  <TableRow key={item.id}>
                    <TableCell align="center">{item.id}</TableCell>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">
                      <FancyTooltip title="Editar" placement="top">
                        <IconButton
                          aria-label="edit"
                          component="label"
                          color="primary"
                          onClick={handleOpenModal}
                          data-name="update"
                          data-id={item.id}
                        >
                          <PenIcon />
                        </IconButton>
                      </FancyTooltip>
                      <FancyTooltip title="Eliminar" placement="top">
                        <IconButton
                          aria-label="trash"
                          component="label"
                          color="error"
                          data-id={item.id}
                          onClick={handleOpenModal}
                          data-name="delete"
                        >
                          <TrashIcon />
                        </IconButton>
                      </FancyTooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>No existen registros</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            areasData !== null
              ? areasData?.content?.length > 0
                ? areasData?.content?.length
                : 0
              : 0
          }
          rowsPerPage={areasData !== null ? areasData?.size : 0}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={areasData?.totalElements}
          totalPages={areasData?.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateAreaType
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                <FancyDialogActions
                  handleAccept={handleSubmit(onSubmit)}
                  cancelModal={handleCancelModal}
                  textAcceptButton={"Eliminar"}
                />
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default ManagementAreaType;
