import { Stack, Typography, Pagination, useTheme } from "@mui/material";

interface TablesPaginationTypes {
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: any;
  onRowsPerPageChange?: any;
  totalElements: any;
  totalPages: any;
}

const FancyTablePagination = ({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  totalElements,
  totalPages,
}: TablesPaginationTypes) => {
  // const { t } = useTranslation();
  const theme = useTheme();
  const initRow = page * rowsPerPage;
  const finalRow = Math.min(initRow + rowsPerPage, totalElements);
  return (
    <Stack
      direction={{
        xs: "column",
        md: "row",
      }}
      alignItems="center"
      justifyContent="space-between"
      sx={{ pt: 6 }}
    >
      <Typography
        variant="body1"
        color="initial"
        sx={{
          color: theme.palette.greyDue.light,
          fontSize: "1rem",
          mb: {
            xs: 6,
            md: 0,
          },
          width: {
            xs: "100%",
            md: "50%",
          },
        }}
      >
        {"Mostrando"}{" "}
        {totalElements > 0 && (
          <>
            {initRow + 1} {"a"} {finalRow} {"de"} {totalElements}
          </>
        )}{" "}
        {"registros"}
      </Typography>
      <Pagination
        count={totalPages}
        color="primary"
        onChange={onPageChange}
        showFirstButton
        showLastButton
        page={page + 1}
      />
    </Stack>
  );
};

export default FancyTablePagination;
