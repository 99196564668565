import { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  Button,
  Divider,
  Dialog,
} from "@mui/material";
import { PlusIcon, PenIcon } from "../components/svgicons/SvgIcons";
import FancyPaper from "../components/FancyPaper";
import FancyTooltip from "../components/utils/FancyTooltip";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import { useForm } from "react-hook-form";
import { deleteRequest } from "../services/bank/BankService";
import { useApp } from "../hooks/useApp";
import { properties } from "../utils/Properties_es";
import AddOrUpdateInventoryControlModal from "../components/utils/AddOrUpdateInventoryControlModal";
import DeleteModal from "../components/utils/DeleteModal";
import ResourceAccess from "../components/security/ResourceAccess";
import FancyDialogActions from "../components/utils/FancyDialogActions";
import FancySearch from "../components/utils/FancySearch";
import {
  getRequestInventoryControl,
  findByIdRequest,
  applyControl,
} from "../services/inventoryControl/InventoryControlService";
import { getRequestWarehouse } from "../services/location/LocationService";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import { getRequestInventoryControlReport } from "../services/report/ReportService";

const ManagementInventoryControl = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);
  const [inventoryData, setInventoryData] = useState<any>([]);
  const [wereHouseData, setWereHouseData] = useState<any>([]);

  const initForm = {
    id: 0,
    code: "",
    createdBy: "",
    description: "",
    path: 0,
  };
  const [formData, setFormData] = useState<any>({});
  const { handleSubmit } = useForm();

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData("", 0);
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  const handleFetchData = async (filter: string, currentPage: number) => {
    setLoading && setLoading(true);
    try {
      let data = await getRequestInventoryControl(filter, currentPage, 10);
      let dataWereHouse: any = await getRequestWarehouse({
        filter: "",
        page: 0,
        size: 100,
      });
      if (data) {
        setInventoryData(data);
      }
      if (dataWereHouse) {
        setWereHouseData(dataWereHouse?.content);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchByID = async (id: string) => {
    setLoading && setLoading(true);
    try {
      let authorityDataID: any = await findByIdRequest(id);
      if (authorityDataID) {
        setFormData(authorityDataID);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await applyControl({
        ...data,
        createdBy: authInfo?.username,
      });

      // if (!createData) {
      //   setErrorMsg &&
      //     setErrorMsg(properties.com_parval_label_bank_create_error);
      //   setLoading && setLoading(false);
      //   return;
      // }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_inventory_control_create);
      setPage(0);
      await handleFetchData("", 0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let updateData = await applyControl({
        ...data,
        //createdBy: authInfo?.username,
        modifiedBy: authInfo?.username,
      });
      console.log(data);

      // if (!updateData) {
      //   setErrorMsg &&
      //     setErrorMsg(properties.com_parval_label_bank_update_error);
      //   setLoading && setLoading(false);
      //   return;
      // }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_inventory_control_update);
      setPage(0);
      await handleFetchData("", 0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRequest(id);
      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg(
            properties.com_parval_label_inventory_control_delete_error
          );
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_inventory_control_delete);
      //reset page and call fetch data
      setPage(0);
      await handleFetchData("", 0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(preFilter, customPage);
    }
  };

  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(preFilter, 0);
    }
  };

  /** Validate errors in case of opening modal **/
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update") {
      await handleFetchByID(id);
      // object = inventoryData.content.find((p: any) => p.id === parseInt(id));
    }

    if (modalAction === "delete") {
      object = inventoryData.content.find((p: any) => p.id === parseInt(id));
      //object = FakeData.content.find((p: any) => p.id === parseInt(id));
    }
    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
  };

  const exportToPdf = async (id: any) => {
    //getRequestOrdersExcel
    setLoading && setLoading(true);
    try {
      let data = await getRequestInventoryControlReport(id);
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  return (
    <>
      <FancyPaper
        pagetitle="Control de inventarios"
        prevpage="Inventario"
        linkprevpage=""
      >
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={7} sm={5} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={"INVENTORYCONTROL:WRITE"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                data-name="create"
              >
                {"Agregar control"}
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>
          </Grid>
          <Grid item md={5} sm={7} xs={12}>
            <FancySearch
              preFilter={preFilter}
              setPreFilter={setPreFilter}
              handleFetchData={() => handleFetchData("", 0)}
              handleApplyFilter={handleApplyFilter}
            />
          </Grid>
        </Grid>
        <Divider />
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Usuario</TableCell>
                <TableCell>Nº de control</TableCell>
                <TableCell>Estado</TableCell>
                <ResourceAccess
                  isCode={true}
                  pathOrCode={"INVENTORYCONTROL:WRITE"}
                >
                  <TableCell align="center">{"Acciones"}</TableCell>
                </ResourceAccess>
              </TableRow>
            </TableHead>
            <TableBody>
              {inventoryData &&
                inventoryData.content &&
                inventoryData.content.map((r: any) => (
                  <TableRow key={r.id}>
                    <TableCell align="center">{r.createdAt}</TableCell>
                    <TableCell align="center">{r.createdByUser}</TableCell>
                    <TableCell align="center">{r.code}</TableCell>
                    <TableCell align="center">
                      {r.status === "APPLIED" ? "Finalizado" : "Pendiente"}
                    </TableCell>
                    <TableCell align="center">
                      <ResourceAccess
                        isCode={true}
                        pathOrCode={"INVENTORYCONTROL:WRITE"}
                      >
                        <FancyTooltip title={"Editar"} placement="top">
                          <IconButton
                            aria-label="edit"
                            component="label"
                            color="primary"
                            onClick={handleOpenModal}
                            data-name="update"
                            data-id={r.id}
                          >
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title={"Imprimir"} placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="secondary"
                            // sx={{
                            //   "&:hover": {
                            //     color: theme.palette.error.dark,
                            //   },
                            // }}
                            data-id={r.id}
                            onClick={() => exportToPdf(r.id)}
                            //data-name="delete"
                          >
                            <PrintRoundedIcon sx={{ fontSize: "1.3rem" }} />
                          </IconButton>
                        </FancyTooltip>
                        {/* <FancyTooltip title={"Eliminar"} placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="error"
                            sx={{
                              "&:hover": {
                                color: theme.palette.error.dark,
                              },
                            }}
                            data-id={r.id}
                            onClick={handleOpenModal}
                            data-name="delete"
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip> */}
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}
              {!inventoryData ||
                !inventoryData.content ||
                (inventoryData.content?.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      {"No hay registros"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            inventoryData?.content?.length > 0
              ? inventoryData?.content?.length
              : 0
          }
          rowsPerPage={inventoryData?.size}
          page={page}
          onPageChange={handleChangePage}
          totalElements={inventoryData?.totalElements}
          totalPages={inventoryData?.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          maxWidth="lg"
          fullWidth
        >
          <AddOrUpdateInventoryControlModal
            data={formData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            wereHouseData={wereHouseData}
            refreshData={handleFetchData}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.code}
            actionButton={
              <>
                <FancyDialogActions
                  handleAccept={handleSubmit(onSubmit)}
                  cancelModal={handleCancelModal}
                  textAcceptButton={"Eliminar"}
                />
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default ManagementInventoryControl;
