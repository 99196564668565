import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  defer,
} from "react-router-dom";

import { AuthLayout } from "../components/security/AuthLayout";
import { getAuthData } from "../utils/LocalStorageManager";
import DashboardLayout from "../layouts/DashboardLayout";
import DashboardHome from "../pages/DashboardHome";
import Auth from "../pages/session/Auth";
import Login from "../pages/session/Login";
import RememberPassword from "../pages/session/RememberPassword";
import ForgotPassword from "../pages/session/ForgotPassword";
import ActiveRegister from "../pages/session/ActiveRegister";
import ManagementRole from "../pages/ManagementRole";
import ManagementUser from "../pages/ManagementUsers";
import SettingAuthority from "../pages/SettingAuthority";
import SettingAuthorityByRoleManagement from "../pages/SettingAuthorityByRoleManagement";
import ManagementInventory from "../pages/maintenance/ManagementInventory";
import ManagementCompany from "../pages/settings/ManagementCompany";
import CancelSubscription from "../pages/session/CancelSubscription";
import ManagementPerson from "../pages/ManagementPerson";
import ManagementBanks from "../pages/ManagementBanks";
import ManagementCountries from "../pages/ManagementCountries";
import ManagementFamiles from "../pages/ManagementFamilies";
import ManagementSubFamilies from "../pages/ManagementSubFamilies";
import ManagementSubSubFamilies from "../pages/ManagementSubSubFamilies";
import ManagementHistory from "../pages/maintenance/ManagementHistory";
import ManagementLocation from "../pages/ManagementLocation";
import ManagementProjects from "../pages/ManagementProjects";
import ManagementExitTicket from "../pages/ManagementExitTicket";
import ManagementConsumptionPerProject from "../pages/ManagementConsumptionPerProject";
import ManagementConsumptionDetail from "../pages/ManagementConsumptionDetail";
import ManagementAdvances from "../pages/maintenance/ManagementAdvances";
import ManagementInventoryControl from "../pages/ManagementInventoryControl";
import ManagementHoliday from "../pages/ManagementHoliday";
import ManagementPosition from "../pages/ManagementPosition";
import ManagementAreaType from "../pages/ManagementAreaType";
import ManagementProvider from "../pages/ManagementProvider";
import ManagementPurchaseOrders from "../pages/ManagementPurchaseOrders";
import ManagementCreditsDebitsTickets from "../components/utils/credit_view/CreditsDebitsTickets";
import DetailsAdvances from "../pages/maintenance/DetailsAdvances";
import DetailsAdvancesAdd from "../pages/maintenance/DetailsAdvancesAdd";
import ManagementXml from "../pages/maintenance/ManagementXml";

export const AppRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route path="/" element={<Auth />}>
        <Route index element={<Login />} />
        <Route path="/rememberPassword" element={<RememberPassword />} />
        <Route
          path="/forgot-password/:code/:email"
          element={<ForgotPassword />}
        />
        <Route
          path="/active-register/:code/:email"
          element={<ActiveRegister />}
        />
      </Route>
      <Route path="/deleteaccount" element={<CancelSubscription />} />
      <Route path="/dashboard" element={<DashboardLayout />}>
        <Route path="home" element={<DashboardHome />} />
        <Route path="settingroles" element={<ManagementRole />} />
        <Route path="banks" element={<ManagementBanks />} />
        <Route path="countries" element={<ManagementCountries />} />
        <Route path="history" element={<ManagementHistory />} />
        <Route path="location" element={<ManagementLocation />} />
        <Route path="settinguser" element={<ManagementUser />} />
        <Route path="settingauthority" element={<SettingAuthority />} />
        <Route path="families" element={<ManagementFamiles />} />
        <Route
          path="subfamilies/:id/:familyName"
          element={<ManagementSubFamilies />}
        />
        <Route
          path="subfamilies/:idFa/:familyName/subsubfamilies/:idSub/:subFamilyName"
          element={<ManagementSubSubFamilies />}
        />
        <Route
          path="authoritiesbyrole/:roleId"
          element={<SettingAuthorityByRoleManagement />}
        />
        <Route path="inventory" element={<ManagementInventory />} />
        <Route path="uploadXml" element={<ManagementXml />} />
        <Route path="purchaseOrders" element={<ManagementPurchaseOrders />} />
        <Route path="projects" element={<ManagementProjects />} />
        <Route path="person" element={<ManagementPerson />} />
        <Route path="company" element={<ManagementCompany />} />
        <Route path="advances" element={<ManagementAdvances />} />
        <Route path="detailsAdvances/:id" element={<DetailsAdvances />} />
        <Route path="detailsAdvances" element={<DetailsAdvancesAdd />} />
        <Route
          path="inventoryControl"
          element={<ManagementInventoryControl />}
        />
        <Route path="holiday" element={<ManagementHoliday />} />
        <Route path="credits" element={<ManagementCreditsDebitsTickets />} />
        <Route path="areaType" element={<ManagementAreaType />} />
        <Route path="position" element={<ManagementPosition />} />
        <Route path="provider" element={<ManagementProvider />} />
        <Route
          path="consumption"
          element={<ManagementConsumptionPerProject />}
        />
        <Route
          path="consumptiondetail/:id/:nameC"
          element={<ManagementConsumptionDetail />}
        />
        <Route path="exitTicket" element={<ManagementExitTicket />} />
      </Route>
    </Route>
  )
);
