import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import UrlBase from "../../url/Urls";

export const getRequestWarehouse = async (data: any) => {
    let responseData = {};
    const requestBody = {
      filter: data.filter,
      page: data.page,
      size: data.size ?? UrlBase.registration_x_page,
    };
  
    await privateFetch
      .post(URL.REQUEST_WAREHOUSE_LIST, JSON.stringify(requestBody), {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      })
      .then(async (response) => {
        responseData = await response.data;
      })
      .catch(async (error) => {
        throw new Error("Error en consulta de Ubicaciones en bodega");
      });
  
    return responseData;
  };
  
export const findByIdRequest = async (id: any) => {
    let responseData = {};
    await privateFetch
      .get(URL.REQUEST_AUTHORITY(id), {})
      .then(async (response) => {
        responseData = await response.data;
      })
      .catch(async (error) => {
        throw new Error("Error en consulta de Ubicación en bodega por ID");
      });
    return responseData;
  };

export const createRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    name: data.name,
  };

  await privateFetch
    .post(URL.REQUEST_WAREHOUSE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en proceso de guardar Ubicación en bodega");
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    name: data.name,
  };

  await privateFetch
    .put(URL.REQUEST_WAREHOUSE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en proceso de actualizar Ubicación en bodega");
    });
  return responseData;
};

export const deleteRequest = async (id: any) => {
    let responseData = {};
    const requestBody = { id };
  
    await privateFetch
      .delete(URL.REQUEST_WAREHOUSE_DELETE, {
        data: requestBody,
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      })
      .then(async (response) => {
        responseData = await response.data;
      })
      .catch((error) => {
        throw new Error("Error en proceso de eliminar Ubicación en bodega");
      });
  
    return responseData;
  };