import { DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import FancyDialogActions from "./FancyDialogActions";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DialogProps, requiredString } from "../../types/Utils";

interface AddOrUpdateAreaType {
  id: number;
  name: string;
}

const AddOrUpdateAreaType = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const defaultValues: AddOrUpdateAreaType = {
    id: data?.id ?? 0,
    name: data?.name ?? "",
  };

  const formSchemaValidation = Yup.object().shape({
    name: requiredString,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm<AddOrUpdateAreaType>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(formSchemaValidation),
    mode: "onChange",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar área" : "Actualizar área"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
          <Grid item xs={12}>
            <Controller
              name={"name"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Descripción"
                  type="text"
                  value={value}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("name")}
                  error={errors.name && Boolean(errors.name)}
                  helperText={errors.name && errors.name.message}
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdateAreaType;
