import { useEffect, useState } from "react";
import {
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  IconButton,
  Autocomplete,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  Tooltip,
  Switch,
  FormControlLabel,
  Button,
  Dialog,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import FancyDialogActions from "./FancyDialogActions";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";
import {
  DialogProps,
  requiredString,
  requiredEmail,
  requiredNumber,
} from "../../types/Utils";
import {
  supplierType,
  projectType,
  detailsType,
  AddOrUpdatePurchaseOrderType,
} from "../../services/purchaseOrders/PurchaseOrdersType";
import {
  getRequestPDFPurchaseOrdersReport,
  getRequestPDFPurchaseOrdersReportBase64,
  requestSendCancellationReportPurchaseOrder,
  requestSendNotificationReportPurchaseOrder,
} from "../../services/purchaseOrders/PurchaseOrderReport";
import { maxDateProjects } from "../../services/projects/ProjectService";
import {
  formatDateReverse,
  formatDateToNewDate,
} from "../../utils/UtilsFunctions";
import FancyDialogProductsPurchaseOrder from "../FancyDialogProductsPurchaseOrder";

interface purchaseOrderType extends DialogProps {
  productsData: any;
  projectsData: any;
  suppliersData: any;
  handleAddOrUpdate: any;
}

const AddOrUpdatePurchaseOrder = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  productsData,
  projectsData,
  suppliersData,
  handleAddOrUpdate,
}: purchaseOrderType) => {
  const theme = useTheme();
  const { setLoading, setErrorMsg, setSuccessMsg } = useApp();
  const [openModal, setOpenModal] = useState(false);
  const [base64Pdf, setBase64Pdf] = useState<string>("");

  const defaultValues: AddOrUpdatePurchaseOrderType = {
    id: data?.id ?? 0,
    orderNumber: data?.orderNumber ?? "",
    date: data?.date ? formatDateToNewDate(data.date) : new Date(),
    supplier: data?.supplier ?? {
      id: 0,
      name: "",
      legalIdNumber: "",
      email: "",
      emails: [],
    },
    project: data?.project ?? {
      id: 0,
      name: "",
      code: "",
    },
    deliveryLocation: data?.deliveryLocation ?? "",
    dayCount: data?.dayCount ?? 0,
    maxDeliveryDate: data?.maxDeliveryDate ?? new Date(),
    status: data?.status ?? "ABIERTA",
    credit: data?.credit ?? false,
    notes: data?.notes ?? "",
    details: data?.details ?? [
      {
        id: 0,
        quantity: 0,
        line: "",
        product: {
          id: 0,
          code: "",
          officialCode: "",
          description: "",
          color: "",
          cost: 0,
          measure: "Cubeta",
        },
        productMeasure: "",
        productColor: "",
        productCode: "",
      },
    ],

    monthName: data?.monthName ?? "",
    year: data?.year ?? 0,
    dayOfMonth: data?.dayOfMonth ?? 0,
  };

  const schemaSupplier = {
    id: Yup.number().required("Campo es requerido"),
    name: requiredString,
    legalIdNumber: Yup.string(),
    email: requiredEmail,
    emails: Yup.array().of(requiredEmail),
  };

  const schemaProject = {
    id: requiredNumber,
    name: requiredString,
    code: requiredString,
  };

  const schemaProduct = {
    id: requiredNumber,
    code: requiredString,
    officialCode: Yup.string().nullable(),
    description: requiredString,
    color: requiredString,
    measure: requiredString,
    cost: requiredNumber,
  };

  const formSchemaValidation = Yup.object().shape({
    id: Yup.string(),
    orderNumber: Yup.string(),
    date: requiredString,
    supplier: Yup.object()
      .shape(schemaSupplier)
      .typeError("Campo es requerido"),
    project: Yup.object().shape(schemaProject).typeError("Campo es requerido"),
    deliveryLocation: requiredString,
    dayCount: requiredNumber,
    maxDeliveryDate: requiredString,
    status: requiredString,
    credit: Yup.boolean(),
    notes: Yup.string(),
    details: Yup.array().of(
      Yup.object().shape({
        id: Yup.number(),
        quantity: requiredNumber
          .min(1, "Debe ser mayor a 0")
          .typeError("Campo es requerido"),
        line: Yup.string(),
        product: Yup.object()
          .shape(schemaProduct)
          .typeError("Campo es requerido")
          .required("Campo es requerido"),
        //daysQuantity: Yup.number(),
      })
    ),
    monthName: Yup.string(),
    year: Yup.string(),
    dayOfMonth: Yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    control,
    setValue,
    watch,
  } = useForm<AddOrUpdatePurchaseOrderType>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(formSchemaValidation),
    mode: "onChange",
  });
  const {
    fields: detailsField,
    append: appenddetails,
    remove: removedetails,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: "details", // unique name for your Field Array
  });
  const watch_supplier: supplierType = watch("supplier");
  const watch_project: projectType = watch("project");
  const watch_details: detailsType[] = watch("details");
  const watch_date: Date = watch("date");
  const watch_dayCount: number = watch("dayCount");
  const watch_status: string = watch("status");

  useEffect(() => {
    if (watch_supplier !== null && watch_supplier?.id !== 0) {
      setValue("supplierLegalIdNumber", watch_supplier?.legalIdNumber);
      setValue("supplierEmail", watch_supplier?.email);
    } else {
      setValue("supplierLegalIdNumber", "");
      setValue("supplierEmail", "");
    }
  }, [watch_supplier]);

  useEffect(() => {
    if (watch_project !== null && watch_project?.id !== 0) {
      setValue("projectCode", watch_project.code);
    } else {
      setValue("projectCode", "");
    }
  }, [watch_project]);

  const handleDetailsProducts = async (data: detailsType, index: number) => {
    try {
      if (data.product.id !== 0) {
        setValue(
          `details.${index}.productColor`,
          watch_details[index].product.color
        );
        setValue(
          `details.${index}.productCode`,
          watch_details[index].product.code
        );
        setValue(
          `details.${index}.productMeasure`,
          watch_details[index].product.measure
        );
      } else {
        setValue(`details.${index}.productColor`, "");
        setValue(`details.${index}.productCode`, "");
        setValue(`details.${index}.productMeasure`, "");
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (watch_details) {
      watch_details.map((item: detailsType, i: number) =>
        handleDetailsProducts(item, i)
      );
    }
  }, [watch_details]);

  const handleCalculateMaxDate = async (date: any, dayCount: number) => {
    const formaData = {
      date: formatDateReverse(new Date(date)),
      dayCount: dayCount,
      dayType: "BUSINESS_DAY",
    };
    try {
      if (dayCount >= 0) {
        const calculate = await maxDateProjects(formaData);
        if (calculate) {
          const date = new Date(formatDateToNewDate(calculate));
          setValue("maxDeliveryDate", date);
        }
      }
    } catch (error: any) {
      setErrorMsg(error);
    }
  };

  useEffect(() => {
    handleCalculateMaxDate(watch_date, watch_dayCount);
  }, []);

  const handleDownloadPDFPurchaseOrder = async (id: string) => {
    setLoading(true);
    try {
      await getRequestPDFPurchaseOrdersReport(id);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error.message);
      console.log(error);
    }
  };
  const handlePreviewPDFPurchaseOrder = async (id: string) => {
    setLoading(true);
    try {
      let data = await getRequestPDFPurchaseOrdersReportBase64(id);
      setBase64Pdf(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error.message);
    }
    setOpenModal(true);
  };

  const handleSendNotification = async (id: string) => {
    setLoading(true);
    try {
      let notification = await requestSendNotificationReportPurchaseOrder(id);
      if (notification.status === 200) {
        setSuccessMsg("Notificación de orden de compra enviada");
        defaultValues.status = "ENVIADA";
        await onSubmit(defaultValues);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const handleSendCancellation = async (id: string) => {
    setLoading(true);
    try {
      let notification = await requestSendCancellationReportPurchaseOrder(id);
      if (notification.status === 200) {
        setSuccessMsg("Notificación de anulación de orden de compra enviada");
        defaultValues.status = "ANULADA";
        await onSubmit(defaultValues);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create"
          ? "Agregar orden de compra"
          : "Actualizar orden de compra"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name={"orderNumber"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="N° de orden"
                  type="text"
                  value={value}
                  disabled
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("orderNumber")}
                  error={errors.orderNumber && Boolean(errors.orderNumber)}
                  helperText={errors.orderNumber && errors.orderNumber.message}
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name={"date"}
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControl
                  error={Boolean(errors.date)}
                  sx={{ width: "100%" }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es"
                  >
                    <DatePicker
                      label="Fecha"
                      value={dayjs(value)}
                      onChange={(date) => {
                        onChange(date);
                        handleCalculateMaxDate(date, watch_dayCount);
                      }}
                      format="DD-MM-YYYY"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          backgroundColor: theme.palette.primary.main,
                        },
                        "& input": {
                          borderRadius: "10px 0 0 10px",
                        },
                        "& button": {
                          paddingLeft: "0",
                          "& svg": {
                            fill: "#FFF",
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={"status"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={Boolean(errors.status)}
                >
                  <InputLabel shrink={true}>Estado</InputLabel>
                  <Select
                    notched
                    value={value}
                    onChange={onChange}
                    label="Estado"
                    sx={{ "& input": { pl: "0!important" } }}
                    defaultValue="ABIERTA"
                  >
                    <MenuItem value="ABIERTA">Abierto</MenuItem>
                    <MenuItem value="ENVIADA">Enviado</MenuItem>
                    <MenuItem value="CERRADA">Cerrado</MenuItem>
                    <MenuItem value="ANULADA">Anulada</MenuItem>
                  </Select>
                  {errors.status && (
                    <span style={{ color: "red", fontSize: "0.8rem" }}>
                      {errors.status?.message}
                    </span>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={"credit"}
              control={control}
              render={({ field: { value } }) => (
                <FormControlLabel
                  checked={value}
                  control={<Switch {...register("credit")} color="primary" />}
                  label="¿Crédito?"
                  labelPlacement="end"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name={"supplier"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={suppliersData ?? []}
                  getOptionLabel={(option: any) => option.name}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Proveedor"
                        variant="outlined"
                        error={!!errors.supplier}
                        helperText={
                          errors.supplier && errors.supplier.name?.message
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name={"supplierLegalIdNumber"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Cédula Jurídica"
                  type="text"
                  disabled
                  value={value}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("supplierLegalIdNumber")}
                  error={
                    errors.supplierLegalIdNumber &&
                    Boolean(errors.supplierLegalIdNumber)
                  }
                  helperText={
                    errors.supplierLegalIdNumber &&
                    errors.supplierLegalIdNumber.message
                  }
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name={"supplierEmail"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Email"
                  type="text"
                  disabled
                  value={value}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("supplierEmail")}
                  error={errors.supplierEmail && Boolean(errors.supplierEmail)}
                  helperText={
                    errors.supplierEmail && errors.supplierEmail.message
                  }
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name={"project"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={projectsData ?? []}
                  getOptionLabel={(option: any) => option.name || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Proyecto"
                        variant="outlined"
                        error={!!errors.project}
                        helperText={
                          errors.project && errors.project.name?.message
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name={"projectCode"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Código del proyecto"
                  type="text"
                  disabled
                  value={value}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("projectCode")}
                  error={errors.projectCode && Boolean(errors.projectCode)}
                  helperText={errors.projectCode && errors.projectCode.message}
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={"deliveryLocation"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Lugar de entrega"
                  type="text"
                  value={value}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("deliveryLocation")}
                  error={
                    errors.deliveryLocation && Boolean(errors.deliveryLocation)
                  }
                  helperText={
                    errors.deliveryLocation && errors.deliveryLocation.message
                  }
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name={"dayCount"}
              control={control}
              render={({ field: { value, onChange } }) => (
                <NumericFormat
                  customInput={TextField}
                  fullWidth
                  decimalScale={2}
                  size="small"
                  label="Cantidad de días"
                  value={value}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    onChange(floatValue || 0);
                    handleCalculateMaxDate(watch_date, floatValue ?? 0);
                  }}
                  error={Boolean(errors.dayCount)}
                  helperText={errors.dayCount?.message}
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                    min: 0,
                    max: 9999999999,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name={"maxDeliveryDate"}
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControl
                  error={Boolean(errors.maxDeliveryDate)}
                  sx={{ width: "100%" }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es"
                  >
                    <DatePicker
                      label="Fecha máxima de entrega"
                      value={dayjs(value)}
                      disabled
                      onChange={(date) => {
                        onChange(date);
                      }}
                      format="DD-MM-YYYY"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          backgroundColor: theme.palette.primary.main,
                        },
                        "& input": {
                          borderRadius: "10px 0 0 10px",
                        },
                        "& button": {
                          paddingLeft: "0",
                          "& svg": {
                            fill: "#FFF",
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ mt: 1, mb: 3 }} />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          {detailsField.map((item, index) => (
            <>
              <Grid item xs={12} sm={6} md={2}>
                <Controller
                  name={`details.${index}.productCode`}
                  control={control}
                  render={({ field: { value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Código"
                      value={value}
                      variant="outlined"
                      disabled
                      {...register(`details.${index}.productCode`)}
                      sx={{ background: "#FFF" }}
                      error={Boolean(errors.details?.[index]?.productCode)}
                      helperText={errors.details?.[index]?.productCode?.message}
                      inputProps={{
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={1}>
                <Controller
                  name={`details.${index}.quantity`}
                  control={control}
                  render={({ field: { value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Cantidad"
                      value={value}
                      variant="outlined"
                      {...register(`details.${index}.quantity` as const)}
                      sx={{ background: "#FFF" }}
                      error={Boolean(errors.details?.[index]?.quantity)}
                      helperText={errors.details?.[index]?.quantity?.message}
                      inputProps={{
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name={`details.${index}.product`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={async (event, newValue: any) => {
                        onChange(newValue);
                        handleDetailsProducts(watch_details[index], index);
                      }}
                      options={productsData ?? []}
                      getOptionLabel={(option: any) =>
                        option.code && option.description
                          ? `${option.code} - ${option.description}`
                          : ""
                      }
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Productos"
                            variant="outlined"
                            error={!!errors.details?.[index]?.product}
                            helperText={
                              errors.details?.[index]?.product &&
                              errors.details?.[index]?.product?.message
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Controller
                  name={`details.${index}.productMeasure`}
                  control={control}
                  render={({ field: { value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Unidad"
                      value={value}
                      variant="outlined"
                      disabled
                      {...register(`details.${index}.productMeasure`)}
                      sx={{ background: "#FFF" }}
                      error={Boolean(errors.details?.[index]?.productMeasure)}
                      helperText={
                        errors.details?.[index]?.productMeasure?.message
                      }
                      inputProps={{
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Controller
                  name={`details.${index}.productColor`}
                  control={control}
                  render={({ field: { value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Color"
                      value={value}
                      variant="outlined"
                      disabled
                      {...register(`details.${index}.productColor`)}
                      sx={{ background: "#FFF" }}
                      error={Boolean(errors.details?.[index]?.productColor)}
                      helperText={
                        errors.details?.[index]?.productColor?.message
                      }
                      inputProps={{
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                sx={{ display: "flex", alignItems: "flex-start" }}
              >
                <Controller
                  name={`details.${index}.line`}
                  control={control}
                  render={({ field: { value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Línea"
                      value={value}
                      variant="outlined"
                      //disabled
                      {...register(`details.${index}.line`)}
                      sx={{ background: "#FFF" }}
                      error={Boolean(errors.details?.[index]?.line)}
                      helperText={errors.details?.[index]?.line?.message}
                      inputProps={{
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
                {index !== 0 && (
                  <Tooltip title="Eliminar producto">
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => {
                        removedetails(index);
                      }}
                    >
                      <RemoveCircleOutlineIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </>
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: {
              xs: "center",
              md: "flex-end",
            },
            paddingTop: "0!important",
          }}
        >
          <IconButton
            aria-label="add"
            onClick={() => {
              appenddetails({
                id: 0,
                quantity: 0,
                line: "",
                product: {
                  id: 0,
                  code: "",
                  officialCode: "",
                  description: "",
                  color: "",
                  cost: 0,
                  measure: "",
                },
                productColor: "",
                productCode: "",
              });
            }}
            sx={{ fontSize: "1rem", borderRadius: "25px" }}
          >
            <AddCircleOutlineIcon
              color="primary"
              fontSize="small"
              sx={{ mr: 1 }}
            />{" "}
            Agregar producto
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 2, mb: 6 }} />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"notes"}
            control={control}
            render={({ field: { value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Notas"
                type="text"
                multiline
                sx={{ background: "#FFF" }}
                rows={2}
                value={value}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("notes")}
                error={errors.notes && Boolean(errors.notes)}
                helperText={errors.notes && errors.notes.message}
                inputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            )}
          />
        </Grid>
        {data?.id && (
          <Grid
            item
            xs={12}
            sx={{
              mt: 3,
              mb: 3,
              textAlign: "right",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              type="button"
              onClick={() => handlePreviewPDFPurchaseOrder(data?.id)}
            >
              Ver PDF
            </Button>
          </Grid>
        )}
      </DialogContent>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
      {openModal && (
        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          maxWidth="lg"
          fullWidth
        >
          <FancyDialogProductsPurchaseOrder
            base64Pdf={base64Pdf}
            cancelModal={() => setOpenModal(false)}
            onSubmit={() => handleDownloadPDFPurchaseOrder(data?.id)}
            status={watch_status}
            handleSendNotification={() => handleSendNotification(data?.id)}
            handleSendCancellation={() => handleSendCancellation(data?.id)}
          />
        </Dialog>
      )}
    </>
  );
};

export default AddOrUpdatePurchaseOrder;
