import { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Stack,
  Dialog,
  useTheme,
} from "@mui/material";
import DeleteModal from "../components/utils/DeleteModal";
import FancyDialogActions from "../components/utils/FancyDialogActions";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import FancySearch from "../components/utils/FancySearch";
import FancyLightButton from "../components/FancyLightButton";
import { TrashIcon, PlusIcon, PenIcon } from "../components/svgicons/SvgIcons";
import { useForm } from "react-hook-form";
import FancyTooltip from "../components/utils/FancyTooltip";
import FancyPaper from "../components/FancyPaper";
import { useApp } from "../hooks/useApp";
import {
  getRequestPurchaseOrders,
  createPurchaseOrders,
  updatePurchaseOrders,
  deletePurchaseOrders,
} from "../services/purchaseOrders/PurchaseOrderService";
import { getRequestProductsInventory } from "../services/inventory/InventoryService";
import { getRequestProjects } from "../services/projects/ProjectService";
import { getRequestProvider } from "../services/provider/ProviderService";
import { formatDate, formatDateReverse } from "../utils/UtilsFunctions";
import {
  getRequestType,
  contentItemPurchaseOrder,
} from "../services/purchaseOrders/PurchaseOrdersType";
import AddOrUpdatePurchaseOrder from "../components/utils/AddOrUpdatePurchaseOrder";
import { properties } from "../utils/Properties_es";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

function getFechaHaceTresMeses() {
  const hoy = new Date();
  const mesActual = hoy.getMonth();
  const añoActual = hoy.getFullYear();

  // Calculamos el mes de hace 3 meses (restando 3, teniendo en cuenta que los meses empiezan en 0)
  const mesHaceTresMeses = mesActual - 3;

  // Ajustamos si el mes resultante es negativo (pasamos de año)
  let año = añoActual;
  let mes = mesHaceTresMeses;
  if (mes < 0) {
    año--;
    mes += 12;
  }

  // Creamos una nueva fecha con el primer día del mes calculado
  const fechaHaceTresMeses = new Date(año, mes, 1);

  return fechaHaceTresMeses;
}

const ManagementPurchaseOrders = () => {
  const theme = useTheme();
  const {
    authInfo,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
  } = useApp();
  const { handleSubmit } = useForm();
  const [buyOrders, setBuyOrders] = useState<getRequestType | null>(null);
  const [preFilterDates, setPreFilterDates] = useState<any>({
    startDate: dayjs(formatDateReverse(getFechaHaceTresMeses())),
    endDate: dayjs(formatDateReverse(new Date())),
  });
  const [preFilter, setPreFilter] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [productsData, setProductsData] = useState<any>(null);
  const [projectsData, setProjectsData] = useState<any>(null);
  const [suppliersData, setSuppliersData] = useState<any>(null);
  const [purchaseOrderStatus, setPurchaseOrderStatus] = useState<{
    id: string;
    title: string;
  }>({ id: "", title: "" });

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFecthBaseData();
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, preFilterDates, preFilter, purchaseOrderStatus);
    };
    dataInit();
    // eslint-disable-next-line
  }, [preFilterDates, purchaseOrderStatus]);

  const handleFetchData = async (
    currentPage: number,
    preFilterDates: any,
    filter: string,
    purchaseOrderStatus: {
      id: string;
      title: string;
    }
  ) => {
    try {
      setLoading(true);
      setPreFilter(filter);
      setPage(currentPage);

      let data: getRequestType | null = await getRequestPurchaseOrders(
        currentPage,
        preFilterDates,
        filter,
        purchaseOrderStatus?.id ?? ""
      );

      if (data) {
        setBuyOrders(data);
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error);
    }
  };

  const handleFecthBaseData = async () => {
    !isLoading && setLoading(true);
    try {
      let getProducts = await getRequestProductsInventory(0, "", 3000);
      if (getProducts) {
        setProductsData(getProducts.content);
      }

      let getProjects = await getRequestProjects(0, "", "", 2500);
      if (getProducts) {
        setProjectsData(getProjects.content);
      }

      let getSuppliers = await getRequestProvider(0, "", 1500);
      if (getProducts) {
        setSuppliersData(getSuppliers.content);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error);
    }
  };

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update") {
      object = buyOrders?.content.find((p: any) => p.id === parseInt(id));
    }

    if (modalAction === "delete") {
      object = buyOrders?.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * Evento de cierre de modal
   * @param event
   */
  const handleCancelModal = () => {
    //@ts-ignore

    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(
        customPage,
        preFilterDates,
        preFilter,
        purchaseOrderStatus
      );
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading(true);
    try {
      //call service
      let deleteData = await deletePurchaseOrders(id);
      if (!deleteData) {
        setErrorMsg("Error eliminando orden de compra");
        setLoading(false);
        return;
      }
      setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_delete);

      //reset page and call fetch data
      setPage(0);
      await handleFetchData(0, preFilterDates, preFilter, purchaseOrderStatus);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading(false);
    }
  };

  const formatDetails = async (dataArray: any) => {
    // Función para formatear una fecha individual
    return dataArray.map((item: any) => {
      const newItem = { ...item }; // Crear una copia para no modificar el original
      newItem.productId = newItem["product"].id;
      delete newItem["productCode"];
      delete newItem["productColor"];
      delete newItem["productMeasure"];
      delete newItem["product"];
      newItem["id"] === 0 && delete newItem["id"];
      return newItem;
    });
  };

  const handleAddOrUpdate = async (data: contentItemPurchaseOrder) => {
    handleCancelModal();
    setLoading(true);

    const arrayDetails = await formatDetails(data.details);
    const formaData: any = {
      id: Number(data.id),
      user: authInfo.username,
      date: formatDate(new Date(data.date)),
      deliveryLocation: data.deliveryLocation,
      dayCount: data.dayCount,
      status: data.status,
      credit: data.credit,
      notes: data.notes,
      details: arrayDetails,
      supplierId: data.supplier.id,
      projectId: data.project.id,
    };
    try {
      if (formaData.id === 0) {
        delete formaData.id;

        let createData = await createPurchaseOrders(formaData);
        if (!createData) {
          setErrorMsg("Error en proceso de guardar orden de compra");
          setLoading && setLoading(false);
          return;
        }
        setLoading(false);
        if (data.status === "ENVIADA") {
          setSuccessMsg("Notificación de orden de compra enviada");
        } else if (data.status === "ANULADA") {
          setSuccessMsg("Notificación de orden de compra anulada enviada");
        } else {
          setSuccessMsg(properties.com_parval_label_request_save);
        }

      } else {
        let updateData = await updatePurchaseOrders(formaData);
        if (!updateData) {
          setErrorMsg("Error en proceso de actualizar orden de compra");
          setLoading && setLoading(false);
          return;
        }
        setLoading(false);
        setSuccessMsg &&
          setSuccessMsg(properties.com_parval_label_request_update);
      }
      await handleFetchData(0, preFilterDates, preFilter, purchaseOrderStatus);
    } catch (error: any) {
      setErrorMsg(error);
      setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAddOrUpdate(data);
        break;
      case "update":
        await handleAddOrUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <FancyPaper pagetitle="Órdenes de compra">
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item xs={12}>
            {/* {<ResourceAccess isCode={true} pathOrCode={"PERSON:WRITE"}>} */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              data-name="create"
            >
              Agregar orden de compra
              <PlusIcon sx={{ ml: 1 }} />
            </Button>
            {/* {</ResourceAccess>} */}
          </Grid>
          <Grid
            item
            md={4}
            sm={5}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing={2}
              sx={{
                background: theme.palette.secondary.light,
                borderRadius: "10px",
                padding: "15px 25px",
                width: "fit-content",
                marginLeft: "auto",
              }}
            >
              <DatePicker
                label="Fecha de inicio"
                value={preFilterDates.startDate}
                onChange={(newValue) =>
                  setPreFilterDates({
                    ...preFilterDates,
                    startDate: newValue,
                  })
                }
                format="YYYY-MM-DD"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    backgroundColor: theme.palette.primary.main,
                  },
                  "& input": {
                    borderRadius: "10px 0 0 10px",
                  },
                  "& button": {
                    paddingLeft: "0",
                    "& svg": {
                      fill: "#FFF",
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "10px",
                  },
                }}
              />
              <DatePicker
                label="Fecha de fin"
                value={preFilterDates.endDate}
                onChange={(newValue) =>
                  setPreFilterDates({
                    ...preFilterDates,
                    endDate: newValue,
                  })
                }
                format="YYYY-MM-DD"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    backgroundColor: theme.palette.primary.main,
                  },
                  "& input": {
                    borderRadius: "10px 0 0 10px",
                  },
                  "& button": {
                    paddingLeft: "0",
                    "& svg": {
                      fill: "#FFF",
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "10px",
                  },
                }}
              />
            </Stack>
          </Grid>
          <Grid item md={8} sm={7} xs={12}>
            <FancySearch
              preFilter={preFilter}
              setPreFilter={setPreFilter}
              handleFetchData={() =>
                handleFetchData(0, preFilterDates, "", purchaseOrderStatus)
              }
              handleApplyFilter={() =>
                handleFetchData(
                  0,
                  preFilterDates,
                  preFilter,
                  purchaseOrderStatus
                )
              }
              setPurchaseOrderStatus={setPurchaseOrderStatus}
              purchaseOrderStatus={purchaseOrderStatus}
              showPurchaseOrderStatus={true}
            />
          </Grid>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Fecha</TableCell>
                <TableCell align="center" sx={{ width: "200px" }}>
                  N° OC
                </TableCell>
                <TableCell align="center">Proveedor</TableCell>
                <TableCell align="center">F. Máx. Entrega</TableCell>
                <TableCell align="center">N° Proyecto</TableCell>
                <TableCell align="center">Proyecto</TableCell>
                <TableCell align="center">Estado</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {buyOrders !== null && buyOrders?.content?.length > 0 ? (
                buyOrders?.content.map((item: contentItemPurchaseOrder) => (
                  <TableRow key={item.id}>
                    <TableCell align="center">{item.date}</TableCell>
                    <TableCell align="center">{item.orderNumber}</TableCell>
                    <TableCell align="center">{item.supplier?.name}</TableCell>
                    <TableCell align="center">{item.maxDeliveryDate}</TableCell>
                    <TableCell align="center">{item.project.code}</TableCell>
                    <TableCell align="center">{item.project.name}</TableCell>
                    <TableCell align="center">
                      {item.status === "ABIERTA" ? (
                        <FancyLightButton
                          backg="#1a932e61"
                          color="#1a932e!important"
                          text={item.status.toLowerCase()}
                        />
                      ) : item.status === "CERRADA" ? (
                        <FancyLightButton
                          backg="#e1c9315c"
                          color="#7d6c00!important"
                          text={item.status.toLowerCase()}
                        />
                      ) : item.status === "ANULADA" ? (
                        <FancyLightButton
                          backg="#80808061"  // Fondo gris claro
                          color="#808080!important"  // Texto gris
                          text={item.status.toLowerCase()}
                        />
                      ) : (
                        <FancyLightButton
                          backg="#e65f2b5e"
                          color="#E65F2B!important"
                          text={item.status.toLowerCase()}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <FancyTooltip title="Editar" placement="top">
                        <IconButton
                          aria-label="edit"
                          component="label"
                          color="primary"
                          onClick={handleOpenModal}
                          data-name="update"
                          data-id={item.id}
                        >
                          <PenIcon />
                        </IconButton>
                      </FancyTooltip>
                      <FancyTooltip title="Eliminar" placement="top">
                        <IconButton
                          aria-label="trash"
                          component="label"
                          color="error"
                          data-id={item.id}
                          onClick={handleOpenModal}
                          data-name="delete"
                        >
                          <TrashIcon />
                        </IconButton>
                      </FancyTooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>No existen registros</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            buyOrders !== null
              ? buyOrders?.content?.length > 0
                ? buyOrders?.content?.length
                : 0
              : 0
          }
          rowsPerPage={buyOrders !== null ? buyOrders?.size : 0}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={buyOrders?.totalElements}
          totalPages={buyOrders?.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
          <Dialog
            open={modalData.modalOpen}
            onClose={handleCancelModal}
            maxWidth="lg"
            fullWidth
          >
            <AddOrUpdatePurchaseOrder
              data={modalData?.modalObject}
              onSubmit={onSubmit}
              cancelModal={handleCancelModal}
              modalType={modalData?.modalType}
              productsData={productsData}
              projectsData={projectsData}
              suppliersData={suppliersData}
              handleAddOrUpdate={handleAddOrUpdate}
            />
          </Dialog>
        )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                <FancyDialogActions
                  handleAccept={handleSubmit(onSubmit)}
                  cancelModal={handleCancelModal}
                  textAcceptButton={"Eliminar"}
                />
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default ManagementPurchaseOrders;
