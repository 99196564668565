import {
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  TextField,
  IconButton,
  Typography,
  Autocomplete,
  Divider,
  Dialog,
  Modal,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DialogProps } from "../../types/Utils";
import FancyDialogActions from "./FancyDialogActions";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { getRequestTse } from "../../services/person/PersonService";
import { Fragment, useEffect, useState } from "react";
import FancyTooltip from "./FancyTooltip";
import { useApp } from "../../hooks/useApp";
import {
  deleteDocumentsPerson,
  getRequestDocumentsPerson,
  uploadDocumentsPerson,
  getRequestDocumentsBase64Person,
} from "../../services/documents/DocumentService";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { NumericFormat } from "react-number-format";
import PdfPreview from "../FancyPDFPreview";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Position = {
  id: number | null;
  name: string;
  salaryPerHour: number;
};

interface AddOrUpdatePersonTypes {
  inventoryImage?: File[];
  inventoryDocuments?: File[];
  id?: number | null;
  dni: string;
  name: string;
  lastName: string;
  phone_1: string;
  phone_2: string;
  email: string;
  birth_date: Date | null;
  number_ccss: string;
  calculated_age: number;
  marital_status: {
    id: string;
    name: string;
  };
  nationality: {
    id: number;
    name: string;
  };
  positions: Position[];

  entry_date: Date | null;
  id_expiration: Date;
  domicile: string;
  iban_account: string;
  account_type: {
    id: number;
    name: string;
  };
  medical_conditions: string;
  beneficiary: string;
  emergency_contact: string;
  state: {
    id: number;
    name: string;
  };
  bank: {
    id: string;
    name: string;
  };
  usersSystemData?: any;
  notes: string;
  expiration_document_date: Date;
}

const AddOrUpdatePersonModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  countries,
  banks,
  position,
  usersSystemData,
}: DialogProps) => {
  const { authInfo, setLoading, setErrorMsg } = useApp();

  const validationaccounttypeandstate = {
    id: Yup.number().required("Campo es requerido"),
    name: Yup.string().required("Campo es requerido"),
  };
  const validationmartialstatus = {
    id: Yup.string().required("Campo es requerido"),
    name: Yup.string().required("Campo es requerido"),
  };
  const validation = Yup.object().shape({
    dni: Yup.string().required("Campo es requerido"),
    name: Yup.string().required("Campo es requerido"),
    lastName: Yup.string().required("Campo es requerido"),
    phone_1: Yup.string().required("Campo es requerido"),
    phone_2: Yup.string(),
    email: Yup.string().email("Correo inválido").required("Campo es requerido"),
    birth_date: Yup.date()
      .nullable()
      .required("La fecha de nacimiento es obligatoria")
      .typeError("Debes ingresar una fecha válida"),
    number_ccss: Yup.string().required("Campo es requerido"),
    marital_status: Yup.object()
      .shape(validationmartialstatus)
      .typeError("Campo es requerido"),
    nationality: Yup.object()
      .shape(validationaccounttypeandstate)
      .typeError("Campo es requerido"),
    positions: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().min(1, "Debe seleccionar una posición válida"),
        name: Yup.string().required("El nombre de la posición es obligatorio"),
        salaryPerHour: Yup.number()
          .min(1, "El salario debe ser mayor a ₡ 0.00")
          .required("El salario por hora es obligatorio"),
      })
    ),
    entry_date: Yup.date()
      .nullable()
      .required("La fecha de ingreso es obligatoria")
      .typeError("Debes ingresar una fecha válida"),
    id_expiration: Yup.date().required("Campo es requerido"),
    domicile: Yup.string().required("Campo es requerido"),
    iban_account: Yup.string().required("Campo es requerido"),
    account_type: Yup.object()
      .shape(validationaccounttypeandstate)
      .typeError("Campo es requerido"),
    medical_conditions: Yup.string(),
    beneficiary: Yup.string().required("Campo es requerido"),
    emergency_contact: Yup.string().required("Campo es requerido"),
    state: Yup.object()
      .shape(validationaccounttypeandstate)
      .typeError("Campo es requerido"),
    bank: Yup.object()
      .shape(validationaccounttypeandstate)
      .typeError("Campo es requerido"),
    notes: Yup.string(),
    expiration_document_date: Yup.date().required("Campo es requerido"),
  });

  const options = [
    { id: 1, name: "Cuenta de ahorros" },
    { id: 2, name: "Cuenta corriente" },
  ];

  const optionsState = [
    { id: 0, name: "Activo" },
    { id: 1, name: "Inactivo" },
    { id: 2, name: "Periodo de Prueba" },
  ];

  const lastName = data?.lastName ? data?.lastName : "";
  const surname = data?.surname ? data?.surname : "";

  const defaultValues: AddOrUpdatePersonTypes = {
    inventoryImage: [] as File[],
    inventoryDocuments: [] as File[],
    id: data?.id ?? null,
    dni: data?.dni ?? "",
    name: data?.name ?? "",
    lastName: lastName && surname ? lastName + " " + surname : lastName,
    phone_1: data?.phone1 ?? "",
    phone_2: data?.phone2 ?? "",
    email: data?.email ?? "",
    birth_date: data?.birthDate ?? null,
    calculated_age: data?.birthDate
      ? new Date().getFullYear() - new Date(data.birthDate).getFullYear()
      : 0,
    number_ccss: data?.numberCcss ?? "",
    marital_status: data?.maritalStatus
      ? { id: data.maritalStatus, name: data.maritalStatus }
      : data?.maritalStatus ?? { id: "", name: "" },
    nationality: data?.nationality
      ? countries.find((f: any) => f.name === data?.nationality) ?? {}
      : {},
    positions:
      data?.positions.length === 0 || data?.positions === undefined
        ? [{ id: null, name: "", salaryPerHour: 0 }]
        : data?.positions,
    entry_date: data?.entryDate ?? null,
    id_expiration: data?.idExpiration ?? new Date(),
    domicile: data?.domicile ?? "",
    iban_account: data?.ibanAccount ?? "",
    account_type: data?.accountType
      ? options.find((f: any) => f.id === data?.accountType) ??
        data?.accountType
      : { id: 0, name: "" },
    medical_conditions: data?.medicalConditions ?? "",
    beneficiary: data?.beneficiary ?? "",
    emergency_contact: data?.emergencyContact ?? "",
    state:
      data?.state !== undefined && data?.state !== null
        ? optionsState.find((f: any) => f.id === data.state) ?? {
            id: 0,
            name: "",
          }
        : { id: 0, name: "" },
    bank: data?.bank ? banks.find((f: any) => f.name === data?.bank) ?? {} : {},
    usersSystemData: data?.userId
      ? usersSystemData.find((f: any) => f.id === data?.userId) ?? {}
      : {},
    notes: data?.notes ?? "",
    expiration_document_date: data?.expiration_document_date ?? new Date(),
  };

  const addPosition = () => {
    setPositions((prev) => [...prev, { id: null, name: "", salaryPerHour: 0 }]);
  };

  const removePosition = (index: number) => {
    setPositions((prev) => prev.filter((_, i) => i !== index));
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useForm<AddOrUpdatePersonTypes>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const [dni, setDni] = useState("");
  const [dniFound, setDniFound] = useState(true);
  const watch_birth_date: any = watch("birth_date");
  //const watch_positions: any = watch("positions");
  const watch_positions = useWatch({ control, name: "positions" });
  const watch_bank = useWatch({ control, name: "bank" });

  console.log(watch_positions);
  console.log(watch_bank);

  const [file, setFile] = useState<any>([]); // Lista de imágenes
  const [activeIndex, setActiveIndex] = useState(0); // Índice de imagen activa
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [refreshImages, setRefreshImages] = useState(false);

  const [files, setFiles] = useState<any[]>([]); // Almacena los archivos subidos
  const [selectedFile, setSelectedFile] = useState<any>(null); // Archivo seleccionado para vista previa
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [refreshFiles, setRefreshFiles] = useState(false);
  const [numPages, setNumPages] = useState(null);

  const [positions, setPositions] = useState<Position[]>(
    defaultValues.positions
  );

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (dni.length > 0) {
        try {
          const response = await getRequestTse(parseInt(dni, 10));
          if (response) {
            setDniFound(true);
            setValue("name", response.name);
            setValue("lastName", response.lastName + " " + response.surname);
            setValue("id_expiration", response.expirationDocumentDate);
          } else {
            setDniFound(false);
            setValue("name", "");
            setValue("id_expiration", new Date());
          }
        } catch (error) {
          setDniFound(false);
          setValue("name", "");
          setValue("id_expiration", new Date());
        }
      }
    };

    const debounceFetch = setTimeout(fetchData, 1000);

    return () => clearTimeout(debounceFetch);
  }, [dni]);

  useEffect(() => {
    const birthDate = getValues("birth_date");
    if (birthDate) {
      const age = new Date().getFullYear() - new Date(birthDate).getFullYear();
      setValue("calculated_age", age);
    }
  }, [watch_birth_date]);

  const handleImageUpload = async (event: any) => {
    const newFile = event.target.files[0];
    if (newFile) {
      const imageUrl = URL.createObjectURL(newFile);
      //NO ELIMINAR

      if (modalType === "update") {
        try {
          const formData = new FormData();
          formData.append("personId", data.id);
          formData.append("file", newFile);
          formData.append("user", authInfo.username);
          formData.append("documentType", "1");
          await uploadDocumentsPerson(formData);
          setLoading && setLoading(false);
        } catch (error: any) {
          setErrorMsg && setErrorMsg(error.message);
          setLoading && setLoading(false);
        }
      }
      setFile((prevFiles: any) => [...prevFiles, imageUrl]);

      // Obtiene el valor actual de `inventoryImage` y le agrega la nueva imagen
      const currentImages = getValues("inventoryImage") || [];
      setValue("inventoryImage", [...currentImages, newFile]);
    }
    setRefreshImages((prev) => !prev);
    event.target.value = "";
  };

  const handleImageDelete = async () => {
    //NO ELIMINAR

    if (modalType === "update") {
      try {
        const imageToDelete = file[activeIndex];

        await deleteDocumentsPerson(imageToDelete.id, data.id);

        setFile((prevFiles: any) => {
          const updatedFiles = [...prevFiles];
          updatedFiles.splice(activeIndex, 1);
          return updatedFiles;
        });

        setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
      } catch (error) {
        console.error("Error al eliminar el documento:", error);
      }
    }

    setFile((prevFiles: any) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(activeIndex, 1);
      return updatedFiles;
    });
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    setRefreshImages((prev) => !prev);
  };

  const handleOpenImageModal = (img: any) => {
    setSelectedImage(img);
    setShowImageModal(true);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
  };

  // Manejo de carga de archivos
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newFile = event.target.files?.[0];
    if (newFile) {
      const fileUrl = URL.createObjectURL(newFile); // Vista previa local

      if (modalType === "update") {
        try {
          const formData = new FormData();
          formData.append("personId", data.id);
          formData.append("file", newFile);
          formData.append("user", authInfo.username);
          formData.append("documentType", "2");
          await uploadDocumentsPerson(formData); // Enviar archivo al servidor
        } catch (error) {
          console.error("Error al cargar el archivo:", error);
        }
      }

      setFiles((prev) => [...prev, { file: newFile, url: fileUrl }]);
      const currentImages = getValues("inventoryDocuments") || [];
      setValue("inventoryDocuments", [...currentImages, newFile]);
    }
    setRefreshFiles((prev) => !prev); // Refrescar la vista de archivos
    event.target.value = "";
  };

  // Manejo de eliminación de archivos
  const handleFileDelete = async (index: number) => {
    const fileToDelete: any = index;
    if (modalType === "update") {
      try {
        await deleteDocumentsPerson(fileToDelete.id, data.id); // Eliminar del servidor
      } catch (error) {
        console.error("Error al eliminar el archivo:", error);
      }
    }
    setFiles((prevFiles: any) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(activeIndex, 1);
      return updatedFiles;
    }); // Eliminar del estado local
    setRefreshFiles((prev) => !prev);
  };

  // Abrir modal de vista previa
  const handlePreviewFile = (file: any) => {
    const normalizedUrl = file.url.replace(/\\/g, "/");
    setSelectedFile({ ...file, url: normalizedUrl });
    setShowPreviewModal(true);
  };

  const handleClosePreviewModal = () => {
    setShowPreviewModal(false);
  };

  const fetchImages = async (productId: number) => {
    try {
      if (data?.id) {
        const response = await getRequestDocumentsPerson(productId, 1);
        setFile(response);
      }
      if (data?.id) {
        const response = await getRequestDocumentsPerson(productId, 2);
        const responseBase64 = await getRequestDocumentsBase64Person(
          productId,
          2
        );
        if (response && responseBase64) {
          //response.map((item:{id:number, url: string}) => item.id === 5)
          const combined = response.map(
            (item1: { id: number; url: string }) => {
              const item2 = responseBase64.find(
                (item2: { id: number; url: string }) => item2.id === item1.id
              );
              return {
                ...item1,
                ...(item2 ? { url64: item2.url } : {}), // Conditionally add url64
              };
            }
          );
          setFiles(combined);
        }
      }
    } catch (error) {
      console.error("Error al obtener las imágenes:", error);
    }
  };

  useEffect(() => {
    if (data?.id) {
      fetchImages(data.id);
    }
  }, [data?.id, refreshImages, refreshFiles]);

  useEffect(() => {}, [positions]);

  useEffect(() => {
    if (
      Array.isArray(watch_positions) &&
      !watch_positions.some(
        (position: any) => position.name === "Encargado de Proyecto"
      )
    ) {
      console.log("entra acá");
      setValue("usersSystemData", {}); // Usa null en vez de {}
    }
    console.log("ejecuta el useEffect");
  }, [watch_positions]);

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create"
          ? "Agregar colaborador"
          : "Actualizar colaborador"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item sm={3} xs={12}>
              <Grid container spacing={3} sx={{ pt: 4, pl: 2 }}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <FancyTooltip
                    title={file?.length > 0 ? "Ampliar imagen" : ""}
                    placement="top"
                  >
                    <Box
                      sx={{
                        width: "120px",
                        height: "120px",
                        border: "1px solid #ccc",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      <Carousel
                        index={activeIndex} // Muestra la imagen activa
                        onChange={(index: any) => setActiveIndex(index)}
                        interval={8000}
                        sx={{
                          width: "120px",
                          height: "120px",
                          "& .carousel-root": {
                            width: "120px",
                            height: "120px",
                          },
                        }}
                        indicators={false}
                      >
                        {file.map((img: any, index: any) => (
                          <Box
                            key={index}
                            onClick={() =>
                              handleOpenImageModal(img.url ? img.url : img)
                            }
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "120px",
                              height: "120px",
                            }}
                          >
                            <Box
                              component="img"
                              src={img.url ? img.url : img}
                              loading="lazy"
                              sx={{
                                maxHeight: "100%",
                                maxWidth: "100%",
                                width: "auto",
                                height: "auto",
                                objectFit: "contain",
                                objectPosition: "center",
                                transition: "max-height 0.3s ease",
                              }}
                              alt={`Imagen ${index + 1}`}
                            />
                          </Box>
                        ))}
                      </Carousel>
                    </Box>
                  </FancyTooltip>
                  <Box sx={{ display: "flex", flexDirection: "column", pl: 5 }}>
                    {/* Ícono para agregar imagen */}
                    <label
                      htmlFor="image-upload"
                      style={{ cursor: "pointer", marginBottom: 1 }}
                    >
                      <AddIcon sx={{ fontSize: 30, color: "green" }} />
                    </label>
                    <input
                      id="image-upload"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                      // disabled={file.length >= 1}
                    />

                    {/* Ícono para eliminar imagen */}
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={handleImageDelete} // Eliminar la imagen actual
                    >
                      <DeleteIcon sx={{ fontSize: 30, color: "red" }} />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={9} xs={12}>
              <Grid container spacing={4} sx={{ pt: 8 }}>
                <Grid item md={4} xs={12}>
                  <Controller
                    name="dni"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="DNI colaborador"
                        type="number"
                        value={value}
                        variant="outlined"
                        {...register("dni")}
                        error={errors.dni && Boolean(errors.dni)}
                        helperText={errors.dni && errors.dni.message}
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        onChange={(e) => {
                          onChange(e);
                          setDni(e.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Controller
                    name={"name"}
                    control={control}
                    render={({ field: { value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Nombre"
                        type="text"
                        value={value}
                        disabled={dniFound}
                        variant="outlined"
                        {...register("name")}
                        error={errors.name && Boolean(errors.name)}
                        helperText={errors.name && errors.name.message}
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Controller
                    name={"lastName"}
                    control={control}
                    render={({ field: { value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Apellidos"
                        type="text"
                        value={value}
                        disabled={dniFound}
                        variant="outlined"
                        {...register("lastName")}
                        error={errors.lastName && Boolean(errors.lastName)}
                        helperText={errors.lastName && errors.lastName.message}
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Controller
                    name={"email"}
                    control={control}
                    render={({ field: { value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Correo electrónico"
                        type="text"
                        value={value}
                        variant="outlined"
                        {...register("email")}
                        error={errors.email && Boolean(errors.email)}
                        helperText={errors.email && errors.email.message}
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name={"phone_1"}
                    control={control}
                    render={({ field: { value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Teléfono 1"
                        type="text"
                        value={value}
                        variant="outlined"
                        {...register("phone_1")}
                        error={errors.phone_1 && Boolean(errors.phone_1)}
                        helperText={errors.phone_1 && errors.phone_1.message}
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name={"phone_2"}
                    control={control}
                    render={({ field: { value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Teléfono 2"
                        type="text"
                        value={value}
                        variant="outlined"
                        {...register("phone_2")}
                        error={errors.phone_2 && Boolean(errors.phone_2)}
                        helperText={errors.phone_2 && errors.phone_2.message}
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 3 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"marital_status"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue || {});
                    }}
                    options={[
                      { id: "Soltero", name: "Soltero" },
                      { id: "Casado", name: "Casado" },
                      { id: "Divorciado", name: "Divorciado" },
                    ]}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Estado civil"
                          variant="outlined"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.marital_status}
                          helperText={
                            errors.marital_status &&
                            errors.marital_status.name?.message
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"birth_date"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    hiddenLabel
                    size="small"
                    label="Fecha de nacimiento"
                    type="date"
                    value={value}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register("birth_date")}
                    error={errors.birth_date && Boolean(errors.birth_date)}
                    helperText={errors.birth_date && errors.birth_date.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"id_expiration"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Fecha vencimiento de cédula"
                    type="date"
                    value={value}
                    disabled={dniFound}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register("id_expiration")}
                    error={
                      errors.id_expiration && Boolean(errors.id_expiration)
                    }
                    helperText={
                      errors.id_expiration && errors.id_expiration.message
                    }
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"calculated_age"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Edad"
                    type="number"
                    value={value || ""}
                    disabled
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"nationality"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue || {});
                    }}
                    options={countries !== null ? countries : {}}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Nacionalidad"
                          variant="outlined"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.nationality}
                          helperText={
                            errors.nationality &&
                            errors.nationality.name?.message
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"state"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue || {});
                    }}
                    options={[
                      { id: 0, name: "Activo" },
                      { id: 1, name: "Inactivo" },
                      { id: 2, name: "Periodo de Prueba" },
                    ]}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Estado"
                          variant="outlined"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.state}
                          helperText={
                            errors.state && errors.state.name?.message
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"domicile"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Domicilio"
                    type="text"
                    value={value}
                    variant="outlined"
                    {...register("domicile")}
                    error={errors.domicile && Boolean(errors.domicile)}
                    helperText={errors.domicile && errors.domicile.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 3 }} />
            </Grid>
            {watch_positions.some(
              (position: any) => position.name === "Encargado de Proyecto"
            ) && (
              <Grid item xs={12}>
                <Controller
                  name={"usersSystemData"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={usersSystemData !== null ? usersSystemData : {}}
                      getOptionLabel={(option: any) =>
                        `${option.firstName || ""} ${
                          option.lastName || ""
                        }`.trim()
                      }
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Usuario del sistema"
                            variant="outlined"
                            sx={{ "& input": { pl: "0!important" } }}
                            error={!!errors.marital_status}
                            helperText={
                              errors.marital_status &&
                              errors.marital_status.name?.message
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {/* Position and salary per hour */}
            {positions &&
              positions.map((p: Position, index: number) => (
                <Fragment key={index}>
                  <Grid item xs={12} md={5}>
                    <Controller
                      name={`positions.${index}.name`}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          onChange={(event, newValue) => {
                            const selectedPosition = newValue || {
                              id: null,
                              name: "",
                              salaryPerHour: 0,
                            };
                            setValue(
                              `positions.${index}.id`,
                              selectedPosition.id
                            );
                            onChange(selectedPosition.name);
                            // Actualizar el estado de las posiciones para mantener el `id` y `salaryPerHour`
                            setPositions((prev) =>
                              prev.map((pos, i) => {
                                return i === index
                                  ? {
                                      ...pos,
                                      id: selectedPosition.id,
                                      name: selectedPosition.name,
                                      salaryPerHour:
                                        selectedPosition.salaryPerHour,
                                    }
                                  : pos;
                              })
                            );
                          }}
                          options={
                            position &&
                            position.filter(
                              (p: Position) =>
                                !positions.some(
                                  (selected) => selected.id === p.id
                                ) || // Remover cargos ya asignados
                                positions[index]?.id === p.id // Permitir el cargo actualmente asignado
                            )
                          }
                          getOptionLabel={(option) => option.name || ""}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value?.id
                          }
                          value={
                            positions[index]?.id !== null
                              ? position.find(
                                  (p: Position) => p.id === positions[index]?.id
                                ) || null
                              : null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Cargo en la empresa"
                              variant="outlined"
                              error={!!errors.positions?.[index]?.name}
                              helperText={
                                errors.positions?.[index]?.name?.message
                              }
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Controller
                      name={`positions.${index}.salaryPerHour`}
                      control={control}
                      rules={{
                        required: "El salario es obligatorio",
                        min: {
                          value: 0.01,
                          message: "El salario debe ser mayor a ₡ 0.00",
                        },
                      }}
                      render={({ field: { value, onChange } }) => (
                        <NumericFormat
                          customInput={TextField}
                          fullWidth
                          size="small"
                          label="Precio por hora"
                          prefix="₡"
                          decimalScale={2}
                          fixedDecimalScale
                          thousandSeparator
                          value={positions[index]?.salaryPerHour || 0}
                          onValueChange={(values) => {
                            const { floatValue } = values;
                            onChange(floatValue || 0);

                            // Actualizar el estado de la posición con el nuevo salario
                            setPositions((prev) =>
                              prev.map((pos, i) =>
                                i === index
                                  ? {
                                      ...pos,
                                      salaryPerHour: floatValue || 0,
                                    }
                                  : pos
                              )
                            );
                          }}
                          variant="outlined"
                          error={!!errors.positions?.[index]?.salaryPerHour}
                          helperText={
                            errors.positions?.[index]?.salaryPerHour?.message ||
                            ""
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  {positions.length > 1 ? (
                    <Grid item xs={1}>
                      <IconButton
                        color="error"
                        onClick={() => removePosition(index)}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid item xs={1}></Grid>
                  )}
                </Fragment>
              ))}
            <Grid item xs={1}>
              <FancyTooltip title={"Agregar posición"}>
                <IconButton
                  color="error"
                  onClick={() => addPosition()}
                  disabled={data?.status === true}
                >
                  <AddCircleIcon />
                </IconButton>
              </FancyTooltip>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 3 }} />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name={"number_ccss"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="N° Seguro CCSS"
                    type="text"
                    value={value}
                    variant="outlined"
                    {...register("number_ccss")}
                    error={errors.number_ccss && Boolean(errors.number_ccss)}
                    helperText={
                      errors.number_ccss && errors.number_ccss.message
                    }
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name={"entry_date"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Fecha de ingreso"
                    type="date"
                    value={value}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register("entry_date")}
                    error={errors.entry_date && Boolean(errors.entry_date)}
                    helperText={errors.entry_date && errors.entry_date.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"bank"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue || {});
                    }}
                    options={banks !== null ? banks : {}}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Banco"
                          variant="outlined"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.bank}
                          helperText={
                            errors.bank?.name?.message
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"account_type"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue || {});
                    }}
                    options={[
                      { id: 1, name: "Cuenta de ahorros" },
                      { id: 2, name: "Cuenta corriente" },
                    ]}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de cuenta"
                          variant="outlined"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.account_type}
                          helperText={
                            errors.account_type &&
                            errors.account_type.name?.message
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"iban_account"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Cuenta IBAN"
                    type="text"
                    value={value}
                    variant="outlined"
                    {...register("iban_account")}
                    error={errors.iban_account && Boolean(errors.iban_account)}
                    helperText={
                      errors.iban_account && errors.iban_account.message
                    }
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 3 }} />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"medical_conditions"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Padecimientos médicos"
                    type="text"
                    value={value}
                    variant="outlined"
                    {...register("medical_conditions")}
                    error={
                      errors.medical_conditions &&
                      Boolean(errors.medical_conditions)
                    }
                    helperText={
                      errors.medical_conditions &&
                      errors.medical_conditions.message
                    }
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"beneficiary"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Beneficiario"
                    type="text"
                    value={value}
                    variant="outlined"
                    {...register("beneficiary")}
                    error={errors.beneficiary && Boolean(errors.beneficiary)}
                    helperText={
                      errors.beneficiary && errors.beneficiary.message
                    }
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name={"emergency_contact"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Contactos de emergencia"
                    type="text"
                    value={value}
                    variant="outlined"
                    {...register("emergency_contact")}
                    error={
                      errors.emergency_contact &&
                      Boolean(errors.emergency_contact)
                    }
                    helperText={
                      errors.emergency_contact &&
                      errors.emergency_contact.message
                    }
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                      min: 0,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"notes"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Notas"
                    multiline
                    maxRows={4}
                    value={value}
                    variant="outlined"
                    {...register("notes")}
                    sx={{ background: "#FFF" }}
                    error={errors.notes && Boolean(errors.notes)}
                    helperText={errors.notes && errors.notes.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <IconButton component="label" sx={{ cursor: "pointer" }}>
                  <NoteAddIcon color="primary" sx={{ fontSize: 24 }} />
                  <input type="file" hidden onChange={handleFileUpload} />
                </IconButton>
                <Typography variant="body2" sx={{ ml: 1, fontSize: "1rem" }}>
                  Subir archivo digital
                </Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                {files.map((file) => (
                  <Box
                    key={file.id}
                    sx={{ display: "flex", alignItems: "center", mb: 1 }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        flex: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {file.url.split("/").pop()}
                    </Typography>
                    <IconButton onClick={() => handlePreviewFile(file)}>
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton onClick={() => handleFileDelete(file)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>

              <Modal open={showPreviewModal} onClose={handleClosePreviewModal}>
                <Box
                  sx={{
                    p: 2,
                    background: "#fff",
                    maxWidth: 800,
                    mx: "auto",
                    mt: 4,
                  }}
                >
                  {selectedFile ? (
                    selectedFile?.file?.type === "application/pdf" ||
                    selectedFile.url.endsWith(".pdf") ? (
                      <>
                        {/* <Document
                          file={selectedFile.url || selectedFile.url}
                          //options={{ workerSrc: "/pdf.worker.min.js" }}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          {Array.from(new Array(numPages), (el, index) => (
                            <Page
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                            />
                          ))}
                        </Document> */}
                        <PdfPreview base64Pdf={selectedFile.url64} />
                      </>
                    ) : (
                      <img
                        src={selectedFile?.url}
                        alt="Vista previa"
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    )
                  ) : (
                    <Typography>
                      No se encontró archivo para previsualizar
                    </Typography>
                  )}
                </Box>
              </Modal>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Dialog open={showImageModal} onClose={handleCloseImageModal}>
        <DialogTitle>Imagen Ampliada</DialogTitle>
        <Dialog
          open={showImageModal}
          onClose={handleCloseImageModal}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle sx={{ fontSize: "1.5rem", textAlign: "center" }}>
            Imagen Ampliada
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            {file && file.length > 0 && (
              <Carousel
                index={activeIndex}
                onChange={(index: any) => setActiveIndex(index)}
                indicators={true}
                navButtonsAlwaysVisible={true}
                autoPlay={false}
                sx={{
                  width: "100%",
                  "& .carousel-root": {
                    width: "100%",
                  },
                }}
              >
                {file.map((img: any, index: any) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "40vh",
                    }}
                  >
                    <Box
                      component="img"
                      src={img.url ? img.url : img}
                      loading="lazy"
                      alt={`Imagen ${index + 1}`}
                      sx={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                    />
                  </Box>
                ))}
              </Carousel>
            )}
          </DialogContent>
        </Dialog>
      </Dialog>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdatePersonModal;
