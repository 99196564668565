import { DialogContent, DialogTitle } from "@mui/material";
import { TrashIcon } from "../svgicons/SvgIcons";

interface DialogProps {
  textTitle?: string;
  textChildren?: any;
  actionButton?: any;
  onSubmit?: any;
  cancelModal?: any;
}

const DeleteModal = ({
  textTitle,
  textChildren,
  actionButton,
  onSubmit,
  cancelModal,
}: DialogProps) => {
  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        <TrashIcon sx={{ fontSize: "1rem", width: 16, color: "#CC0000" }} />
        {"Eliminar registro"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, py: 8, mt: 8 }}>
        {"¿Estás seguro de eliminar el registro?"}{" "}
        <strong>{textChildren}</strong>
      </DialogContent>
      {actionButton}
    </>
  );
};

export default DeleteModal;
