import { useState, useEffect, useMemo, forwardRef, Fragment } from "react";
import {
  Link,
  LinkProps,
  useLocation,
  Outlet,
  useNavigate,
} from "react-router-dom";
import {
  Box,
  Drawer,
  Divider,
  IconButton,
  List,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  styled,
  CssBaseline,
  MenuItem,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import { MENUITEMS } from "../utils/Const";
import theme from "../theme/theme";
import { useApp } from "../hooks/useApp";
import Loading from "../components/Loading";
import { sideNavWidth, topBarHeight } from "../utils/Const";
import ResourceAccess from "../components/security/ResourceAccess";
import { ReactComponent as Logo } from "../resources/images/asap-blanco.svg";
import LogoMR from "../resources/images/imagen_logo_mrpintores.png";
import MessageManager from "../components/MessageManager";
import { CONSTANT } from "../utils/UrlContants";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const WrapperDiv = styled("div")(({ theme }) => ({
  background: theme.palette.primary.dark,
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 2px rgba(0, 0, 0, 0.1)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#fff",
    outline: "1px solid #FFF",
    borderRadius: "15px",
  },
}));

const IconHamburger = styled("i")(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: "1px",
  content: '""',
  cursor: "pointer",
  display: "block",
  height: "3px",
  transition: "background .1s ease-in-out,color .1s ease-in-out",
  width: "24px",
  position: "relative",
  "&:before,&:after": {
    background: theme.palette.primary.main,
    borderRadius: "1px",
    content: '""',
    cursor: "pointer",
    display: "block",
    height: "3px",
    transition: "background .1s ease-in-out,color .1s ease-in-out",
    position: "absolute",
  },
  "&:before": {
    top: "-7.5px",
    width: "24px",
  },
  "&:after": {
    bottom: "-7.5px",
    width: "16px",
  },
}));

const IconNestedLink = styled(ListItemIcon)(({ theme }) => ({
  color: "inherit",
  "&:before": {
    content: '"→"',
    display: "inline-block",
    left: "0",
    position: "relative",
    transform: "translateX(0px)",
    transition: "all 0.1s ease 0s",
  },
}));

const ListItemTextNested = styled(ListItemText)(({ theme }) => ({
  "& span": {
    fontSize: "90%",
  },
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  background: "#f4e8e0",
  flexGrow: 1,
  padding: 0,
  display: "flex",
  flexDirection: "column",
  width: `calc(100% - ${sideNavWidth}px)`,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("md")]: {
    marginLeft: `-${sideNavWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  boxShadow: "none",
  padding: "0",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("md")]: {
    ...(open && {
      width: `calc(100% - ${sideNavWidth}px)`,
      marginLeft: `${sideNavWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
}));

const Footer = styled("footer")(({ theme }) => ({
  borderTop: `1px solid #00000026`,
  padding: `${theme.spacing(2)} ${theme.spacing(3.5)}`,
}));

function ListItemLink(props: any) {
  const { to } = props;

  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<LinkProps, "to">>((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} color="greyDue.main" />
      )),
    [to]
  );

  return <ListItem {...props} component={to ? renderLink : "button"} />;
}

const filterByPermissions = (items: any[], permission: string): any[] => {
  const elements = items.filter((item) =>
    item.permissions.includes(permission)
  );
  elements.forEach((el, index) => {
    if (el.expand) {
      elements[index] = {
        ...el,
        children: filterByPermissions(el.children, permission),
      };
    }
  });
  return elements;
};

const DashboardLayout = () => {
  const {
    authInfo,
    authenticate,
    errorMsg,
    successMsg,
    logoutProvider,
    resetErrorMsg,
    resetSuccessMsg,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
  } = useApp();
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [errorNoMoreViewsMsg, setErrorNoMoreViewsMsg] = useState("");
  const user = {
    is_superuser: true,
    is_client: false,
    is_company: false,
  };

  const menuItems = getMenuItems();
  const [expanded, setExpanded] = useState<boolean[]>(
    new Array(menuItems.length).fill(false)
  );

  function getMenuItems() {
    let menuItems = [...MENUITEMS];
    if (user.is_superuser) {
      menuItems = filterByPermissions(menuItems, "is_superuser");
    }
    return menuItems;
  }

  const handleClick = (index: number, item: any) => {
    if (item.expand) {
      setExpanded(
        expanded.map((o, i) => {
          if (i === index) {
            return !o;
          }
          return o;
        })
      );
    }
  };

  const handleSelected = (item: any) => {
    return item.to === location.pathname;
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    //window.document.querySelector("html").style.borderWidth = '20px';
  };

  const drawer = (
    <WrapperDiv>
      <Toolbar
        sx={{
          justifyContent: "center",
        }}
      >
        <div>
          <img
            src={LogoMR}
            alt="Logo"
            style={{ width: "150px", height: "auto" }}
          />
        </div>
      </Toolbar>
      <List sx={{ pt: 2, px: 4, zIndex: "2" }}>
        {menuItems.map((item, index) => (
          <Fragment key={index}>
            {item.label === "Cerrar sesión" ? (
              <ListItemLink
                button
                onClick={logoutProvider}
                selected={handleSelected(item)}
                to={item.to}
                sx={{
                  padding: ".625rem 1.125rem .625rem 1.725rem",
                  mt: "10px",
                }}
              >
                <ListItemIcon
                  style={{
                    fontSize: ".8125rem",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  color={theme.palette.primary.light}
                />
              </ListItemLink>
            ) : (
              <>
                <ResourceAccess
                  isCode={true}
                  pathOrCode={`${item.permissions[1]}`}
                >
                  <ListItemLink
                    button
                    onClick={() => handleClick(index, item)}
                    selected={handleSelected(item)}
                    className={expanded[index] ? "expande_true" : ""}
                    to={item.to}
                    sx={{
                      padding: ".625rem 1.125rem .625rem 1.725rem",
                      mt: "10px",
                    }}
                  >
                    <ListItemIcon
                      style={{
                        fontSize: ".8125rem",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                    {item.expand ? (
                      expanded[index] ? (
                        <ExpandLess sx={{ color: "primary.light" }} />
                      ) : (
                        <ExpandMore sx={{ color: "primary.light" }} />
                      )
                    ) : null}
                  </ListItemLink>
                </ResourceAccess>

                {item.expand && (
                  <Collapse
                    in={expanded[index]}
                    timeout="auto"
                    unmountOnExit
                    sx={{
                      background: theme.palette.primary.light,
                      //backgroundColor: "rgba(204, 0, 0, 0.08)",
                      borderRadius: "0 0 22px 22px",
                      overflow: "hidden",
                    }}
                  >
                    <List component="div" disablePadding dense>
                      {item.children.map((child) => (
                        <Fragment key={child.label}>
                          <ResourceAccess
                            pathOrCode={`/${child.to}`}
                            isCode={false}
                          >
                            <ListItemLink
                              button
                              //key={child.label}
                              data-to={child.to}
                              data-location={location.pathname}
                              to={child.to}
                              selected={
                                "/dashboard/" + child.to === location.pathname
                              }
                              className="item-menu"
                              sx={{
                                borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
                                padding: ".625rem 1.5rem .625rem 2.35rem",
                              }}
                            >
                              <IconNestedLink
                                sx={{
                                  color: "#E65F2B",
                                }}
                              />
                              <ListItemTextNested
                                primary={child.label}
                                sx={{
                                  color: "#E65F2B",
                                }}
                              />
                            </ListItemLink>
                          </ResourceAccess>
                        </Fragment>
                      ))}
                    </List>
                  </Collapse>
                )}
              </>
            )}
          </Fragment>
        ))}
      </List>
    </WrapperDiv>
  );

  //validate auth
  if (!authenticate) {
    console.log(authenticate);
    console.log("navigate es");
    navigate("/", { replace: true });
  }

  const closeNewTab = async () => {
    window.opener = null;
    window.close();
    window.location.replace("https://www.google.com");
    setErrorNoMoreViewsMsg("");
  };

  useEffect(() => {
    setErrorNoMoreViewsMsg("");
    function messageListener(e: any) {
      const { data } = e;

      if (data === "ping") {
        setErrorMsg("No puedes abrir más de una página al mismo tiempo");
        bc.postMessage("pong");
      }

      if (data === "pong") {
        setErrorNoMoreViewsMsg(
          "No puedes abrir más de una página al mismo tiempo, esta ventana se cerrara"
        );
        setTimeout(() => {
          window.close();
        }, 1000);
        // Do something here to block the page and/or message the user
      }
    }

    const bc = new BroadcastChannel("single_tab");

    bc.postMessage("ping");

    bc.addEventListener("message", messageListener);

    return () => bc.removeEventListener("message", messageListener);
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      {errorNoMoreViewsMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorNoMoreViewsMsg}
          details=""
          callback={closeNewTab}
        />
      )}
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        <Box
          component="nav"
          sx={{
            width: { sm: sideNavWidth },
            flexShrink: { sm: 0 },
            background: theme.palette.primary.dark,
            overflow: "hidden",
          }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              borderRadius: 0,
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: sideNavWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="persistent"
            className="ana2"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                borderRadius: 0,
                boxSizing: "border-box",
                width: sideNavWidth,
                background: theme.palette.primary.dark,
                overflow: "hidden",
              },
            }}
            open={!mobileOpen}
          >
            {drawer}
          </Drawer>
        </Box>
        <Main open={!mobileOpen}>
          <Box sx={{ flex: "1 1", position: "relative" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                "&:hover": {
                  backgroundColor: "transparent",
                },
                position: "absolute",
                top: "19px",
                left: "35px",
              }}
            >
              <IconHamburger />
            </IconButton>
            <Outlet />
          </Box>
        </Main>
      </Box>
    </>
  );
};

export default DashboardLayout;
