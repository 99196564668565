import { useState, useEffect, useRef } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Dialog,
  Typography,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import {
  TrashIcon,
  PlusIcon,
  PenIcon
} from "../../components/svgicons/SvgIcons";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FancyTooltip from "../../components/utils/FancyTooltip";
import FancyPaper from "../../components/FancyPaper";
import { useForm } from "react-hook-form";
import DeleteModal from "../../components/utils/DeleteModal";
import ResourceAccess from "../../components/security/ResourceAccess";
import FancyTablePagination from "../../components/utils/FancyTablePagination";
import { useApp } from "../../hooks/useApp";
import AddOrUpdateInventory from "../../components/utils/AddOrUpdateInventory";
import FancyDialogActions from "../../components/utils/FancyDialogActions";
import { properties } from "../../utils/Properties_es";
import {
  createRequest,
  deleteRequest,
  getRequestProductsInventory,
  updateRequest,
  getRequestListFamily,
  getRequestListSubFamily,
  getRequestListSubSubFamily,
  getRequestWarehouse,
  getRequestTotal,
  uploadInventoryXml,
} from "../../services/inventory/InventoryService";
import FancySearch from "../../components/utils/FancySearch";
import { uploadDocuments } from "../../services/documents/DocumentService";
import dayjs from "dayjs";
import AddOrUpdateInventoryXml from "../../components/utils/AddOrUpdateInventoryXml";

const ManagementXml = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const [inventoryData, setInventoryData] = useState<any>([]);
  const [FamilyData, setFamilyData] = useState<any>([]);
  const [WarehouseData, setWarehouseData] = useState<any>([]);
  const [TotalPrice, setTotalPrice] = useState<any>([]);
  const [PriceSelected, setPriceSelected] = useState<number>(0);
  const [preFilter, setPreFilter] = useState<any>("");
  const [formData, setFormData] = useState<any>("");
  const [page, setPage] = useState(0);
  const { handleSubmit } = useForm();
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [warehouseFilter, setWarehouseFilter] = useState<string>("");
  const [quantityFilter, setQuantityFilter] = useState<number | null>(null);
  const [quantityOperator, setQuantityOperator] = useState<any>("");
  const [showFilters, setShowFilters] = useState(false);
  const [selectedProductMap, setSelectedProductMap] = useState<{ [key: number]: any }>({});
  const [detailLineProducts, setDetailLineProducts] = useState<any[]>([]);
  const [matchedProducts, setMatchedProducts] = useState<{ [key: string]: boolean }>({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const initForm = {
    user: "",
    id: 0,
    code: "",
    officialCode: "",
    serialCode: "",
    description: "",
    acquisitionDate: dayjs().format("YYYY-MM-DD"),
    availability: true,
    availableQuantity: 0,
    quantity: 0,
    brand: "",
    category: "",
    color: "",
    cost: 0,
    status: 0,
    warehouseLocation: "",
    notes: "",
    subSubFamily: {
      id: 0,
      name: "",
      code: "",
      description: "",
      subFamily: {
        id: 0,
        name: "",
        code: "",
        description: "",
        family: {
          id: 0,
          name: "",
          code: "",
          description: "",
        },
      },
    },
  };

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
      let Familydata = await getRequestListFamily("", 0, "200");
      setFamilyData(Familydata);
      let WherehouseList = await getRequestWarehouse();
      setWarehouseData(WherehouseList);
      let TotalPrice = await getRequestTotal();
      setTotalPrice(TotalPrice);
    };
    dataInit();
    // eslint-disable-next-line
  }, [warehouseFilter, statusFilter, quantityFilter, quantityOperator]);


  const handleFetchData = async (currentPage: number, filter: string) => {
    setLoading && setLoading(true);
    setPage(currentPage);

    try {
      let data = await getRequestProductsInventory(
        currentPage,
        showFilters ? "" : filter,
        2500
      );

      if (data) {
        setInventoryData(data);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchSubFamilyData = async (
    id: number,
    setSubfamily: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    try {
      let SubFamilydata = await getRequestListSubFamily(id, "", 0, "500");
      if (SubFamilydata && "content" in SubFamilydata) {
        setSubfamily(SubFamilydata.content as any[]);
      } else {
        setSubfamily([]);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchSubSubFamilyData = async (
    id: number,
    setSubSubfamily: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    try {
      let SubSubFamilydata = await getRequestListSubSubFamily(id, "", 0, "500");
      if (SubSubFamilydata && "content" in SubSubFamilydata) {
        setSubSubfamily(SubSubFamilydata.content as any[]);
      } else {
        setSubSubfamily([]);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData: any = await createRequest({
        ...data,
        user: authInfo?.username,
      });

      if (!createData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_inventory_create_error);
        setLoading && setLoading(false);
        return;
      }

      const idProduct = createData.id;

      if (!!data.inventoryImage.length) {
        const formData = new FormData();
        formData.append("productId", idProduct);
        for (let i = 0; i < data.inventoryImage.length; i++) {
          formData.append("file", data.inventoryImage[i]);
        }
        formData.append("user", authInfo.username);
        await uploadDocuments(formData);
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_inventory_create);
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let updateData = await updateRequest({
        ...data,
        user: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_inventory_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_inventory_update);

      await handleFetchData(page, preFilter);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData: any = await deleteRequest(id);
      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_inventory_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_inventory_delete);
      setPage(0);
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-code");

    if (modalAction === "update") {
      object = detailLineProducts.find((p: any) => p.code === id);
    }

    if (modalAction === "delete") {
      object = inventoryData.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * Evento de aplicar filtro de busqueda
   */
  const handleApplyFilter = async () => {
    await handleFetchData(0, preFilter);
  };

  /**
   * Evento de cierre de modal
   * @param event
   */
  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };

  const onSubmit = async (data: any) => {
    data.acquisitionDate = dayjs(data.acquisitionDate).format("YYYY-MM-DD");
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject.id);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== "delete") {
      setFormData(formData);
    }
  };

  const handleCheckboxChange = (isChecked: boolean, cost: number) => {
    setPriceSelected((prev) => (isChecked ? prev + cost : prev - cost));
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleOpenFileExplorer = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const [tableData, setTableData] = useState<any[]>([]);

  const handleCompleteInvoice = async () => {
    setLoading && setLoading(true);

    try {
      for (const product of detailLineProducts) {
        const selectedProductId = selectedProductMap[product.code];

        if (selectedProductId) {
          const existingProduct = inventoryData.content.find(
            (p: any) => p.id === selectedProductId
          );

          if (existingProduct) {
            await updateRequest({
              ...existingProduct,
              quantity: existingProduct.quantity + product.availableQuantity,
              availableQuantity: existingProduct.availableQuantity + product.availableQuantity,
              user: authInfo?.username,
            });
          }
        } else {
          await createRequest({
            ...product,
            user: authInfo?.username,
          });
        }
      }

      setSuccessMsg && setSuccessMsg("Factura completada exitosamente");

      await handleFetchData(0, "");

      setDetailLineProducts([]);
      setSelectedProductMap({});
    } catch (error: any) {

      setErrorMsg && setErrorMsg(error.message);
    } finally {
      setLoading && setLoading(false);
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setLoading && setLoading(true);

    try {
      const response = await uploadInventoryXml(file);

      setDetailLineProducts(response?.detaiLineProducts || []);

      // Verificar matches y actualizar matchedProducts
      const newMatchedProducts: { [key: string]: boolean } = {};

      response?.detaiLineProducts.forEach((product: any) => {
        const match = inventoryData.content.find(
          (p: any) => p.code === product.code
        );
        if (match) {
          newMatchedProducts[product.code] = true; // Hay un match
        }
      });

      setMatchedProducts(newMatchedProducts);

      if (response?.matchingProducts.length > 0) {
        const newSelectedProductMap: { [key: string]: any } = {};

        response?.matchingProducts.forEach((matchingProduct: any) => {
          const foundProduct = response.detaiLineProducts.find(
            (product: any) => product.code === matchingProduct.code
          );

          if (foundProduct) {
            newSelectedProductMap[foundProduct.code] = matchingProduct.id;
          }
        });

        setSelectedProductMap(newSelectedProductMap);
      }
    } catch (error) {
      console.error("Error al subir el archivo:", error);
    } finally {
      setLoading && setLoading(false);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleUpdateProductInTable = (updatedProduct: any) => {
    setDetailLineProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.code === updatedProduct.code ? updatedProduct : product
      )
    );
  };




  return (
    <>
      <FancyPaper pagetitle="Carga de facturas">
        <Grid container spacing={8} sx={{ pb: 8, alignItems: "center" }}>
          <input
            type="file"
            accept=".xml"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />

          {/* Botón para agregar factura */}
          <Grid item lg={7} md={7} sm={5} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={"XML:WRITE"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenFileExplorer}
              >
                Agregar factura
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>
          </Grid>
          <Grid item lg={5} md={5} sm={7} xs={12}>
          </Grid>
        </Grid>
        <TableContainer>
          <Table
            sx={{ minWidth: 650, textAlign: "center" }}
            aria-label="inventory table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>
                <TableCell align="center">Descripción</TableCell>
                <TableCell align="center">Cantidad</TableCell>
                <TableCell>Seleccionar Producto</TableCell>
                <ResourceAccess isCode={true} pathOrCode={"XML:WRITE"}>
                  <TableCell align="center">Acciones</TableCell>
                </ResourceAccess>
              </TableRow>
            </TableHead>
            <TableBody>
              {detailLineProducts.length > 0 ? (
                detailLineProducts.map((row: any) => (
                  <TableRow key={row.code}>
                    <TableCell>{row.code}</TableCell>
                    <TableCell align="center">{row.description}</TableCell>
                    <TableCell align="center">{row.availableQuantity}</TableCell>
                    <TableCell>
                      <Autocomplete
                        options={inventoryData.content}
                        getOptionLabel={(product) =>
                          `${product.description} (Código: ${product.code})`
                        }
                        value={
                          inventoryData.content.find(
                            (product: any) => product.id === selectedProductMap[row.code]
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          setSelectedProductMap((prev) => {
                            const newSelected = { ...prev };
                            if (newValue) {
                              newSelected[row.code] = newValue.id;
                            } else {
                              delete newSelected[row.code];
                            }
                            return newSelected;
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Seleccionar producto"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={matchedProducts[row.code]}
                          />
                        )}
                        disabled={matchedProducts[row.code]}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        color="primary"
                        checked={selectedProductMap[row.code] !== undefined}
                        disabled={true}
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          setSelectedProductMap((prev) => {
                            const newSelected = { ...prev };
                            if (isChecked) {
                              const foundProduct = inventoryData.content.find(
                                (product: any) => product.code === row.code
                              );
                              if (foundProduct) {
                                newSelected[row.code] = foundProduct.id;
                              }
                            } else {
                              delete newSelected[row.code];
                            }
                            return newSelected;
                          });
                        }}
                      />
                      <FancyTooltip title="Editar" placement="top">
                        <IconButton
                          aria-label="edit"
                          component="label"
                          color="primary"
                          onClick={handleOpenModal}
                          data-name="update"
                          data-code={row.code}
                          disabled={selectedProductMap[row.code] !== undefined}
                        >
                          <PenIcon />
                        </IconButton>
                      </FancyTooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>No existen registros</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {detailLineProducts.length > 0 && (
            <Grid container justifyContent="flex-end" sx={{ mt: 4 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenConfirmDialog(true)} 
              >
                Completar factura
              </Button>
            </Grid>
          )}

          <Dialog
            open={openConfirmDialog}
            onClose={() => setOpenConfirmDialog(false)} 
          >
            <DialogTitle>¿Estás seguro?</DialogTitle>
            <DialogContent>
              <Typography>
                ¿Deseas completar la factura? Esta acción no se puede revertir.
              </Typography>
            </DialogContent>
            <FancyDialogActions
              handleAccept={() => {
                handleCompleteInvoice(); 
                setOpenConfirmDialog(false); 
              }}
              cancelModal={() => setOpenConfirmDialog(false)} 
              textAcceptButton="Confirmar"
              textCancelButton="Cancelar"
            />
          </Dialog>
        </TableContainer>
        {/* <FancyTablePagination
          count={
            inventoryData?.content?.length > 0
              ? inventoryData?.content?.length
              : 0
          }
          rowsPerPage={inventoryData.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={inventoryData.totalElements}
          totalPages={inventoryData.totalPages}
        /> */}
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
          <Dialog
            open={modalData.modalOpen}
            onClose={handleCancelModal}
            maxWidth="md"
            fullWidth
          >
            <AddOrUpdateInventoryXml
              data={modalData?.modalObject}
              FamilyData={FamilyData}
              WarehouseData={WarehouseData}
              handleFetchSubFamilyData={handleFetchSubFamilyData}
              handleFetchSubSubFamilyData={handleFetchSubSubFamilyData}
              onSubmit={handleUpdateProductInTable}
              cancelModal={handleCancelModal}
              modalType={modalData?.modalType}
            />
          </Dialog>
        )}
    </>
  );
};

export default ManagementXml;
