import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GridViewIcon from "@mui/icons-material/GridView";
import GroupsIcon from "@mui/icons-material/Groups";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import { MenuItem } from "../types/Menu";

export const MENUITEMS: MenuItem[] = [
  {
    label: "Inicio",
    icon: <GridViewIcon />,
    to: "home",
    permissions: ["is_superuser", "HOME:READ"],
  },
  {
    label: "Inventario",
    icon: <ViewInArIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Productos",
        to: "inventory",
        permissions: ["is_superuser", "INVENTORY:READ"],
      },
      {
        label: "Historial de movimientos",
        to: "history",
        permissions: ["is_superuser", "HISTORY:READ"],
      },
      {
        label: "Boletas de salida / entrada",
        to: "exitTicket",
        permissions: ["is_superuser", "EXITTICKET:READ"],
      },
      {
        label: "Control de inventario",
        to: "inventoryControl",
        permissions: ["is_superuser", "INVENTORYCONTROL:READ"],
      },
      {
        label: "Familias",
        to: "families",
        permissions: ["is_superuser", "INVENTORY:READ"],
      },
      {
        label: "Ubicación en bodega",
        to: "location",
        permissions: ["is_superuser", "LOCATION:READ"],
      },
      {
        label: "Órdenes de compra",
        to: "purchaseOrders",
        permissions: ["is_superuser", "PURCHASEORDERS:READ"],
      },
      {
        label: "Carga de facturas",
        to: "uploadXml",
        permissions: ["is_superuser", "XML:READ"],
      },
      {
        label: "Proveedores",
        to: "provider",
        permissions: ["is_superuser", "PROVIDER:READ"],
      },
    ],
    permissions: ["is_superuser", "INVENTORYMENU:READ"],
  },
  {
    label: "Colaboradores",
    icon: <GroupsIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Personal",
        to: "person",
        permissions: ["is_superuser", "PERSON:READ"],
      },
      {
        label: "Bancos",
        to: "banks",
        permissions: ["is_superuser", "BANK:READ"],
      },
      {
        label: "Países",
        to: "countries",
        permissions: ["is_superuser", "COUNTRY:READ"],
      },
      {
        label: "Cargos",
        to: "position",
        permissions: ["is_superuser", "POSITION:READ"],
      },
    ],

    permissions: ["is_superuser", "COLLABORATORSMENU:READ"],
  },
  {
    label: "Proyectos",
    icon: <AllInboxIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Proyectos",
        to: "projects",
        permissions: ["is_superuser", "PROJECTS:READ"],
      },
      {
        label: "Consumo por proyecto",
        to: "consumption",
        permissions: ["is_superuser", "CONSUMPTION:READ"],
      },
      {
        label: "Tipo de áreas",
        to: "areaType",
        permissions: ["is_superuser", "AREATYPE:READ"],
      },
      {
        label: "Avances",
        to: "advances",
        permissions: ["is_superuser", "ADVANCES:READ"],
      },
      {
        label: "Créditos / Débitos / Pasajes",
        to: "credits",
        permissions: ["is_superuser", "CREDITS:READ"],
      },
      {
        label: "Días feriados",
        to: "holiday",
        permissions: ["is_superuser", "HOLIDAY:READ"],
      },
    ],

    permissions: ["is_superuser", "PROJECTSMENU:READ"],
  },
  {
    label: "Configuración",
    icon: (
      <SettingsIcon
        sx={{ transform: "translateY(-1px)", fontSize: "1.025rem" }}
      />
    ),
    to: null,
    expand: true,
    children: [
      {
        label: "Usuarios",
        to: "settinguser",
        permissions: ["is_superuser", "USER:READ"],
      },
      {
        label: "Perfiles",
        to: "settingroles",
        permissions: ["is_superuser", "ROLE:READ"],
      },
      {
        label: "Permisos",
        to: "settingauthority",
        permissions: ["is_superuser", "AUTHORITY:READ"],
      },
      {
        label: "Empresa",
        to: "company",
        permissions: ["is_superuser", "COMPANY:READ"],
      },
    ],
    permissions: ["is_superuser", "SETTINGSMENU:READ"],
  },
  {
    label: "Cerrar sesión",
    icon: <ExitToAppIcon />,
    to: null,

    permissions: ["is_superuser"],
  },
];

export const topBarHeight = 64;
export const sideNavWidth = 260;
