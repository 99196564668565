import {
  Checkbox,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DialogProps } from "../../types/Utils";
import FancyDialogActions from "./FancyDialogActions";
import { NumericFormat } from "react-number-format";

interface AddOrUpdateBank {
  id?: number;
  name: string;
  salaryPerHour: number;
  payrollExclude?: boolean;
}

const AddOrUpdatePositionModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  roleData,
}: DialogProps) => {
  // const { t } = useTranslation();
  const validation = Yup.object().shape({
    name: Yup.string().required("Campo es requerido"),
    salaryPerHour: Yup.number()
      .min(1, "El salario debe ser mayor a ₡ 0.00")
      .required("El salario por hora es obligatorio"),
  });

  const defaultValues = {
    id: data?.id ?? 0,
    name: data?.name ?? "",
    salaryPerHour: data?.salaryPerHour ?? 0,
    payrollExclude: data?.payrollExclude ?? false,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateBank>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  console.log(data);

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar cargo" : "Actualizar cargo"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={6}>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={"Nombre"}
                    type="text"
                    variant="outlined"
                    value={value}
                    disabled={data?.id === 12 || data?.id === 25}
                    {...register("name")}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={"salaryPerHour"}
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    customInput={TextField}
                    fullWidth
                    size="small"
                    label="Precio por hora"
                    prefix="₡"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    value={field.value}
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      field.onChange(floatValue);
                    }}
                    error={Boolean(errors.salaryPerHour)}
                    helperText={errors.salaryPerHour?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="payrollExclude"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox {...field} checked={Boolean(field.value)} />
                    }
                    label="Excluir en nómina"
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdatePositionModal;
