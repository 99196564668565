import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  Grid,
  useMediaQuery,
  Typography,
  Paper,
  Box,
  Stack,
  Dialog,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ResourceAccess from "../../components/security/ResourceAccess";
import {
  PenIcon,
  PlusIcon,
  TrashIcon,
} from "../../components/svgicons/SvgIcons";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import FancyTooltip from "../../components/utils/FancyTooltip";
import FancyPaper from "../../components/FancyPaper";
import FancySearch from "../../components/utils/FancySearch";
import FakeDataAdvances from "../fakeData/FakeDataAdvances";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { useApp } from "../../hooks/useApp";
import {
  deleteCompleteAdvanceRequest,
  getRequestAdvanceCSSReport,
  getRequestAdvanceMonthReport,
  getRequestAdvances,
} from "../../services/advances/advancesService";
import FancyTablePagination from "../../components/utils/FancyTablePagination";
import DeleteModal from "../../components/utils/DeleteModal";
import FancyDialogActions from "../../components/utils/FancyDialogActions";
import { useForm } from "react-hook-form";

const ManagementAdvances = () => {
  const {
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    authInfo,
  } = useApp();
  const { handleSubmit } = useForm();
  const theme = useTheme();
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const [data, setData] = useState<any>(null);
  const [preFilter, setPreFilter] = useState("");
  const [page, setPage] = useState<number>(0);

  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());

  useEffect(() => {
    if (startDate && endDate) {
      const storedStartDate = localStorage.getItem("startDate");
      const storedEndDate = localStorage.getItem("endDate");

      if (storedStartDate && storedEndDate) {
        setStartDate(dayjs(storedStartDate));
        setEndDate(dayjs(storedEndDate));
      } else {
        handleFetchData(page, preFilter);
      }
    }
  }, []);

  const handleFetchData = async (currentPage: number, filter: string) => {
    try {
      setLoading(true);
      setPreFilter(filter);
      setPage(currentPage);

      const fetchStartDate = startDate;
      const fetchEndDate = endDate;

      const data = await getRequestAdvances(
        currentPage,
        10,
        fetchStartDate?.format("DD-MM-YYYY"),
        fetchEndDate?.format("DD-MM-YYYY"),
        filter,
        authInfo?.id
      );

      if (data) {
        setData(data);
      }

      localStorage.removeItem("startDate");
      localStorage.removeItem("endDate");
    } catch (error: any) {
      setErrorMsg(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      handleFetchData(page, preFilter);
    }
  }, [startDate, endDate]);


  const handleApplyFilter = async () => {
    await handleFetchData(0, preFilter);
  };

  const navigateToDetails = (event: React.MouseEvent<HTMLButtonElement>) => {
    const id = event.currentTarget.dataset.id;
    const managerId = authInfo?.id;
    navigate(`/dashboard/detailsAdvances`, {
      state: {
        managerId,
        id,
        startDate: startDate?.format("YYYY-MM-DD"),
        endDate: endDate?.format("YYYY-MM-DD"),
      },
    });
  };

  const navigateToAddDetails = (event: React.MouseEvent<HTMLButtonElement>) => {
    const managerId = authInfo?.id;
    navigate(`/dashboard/detailsAdvances`, {
      state: {
        managerId,
        startDate: startDate?.format("YYYY-MM-DD"),
        endDate: endDate?.format("YYYY-MM-DD"),
      },
    });
  };


  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteCompleteAdvanceRequest(id);
      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg("Error al eliminar el registro, intente nuevamente");
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg("Registro eliminado correctamente");
      //reset page and call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    const customPage = newPage - 1;
    setPage(customPage);
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };
  console.log(data?.content[0]?.managerId);

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "delete") {
      object = data.content.find((p: any) => p.id === parseInt(id));
      //object = FakeData.content.find((p: any) => p.id === parseInt(id));
    }
    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const exportToExcel = async (id: any) => {
    //getRequestOrdersExcel
    setLoading && setLoading(true);
    try {
      let data = await getRequestAdvanceCSSReport(id);
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleExport = async () => {
    setLoading && setLoading(true);
    try {
      const year = startDate?.format("YYYY");
      const month = startDate?.format("MM");

      await getRequestAdvanceMonthReport(month, year);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    } finally {
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg("Reporte mensual CCSS generado correctamente");
    }
  };

  return (
    <>
      <FancyPaper
        pagetitle="Avances"
        showHome={authInfo?.userRole?.roleName === "Encargado" ? false : true}
      >
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={8} sm={12} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={"ADVANCES:WRITE"}>
              <Button
                variant="contained"
                color="primary"
                onClick={navigateToAddDetails}
                data-name="create"
                data-manager={data?.content[0]?.managerId}
              >
                Agregar avance
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>
          </Grid>
        </Grid>

        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={12} sm={12} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={2}
              >
                <DatePicker
                  label="Fecha de inicio"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  format="YYYY-MM-DD"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      backgroundColor: theme.palette.primary.main,
                    },
                    "& input": {
                      borderRadius: "10px 0 0 10px",
                    },
                    "& button": {
                      paddingLeft: "0",
                      "& svg": {
                        fill: "#FFF",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "10px",
                    },
                  }}
                />
                <DatePicker
                  label="Fecha de fin"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  format="YYYY-MM-DD"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      backgroundColor: theme.palette.primary.main,
                    },
                    "& input": {
                      borderRadius: "10px 0 0 10px",
                    },
                    "& button": {
                      paddingLeft: "0",
                      "& svg": {
                        fill: "#FFF",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
                sx={{
                  flexDirection: { xs: "column", sm: "row" },
                  width: "100%",
                  "& > *": {
                    width: { xs: "100%", sm: "auto" },
                  },
                }}
              >
                <ResourceAccess
                  isCode={true}
                  pathOrCode={"ADVANCES:READCOLUMNS"}
                >
                  <Button variant="contained" onClick={handleExport}>
                    Exportar
                  </Button>
                </ResourceAccess>
                <FancySearch
                  preFilter={preFilter}
                  setPreFilter={setPreFilter}
                  handleFetchData={() => handleFetchData(0, "")}
                  handleApplyFilter={handleApplyFilter}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        {isSmallScreen && (
          <Grid container spacing={2}>
            {data &&
              data?.content &&
              data?.content.map((row: any) => (
                <Grid item xs={12} key={row.id}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Typography variant="h6">{row.project}</Typography>
                    <Typography variant="body2">Año: {row.year}</Typography>
                    <Typography variant="body2">Mes: {row.month}</Typography>
                    <Typography variant="body2">Semana: {row.week}</Typography>
                    <Typography variant="body2">Código: {row.code}</Typography>
                    <Typography variant="body2">
                      Encargado: {row.manager}
                    </Typography>
                    <Typography variant="body2">
                      Estado: {row.status}
                    </Typography>
                    <Box mt={1} display="flex" justifyContent="flex-end">
                      <ResourceAccess
                        isCode={true}
                        pathOrCode={"ADVANCES:WRITE"}
                      >
                        <FancyTooltip title="Editar" placement="top">
                          <IconButton
                            aria-label="edit"
                            color="primary"
                            data-id={row.id}
                            size="small"
                            onClick={navigateToAddDetails}
                          >
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title="Eliminar" placement="top">
                          <IconButton
                            aria-label="trash"
                            color="error"
                            data-id={row.id}
                            data-name="delete"
                            size="small"
                            onClick={handleDelete}
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      </ResourceAccess>
                    </Box>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        )}

        {isMediumScreen && (
          <Grid container spacing={2}>
            {data &&
              data?.content &&
              data?.content.map((row: any) => (
                <Grid item xs={12} sm={6} key={row.Id}>
                  <Paper elevation={2} sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ fontSize: "medium" }}>
                      {row.project}
                    </Typography>
                    <Typography variant="body2">Año: {row.year}</Typography>
                    <Typography variant="body2">Mes: {row.month}</Typography>
                    <Typography variant="body2">Semana: {row.week}</Typography>
                    <Typography variant="body2">Código: {row.code}</Typography>
                    <Typography variant="body2">
                      Encargado: {row.manager}
                    </Typography>
                    <Typography variant="body2">
                      Estado: {row.status}
                    </Typography>
                    <Box mt={1} display="flex" justifyContent="flex-end">
                      <ResourceAccess
                        isCode={true}
                        pathOrCode={"ADVANCES:WRITE"}
                      >
                        <FancyTooltip title="Editar" placement="top">
                          <IconButton
                            aria-label="edit"
                            color="primary"
                            data-id={row.id}
                            data-manager={data?.content[0]?.managerId}
                            size="small"
                            onClick={navigateToDetails}
                          >
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title="Eliminar" placement="top">
                          <IconButton
                            aria-label="trash"
                            color="error"
                            data-id={row.id}
                            data-name="delete"
                            size="small"
                            onClick={handleDelete}
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      </ResourceAccess>
                    </Box>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        )}

        {isLargeScreen && (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="Advances table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Año</TableCell>
                  <TableCell align="center">Mes</TableCell>
                  <TableCell align="center">Semana</TableCell>
                  <TableCell align="center">Código</TableCell>
                  <TableCell align="center">Nombre</TableCell>
                  <TableCell align="center">Encargado</TableCell>
                  <TableCell align="center">Estado</TableCell>
                  <ResourceAccess isCode={true} pathOrCode={"ADVANCES:WRITE"}>
                    <TableCell align="center">Acciones</TableCell>
                  </ResourceAccess>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data?.content && data?.content?.length > 0 ? (
                  data?.content.map((row: any) => (
                    <TableRow key={row.Id}>
                      <TableCell align="center">{row.year}</TableCell>
                      <TableCell align="center">{row.month}</TableCell>
                      <TableCell align="center">{row.week}</TableCell>
                      <TableCell align="center">{row.code}</TableCell>
                      <TableCell align="center">{row.project}</TableCell>
                      <TableCell align="center">{row.manager}</TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                      <TableCell align="center">
                        <ResourceAccess
                          isCode={true}
                          pathOrCode={"ADVANCES:WRITE"}
                        >
                          <FancyTooltip title="Editar" placement="top">
                            <IconButton
                              aria-label="edit"
                              color="primary"
                              data-name={row.project}
                              data-id={row.id}
                              data-week={row.week}
                              size="small"
                              onClick={navigateToDetails}
                            >
                              <PenIcon />
                            </IconButton>
                          </FancyTooltip>
                          <ResourceAccess
                            isCode={true}
                            pathOrCode={"ADVANCES:READCOLUMNS"}
                          >
                            <FancyTooltip title={"Imprimir"} placement="top">
                              <IconButton
                                aria-label="trash"
                                component="label"
                                color="secondary"
                                // sx={{
                                //   "&:hover": {
                                //     color: theme.palette.error.dark,
                                //   },
                                // }}
                                data-id={row.id}
                                onClick={() => exportToExcel(row.projectId)}
                              //data-name="delete"
                              >
                                <PrintRoundedIcon sx={{ fontSize: "1.3rem" }} />
                              </IconButton>
                            </FancyTooltip>
                          </ResourceAccess>
                          <FancyTooltip title="Eliminar" placement="top">
                            <IconButton
                              aria-label="trash"
                              color="error"
                              data-id={row.id}
                              data-name="delete"
                              onClick={handleOpenModal}
                            >
                              <TrashIcon />
                            </IconButton>
                          </FancyTooltip>
                        </ResourceAccess>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>No existen registros</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <FancyTablePagination
          count={data?.content?.length > 0 ? data?.content?.length : 0}
          rowsPerPage={data?.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={data?.totalElements}
          totalPages={data?.totalPages}
        />
      </FancyPaper>
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.code}
            actionButton={
              <>
                <FancyDialogActions
                  handleAccept={handleSubmit(onSubmit)}
                  cancelModal={handleCancelModal}
                  textAcceptButton={"Eliminar"}
                />
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default ManagementAdvances;
