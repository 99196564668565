import React, { useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FancyDialogActions from "../FancyDialogActions";
import { NumericFormat } from "react-number-format";
import { formatDateRange } from "../formatters";
import { getRequestCredits, updateCreditRequest } from "../../../services/creditsDebitsTickets/CreditsService";
import { Credit } from "./interface";
import { useApp } from "../../../hooks/useApp";



const validationSchema = yup.object({
  idCollaborator: yup.string().required("El ID del colaborador es requerido"),
  name: yup.string().required("El nombre del colaborador es requerido"),
  week: yup.number(),
  position: yup.string(),
  project: yup.string(),
  amount: yup
    .number()
    .typeError("El monto debe ser un número")
    .positive("El monto debe ser positivo")
    .required("El monto es requerido"),
  type: yup.string().required("El tipo de movimiento es requerido"),
  currency: yup.string(),
  outstandingBalance: yup.number(),
  authorized: yup.boolean().required("La autorización es requerida"),
  weekToApply: yup.string().required("La semana es requerida"),
  notes: yup.string().max(255, "Las notas no pueden exceder los 255 caracteres"),
});

interface CreditDebitModalProps {
  data: any;
  cancelModal: () => void;
  getData: () => void;
}

const CreditDebitModal: React.FC<CreditDebitModalProps> = ({
  data,
  cancelModal,
  getData,
}) => {
  const {
    setLoading,
    setErrorMsg,
    setSuccessMsg,
    authInfo,
  } = useApp();

  const { control, handleSubmit, formState: { errors }, watch, reset } = useForm({
    defaultValues: {
      idCollaborator: data.modalObject.dni || "",
      name: data.modalObject.name || "",
      position: data.modalObject.position || "",
      project: data.modalObject.project.name || "",
      type: "credit",
      currency: "Colones",
      week: data.modalObject.filteredWeek,
      weekToApply: formatDateRange(data.modalObject.dateRange) || "",
      outstandingBalance: 0,
      amount: 0,
      authorized: false,
      notes: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const typeValue = watch("type");

  const mapApiResponseToForm = (apiResponse: Credit) => {
    return {
      idCollaborator: data.modalObject.dni,
      name: apiResponse.person.fullName || "",
      position: apiResponse.person.position,
      project: apiResponse.project.name,
      type: apiResponse.credit ? "credit" : "deduction",
      currency: "Colones",
      week: apiResponse.week,
      amount: apiResponse.amount,
      weekToApply: formatDateRange(data.modalObject.dateRange) || "",
      balance: apiResponse.outstandingBalance,
      authorized: apiResponse.authorized,
      notes: apiResponse.notes || "",
      outstandingBalance: apiResponse.outstandingBalance,
    };
  };

  const onSubmit = async (formData: any) => {

    const payload = {
      ...formData,
      id: data.modalObject.salaryAdjustmentId || null,
      year: data.modalObject.year,
      projectId: data.modalObject.project.id,
      personId: data.modalObject.id,
      positionId: data.modalObject.positionId,
      user: authInfo?.username,
    };

    try {
      setLoading && setLoading(true);
      const response = await updateCreditRequest(payload);

      if (response) {
        if (payload.id) {
          setSuccessMsg && setSuccessMsg("Datos actualizados exitosamente");
        }
        else {
          setSuccessMsg && setSuccessMsg("Datos guardados exitosamente");
        }
        cancelModal && cancelModal();
      }
      await getData();
      setLoading && setLoading(false);

    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }

  };


  useEffect(() => {
    if (data.modalObject.salaryAdjustmentId) {
      getCredits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.modalObject.salaryAdjustmentId]);

  const getCredits = async () => {
    try {
      setLoading && setLoading(true);
      const response = await getRequestCredits(data.modalObject.salaryAdjustmentId);

      const formData = mapApiResponseToForm(response);
      reset(formData);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    } finally {
      setLoading && setLoading(false);
    }
  };


  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>Créditos / Débitos</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} sx={{ pt: 4, pb: 8 }}>
            {/* ID del Colaborador */}
            <Grid item xs={12} sm={6}>
              <Controller
                name="idCollaborator"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="ID Colaborador"
                    variant="outlined"
                    error={!!errors.idCollaborator}
                    // helperText={errors.idCollaborator?.message}
                    disabled={true}
                  />
                )}
              />
            </Grid>

            {/* Nombre del Colaborador */}
            <Grid item xs={12} sm={6}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="Nombre del Colaborador"
                    variant="outlined"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    disabled={true}
                  />
                )}
              />
            </Grid>

            {/* Cargo o posición */}
            <Grid item xs={12} sm={6}>
              <Controller
                name="position"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="Puesto"
                    variant="outlined"
                    disabled={true}
                  />
                )}
              />
            </Grid>

            {/* Projecto */}
            <Grid item xs={12} sm={6}>
              <Controller
                name="project"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="Proyecto"
                    variant="outlined"
                    disabled={true}
                  />
                )}
              />
            </Grid>

            {/* Tipo de Movimiento y Moneda */}
            <Grid item xs={12}>
              <Grid container spacing={3} alignItems="center">
                {/* Tipo de Movimiento */}
                <Grid item xs={12} sm={6} sx={{ ml: 2, mr: -2 }}>
                  <Typography variant="subtitle1">Tipo de Movimiento</Typography>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field} row>
                        <FormControlLabel value="credit" control={<Radio />} label="Crédito" />
                        <FormControlLabel value="deduction" control={<Radio />} label="Deducción" />
                      </RadioGroup>
                    )}
                  />
                </Grid>

                {/* Moneda */}
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="currency"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={{ background: "#FFF" }}
                        fullWidth
                        size="small"
                        label="Moneda"
                        variant="outlined"
                        error={errors.currency && Boolean(errors.currency)}
                        helperText={errors.currency && errors.currency.message}
                        inputProps={{
                          autoComplete: "new-password",
                          form: { autoComplete: "off" },
                        }}
                        disabled={true}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Monto */}
            <Grid item xs={12} sm={6}>
              <Controller
                name="amount"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <NumericFormat
                    customInput={TextField}
                    fullWidth
                    label="Monto"
                    prefix="₡"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    value={value || 0}
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      onChange(floatValue || 0);
                    }}
                    error={!!errors.amount}
                    helperText={errors.amount?.message}
                  />
                )}
              />
            </Grid>

            {/* Semana a Aplicar */}
            <Grid item xs={12} sm={6}>
              <Controller
                name="weekToApply"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Semana a Aplicar"
                    variant="outlined"
                    error={!!errors.weekToApply}
                    helperText={errors.weekToApply?.message}
                    disabled={true}
                  />
                )}
              />
            </Grid>

            {/* Saldo y Autorizada */}
            <Grid item xs={12}>
              <Grid container spacing={3} alignItems="center">
                {/* Saldo */}
                <Grid item xs={12} sm={6}>
                  {typeValue === "deduction" ? (
                    <Controller
                      name="outstandingBalance"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <NumericFormat
                          customInput={TextField}
                          fullWidth
                          label="Saldo"
                          prefix="₡"
                          decimalScale={2}
                          fixedDecimalScale
                          thousandSeparator
                          value={typeValue === "deduction" ? 0 : value || 0}
                          onValueChange={(values) => {
                            const { floatValue } = values;
                            onChange(floatValue || 0);
                          }}
                          disabled={typeValue !== "deduction"}
                          variant="outlined"
                          error={!!errors.outstandingBalance}
                          helperText={errors.outstandingBalance?.message}
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      label="Saldo"
                      variant="outlined"
                      value={"No Aplica"}
                      disabled={true}
                    />
                  )}
                </Grid>

                {/* Autorizada */}
                <Grid item xs={12} sm={6} sx={{ ml: 2, mr: -2 }}>
                  <Typography variant="subtitle1">Autorizada</Typography>
                  <Controller
                    name="authorized"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field} row>
                        <FormControlLabel value="true" control={<Radio />} label="Sí" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Notas */}
            <Grid item xs={12}>
              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Notas"
                    multiline
                    maxRows={4}
                    variant="outlined"
                    sx={{ background: "#FFF" }}
                    error={!!errors.notes}
                    helperText={errors.notes?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          {/* Botones */}
          <FancyDialogActions
            handleAccept={handleSubmit(onSubmit)}
            cancelModal={cancelModal}
            textAcceptButton="Guardar"
          />
        </form>
      </DialogContent >
    </>
  );
};

export default CreditDebitModal;