import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  TextField,
  IconButton,
  Typography,
  Autocomplete,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  Tooltip,
} from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FancyDialogActions from "./FancyDialogActions";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  DialogProps,
  requiredString,
  formSchemaOptionsIdNumberAndName,
  AddOrUpdateProjectsType,
  mileagesType,
} from "../../types/Utils";
import { useApp } from "../../hooks/useApp";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";
import {
  getRequestCanton,
  getRequestDistrict,
} from "../../services/country/CountryService";
import { maxDateProjects } from "../../services/projects/ProjectService";
import { NumericFormat } from "react-number-format";
import "react-datepicker/dist/react-datepicker.css";
import {
  formatDateReverse,
  formatDateToNewDate,
  validarFecha,
} from "../../utils/UtilsFunctions";
import FancyRangeDatePicker from "../FancyRangeDatePicker";
import {
  uploadProjectDocuments,
  deleteProjectDocuments,
  getRequestProjectDocuments,
} from "../../services/documents/DocumentService";
import { Dispatch, SetStateAction } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import PDFViewer from "pdf-viewer-reactjs";

interface cantonType {
  id: number;
  name: string;
}

interface addorUpdateProjectsType extends DialogProps {
  provinces?: any | null;
  projectStatusData?: any | null;
  projectWeekendStatus?: any | null;
  mileagesType?: any | null;
  attendantData?: any | null;
  managerData?: any | null;
  file: any;
  setFile: Dispatch<SetStateAction<any>>;
}

interface calculateDateType {
  date: string;
  dayCount: number;
  dayType: number;
}

const AddorUpdateProjects = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  provinces,
  projectStatusData,
  projectWeekendStatus,
  mileagesType,
  attendantData,
  managerData,
  file,
  setFile,
}: addorUpdateProjectsType) => {
  const theme = useTheme();
  const [cantons, setCantons] = useState<cantonType[] | null>(null);
  const [districts, setDistricts] = useState<cantonType[] | null>(null);
  // const [file, setFile] = useState<any>([]); // Lista de imágenes
  const [refreshImages, setRefreshImages] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0); // Índice de imagen activa
  //const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [showImageModal, setShowImageModal] = useState<{
    open: boolean;
    url: string;
  }>({
    open: false,
    url: "",
  });

  const allowImageExt: any = ["JPEG", "JPG", "PNG"];
  const allowDocExt: any = ["DOC", "DOCX", "XLSX"];

  const {
    authInfo,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();

  const defaultValues: AddOrUpdateProjectsType = {
    id: data?.id ?? 0,
    code: data?.code ?? "",
    type: data?.type ?? "",
    name: data?.name ?? "",
    attendant: {
      id: 0,
      name: "",
      lastName: "",
      surname: "",
      fullName: "",
    },
    managerId: {
      id: 0,
      name: "",
      lastName: "",
      surname: "",
      fullName: "",
    },
    contractAmount: data?.contractAmount ?? 0,
    currency: data?.currency ?? "",
    guaranteeCompliance: data?.guaranteeCompliance ?? "",
    startDate: data?.startDate ?? new Date(),
    finalDate: data?.finalDate ?? new Date(),
    mileages: data?.mileages ?? [
      {
        area: 0,
        areaType: "0",
        startDate: new Date(),
        daysQuantity: 0,
        dayType: "CALENDAR_DAY",
        mileageTypeId: 0,
        mileageType: { id: 0, name: "" },
        endDate: new Date(),
      },
    ],
    province: data?.district?.canton?.province ?? { id: 0, name: "" },
    canton: data?.district?.canton ?? { id: 0, name: "" },
    district: data?.district ?? { id: 0, name: "" },
    address: data?.address ?? "",
    status: data?.status ?? "",
    notes: data?.notes ?? "",
    weekendPermissions: data?.weekendPermissions ?? [
      {
        date: new Date(),
        endDate: new Date(),
        rangeDate: `{
                   "start": "${formatDateReverse(new Date())}",
                   "end": "${formatDateReverse(new Date())}"
                  }`,
        status: {
          id: 0,
          name: "",
        },
        notes: "",
      },
    ],
    projectImage: [] as File[],
  };

  const formSchemaWeekendPermissions = {
    date: Yup.string(),
    status: Yup.object().nullable().shape({
      id: Yup.number(),
      name: Yup.string(),
    }),
    notes: Yup.string(),
  };

  const formSchemaValidation = Yup.object().shape({
    code: Yup.string(),
    type: requiredString,
    name: requiredString,
    attendant: Yup.object()
      .shape(formSchemaOptionsIdNumberAndName)
      .typeError("Campo es requerido"),
    managerId: Yup.object()
      .shape(formSchemaOptionsIdNumberAndName)
      .typeError("Campo es requerido"),
    contractAmount: Yup.number()
      .required("Campo es requerido")
      .typeError("Campo debe ser númerico"),
    currency: requiredString,
    guaranteeCompliance: Yup.string(),
    startDate: Yup.string(),
    finalDate: Yup.string(),
    province: Yup.object()
      .shape(formSchemaOptionsIdNumberAndName)
      .typeError("Campo es requerido"),
    canton: Yup.object()
      .shape(formSchemaOptionsIdNumberAndName)
      .typeError("Campo es requerido"),
    district: Yup.object().shape(formSchemaOptionsIdNumberAndName),
    address: requiredString,
    notes: Yup.string().max(512, "El campo no debe superar los 512 caracteres"),
    status: Yup.object().shape(formSchemaOptionsIdNumberAndName),
    mileages: Yup.array().of(
      Yup.object().shape({
        area: Yup.number(),
        areaType: Yup.string(),
        startDate: Yup.string(),
        daysQuantity: Yup.number().when(["areaType"], {
          is: (val: any) => val !== "0" || val !== 0,
          then: (schema) =>
            schema
              .required("Campo es requerido")
              .min(1, "No puede ser menor a 1")
              .typeError("Valor debe ser un número"),
          otherwise: (schema) => schema.notRequired(),
        }),
        dayType: Yup.string(),
        mileageType: Yup.object()
          .shape(formSchemaOptionsIdNumberAndName)
          .typeError("Campo es requerido"),
      })
    ),
    weekendPermissions: Yup.array()
      .of(Yup.object().shape(formSchemaWeekendPermissions))
      .nullable(),
    documentType: Yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    control,
    setValue,
    getValues,
    watch,
  } = useForm<AddOrUpdateProjectsType>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(formSchemaValidation),
    mode: "onChange",
  });
  const {
    fields: weekendPermissionsField,
    append: appendWeekendPermissions,
    remove: removeWeekendPermissions,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: "weekendPermissions", // unique name for your Field Array
  });
  const {
    fields: mileagesField,
    append: appendMileages,
    remove: removeMileages,
  } = useFieldArray({
    control,
    name: "mileages",
  });
  const watch_mileages: mileagesType[] = watch("mileages");
  const watch_weekendPermissions: any = watch("weekendPermissions");

  console.log(watch_mileages);

  useEffect(() => {
    if (data !== null) {
      if (data?.attendand !== null && data?.attendand?.id !== 0) {
        const findAttendan = attendantData?.find(
          (item: any) => item.id === data.attendand.id
        );

        setValue("attendant", findAttendan);
      }
      if (data?.manager !== null && data?.manager?.id !== 0) {
        const findManagerId = managerData?.find(
          (item: any) => item.id === data.manager.id
        );

        setValue("managerId", findManagerId);
      }
    }
  }, [data]);

  const getCanton = async (id: number) => {
    try {
      setLoading(true);
      const canton: any = await getRequestCanton(id);
      if (canton) {
        setCantons(canton);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error);
    }
  };

  const getDistrict = async (id: number) => {
    try {
      setLoading(true);
      const district: any = await getRequestDistrict(id);
      if (district) {
        setDistricts(district);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error);
    }
  };

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    //key: "selection",
  };

  const onChangeWeekendPermissions = (dates: any) => {
    const [start, end] = dates;
    console.log(start);
    console.log(end);
  };

  const handleCalculateMaxDate = async (data: mileagesType, index: number) => {
    let dateWithFormat = null;

    const awaitFormatDate = async () => {
      dateWithFormat = validarFecha(new Date(data.startDate))
        ? formatDateReverse(new Date(data.startDate))
        : formatDateReverse(new Date(formatDateToNewDate(data.startDate)));
    };

    await awaitFormatDate();

    const formaData: any = {
      date: dateWithFormat,
      dayCount: data.daysQuantity,
      dayType: data.dayType,
    };

    try {
      //@ts-ignore
      if (data.daysQuantity >= 1 && new Date(data.startDate)) {
        //if (data.daysQuantity >= 1 && !isNaN(new Date(data.startDate))) {
        const calculate = await maxDateProjects(formaData);

        if (calculate) {
          //setLoading(false);
          const date = new Date(formatDateToNewDate(calculate));

          setValue(`mileages.${index}.endDate`, date);
        }
      }
    } catch (error: any) {
      console.log(error);
      setErrorMsg(error);
    }
  };

  useEffect(() => {
    if (watch_mileages) {
      watch_mileages.map((item: any, i: number) =>
        handleCalculateMaxDate(item, i)
      );
    }
  }, [watch_mileages]);

  const handleUploadProjectDocument = async (event: any) => {
    const newFile = event.target.files[0];
    if (newFile) {
      const imageUrl = URL.createObjectURL(newFile);
      if (modalType === "update") {
        try {
          const formData = new FormData();
          formData.append("projectId", modalData?.modalObject.id);
          formData.append("file", newFile);
          formData.append("user", authInfo.username);
          formData.append("documentType", "1");
          await uploadProjectDocuments(formData);
          setLoading && setLoading(false);
        } catch (error: any) {
          setErrorMsg && setErrorMsg(error.message);
          setLoading && setLoading(false);
        }
      }

      //setFile((prevFiles: any) => [...prevFiles, imageUrl]);
      //setFile((prevFiles: any) => [...prevFiles, newFile]);
      setFile((prev: any) => [...prev, { file: newFile, url: imageUrl }]);

      // Obtiene el valor actual de `projectImage` y le agrega la nueva imagen
      const currentImages = getValues("projectImage") || [];
      setValue("projectImage", [...currentImages, newFile]);
    }
    setRefreshImages((prev) => !prev);
  };

  const handleDeleteFile = async (file: any) => {
    if (modalType === "update") {
      try {
        const imageToDelete = file;

        await deleteProjectDocuments(imageToDelete.id, data.id);

        setFile((prevFiles: any) => {
          const updatedFiles = [...prevFiles];
          updatedFiles.splice(activeIndex, 1);
          return updatedFiles;
        });

        setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
      } catch (error) {
        console.error("Error al eliminar el documento:", error);
      }
    }

    setFile((prevFiles: any) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(activeIndex, 1); // Elimina la imagen activa
      return updatedFiles;
    });
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    setRefreshImages((prev) => !prev);
  };

  const handleFetchImages = async (projectId: number, documentType: number) => {
    try {
      if (data?.id) {
        const response = await getRequestProjectDocuments(
          projectId,
          documentType
        );
        setFile(response);
      }
    } catch (error) {
      console.error("Error al obtener las imágenes:", error);
    }
  };

  useEffect(() => {
    if (data?.id) {
      handleFetchImages(data.id, 1);
    }
  }, [data?.id, refreshImages]);

  const handlePreviewFile = (img: any) => {
    console.log("handlePreviewFile: ", img);
    //setShowImageModal(true);
    setShowImageModal({
      open: true,
      url: img.url,
    });
  };

  const handleCloseImageModal = () => {
    //setShowImageModal(false);
    setShowImageModal({
      open: false,
      url: "",
    });
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar proyecto" : "Actualizar proyecto"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
          {modalType !== "create" && (
            <Grid item xs={12} md={4}>
              <Controller
                name={"code"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Código"
                    type="text"
                    value={value}
                    disabled
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register("code")}
                    error={errors.code && Boolean(errors.code)}
                    helperText={errors.code && errors.code.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} md={modalType !== "create" ? 4 : 4}>
            <Controller
              name={"type"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={!!errors.type}
                >
                  <InputLabel shrink={true}>Tipo</InputLabel>
                  <Select
                    notched
                    value={value}
                    onChange={onChange}
                    label="Perfil"
                    sx={{ "& input": { pl: "0!important" } }}
                  >
                    <MenuItem value="PROJECT">Proyecto</MenuItem>
                    <MenuItem value="GUARANTEE">Garantía</MenuItem>
                  </Select>
                  {errors.type && (
                    <span style={{ color: "red", fontSize: "0.8rem" }}>
                      {errors.type.message}
                    </span>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={modalType !== "create" ? 4 : 4}>
            <Controller
              name={"name"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Nombre"
                  type="text"
                  value={value}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("name")}
                  error={errors.name && Boolean(errors.name)}
                  helperText={errors.name && errors.name.message}
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name={"managerId"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={managerData ?? []}
                  getOptionLabel={(option: any) =>
                    option.name + " " + option.lastName || ""
                  }
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Encargado"
                        variant="outlined"
                        error={!!errors.managerId}
                        helperText={
                          errors.managerId && errors.managerId.name?.message
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name={"attendant"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={attendantData ?? []}
                  getOptionLabel={(option: any) =>
                    option.name + " " + option.lastName || ""
                  }
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Sub encargado"
                        variant="outlined"
                        error={!!errors.attendant}
                        helperText={
                          errors.attendant && errors.attendant.name?.message
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name={"contractAmount"}
              control={control}
              render={({ field: { value, onChange } }) => (
                <NumericFormat
                  customInput={TextField}
                  fullWidth
                  size="small"
                  label="Monto contrato"
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix="₡"
                  value={value || ""}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    onChange(floatValue || 0);
                  }}
                  error={Boolean(errors.contractAmount)}
                  helperText={errors.contractAmount?.message}
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                    min: 0,
                    max: 9999999999,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name={"currency"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={!!errors.currency}
                >
                  <InputLabel shrink={true}>Moneda</InputLabel>
                  <Select
                    notched
                    value={value}
                    onChange={onChange}
                    label="Moneda"
                    sx={{ "& input": { pl: "0!important" } }}
                  >
                    <MenuItem value="COLONES">Colones</MenuItem>
                    <MenuItem value="DOLARES">Dólares</MenuItem>
                  </Select>
                  {errors.currency && (
                    <span style={{ color: "red", fontSize: "0.8rem" }}>
                      {errors.currency.message}
                    </span>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name={"guaranteeCompliance"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Garantía de cumplimiento"
                  type="text"
                  value={value}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("guaranteeCompliance")}
                  error={
                    errors.guaranteeCompliance &&
                    Boolean(errors.guaranteeCompliance)
                  }
                  helperText={
                    errors.guaranteeCompliance &&
                    errors.guaranteeCompliance.message
                  }
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name={"status"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={async (event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={projectStatusData ?? []}
                  getOptionLabel={(option: any) => option.name || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Estado"
                        variant="outlined"
                        error={!!errors.status}
                        helperText={
                          errors.status && errors.status.name?.message
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name={"province"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={async (event, newValue: any) => {
                    onChange(newValue);
                    setValue("canton", { id: 0, name: "" });
                    setValue("district", { id: 0, name: "" });
                    setCantons(null);
                    setDistricts(null);
                    if (newValue !== null && newValue?.id !== 0) {
                      await getCanton(newValue.id);
                    }
                  }}
                  options={provinces ?? []}
                  getOptionLabel={(option: any) => option.name || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.id === value?.id
                  }
                  value={value}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Provincia"
                        variant="outlined"
                        error={!!errors.province}
                        helperText={
                          errors.province && errors.province.name?.message
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name={"canton"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={async (event, newValue: any) => {
                    onChange(newValue);
                    setValue("district", { id: 0, name: "" });
                    if (newValue !== null && newValue?.id !== 0) {
                      await getDistrict(newValue.id);
                    }
                  }}
                  options={cantons ?? []}
                  disabled={cantons === null}
                  getOptionLabel={(option: any) => option.name || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Canton"
                        variant="outlined"
                        error={!!errors.canton}
                        helperText={
                          errors.canton && errors.canton.name?.message
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name={"district"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={async (event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={districts ?? []}
                  disabled={districts === null}
                  getOptionLabel={(option: any) => option.name || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Distrito"
                        variant="outlined"
                        error={!!errors.district}
                        helperText={
                          errors.district && errors.district.name?.message
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={"address"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Dirección"
                  type="text"
                  value={value}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("address")}
                  error={errors.address && Boolean(errors.address)}
                  helperText={errors.address && errors.address.message}
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={"notes"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Notas"
                  multiline
                  maxRows={4}
                  value={value}
                  variant="outlined"
                  {...register("notes")}
                  sx={{ background: "#FFF" }}
                  error={errors.notes && Boolean(errors.notes)}
                  helperText={errors.notes && errors.notes.message}
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
              Área
            </Typography>
            <Divider sx={{ mt: 1, mb: 3 }} />
          </Grid>
          {mileagesField.map((item, index) => (
            <>
              <Grid item xs={12} sm={6} md={2}>
                <Controller
                  name={`mileages.${index}.areaType`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={Boolean(errors.mileages?.[index]?.areaType)}
                    >
                      <InputLabel shrink={true}>Tipo de metraje</InputLabel>
                      <Select
                        notched
                        value={value}
                        onChange={onChange}
                        label="Tipo de metraje"
                        sx={{ "& input": { pl: "0!important" } }}
                      >
                        <MenuItem value="M2">Metro cuadrado</MenuItem>
                        <MenuItem value="ML">Metro lineal</MenuItem>
                      </Select>
                      {errors.mileages?.[index]?.areaType && (
                        <span style={{ color: "red", fontSize: "0.8rem" }}>
                          {errors.mileages?.[index]?.areaType?.message}
                        </span>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name={`mileages.${index}.area`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Metraje"
                      type="number"
                      inputProps={{ step: "0.01", min: "0" }}
                      value={value ?? ""}
                      onChange={(e) =>
                        onChange(parseFloat(e.target.value) || "")
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name={`mileages.${index}.mileageType`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={async (event, newValue: any) => {
                        onChange(newValue || {});
                      }}
                      options={mileagesType ?? []}
                      getOptionLabel={(option: any) => option.name || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Tipo de área"
                            variant="outlined"
                            error={Boolean(
                              errors.mileages?.[index]?.mileageType
                            )}
                            helperText={
                              errors.mileages?.[index]?.mileageType?.name
                                ?.message
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name={`mileages.${index}.startDate`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControl
                      error={Boolean(errors.mileages?.[index]?.startDate)}
                      sx={{ width: "100%" }}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="es"
                      >
                        <MuiDatePicker
                          label="Fecha Inicio"
                          value={dayjs(formatDateToNewDate(value))}
                          onChange={(date) => {
                            onChange(date);
                            handleCalculateMaxDate(
                              watch_mileages[index],
                              index
                            );
                          }}
                          format="DD-MM-YYYY"
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                              backgroundColor: theme.palette.primary.main,
                            },
                            "& input": {
                              borderRadius: "10px 0 0 10px",
                            },
                            "& button": {
                              paddingLeft: "0",
                              "& svg": {
                                fill: "#FFF",
                              },
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: "10px",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name={`mileages.${index}.dayType`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={Boolean(errors.mileages?.[index]?.dayType)}
                    >
                      <InputLabel shrink={true}>Tipo de días</InputLabel>
                      <Select
                        notched
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                          handleCalculateMaxDate(watch_mileages[index], index);
                        }}
                        label="Tipo de días"
                        sx={{ "& input": { pl: "0!important" } }}
                      >
                        <MenuItem value="BUSINESS_DAY">Hábiles</MenuItem>
                        <MenuItem value="CALENDAR_DAY">Naturales</MenuItem>
                      </Select>
                      {errors.mileages?.[index]?.dayType && (
                        <span style={{ color: "red", fontSize: "0.8rem" }}>
                          {errors.mileages?.[index]?.dayType?.message}
                        </span>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name={`mileages.${index}.daysQuantity`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Días"
                      value={value}
                      variant="outlined"
                      onChange={(e) => {
                        onChange(e.target.value);
                        handleCalculateMaxDate(watch_mileages[index], index);
                      }}
                      //sx={{ background: "#FFF" }}
                      error={Boolean(errors.mileages?.[index]?.daysQuantity)}
                      helperText={
                        errors.mileages?.[index]?.daysQuantity?.message
                      }
                      inputProps={{
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Controller
                  name={`mileages.${index}.endDate`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControl error={false} sx={{ width: "100%" }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="es"
                      >
                        <MuiDatePicker
                          label="Fecha Fin"
                          value={dayjs(value)}
                          onChange={(date) => {
                            onChange(date);
                          }}
                          disabled
                          format="DD-MM-YYYY"
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                              backgroundColor: theme.palette.primary.main,
                            },
                            "& input": {
                              borderRadius: "10px 0 0 10px",
                            },
                            "& button": {
                              paddingLeft: "0",
                              "& svg": {
                                fill: "#FFF",
                              },
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: "10px",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  )}
                />
                {index !== 0 && (
                  <Tooltip title="Eliminar fachada">
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => {
                        removeMileages(index);
                      }}
                    >
                      <RemoveCircleOutlineIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
              {mileagesField.length > 0 && (
                <Grid item xs={12}>
                  <Divider sx={{ mt: 1, mb: 3 }} />
                </Grid>
              )}
            </>
          ))}
          {watch_mileages &&
            watch_mileages?.some(
              (item: any) => item.areaType !== "No Aplica"
            ) && (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    md: "flex-end",
                  },
                  paddingTop: "0!important",
                }}
              >
                <IconButton
                  aria-label="add"
                  onClick={() => {
                    appendMileages({
                      area: 0,
                      areaType: "0",
                      startDate: new Date(),
                      daysQuantity: 0,
                      dayType: "CALENDAR_DAY",
                      mileageTypeId: 0,
                      mileageType: {
                        id: "0",
                        name: "",
                      },
                      endDate: new Date(),
                    });
                  }}
                  sx={{ fontSize: "1rem", borderRadius: "25px" }}
                >
                  <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    sx={{ mr: 1 }}
                  />{" "}
                  Agregar área
                </IconButton>
              </Grid>
            )}
          {modalType !== "create" && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
                  Resumen de Fechas del Proyecto
                </Typography>
                <Divider sx={{ mt: 1, mb: 3 }} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name={"startDate"}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <MuiDatePicker
                      label="Fecha de inicio"
                      value={dayjs(new Date(formatDateToNewDate(value)))}
                      onChange={(date) => {
                        onChange(date);
                      }}
                      disabled
                      format="DD-MM-YYYY"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          backgroundColor: theme.palette.primary.main,
                        },
                        "& input": {
                          borderRadius: "10px 0 0 10px",
                        },
                        "& button": {
                          paddingLeft: "0",
                          "& svg": {
                            fill: "#FFF",
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name={"finalDate"}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <MuiDatePicker
                      label="Fecha final"
                      //value={dayjs(value)}
                      value={dayjs(new Date(formatDateToNewDate(value)))}
                      onChange={(date) => {
                        onChange(date);
                      }}
                      format="DD-MM-YYYY"
                      disabled
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          backgroundColor: theme.palette.primary.main,
                        },
                        "& input": {
                          borderRadius: "10px 0 0 10px",
                        },
                        "& button": {
                          paddingLeft: "0",
                          "& svg": {
                            fill: "#FFF",
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
              Permisos fin de semana
            </Typography>
            <Divider sx={{ mt: 1, mb: 3 }} />
          </Grid>
          {weekendPermissionsField.map((item: any, index) => (
            <>
              <Grid item xs={12} md={4} sm={6}>
                <Controller
                  name={`weekendPermissions.${index}.rangeDate`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControl
                      error={Boolean(
                        errors.weekendPermissions?.[index]?.rangeDate
                      )}
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FancyRangeDatePicker
                        rangeDate={value}
                        date={item.date}
                        endValue={item.endDate}
                        onChange={onChange}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={8} sm={6}>
                <Grid item xs={12} sx={{ marginBottom: 3 }}>
                  <Controller
                    name={`weekendPermissions.${index}.status`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={async (event, newValue: any) => {
                          onChange(newValue);
                        }}
                        options={projectWeekendStatus ?? []}
                        getOptionLabel={(option: any) => option.name || ""}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={value}
                        renderInput={(params) => {
                          const inputProps = params.inputProps;
                          inputProps.autoComplete = "new-password";
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              label="Estado"
                              variant="outlined"
                              error={
                                !!errors.weekendPermissions?.[index]?.status
                              }
                              helperText={
                                errors.weekendPermissions?.[index]?.status &&
                                errors.weekendPermissions?.[index]?.status
                                  ?.message
                              }
                              InputLabelProps={{ shrink: true }}
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Controller
                    name={`weekendPermissions.${index}.notes`}
                    control={control}
                    render={({ field: { value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Notas"
                        multiline
                        maxRows={4}
                        value={value}
                        variant="outlined"
                        {...register(
                          `weekendPermissions.${index}.notes` as const
                        )}
                        sx={{ background: "#FFF" }}
                        error={Boolean(
                          errors.weekendPermissions?.[index]?.notes
                        )}
                        helperText={
                          errors.weekendPermissions?.[index]?.notes?.message
                        }
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  {index !== 0 && (
                    <Tooltip title="Eliminar permiso">
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={() => {
                          removeWeekendPermissions(index);
                        }}
                      >
                        <RemoveCircleOutlineIcon fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
              {weekendPermissionsField.length > 0 && (
                <Grid item xs={12}>
                  <Divider sx={{ mt: 1, mb: 3 }} />
                </Grid>
              )}
            </>
          ))}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                md: "flex-end",
              },
              paddingTop: "0!important",
            }}
          >
            <IconButton
              aria-label="add"
              onClick={() => {
                appendWeekendPermissions({
                  date: new Date(),
                  endDate: new Date(),
                  rangeDate: `{
                    "start": "${formatDateReverse(new Date())}",
                    "end": "${formatDateReverse(new Date())}"
                   }`,
                  statusId: "",
                  notes: "",
                });
              }}
              sx={{ fontSize: "1rem", borderRadius: "25px" }}
            >
              <AddCircleOutlineIcon
                color="primary"
                fontSize="small"
                sx={{ mr: 1 }}
              />{" "}
              Agregar permiso
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <IconButton component="label" sx={{ cursor: "pointer" }}>
                <NoteAddIcon color="primary" sx={{ fontSize: 24 }} />
                <input
                  type="file"
                  hidden
                  onChange={handleUploadProjectDocument}
                />
              </IconButton>
              <Typography variant="body2" sx={{ ml: 1, fontSize: "1rem" }}>
                Subir archivo digital
              </Typography>
            </Box>
            <Box sx={{ mt: 2, px: 2 }}>
              {file.map((item: any) => (
                <Box
                  key={file.id}
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      flex: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item?.file?.name ?? item?.url?.split("/")?.pop()}
                  </Typography>
                  <IconButton onClick={() => handlePreviewFile(item)}>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteFile(item)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <Dialog
        open={showImageModal.open}
        onClose={handleCloseImageModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Documento Ampliado</DialogTitle>
        <Dialog
          open={showImageModal.open}
          onClose={handleCloseImageModal}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle sx={{ fontSize: "1.5rem", textAlign: "center" }}>
            Documento Ampliado
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pt: "120px",
              textAlign: "center",
            }}
          >
            {showImageModal.url
              ?.split("/")
              ?.pop()
              ?.split(".")
              ?.pop()
              ?.toUpperCase() === "PDF" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <PDFViewer
                  document={{ url: `${showImageModal.url}` }}
                  hideNavbar={true}
                  canvasCss={"canvasPDF"}
                  loader={true}
                />
              </Box>
            ) : showImageModal.url
                ?.split("/")
                ?.pop()
                ?.split(".")
                ?.pop()
                ?.toUpperCase() === "DOC" ||
              showImageModal.url
                ?.split("/")
                ?.pop()
                ?.split(".")
                ?.pop()
                ?.toUpperCase() === "DOCX" ||
              showImageModal.url
                ?.split("/")
                ?.pop()
                ?.split(".")
                ?.pop()
                ?.toUpperCase() === "XLSX" ||
              showImageModal.url
                ?.split("/")
                ?.pop()
                ?.split(".")
                ?.pop()
                ?.toUpperCase() === "CSV" ? (
              <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${showImageModal.url}`}
                width="100%"
                height="800px"
              ></iframe>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "40vh",
                }}
              >
                <Box
                  component="img"
                  src={showImageModal.url}
                  loading="lazy"
                  sx={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                    objectPosition: "center",
                  }}
                />
              </Box>
            )}

            {/* {file && file.length > 0 && (
              <Carousel
                index={activeIndex}
                onChange={(index: any) => setActiveIndex(index)}
                indicators={true}
                navButtonsAlwaysVisible={true}
                autoPlay={false}
                sx={{
                  width: "100%",
                  "& .carousel-root": {
                    width: "100%",
                  },
                }}
              >
                {file?.map((img: any, index: any) =>
                  img?.url
                    ?.split("/")
                    ?.pop()
                    ?.split(".")
                    ?.pop()
                    ?.toUpperCase() === "PDF" ||
                  img?.file?.type === "application/pdf" ? (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50vh",
                      }}
                    >
                      <FancyPdfView pdf={`${img.url}`} />
                      <PDFViewer document={{ url: `${img.url}` }} />
                    </Box>
                  ) : img?.url
                      ?.split("/")
                      ?.pop()
                      ?.split(".")
                      ?.pop()
                      ?.toUpperCase() === "DOC" ||
                    img?.url
                      ?.split("/")
                      ?.pop()
                      ?.split(".")
                      ?.pop()
                      ?.toUpperCase() === "DOCX" ||
                    img.url
                      ?.split("/")
                      ?.pop()
                      ?.split(".")
                      ?.pop()
                      ?.toUpperCase() === "XLSX" ||
                    img.url
                      ?.split("/")
                      ?.pop()
                      ?.split(".")
                      ?.pop()
                      ?.toUpperCase() === "CSV" ? (
                    <iframe
                      src={`https://view.officeapps.live.com/op/embed.aspx?src=${img.url}`}
                      width="100%"
                      height="800px"
                    ></iframe>
                  ) : (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "40vh",
                      }}
                    >
                      <Box
                        component="img"
                        src={img.url ? img.url : img}
                        loading="lazy"
                        alt={`Imagen ${index + 1}`}
                        sx={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                          objectPosition: "center",
                        }}
                      />
                    </Box>
                  )
                )}
              </Carousel>
            )} */}
          </DialogContent>
        </Dialog>
      </Dialog>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddorUpdateProjects;
