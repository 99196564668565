import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";

export const getRequestPerson = async (
  page: number,
  filter: string,
  state?: string,
  size?: number
) => {
  const requestBody = {
    ...(filter && { filter: filter }),
    ...(state && { state: state }),
    page: page,
    size: size ? size : UrlBase.registration_x_page,
  };
  try {
    const response = await privateFetch.post(
      URL.REQUEST_PERSON_LIST,
      JSON.stringify(requestBody),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestPersonPositionAllProjectManagers = async () => {
  try {
    const response = await privateFetch.get(URL.REQUEST_POSITION_LIST2);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestPersonPositionAttendantProjectManagers = async () => {
  try {
    const response = await privateFetch.get(
      URL.REQUEST_POSITION_LIST_ATTENDANT_PROJECT_MANAGERS
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestTse = async (id: number) => {
  try {
    const response = await privateFetch.get(URL.REQUEST_PERSON_TSE(id));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestPosition = async (
  page: number,
  filter: string,
  size?: number
) => {
  const requestBody = {
    ...(filter && { filter: filter }),
    page: page,
    size: size ? size : UrlBase.registration_x_page,
  };
  try {
    const response = await privateFetch.post(
      URL.REQUEST_POSITION_LIST,
      JSON.stringify(requestBody),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createRequest = async (data: any) => {
  console.log(data);
  let responseData = {};

  const requestBody = {
    dni: data.dni,
    name: data.name,
    lastName: data.lastName,
    phone1: data.phone_1,
    phone2: data.phone_2,
    email: data.email,

    birthDate: data.birth_date.toISOString().split("T")[0], // Fecha en formato "YYYY-MM-DD"
    age: new Date().getFullYear() - new Date(data.birth_date).getFullYear(), // Cálculo de la edad
    numberCcss: data.number_ccss,
    maritalStatus: data.marital_status.name,
    nationality: data.nationality.name,
    positions: data.positions,

    entryDate: data.entry_date.toISOString().split("T")[0], // Fecha en formato "YYYY-MM-DD"
    idExpiration: data.id_expiration.toISOString().split("T")[0], // Fecha en formato "YYYY-MM-DD"
    domicile: data.domicile,
    ibanAccount: data.iban_account,
    accountType: data.account_type.id,
    medicalConditions: data.medical_conditions,
    beneficiary: data.beneficiary,
    emergencyContact: data.emergency_contact,
    state: data.state.id,
    bank: data.bank.name,
    notes: data.notes,
    userId: data.usersSystemData.id ? data.usersSystemData.id : null,
    user: data.user,
  };
  await privateFetch

    .post(URL.REQUEST_PERSON, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(
        error.response?.data?.message ? error.response.data.message : properties.com_parval_label_inventory_create_error
      );
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  console.log(data.positions);
  console.log(data);

  let responseData = {};
  const requestBody = {
    id: data.id,
    dni: data.dni,
    name: data.name,
    lastName: data.lastName,
    phone1: data.phone_1,
    phone2: data.phone_2,
    email: data.email,

    birthDate: data.birth_date.toISOString().split("T")[0], // Fecha en formato "YYYY-MM-DD"
    age: new Date().getFullYear() - new Date(data.birth_date).getFullYear(), // Cálculo de la edad

    numberCcss: data.number_ccss,
    maritalStatus: data.marital_status.name,
    nationality: data.nationality.name,
    positions: data.positions,
    entryDate: data.entry_date.toISOString().split("T")[0], // Fecha en formato "YYYY-MM-DD"
    idExpiration: data.id_expiration.toISOString().split("T")[0], // Fecha en formato "YYYY-MM-DD"

    domicile: data.domicile,
    ibanAccount: data.iban_account,
    accountType: data.account_type.id,
    medicalConditions: data.medical_conditions,
    beneficiary: data.beneficiary,
    emergencyContact: data.emergency_contact,
    state: data.state.id, // Estado como número (id)
    bank: data.bank.name, // Banco como string
    notes: data.notes,
    userId: data.usersSystemData.id ? data.usersSystemData.id : null,
    user: data.user,
  };
  console.log(requestBody);
  await privateFetch
    .put(URL.REQUEST_PERSON, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      console.log(response);
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log(error);
      throw new Error(error.response?.data?.message ? error.response.data.message : properties.com_spage_label_collaborators_update_error);
    });
  return responseData;
};

export const deleteRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data,
  };

  await privateFetch
    .delete(URL.REQUEST_PERSON_DELETE, { data: requestBody })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response?.data?.message ? error.response.data.message : properties.com_spage_label_collaborators_delete_error);
    });
  return responseData;
};
