import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  IconButton,
  Button,
  Dialog,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FancyPaper from "../../components/FancyPaper";
import { useApp } from "../../hooks/useApp";
import {
  getRequestCoworkers,
  getRequestProjects,
  getRequestProjectsByManager,
  //createAdvance
} from "../../services/projects/ProjectService"; // Asegúrate de importar createAdvance
import { useLocation } from "react-router-dom";
import WeekFilter from "../../components/utils/credit_view/WeekFilter";
import AddOrUpdateCoworkerModal from "../../components/utils/AddOrUpdateCoworkerModal";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import WarningIcon from "@mui/icons-material/Warning";
import {
  createAdvance,
  deleteAdvanceRequest,
  getRequestDetailsAdvances,
} from "../../services/advances/advancesService";
import { formatNumber } from "../../components/utils/formatters";
import FancyTooltip from "../../components/utils/FancyTooltip";
import { TrashIcon } from "../../components/svgicons/SvgIcons";
import DeleteModal from "../../components/utils/DeleteModal";
import FancyDialogActions from "../../components/utils/FancyDialogActions";
import { useForm } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import ResourceAccess from "../../components/security/ResourceAccess";

const DetailsAdvancesAdd = () => {
  const theme = useTheme();
  const {
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    authInfo,
    setSuccessMsg,
  } = useApp();
  const location = useLocation();
  const { managerId: manager, id: id, startDate, endDate } = location.state || {};

  const [initialStartDate, setInitialStartDate] = useState<Dayjs | null>(
    startDate ? dayjs(startDate) : null
  );
  const [initialEndDate, setInitialEndDate] = useState<Dayjs | null>(
    endDate ? dayjs(endDate) : null
  );

  const { handleSubmit } = useForm();

  const [details, setDetails] = useState<any>(null);
  const [projects, setProjects] = useState<any[]>([]);
  const [coworkersData, setCoworkersData] = useState<any[]>([]);
  const [selectedProject, setSelectedProject] = useState<any | null>(null);
  const [filteredWeek, setFilteredWeek] = useState<number>(1);
  const [dateRange, setDateRange] = useState<string>("");
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [advanceCreated, setAdvanceCreated] = useState(false);
  const [advanceData, setAdvanceData] = useState<any>(null);

  // Estado del modal de horas
  const [openHoursModal, setOpenHoursModal] = useState(false);

  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    if (initialStartDate && initialEndDate) {
      localStorage.setItem("startDate", initialStartDate.format("YYYY-MM-DD"));
      localStorage.setItem("endDate", initialEndDate.format("YYYY-MM-DD"));
    }
  }, [initialStartDate, initialEndDate]);

  useEffect(() => {
    if (details && details.header && projects.length > 0) {
      const projectFound = projects.find(
        (project) => project.code === details.header.code
      );
      if (projectFound) {
        setSelectedProject(projectFound);
      }
    }
  }, [details, projects]);

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject);
        break;
      default:
        break;
    }
  };

  const fetchProjects = async () => {
    try {
      setLoading(true);
      const data: any = await getRequestProjectsByManager(manager);
      if (data) {
        setProjects(data);
      }

      if (advanceData?.id || id) {
        const dataTable: any = await getRequestDetailsAdvances(
          0,
          1000,
          advanceData?.id || id
        );
        if (dataTable) {
          setDetails(dataTable);
        }
      }

      if (advanceData?.id || id) {
        const data: any = await getRequestCoworkers(
          advanceData?.id ? advanceData?.id : id
        );
        if (data) {
          setCoworkersData(data?.content);
        }
      }
    } catch (error: any) {
      setErrorMsg(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const dataInit = async () => {
      if (!advanceData?.id) return;

      try {
        setLoading(true);
        const data: any = await getRequestCoworkers(advanceData?.id);
        if (data) {
          setCoworkersData(data?.content);
        }
      } catch (error: any) {
        setErrorMsg(error);
      } finally {
        setLoading(false);
      }
    };
    dataInit();
  }, [advanceData]);

  const handleChange = (selectedProject: any) => {
    setSelectedProject(selectedProject);
  };

  // Función para crear el avance
  const handleCreateAdvance = async () => {
    if (!selectedProject || !year || !filteredWeek) {
      setErrorMsg("Por favor, selecciona un proyecto, año y semana.");
      return;
    }

    try {
      setLoading(true);
      const data = {
        year,
        week: filteredWeek,
        projectId: selectedProject.id,
        user: authInfo?.id,
      };

      // Enviar el objeto `data` al endpoint
      const response = await createAdvance(data);
      setAdvanceData(response);
      setAdvanceCreated(true);
    } catch (error: any) {
      setErrorMsg(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "delete") {
      object = details?.details.find((p: any) => p.id === parseInt(id));
    }

    console.log(object);
    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleOpenHoursModal = (data: any) => {
    setModalData &&
      setModalData({
        modalOpen: true,
        modalType: "addHours",
        modalObject: data,
      });
  };

  const handleCloseHoursModal = () => {
    setModalData &&
      setModalData({ modalOpen: false, modalType: "", modalObject: null });
  };

  const handleDelete = async (data: any) => {
    handleCloseHoursModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteAdvanceRequest({
        ...data,
        advanceId: advanceData?.id ? advanceData?.id : id,
      });
      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg("Error al eliminar el registro, intente nuevamente");
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg("Registro eliminado correctamente");
      //reset page and call fetch data
      await fetchProjects();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  return (
    <>
      <FancyPaper
        pagetitle="Detalles de Avances"
        prevpage="Avances"
        linkprevpage="/dashboard/advances"
        showHome={authInfo?.userRole?.roleName === "Encargado" ? false : true}
      >
        <Grid
          container
          spacing={6}
          sx={{
            background: theme.palette.secondary.light,
            borderRadius: 2,
            padding: 4,
            marginBottom: 6,
            marginTop: 4,
            marginLeft: 4,
          }}
        >
          {/* Selector de Semana */}
          <Grid item xs={12} md={3} sx={{ marginBottom: 4 }}>
            <WeekFilter
              onWeekSelect={(week) => week !== null && setFilteredWeek(week)}
              dateRange={dateRange}
              setDateRange={setDateRange}
              year={year}
              setYear={setYear}
              projectId={selectedProject?.id || 0}
              initialStartDate={
                details?.header?.firstDayOfWeek
                  ? dayjs(details.header.firstDayOfWeek, "DD-MM-YYYY")
                  : null
              }
              initialEndDate={
                details?.header?.lastDayOfWeek
                  ? dayjs(details.header.lastDayOfWeek, "DD-MM-YYYY")
                  : null
              }
              disabled={advanceData?.id || id}
            />
          </Grid>

          {/* Selector de Proyecto y Avance */}
          <Grid
            item
            xs={12}
            md={3}
            sx={{ marginTop: 2, marginLeft: 40, marginRight: 25 }}
          >
            <Autocomplete
              disablePortal
              options={projects.map((project) => ({
                id: project.id,
                name: project.name,
                code: project.code,
                manager: project.manager,
                attendand: project.attendand,
              }))}
              sx={{ width: "100%" }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => `${option.code} - ${option.name}`}
              value={selectedProject}
              onChange={(event, newValue) => newValue && handleChange(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleccione un Proyecto"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              disabled={advanceData?.id || id}
            />
            <Grid sx={{ marginTop: 8 }}>
              <Typography variant="body2" fontWeight="bold">
                Avance:
              </Typography>
              <Typography>{selectedProject?.name}</Typography>
            </Grid>
          </Grid>

          {/* Encargado y Subencargado */}
          <Grid item xs={12} md={3} sx={{ marginBottom: 4 }}>
            <Typography variant="body2" fontWeight="bold">
              Encargado:
            </Typography>
            <Typography>{selectedProject?.manager}</Typography>
            <Grid sx={{ marginTop: 8 }}>
              <Typography variant="body2" fontWeight="bold">
                Subencargado:
              </Typography>
              <Typography>{selectedProject?.attendand}</Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Botón para crear avance */}
        {filteredWeek && selectedProject && !advanceCreated && !id && (
          <Grid container justifyContent="right" sx={{ mt: 4 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleCreateAdvance}
            >
              Crear Avance
            </Button>
          </Grid>
        )}

        {(advanceCreated || id) && (
          <Grid container justifyContent="right" sx={{ mt: 4 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => handleOpenHoursModal(dateRange)}
            >
              Agregar Horas
            </Button>
          </Grid>
        )}

        <Divider sx={{ my: 3 }} />

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">Nombre</TableCell>
                  <TableCell align="center">Cargo</TableCell>
                  <TableCell align="center">Monto por Hora</TableCell>
                  <TableCell align="center">J</TableCell>
                  <TableCell align="center">V</TableCell>
                  <TableCell align="center">S</TableCell>
                  <TableCell align="center">D</TableCell>
                  <TableCell align="center">L</TableCell>
                  <TableCell align="center">K</TableCell>
                  <TableCell align="center">M</TableCell>
                  <TableCell align="center">Total Horas</TableCell>
                  <ResourceAccess isCode={true} pathOrCode={"ADVANCES:READCOLUMNS"}>
                    <TableCell align="center">Total Horas Extra</TableCell>

                    <FancyTooltip
                      title="Refleja el salario bruto del colaborador (incluye horas extra)"
                      placement="top"
                    >
                      <TableCell align="center">Subtotal</TableCell>
                    </FancyTooltip>
                    <FancyTooltip
                      title="Refleja el salario bruto percibido por concepto de horas extra"
                      placement="top"
                    >
                      <TableCell align="center">Subtotal horas extra</TableCell>
                    </FancyTooltip>
                    <TableCell align="center">Deducción CCSS</TableCell>
                    <TableCell align="center">CCSS Horas Extra</TableCell>
                    <TableCell align="center">Créditos / Débitos</TableCell>
                    <TableCell align="center">Total a Pagar</TableCell>
                    <TableCell align="center">Total Horas Extra</TableCell>
                    <TableCell align="center">Pasajes</TableCell>
                  </ResourceAccess>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details?.details?.map((detail: any) => (
                  <TableRow key={detail.id}>
                    <TableCell align="center">
                      <span>
                        {details.overtimeWorkedHoursWarning &&
                          details.overtimeWorkedHoursWarning[detail.id] && (
                            <Tooltip
                              title={
                                <div>
                                  {details.overtimeWorkedHoursWarning[detail.id].map(
                                    (warning: any, index: any) => (
                                      <div key={index}>{warning}</div> // Muestra cada advertencia en una línea separada
                                    )
                                  )}
                                </div>
                              }
                              placement="top"
                              sx={{
                                marginLeft: "-20px",
                                marginRight: "4px",
                                transform: "translateY(4px)",
                              }}
                            >
                              <WarningIcon color="warning" />
                            </Tooltip>
                          )}
                        {detail.id}
                      </span>
                    </TableCell>
                    <TableCell align="center">{detail.name}</TableCell>
                    <TableCell align="center">{detail.position}</TableCell>
                    <TableCell align="center">
                      {formatNumber(detail.salaryPerHour)}
                    </TableCell>

                    {/* Días de la semana */}
                    <TableCell
                      align="center"
                      style={{
                        color: details?.holidays?.includes("thursday")
                          ? "green"
                          : "inherit",
                        fontWeight: details?.holidays?.includes("thursday")
                          ? "bold"
                          : "inherit",
                      }}
                    >
                      {detail.thursdayWorkedHours}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: details?.holidays?.includes("friday")
                          ? "green"
                          : "inherit",
                        fontWeight: details?.holidays?.includes("friday")
                          ? "bold"
                          : "inherit",
                      }}
                    >
                      {detail.fridayWorkedHours}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: details?.holidays?.includes("saturday")
                          ? "green"
                          : "inherit",
                        fontWeight: details?.holidays?.includes("saturday")
                          ? "bold"
                          : "inherit",
                      }}
                    >
                      {detail.saturdayWorkedHours}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: details?.holidays?.includes("sunday")
                          ? "green"
                          : "inherit",
                        fontWeight: details?.holidays?.includes("sunday")
                          ? "bold"
                          : "inherit",
                      }}
                    >
                      {detail.sundayWorkedHours}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: details?.holidays?.includes("monday")
                          ? "green"
                          : "inherit",
                        fontWeight: details?.holidays?.includes("monday")
                          ? "bold"
                          : "inherit",
                      }}
                    >
                      {detail.mondayWorkedHours}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: details?.holidays?.includes("tuesday")
                          ? "green"
                          : "inherit",
                        fontWeight: details?.holidays?.includes("tuesday")
                          ? "bold"
                          : "inherit",
                      }}
                    >
                      {detail.tuesdayWorkedHours}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: details?.holidays?.includes("wednesday")
                          ? "green"
                          : "inherit",
                        fontWeight: details?.holidays?.includes("wednesday")
                          ? "bold"
                          : "inherit",
                      }}
                    >
                      {detail.wednesdayWorkedHours}
                    </TableCell>

                    <TableCell align="center">{detail.totalHours}</TableCell>
                    <ResourceAccess isCode={true} pathOrCode={"ADVANCES:READCOLUMNS"}>
                      <TableCell align="center">
                        {detail.overtimeWorkedHours}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(detail.grossSalary)}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(detail.overtimeGrossSalaryAmount)}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(detail.ccssAmount)}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(detail.overtimeCcssAmount)}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(detail.creditDebitAmount)}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(detail.netSalary)}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(detail.overtimeNetSalaryAmount)}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(detail.transportationPaymentAmount)}
                      </TableCell>
                    </ResourceAccess>
                    <TableCell align="center">
                      <FancyTooltip title="Eliminar" placement="top">
                        <IconButton
                          aria-label="trash"
                          color="error"
                          data-id={detail.id}
                          data-name="delete"
                          onClick={handleOpenModal}
                        >
                          <TrashIcon />
                        </IconButton>
                      </FancyTooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      </FancyPaper>
      {modalData?.modalType === "addHours" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCloseHoursModal}
          maxWidth="lg"
          fullWidth
        >
          <AddOrUpdateCoworkerModal
            data={{
              modalObject: modalData,
              advanceData: advanceData,
              advanceId: id,
              year: year,
              week: filteredWeek,
            }}
            cancelModal={handleCloseHoursModal}
            coworkersData={coworkersData}
            getData={fetchProjects}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCloseHoursModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.code}
            actionButton={
              <>
                <FancyDialogActions
                  handleAccept={handleSubmit(onSubmit)}
                  cancelModal={handleCloseHoursModal}
                  textAcceptButton={"Eliminar"}
                />
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default DetailsAdvancesAdd;
