import React, { useEffect, useRef, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  IconButton,
  styled,
  Autocomplete,
} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FancyDialogActions from "./FancyDialogActions";
import FancyTooltip from "./FancyTooltip";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { getRequestProductsPerProject } from "../../services/exitTicket/ExitTicketService";
import { useApp } from "../../hooks/useApp";

interface productsTypes {
  id?: number;
  description: string;
  quantity: number;
  availableQuantity: number;
}

interface AddOrUpdateExitTicketTypes {
  date: string;
  person: {
    id?: number;
    name: string;
  };
  project: {
    id?: number;
    name: string;
  };
  projectTransfer: {
    id?: number;
    name: string;
  };
  notes: string;
  ticketNumber: string;
  status: {
    id?: number;
    name: string;
  };
  items: {
    // productCost: number;
    product: productsTypes;
    /*  product: {
      id?: number;
      description: string;
      quantity: number;
    }; */
    quantity: number;
  }[];
  movement_type: {
    id: number;
    name: string;
  };
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType: string;
  roleData?: any;
  handleActiveUser?: any;
  refreshData?: any;
  typeMovement?: any;
  projects?: any;
  products?: any;
  user?: any;
}

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

const AddOrUpdateExitTicketModal = ({
  actionButton,
  data,
  roleData,
  onSubmit,
  cancelModal,
  modalType,
  refreshData,
  typeMovement,
  projects,
  products,
  user,
}: DialogProps) => {
  const { setErrorMsg } = useApp();
  const validation = {
    id: Yup.number().required("Campo es requerido"),
    name: Yup.string().required("Campo es requerido"),
  };

  const validationSchema = Yup.object().shape({
    date: Yup.string().required("Campo requerido"),
    person: Yup.object()
      .required("Campo es requerido")
      .shape(validation)
      .typeError("Campo es requerido"),
    project: Yup.object()
      .required("Campo es requerido")
      .shape(validation)
      .typeError("Campo es requerido"),
    movement_type: Yup.object()
      .required("Campo es requerido")
      .shape(validation)
      .typeError("Campo es requerido"),
    status: Yup.object()
      .required("Campo es requerido")
      .shape(validation)
      .typeError("Campo es requerido"),
    items: Yup.array().of(
      Yup.object().shape({
        product: Yup.object()
          .shape({
            id: Yup.number().required("Campo es requerido"),
            description: Yup.string().required("Campo es requerido"),
          })
          .required("Campo es requerido"),
        quantity: Yup.number()
          .typeError("Campo es requerido")
          .required("Campo es requerido")
          .min(1, "Debe ser al menos 1")
          .test(
            "max-quantity",
            "No puede ser mayor a la cantidad disponible",
            function (value) {
              const { product } = this.parent;

              // Buscar el producto en la lista de productos para obtener availableQuantity
              const selectedProduct = products.find((p: any) => p.id === product.id);
              const availableQuantity = selectedProduct ? selectedProduct.availableQuantity : 0;

              if (movement_type?.name === "Cambio de Proyecto") {
                return value <= (initialQuantities[product.id] ?? 0);
              } else if (
                movement_type?.name === "Salida de Bodega" ||
                movement_type?.name === "Salida a Taller Servicio"
              ) {
                return value <= availableQuantity;
              }
              return true;
            }
          ),
      })
    ),
  });

  console.log(data)

  const defaultValues = {
    id: data?.id ?? 0,
    date: data?.date ?? new Date().toISOString().split("T")[0],
    person: data?.person
      ? user?.find((f: any) => f.id === data?.person.id) ?? []
      : [],
    project: data?.project
      ? projects?.find((f: any) => f.id === data?.project.id) ?? []
      : [],
    projectTransfer: data?.ticketProjectTransfer
      ? projects?.find(
        (f: any) => f.id === data?.ticketProjectTransfer?.projectId
      ) ?? []
      : [],
    notes: data?.notes ?? "",
    status:
      data?.status === false
        ? { id: 0, name: "Pendiente" }
        : data?.status === true
          ? { id: 1, name: "Finalizada" }
          : {},
    ticketNumber: data?.ticketNumber ?? "",
    items: data?.ticketActivities?.length
      ? data.ticketActivities.map((ticket: any) => ({
        productCost: ticket.productCost ?? 0,
        product: ticket.product ?? { id: 0, description: "" },
        quantity: ticket.quantity ?? 0,
      }))
      : null,
    movement_type: data?.activityType
      ? typeMovement?.find((f: any) => f.id === data?.activityType.id) ?? []
      : { id: 0, name: "" },
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<AddOrUpdateExitTicketTypes>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const addItem = () => {
    append({
      product: { id: 0, description: "", quantity: 0, availableQuantity: 0 },
      quantity: 0,
    });

    setTimeout(() => {
      const lastIndex = fields.length; // Último índice después de agregar
      inputRefs.current[lastIndex]?.focus(); // Enfocar en el nuevo insumo
    }, 100);
  };

  const projectTransfer = watch("projectTransfer");
  const movement_type = watch("movement_type");
  const products_watch = watch("items");
  const [initialQuantities, setInitialQuantities] = useState<
    Record<number, number>
  >({});
  const [previousMovementType, setPreviousMovementType] = useState<
    string | null
  >(movement_type?.name || null);

  const [selectedProducts, setSelectedProducts] = useState<any>(
    data?.ticketActivities?.map((ticket: any) => ticket.product) ?? []
  );

  const [inputs, setInputs] = useState([{ name: "", quantity: "" }]);

  const handleProductChange = (index: any, newValue: any) => {
    const newSelectedProducts: any = [...selectedProducts];
    newSelectedProducts[index] = newValue;
    setSelectedProducts(newSelectedProducts);
    setValue(`items.${index}.quantity`, 0);
  };

  const handleRemoveItem = (index: number) => {
    setSelectedProducts((prev: any) => {
      const updatedProducts = [...prev];
      updatedProducts.splice(index, 1);
      return updatedProducts;
    });

    remove(index);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent,
    index: number,
    value: number
  ) => {
    if (event.key === "Enter") {
      addItem();
    }

    if (
      Number(products_watch[index].quantity) >
      products_watch[index].product.availableQuantity
    ) {
      setError(`items.${index}.quantity`, {
        type: "typeError",
        //message: `Valor no puede ser mayor `,
        message: `Valor no puede ser mayor a la cantidad disponible ${products_watch[index].product?.availableQuantity}`,
      });
    } else {
      clearErrors(`items.${index}.quantity`);
    }


    /* else {
      event.preventDefault();
    } */
  };

  useEffect(() => {
    const dataInit = async () => {
      try {
        // Caso: Cuando el tipo de movimiento es "Cambio de Proyecto" y se ha cambiado de tipo
        if (
          modalType === "update" &&
          movement_type?.name === "Cambio de Proyecto" &&
          previousMovementType !== "Cambio de Proyecto" &&
          projectTransfer?.id
        ) {
          await handleFetchData();
        }

        // Caso: Creación con "Cambio de Proyecto"
        else if (
          modalType === "create" &&
          movement_type?.name === "Cambio de Proyecto" &&
          projectTransfer?.id
        ) {
          await handleFetchData();
        }

        // Caso: Cambio desde "Cambio de Proyecto" a otro movimiento
        else if (
          previousMovementType === "Cambio de Proyecto" &&
          (!movement_type || movement_type?.name !== "Cambio de Proyecto")
        ) {
          setValue("items", []);
          setSelectedProducts([]);
          setValue("projectTransfer", { id: 0, name: "" });
        }

        // Actualiza el tipo de movimiento previo al final, solo cuando ambos valores han cambiado
        if (movement_type?.name !== previousMovementType) {
          setPreviousMovementType(movement_type?.name || null);
        }
      } catch (error) {
        console.error("Error en dataInit:", error);
      }
    };

    if (projectTransfer?.id && movement_type?.name) {
      dataInit();
    }

    // eslint-disable-next-line
  }, [projectTransfer, movement_type, modalType]);

  const handleFetchData = async () => {
    try {
      const data: any = await getRequestProductsPerProject(
        projectTransfer?.id ?? 0
      );

      if (data) {
        const transformedData = data.map((item: any) => ({
          product: {
            id: item.productId,
            description: item.productName,
            code: item.productCode,
          },
          quantity: item.totalQuantity,
          cost: item.productCost,
        }));

        // Guardar las cantidades iniciales por producto
        const quantitiesMap: Record<number, number> = {};
        data.forEach((item: any) => {
          quantitiesMap[item.productId] = item.totalQuantity;
        });
        console.log("quantitiesMap: ", quantitiesMap);
        setInitialQuantities(quantitiesMap);

        const transformedSelectData = data.map((item: any) => ({
          id: item.productId,
          description: item.productName,
        }));

        setSelectedProducts(transformedSelectData);

        transformedData.forEach((product: any) => append(product));
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  /* const handleValidate = async () => { 
    products_watch.map((item1: any, index: number) => {
      let count = 0;
      console.log("count: ", count);
      const item2 = products.find(
        (item2: any) => item1.product.id === item2.id
      );
      if (item2 && item1.quantity > item2.quantity) {
        count++;
        setError(`items.${index}.quantity`, {
          type: "custom",
          message: "Valor no puede ser mayor al disponibleeee",
        });
        
          //error: `Quantity in array1 (${item1.quantity}) exceeds quantity in array2 (${item2.quantity})`
        
      }
      if (count > 0) {
        console.log("se ejecuta handleSubmit(onSubmit)");
        //handleSubmit(onSubmit)
      }

      //return item1;
    });
  }; */

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create"
          ? "Crear Boleta de Salida"
          : "Actualizar Boleta de Salida"}
      </DialogTitle>

      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={modalType === "create" ? 6 : 4}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Fecha"
                    type="date"
                    disabled={data?.status === true}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors.date)}
                    helperText={errors.date?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            {modalType === "update" && (
              <Grid item xs={4}>
                <Controller
                  name="ticketNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="N° Boleta"
                      disabled={data?.status === true}
                      type="text"
                      InputProps={{ readOnly: true }}
                      {...field}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={modalType === "create" ? 6 : 4}>
              <Controller
                name={"status"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      console.log(newValue);
                      onChange(newValue || null);
                    }}
                    options={[
                      { id: 0, name: "Pendiente" },
                      { id: 1, name: "Finalizado" },
                    ]}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    disabled={data?.status === true}
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Estado"
                          variant="outlined"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.status}
                          helperText={
                            (errors.status && errors.status.name?.message) ||
                            errors.status?.message
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={modalType === "create" ? 6 : 4}>
              <Controller
                name={"person"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={user || []}
                    getOptionLabel={(option: any) =>
                      `${option.name || ""} ${option.lastName || ""} ${option.surname || ""
                        }`.trim()
                    }
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value?.id
                    }
                    disabled={data?.status === true}
                    value={value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Se entrega a"
                        variant="outlined"
                        sx={{ "& input": { pl: "0!important" } }}
                        error={!!errors.person}
                        helperText={errors.person && errors.person.message}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={modalType === "create" ? 6 : 4}>
              <Controller
                name={"project"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={projects !== null ? projects : {}}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    disabled={data?.status === true}
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label={
                            movement_type?.name === "Cambio de Proyecto"
                              ? "Proyecto destino"
                              : "Proyecto"
                          }
                          variant="outlined"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.project}
                          helperText={errors.project && errors.project.message}
                          InputLabelProps={{ shrink: true }}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>

            {movement_type?.name === "Cambio de Proyecto" && (
              <Grid item xs={modalType === "create" ? 6 : 4}>
                <Controller
                  name={"projectTransfer"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={projects !== null ? projects : {}}
                      getOptionLabel={(option: any) => option.name || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      disabled={data?.status === true}
                      value={value}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Proyecto actual"
                            variant="outlined"
                            sx={{ "& input": { pl: "0!important" } }}
                            error={!!errors.project}
                            helperText={
                              errors.project && errors.project.message
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={modalType === "create" ? 6 : 4}>
              <Controller
                name={"movement_type"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={typeMovement !== null ? typeMovement : {}}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    disabled={data?.status === true}
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de movimiento"
                          variant="outlined"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.movement_type}
                          helperText={
                            (errors.movement_type &&
                              errors.movement_type.name?.message) ||
                            errors.movement_type?.message
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} /* alignItems="center" */>
                <Grid item xs={12}>
                  <strong>Insumos / Herramientas</strong>
                </Grid>

                {fields.map((item, index: number) => (
                  <React.Fragment key={item.id}>
                    <Grid item xs={8}>
                      <Controller
                        name={`items.${index}.product`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, newValue) => {
                              onChange(newValue);
                              handleProductChange(index, newValue);
                            }}
                            options={
                              products !== null
                                ? products.filter(
                                  (product: any) =>
                                    !selectedProducts.some(
                                      (selected: any) =>
                                        selected?.id === product.id
                                    )
                                )
                                : []
                            }
                            getOptionLabel={(option: any) =>
                              option.code && option.description
                                ? `${option.code} - ${option.description}`
                                : ""
                            }
                            isOptionEqualToValue={(option: any, value: any) =>
                              option.id === value.id
                            }
                            disabled={
                              data?.status === true ||
                              movement_type?.name === "Cambio de Proyecto"
                            }
                            value={value}
                            renderInput={(params) => {
                              const inputProps = params.inputProps;
                              inputProps.autoComplete = "new-password";
                              return (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Insumo / Herramienta"
                                  variant="outlined"
                                  sx={{ "& input": { pl: "0!important" } }}
                                  error={Boolean(
                                    errors.items?.[index]?.product?.description
                                  )}
                                  helperText={
                                    errors.items?.[index]?.product?.description
                                      ?.message
                                  }
                                  InputLabelProps={{ shrink: true }}
                                  inputRef={(el) => (inputRefs.current[index] = el)}
                                />
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{ display: "flex", alignItems: "flex-start" }}
                    >
                      <Controller
                        name={`items.${index}.quantity`}
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            fullWidth
                            size="small"
                            label={"Cantidad"}
                            disabled={data?.status === true}
                            type="number"
                            variant="outlined"
                            onKeyDown={(e: any) =>
                              handleKeyDown(e, index, e.target.value)
                            }
                            value={value}
                            {...register(`items.${index}.quantity` as const, {
                              max:
                                movement_type?.name === "Salida de Bodega" ||
                                  movement_type?.name ===
                                  "Salida a Taller Servicio"
                                  ? products_watch[index].product
                                    ?.availableQuantity
                                  : Number(
                                    products_watch[index].product?.quantity
                                  ),
                              validate: {
                                positive: (v) =>
                                  Number(v) <
                                  products_watch[index].product
                                    ?.availableQuantity,
                              },
                            })}
                            error={Boolean(errors.items?.[index]?.quantity)}
                            helperText={
                              errors.items?.[index]?.quantity?.message
                            }
                            inputProps={{
                              min: 0,
                              max:
                                movement_type?.name === "Salida de Bodega" ||
                                  movement_type?.name ===
                                  "Salida a Taller Servicio"
                                  ? products_watch[index].product
                                    ?.availableQuantity
                                  : products_watch[index].product?.quantity,
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />

                      <IconButton
                        color="error"
                        onClick={() => handleRemoveItem(index)}
                        disabled={data?.status === true}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ))}

                <Grid item xs={2}>
                  <FancyTooltip title={"Agregar insumo"} placement="top">
                    <IconButton
                      color="primary"
                      onClick={addItem}
                      disabled={
                        data?.status === true ||
                        movement_type?.name === "Cambio de Proyecto"
                      }
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </FancyTooltip>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name={"notes"}
                    control={control}
                    render={({ field: { value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Notas"
                        multiline
                        maxRows={4}
                        disabled={data?.status === true}
                        value={value}
                        variant="outlined"
                        {...register("notes")}
                        sx={{ background: "#FFF" }}
                        error={errors.notes && Boolean(errors.notes)}
                        helperText={errors.notes && errors.notes.message}
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdateExitTicketModal;
