import { CheckCircleOutline } from "@mui/icons-material";
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useApp } from "../../hooks/useApp";
import { Content } from "../../services/authority/AuthorityInterfaces";
import { CircleCheckIcon } from "../svgicons/SvgIcons";
import FancyTablePagination from "./FancyTablePagination";
import FancyTooltip from "./FancyTooltip";
import FancySearch from "./FancySearch";
import FancyDialogActions from "./FancyDialogActions";

interface DialogProps {
  data: any;
  cancelModal: any;
  onSubmit: any;
  refresh: any;
  authorityDataProp: Content[] | null;
}

const AddAuthorityByRole = ({
  cancelModal,
  onSubmit,
  data,
  authorityDataProp,
}: DialogProps) => {
  const theme = useTheme();
  const { setErrorMsg } = useApp();
  const [customLoading, setCustomLoading] = useState<boolean>();
  const [authorityData, setAuthorityData] = useState<Content[] | null>(
    authorityDataProp
  );
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);
  const [authoritySelected, setAuthoritySelected] = useState<number[]>([]);

  useEffect(() => {
    if (authorityDataProp) {
      setAuthorityData(authorityDataProp);
    }
  }, [authorityDataProp]);

  const handleFetchData = async (
    roleId: number,
    currentPage: number,
    size: number,
    filter: string
  ) => {
    setCustomLoading(true);
    try {
      const filteredData = authorityDataProp
        ? authorityDataProp.filter(
            (item) =>
              item.description.toLowerCase().includes(filter.toLowerCase()) ||
              item.code.toLowerCase().includes(filter.toLowerCase()) ||
              item.id.toString().toLowerCase().includes(filter.toLowerCase())
          )
        : [];
      setAuthorityData(filteredData);
      setPage(currentPage);
      setCustomLoading(false);
    } catch (error: any) {
      setCustomLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(data, 0, 100, preFilter);
    }
  };

  const selectAuthority = async (id: number) => {
    if (authoritySelected.includes(id)) {
      setAuthoritySelected(authoritySelected.filter((i) => i !== id));
    } else {
      setAuthoritySelected([...authoritySelected, id]);
    }
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {"Agregar permiso"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingTop={4}
          paddingBottom={4}
        >
          <FancySearch
            preFilter={preFilter}
            setPreFilter={setPreFilter}
            handleFetchData={() => handleFetchData(data, 0, 100, "")}
            handleApplyFilter={handleApplyFilter}
          />
          <Stack
            flexDirection="row"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: 500 }}>
              {"Seleccionar todo"} / {"Limpiar selección"}
            </Typography>
            <Checkbox
              checked={authoritySelected.length === authorityData?.length}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: "1.5rem",
                },
              }}
              onChange={() => {
                if (authoritySelected.length === authorityData?.length) {
                  setAuthoritySelected([]);
                } else {
                  setAuthoritySelected(
                    authorityData?.map((a: Content) => a.id) || []
                  );
                }
              }}
            />
          </Stack>
        </Stack>

        <Divider sx={{ mb: 4 }} />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>{"Códigos"}</TableCell>
                <TableCell align="center">{"Descripción"}</TableCell>
                <TableCell align="center">{"Acciones"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!customLoading &&
                authorityData &&
                authorityData.length > 0 &&
                authorityData.slice(page * 5, page * 5 + 5).map((r) => (
                  <TableRow key={r.id}>
                    <TableCell scope="row">{r.id}</TableCell>
                    <TableCell>{r.code}</TableCell>
                    <TableCell>{r.description}</TableCell>
                    <TableCell align="center">
                      <FancyTooltip title="Agregar permiso" placement="top">
                        <IconButton
                          aria-label="create"
                          component="label"
                          color="secondary"
                          sx={{
                            "&:hover": {
                              color: theme.palette.secondary.dark,
                            },
                          }}
                          // onClick={onSubmit}
                          onClick={() => {
                            selectAuthority(r.id);
                          }}
                          data-name="create"
                          data-id={r.id}
                        >
                          {authoritySelected.includes(r.id) ? (
                            <CircleCheckIcon sx={{ pointerEvents: "none" }} />
                          ) : (
                            <CheckCircleOutline />
                          )}
                        </IconButton>
                      </FancyTooltip>
                    </TableCell>
                  </TableRow>
                ))}
              {!authorityDataProp ||
                !authorityDataProp ||
                (authorityDataProp?.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      {"No hay registros"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={authorityData ? authorityData.length : 0}
          rowsPerPage={5}
          page={page}
          onPageChange={handleChangePage}
          totalElements={authorityData?.length}
          totalPages={authorityData ? Math.ceil(authorityData.length / 5) : 0}
        />
      </DialogContent>
      <FancyDialogActions
        handleAccept={() => {
          onSubmit(authoritySelected);
          cancelModal();
          handleFetchData(data, 0, 100, "");
        }}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddAuthorityByRole;
