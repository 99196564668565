import { addDays, format, parse, parseISO } from "date-fns";
import { es } from "date-fns/locale";
export const formatNumber = (value: number): string => {
    if (isNaN(value)) {
        return '₡0,00'; // Si no es un número, devuelve ₡0,00
    }

    // Verifica si el número es negativo
    const isNegative = value < 0;

    // Formatea el valor absoluto del número
    const formattedValue = new Intl.NumberFormat('es-CR', {
        style: 'currency',
        currency: 'CRC',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(Math.abs(value)); // Usamos Math.abs para evitar el signo negativo en el formato

    // Reemplaza los espacios con puntos (según tu requerimiento)
    const valueWithDots = formattedValue.replace(/\s/g, '.');

    // Si el número es negativo, colocamos el signo negativo después del símbolo de la moneda
    return isNegative ? `₡-${valueWithDots.replace('₡', '')}` : valueWithDots;
};

export const formatDateRange = (dateRange: string): string => {
    if (!dateRange) return "Fecha inválida";

    // Dividir el rango de fechas
    const [startDate, endDate] = dateRange.split(" / ");

    // Parsear las fechas en formato ISO
    const start = parseISO(startDate);
    const end = parseISO(endDate);

    // Validar que las fechas sean válidas
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        return "Fecha inválida";
    }

    // Formatear las fechas
    const startDay = format(start, "d", { locale: es });
    const endDay = format(end, "d", { locale: es });
    const month = format(end, "MMMM", { locale: es });
    const year = format(start, "yyyy", { locale: es });

    return `del ${startDay} al ${endDay} de ${month} del ${year}`;
};

export const parseDateRange = (dateRange: string): { startDate: Date; endDate: Date } | null => {
    if (!dateRange) return null;

    const [start, end] = dateRange.split(" - ").map((date) => new Date(date.trim()));
    if (isNaN(start.getTime()) || isNaN(end.getTime())) return null;

    return { startDate: start, endDate: end };
};

export const getDatesInRange = (startDateStr: string, endDateStr: string) => {
    const startDate = parse(startDateStr, 'yyyy-MM-dd', new Date());
    const endDate = parse(endDateStr, 'yyyy-MM-dd', new Date());
    const dates = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
        dates.push(format(currentDate, 'yyyy-MM-dd'));
        currentDate = addDays(currentDate, 1);
    }

    return dates;
};

export const formatDateForBackend = (date: Date): string => {
    return format(date, 'dd-MM-yyyy');
};

function utcToZonedTime(arg0: Date, arg1: string) {
    throw new Error("Function not implemented.");
}
