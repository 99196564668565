import { Outlet } from "react-router-dom";
import { useApp } from "../../hooks/useApp";
import MessageManager from "../../components/MessageManager";
import Loading from "../../components/Loading";
import { CONSTANT } from "../../utils/UrlContants";

const Auth = () => {
  const { errorMsg, successMsg, resetErrorMsg, resetSuccessMsg, isLoading } =
    useApp();

  return (
    <>
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      {isLoading && <Loading />}
      {/* <Content 
      // justifyContent="center" 
      // alignItems="center" 
      // spacing={2}
      > */}
      {/* <Box>
          <Logo /> 
           <div>
            <img src={LogoMR} alt="Logo" style={{ width: '300px', height: 'auto' }} /> 
          </div>
        </Box> */}
      {/* <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            //width: "430px",
            maxWidth: "100%",
            position: "relative",
            mx: "auto",
            mb: `${theme.spacing(8)}!important`,
          }}
        > */}
      {/* <Paper
            sx={{
              background: "#FFFFFF",
              py: 8,
              px: 8,
              //boxShadow: "none",
            }}
          > */}
      <Outlet />
      {/* </Paper> */}
      {/* </Stack> */}
      {/* <Footer /> */}
      {/* </Content> */}
    </>
  );
};

export default Auth;
