import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { SubFamilyResponse } from "./SubFamilyInterface";

export const getRequest = async (
  company: string
): Promise<SubFamilyResponse> => {
  try {
    const response = await privateFetch.get(
      URL.REQUEST_FAMILY(company, UrlBase.registration_x_page)
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestList = async (
  familyId: number,
  filter: string,
  page: number,
  size?: number
) => {
  let responseData = {};
  await privateFetch
    .get(
      URL.REQUEST_SUBFAMILY_LIST(
        familyId,
        filter,
        page,
        //@ts-ignore
        size ? size : UrlBase.registration_x_page
      )
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en la consulta de SubFamilias " + error);
    });
  return responseData;
};

export const getRequestSubFamily = async () => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_SUBFAMILY2(null))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      //throw new Error(properties.com_parval_label_role_find_error);
      throw new Error(error);
    });
  return responseData;
};

export const findByIdRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_SUBFAMILY(id, UrlBase.registration_x_page), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const findByIdRequestById = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_SUBFAMILY_BY_ID(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const createRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    user: data.user,
    name: data.name,
    code: data.code,
    description: data.description,
    idFamily: data.idFamily,
  };

  console.log("Data agregar ", requestBody);

  await privateFetch

    .post(URL.REQUEST_SUBFAMILY_CREATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_spage_label_families_button_add_error);
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    user: data.user,
    id: data.id,
    name: data.name,
    code: data.code,
    description: data.description,
    idFamily: data.idFamily,
  };
  await privateFetch

    .put(URL.REQUEST_SUBFAMILY_UPDATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_spage_label_families_button_update_error);
    });
  return responseData;
};

export const deleteRequest = async (id: number) => {
  try {
    const requestBody = {
      id: id,
    };
    const response = await privateFetch.delete(
      URL.REQUEST_SUBFAMILY_DELETE(id)
    );
    return response.data;
  } catch (error) {
    console.error("Error eliminando la SubFamilia:", error);
    throw new Error("Error eliminando la SubFamilia");
  }
};
